import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Title = styled(Typography)<{ index: number }>`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => (props.index === 0 ? 0 : props.theme.spacing(4))}
    ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;
