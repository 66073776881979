import { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';

import { Sidebar } from './Sidebar';
import { Main } from './styles';

export enum SidebarSize {
  sm = 'sm',
  md = 'md',
}

export const SidebarWidth: Record<SidebarSize, number> = {
  [SidebarSize.sm]: 280,
  [SidebarSize.md]: 350,
};

type Props = PropsWithChildren<{
  sidebarProps: {
    size?: SidebarSize;
    open: boolean;
    onClose: () => void;
    title: ReactNode;
    content: ReactNode;
  };
}>;

export const SidebarLayout: FC<Props> = ({
  sidebarProps: {
    open,
    title,
    onClose,
    content: sidebarContent,
    size = SidebarSize.sm,
  },
  children,
}) => {
  const width = useMemo(() => SidebarWidth[size], [size]);
  return (
    <Fragment>
      <Main open={open} width={width}>
        {children}
      </Main>
      <Sidebar width={width} open={open} title={title} onClose={onClose}>
        {sidebarContent}
      </Sidebar>
    </Fragment>
  );
};
