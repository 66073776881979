import { FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import { Field } from 'formik';
import { RadioGroup } from 'formik-mui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShopStatus } from 'enums';
import { TranslationNamespace } from 'i18n';
import { shopUtils } from 'utils';

export const ShopStatusSelect: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage.fields',
  });
  const tKeyTitle = useMemo(() => t('status'), [t]);

  return (
    <FormControl>
      <FormLabel>{tKeyTitle}</FormLabel>
      <Field component={RadioGroup} name="status" row>
        {Object.values(ShopStatus).map((status) => (
          <FormControlLabel
            key={status}
            value={status}
            control={<Radio />}
            label={shopUtils.getShopStatusLabel(status)}
          />
        ))}
      </Field>
    </FormControl>
  );
};
