import { Tab } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { tradeMethodsApi } from 'api';
import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { QueryKey } from 'enums';
import { useQueryTab } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethodPayoutPDFTemplate, TradeMethodWithRelations } from 'types';

import {
  PayoutPDFTemplates,
  PayoutPDFtemplateDetailsDialog,
} from './PayoutPDFTemplates';

enum TradeMethodFilesValidationTab {
  PayoutPDFTemplates = 'payout-pdf-templates',
}

export const TradeMethodFilesValidationPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.trade_methods.files_validation',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { id: tradeMethodId = '' } = useParams<{ id: string }>();
  const { tab } = useQueryTab();
  const [
    payoutPDFtemplateDetailsDialogOpen,
    setPayoutPDFtemplateDetailsDialogOpen,
  ] = useState(false);

  const tradeMethodQuery = useQuery<TradeMethodWithRelations>(
    [QueryKey.TradeMethods, tradeMethodId],
    () => tradeMethodsApi.getOne(tradeMethodId!),
  );

  const subtitle = useMemo(() => {
    if (!tradeMethodQuery.data) {
      return;
    }

    const { bank, paymentType, fiatCurrency } = tradeMethodQuery.data;
    const paymentName = tCommon(
      `features.requisites.types.${paymentType.code}`,
      paymentType.name,
    );
    return `${bank.name} - ${paymentName} - ${fiatCurrency.code}`;
  }, [tradeMethodQuery.data, tCommon]);

  const renderRightContentButton = useCallback(() => {
    if (tab === TradeMethodFilesValidationTab.PayoutPDFTemplates) {
      return {
        onClick: () => setPayoutPDFtemplateDetailsDialogOpen(true),
      };
    }
  }, [tab]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        subtitle={subtitle}
        rightContentButton={renderRightContentButton()}
      />
      <QueryTabs tabsEnum={TradeMethodFilesValidationTab}>
        <Tab
          value={TradeMethodFilesValidationTab.PayoutPDFTemplates}
          label={t('payout_pdf_templates.title')}
        />
      </QueryTabs>
      <QueryTabPanel value={TradeMethodFilesValidationTab.PayoutPDFTemplates}>
        <PayoutPDFTemplates tradeMethodId={tradeMethodId} />
      </QueryTabPanel>
      <PayoutPDFtemplateDetailsDialog
        open={payoutPDFtemplateDetailsDialogOpen}
        data={{ tradeMethodId } as TradeMethodPayoutPDFTemplate}
        onClose={() => setPayoutPDFtemplateDetailsDialogOpen(false)}
      />
    </Fragment>
  );
};
