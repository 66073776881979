import { Alert, Divider, Stack } from '@mui/material';
import { compact, find, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StylizedNumber,
  DescriptionRow,
  Money,
  ShopAgents,
  CopyText,
} from 'components';
import { ShopStatus } from 'enums';
import { useCurrencies, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Shop, User } from 'types';
import { formatUtils, shopUtils } from 'utils';

type Props = {
  shop: Shop | null;
  users?: User[];
  adminView: boolean;
};

export const ShopDetails: React.FC<Props> = ({
  shop,
  users,
  adminView,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shop_details',
  });

  const { getAssetCurrencyCode } = useCurrencies();
  const { p2pProviders } = useUserContext();
  const { isAdminOrTechOperator } = useUser();

  const showStatusHelper = useMemo(
    () => !adminView && shop?.status === ShopStatus.Inactive,
    [adminView, shop?.status],
  );

  const shopP2PProviders = useMemo(
    () =>
      compact(
        map(shop?.p2pProvidersConfigs, (config) =>
          find(p2pProviders, ({ id }) => id === config.p2pProviderId),
        ),
      ),
    [shop?.p2pProvidersConfigs, p2pProviders],
  );

  if (!shop) {
    return null;
  }

  return (
    <Stack direction="column" spacing={6}>
      <Stack direction="column" spacing={3}>
        {showStatusHelper && (
          <Alert severity="info">{t('helper_messages.status')}</Alert>
        )}

        <Divider textAlign="left">{t('titles.basic')}</Divider>

        <DescriptionRow title={t('rows.id')} value={shop.id} canCopy />
        {adminView && (
          <DescriptionRow
            title={t('rows.merchant_name')}
            value={shop.user?.name}
          />
        )}
        <DescriptionRow
          title={t('rows.website_url')}
          value={shop.webSiteUrl}
          canCopy
        />
        <DescriptionRow title={t('rows.website_ip')} value={shop.webSiteIp} />
        <DescriptionRow
          title={t('rows.currency')}
          value={getAssetCurrencyCode(shop.assetCurrencyId)}
        />
        <DescriptionRow
          title={t('rows.commission')}
          value={
            <Stack direction="row" spacing={1}>
              <StylizedNumber value={shop?.commission} unit="%" />
              <span>{'/'}</span>
              <StylizedNumber value={shop?.payoutCommission} unit="%" />
            </Stack>
          }
          description={t('helper_messages.commission')}
        />
        {adminView && (
          <DescriptionRow
            title={t('rows.payin_trader_spread_compensation')}
            value={formatUtils.formatBoolean(
              shop.payinTraderSpreadCompensation,
            )}
          />
        )}
        <DescriptionRow
          title={t('rows.payin_order_amount_limits')}
          value={
            <Stack direction="row" spacing={1}>
              {shop.payinOrderMinAmount ? (
                <Money
                  value={shop.payinOrderMinAmount}
                  fiatCurrencyId={shop.fiatCurrencyId}
                  symbol
                />
              ) : (
                '-'
              )}
              <span>{'/'}</span>
              {shop.payinOrderMaxAmount ? (
                <Money
                  value={shop.payinOrderMaxAmount}
                  fiatCurrencyId={shop.fiatCurrencyId}
                  symbol
                />
              ) : (
                '-'
              )}
            </Stack>
          }
        />
        <DescriptionRow
          title={t('rows.payout_order_amount_limits')}
          value={
            <Stack direction="row" spacing={1}>
              {shop.payoutOrderMinAmount ? (
                <Money
                  value={shop.payoutOrderMinAmount || 0}
                  fiatCurrencyId={shop.fiatCurrencyId}
                  symbol
                />
              ) : (
                '-'
              )}
              <span>{'/'}</span>
              {shop.payoutOrderMaxAmount ? (
                <Money
                  value={shop.payoutOrderMaxAmount}
                  fiatCurrencyId={shop.fiatCurrencyId}
                  symbol
                />
              ) : (
                '-'
              )}
            </Stack>
          }
        />
        {adminView && shop.customerDataCollectionOrder && (
          <DescriptionRow
            title={t('rows.customer_data_collection_order')}
            value={shopUtils.getCustomerDataCollectionOrder(
              shop.customerDataCollectionOrder,
            )}
          />
        )}
        {adminView && <ShopAgents shop={shop} users={users} />}
        <DescriptionRow
          title={t('rows.collect_customer_receipts')}
          value={formatUtils.formatBoolean(shop.collectCustomerReceipts)}
        />
      </Stack>

      <Stack direction="column" spacing={3}>
        <Divider textAlign="left">{t('titles.additional')}</Divider>

        <DescriptionRow title={t('rows.api_key')} value={shop.apiKey} canCopy />
        <DescriptionRow
          title={t('rows.signature_key')}
          value={shop.signatureKey}
          canCopy
        />
        <DescriptionRow
          title={t('rows.assets_api_key')}
          value={shop.assetsApiKey}
          canCopy
        />
      </Stack>

      {isAdminOrTechOperator && !isEmpty(shopP2PProviders) && (
        <Stack direction="column" spacing={3}>
          <Divider textAlign="left">{t('titles.p2p_providers')}</Divider>

          {map(shopP2PProviders, (provider) => (
            <Stack direction="column" spacing={0} key={provider.id}>
              <CopyText text={provider.id} />
              <div>{provider.name}</div>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
