import React, { useMemo } from 'react';

import { FormControls } from 'components';
import { FormField } from 'types';

import { fieldsList } from './common';

type Props = {
  fields?: FormField[];
  disabled?: boolean;
};

export const FormFields: React.FC<Props> = ({ fields = [], disabled }) => {
  const currentFields = useMemo(
    () =>
      fields.map((field) => {
        const { type, required, name, readonly } = field;
        const { Field } = fieldsList[type];
        return (
          <Field
            key={name}
            name={name}
            required={required}
            disabled={disabled && readonly}
          />
        );
      }),
    [fields, disabled],
  );

  if (!currentFields.length) {
    return null;
  }

  return <FormControls>{currentFields}</FormControls>;
};
