import { Block as BlockIcon } from '@mui/icons-material';
import { FormControl, FormLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesStatus, RequisitesStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';
import { formatUtils } from 'utils';

type Props = {
  status: RequisitesStatus;
  statusDetails?: RequisitesStatusDetails;
  blockedBy?: Partial<User>;
  blockedMessage?: string;
  blockedAt?: string;
};

export const RequisitesBlockedStatusLabel: React.FC<Props> = ({
  status,
  statusDetails,
  blockedBy,
  blockedMessage,
  blockedAt,
}) => {
  const { t } = useTranslation();

  const { t: tFields } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_details.fields',
  });

  if (status !== RequisitesStatus.Blocked) {
    return null;
  }

  return (
    <FormControl sx={{ py: 2 }}>
      <FormLabel>{tFields('status')}</FormLabel>
      <div className={'tw-flex tw-mt-2'}>
        <BlockIcon color="error" sx={{ mr: 2 }} fontSize="small" />
        <div>
          <div>{`${t('requisites_status.blocked')} (${formatUtils.formatDate(
            blockedAt,
          )})`}</div>
          <div>
            {t(
              `requisites_status_details.${
                statusDetails as RequisitesStatusDetails
              }`,
            )}
          </div>
          {blockedMessage && <div className="tw-mt-2">{blockedMessage}</div>}
          {blockedBy && <div>{blockedBy.name}</div>}
        </div>
      </div>
    </FormControl>
  );
};
