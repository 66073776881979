import { Collapse, ListItemProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { useToggle } from 'hooks';

import {
  Badge,
  ExpandLessIcon,
  ExpandMoreIcon,
  ListItemButton,
  Title,
} from './styles';
import { LayoutMenuItem } from '../../../types';

export const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink end {...props} />
  </div>
));

type Props = ListItemProps & {
  className?: string;
  depth: number;
  open?: boolean;
  item: LayoutMenuItem;
};

export const NavListItem: React.FC<Props> = ({
  depth = 0,
  children,
  open: openProp = false,
  item,
}) => {
  const { link, MuiIcon: Icon, badge, title } = item;

  const { isOpen, toggle } = useToggle(openProp);

  if (children) {
    return (
      <>
        <ListItemButton depth={depth} onClick={toggle}>
          {Icon && <Icon />}

          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>

          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>

        <Collapse in={isOpen}>{children}</Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      depth={depth}
      component={CustomRouterLink}
      to={link}
      activeclassname="active"
    >
      {Icon && <Icon />}
      <Title depth={depth}>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
    </ListItemButton>
  );
};
