import { SxProps, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatusLabel } from 'components';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
  sx?: SxProps;
};

export const P2PProviderStatus: React.FC<Props> = ({ order, sx }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  if (!order.p2pProviderOrderId) {
    return null;
  }

  return (
    <Tooltip title={t('table.columns.p2p_order')} sx={sx}>
      <StatusLabel
        label={t('details.p2p_order')}
        status={order.p2pSyncDisabled ? 'inactive' : 'active'}
      />
    </Tooltip>
  );
};
