import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { TraderActiveFundsRequests, TraderArchiveFundsRequests } from '.';

type Props = {
  title?: string;
};

enum AssetTab {
  ActiveFundsRequest = 'active',
  ArchiveFundsRequest = 'archive',
}

export const TraderFundsRequestsPage: React.FC<Props> = ({ title }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.assets.funds_requests',
  });

  return (
    <div>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={AssetTab}>
        <Tab value={AssetTab.ActiveFundsRequest} label={t('tabs.active')} />
        <Tab value={AssetTab.ArchiveFundsRequest} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={AssetTab.ActiveFundsRequest}>
        <TraderActiveFundsRequests />
      </QueryTabPanel>
      <QueryTabPanel value={AssetTab.ArchiveFundsRequest}>
        <TraderArchiveFundsRequests />
      </QueryTabPanel>
    </div>
  );
};
