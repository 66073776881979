// check backend type for statuses explanation
export enum OrderStatus {
  New = 'new',
  Requisites = 'requisites',
  CustomerConfirm = 'customer_confirm',
  TraderConfirm = 'trader_confirm',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Error = 'error',
  Dispute = 'dispute',
}
