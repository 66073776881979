import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useField, useFormikContext } from 'formik';
import moment, { Moment, unitOfTime } from 'moment';
import { useCallback } from 'react';

import { DateFormat } from 'enums';

type Props = {
  name: string;
  startOf?: unitOfTime.StartOf;
  endOf?: unitOfTime.StartOf;
} & Omit<DateTimePickerProps<Moment>, 'onChange' | 'value'>;

export const FormikDateTimePicker: React.FC<Props> = ({
  name,
  startOf,
  endOf,
  minDate,
  maxDate,
  ...restProps
}) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = useCallback(
    (value: Moment | null) => {
      if (value) {
        if (startOf) {
          value = moment(value).startOf(startOf);
        } else if (endOf) {
          value = moment(value).endOf(endOf);
        }
      }
      setFieldValue(name, value);
    },
    [name, startOf, endOf, setFieldValue],
  );

  return (
    <DateTimePicker
      minDate={moment(minDate || 0)}
      maxDate={maxDate ? moment(maxDate) : undefined}
      viewRenderers={{
        hours: null,
        minutes: null,
        seconds: null,
      }}
      slotProps={{
        textField: {
          variant: 'standard',
        },
      }}
      format={DateFormat.Long}
      {...restProps}
      value={field.value ? moment(field.value) : null}
      onChange={handleChange}
    />
  );
};
