import { FormFieldType } from 'enums';
import { FormFieldCombined } from 'types';

import {
  BeneficiaryName,
  Card,
  CardHolder,
  IBAN,
  Holder,
  IFSCCode,
  IMPSAccountNumber,
  Phone,
  SwiftBic,
  UPI_ID,
  IDCard,
  ERIP,
  ExpirationDate,
  AccountLastDigits,
  AccountNumber,
  Bic,
  TaxId,
} from './fields';

export const fieldsList: Record<FormFieldType, FormFieldCombined> = {
  [FormFieldType.AccountNumber]: AccountNumber,
  [FormFieldType.IBAN]: IBAN,
  [FormFieldType.Holder]: Holder,
  [FormFieldType.SwiftBic]: SwiftBic,
  [FormFieldType.Bic]: Bic,
  [FormFieldType.IMPSAccountNumber]: IMPSAccountNumber,
  [FormFieldType.IFSCCode]: IFSCCode,
  [FormFieldType.BeneficiaryName]: BeneficiaryName,
  [FormFieldType.Phone]: Phone,
  [FormFieldType.UPIId]: UPI_ID,
  [FormFieldType.Card]: Card,
  [FormFieldType.Cardholder]: CardHolder,
  [FormFieldType.IdCard]: IDCard,
  [FormFieldType.ERIP]: ERIP,
  [FormFieldType.ExpirationDate]: ExpirationDate,
  [FormFieldType.AccountLastDigits]: AccountLastDigits,
  [FormFieldType.TaxId]: TaxId,
};
