import { Warning as WarningIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { find, isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DataWrapper, FormikArray } from 'components';
import { TranslationNamespace } from 'i18n';
import { Group } from 'types';

type Props = {
  groups: Group[];
};

export const ShopPayinGroups: React.FC<Props> = ({ groups }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {t('sections.payin_groups.title')}
      </Typography>
      <DataWrapper isEmpty={isEmpty(groups)}>
        <FormikArray
          name="payinGroupsConfigs"
          sortable
          paperPaddingY={2}
          ItemComponent={({ item }) => (
            <div className="tw-flex-1">
              {find(groups, { id: item.groupId })?.name}
              {!item.priority && (
                <div className="tw-flex tw-items-center tw-mt-2">
                  <WarningIcon
                    fontSize="small"
                    color="warning"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="caption" color="GrayText">
                    {t('sections.payin_groups.no_priority')}
                  </Typography>
                </div>
              )}
            </div>
          )}
        />
      </DataWrapper>
    </Fragment>
  );
};
