import { Tab } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { OrderType } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { DailySummaryTab } from './DailySummaryTab';
import { RequisitesTab } from './RequisitesTab';
import { SummaryTab } from './SummaryTab';
import { TradersReferralsTab } from './TradersReferralsTab';

type Props = {
  orderType: OrderType;
};

enum StatisticTabs {
  Summary = 'summary',
  DailySummary = 'daily-summary',
  Requisites = 'requisites',
  TradersReferralsSummary = 'traders-referrals-summary',
}

export const Statistics: React.FC<Props> = ({ orderType }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics',
  });
  const { isMerchant, isTrader } = useUser();

  const tabs = useMemo(() => {
    const result = [StatisticTabs.Summary, StatisticTabs.DailySummary];
    if (!isMerchant) {
      result.push(StatisticTabs.Requisites);
    }
    if (isTrader) {
      result.push(StatisticTabs.TradersReferralsSummary);
    }
    return result;
  }, [isMerchant, isTrader]);

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabs={tabs}>
        <Tab value={StatisticTabs.Summary} label={t('tabs.summary')}></Tab>
        <Tab
          value={StatisticTabs.DailySummary}
          label={t('tabs.daily_summary')}
        ></Tab>
        {!isMerchant && (
          <Tab
            value={StatisticTabs.Requisites}
            label={t('tabs.requisites')}
          ></Tab>
        )}
        {isTrader && (
          <Tab
            value={StatisticTabs.TradersReferralsSummary}
            label={t('tabs.traders_referrals')}
          ></Tab>
        )}
      </QueryTabs>
      <QueryTabPanel value={StatisticTabs.Summary}>
        <SummaryTab orderType={orderType} />
      </QueryTabPanel>
      <QueryTabPanel value={StatisticTabs.DailySummary}>
        <DailySummaryTab orderType={orderType} />
      </QueryTabPanel>
      {!isMerchant && (
        <QueryTabPanel value={StatisticTabs.Requisites}>
          <RequisitesTab orderType={orderType} />
        </QueryTabPanel>
      )}
      {isTrader && (
        <QueryTabPanel value={StatisticTabs.TradersReferralsSummary}>
          <TradersReferralsTab orderType={orderType} />
        </QueryTabPanel>
      )}
    </Fragment>
  );
};
