import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import * as Yup from 'yup';

import { requisitesTelegramAutomationApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikPasswordInput,
} from 'components';
import { TELEGRAM_ERROR_MESSAGE } from 'constants/common.constants';
import { StatusCode } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesTelegramAutomation } from 'types';
import { validationUtils } from 'utils';

type Props = DialogProps<UseQueryResult<RequisitesTelegramAutomation>>;

type Values = {
  code: string;
};

export const RequisitesTelegramAutomationSignInDialog: React.FC<Props> = ({
  open,
  data: queryResult,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites_telegram_automation',
  });
  const { t: tCommon } = useTranslation();

  const data = useMemo(() => queryResult?.data, [queryResult]);

  const initialValues = useMemo(
    () => ({
      code: '',
    }),
    [],
  );

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(tCommon('errors.required')),
  });

  const signInMutation = useMutation<
    RequisitesTelegramAutomation,
    AxiosError,
    { id: string; code: string }
  >(requisitesTelegramAutomationApi.signIn, {
    notifierType: 'execute',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (status === StatusCode.BadRequest) {
          const message = error.response?.data?.message;
          switch (message) {
            case TELEGRAM_ERROR_MESSAGE.AUTH_RESTART:
              return t('errors.auth_restart');
            case TELEGRAM_ERROR_MESSAGE.SESSION_PASSWORD_NEEDED:
              return t('errors.session_password_needed');
            case TELEGRAM_ERROR_MESSAGE.PHONE_CODE_EXPIRED:
              return t('errors.phone_code_expired');
            case TELEGRAM_ERROR_MESSAGE.PHONE_CODE_INVALID:
              return t('errors.phone_code_invalid');
          }
        }
      },
    },
  });

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          onClose({ ok: true });
          queryResult?.refetch();
          formikHelpers.resetForm();
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      signInMutation.mutate({ id: data!.id, code: values.code }, options);
    },
    [signInMutation, data, onClose, queryResult],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('sign_in_dialog.title')}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={signInMutation}
          okDisabled={!formik.isValid}
          onClose={handleClose}
        >
          <FormControls>
            <FormikPasswordInput
              autoComplete="new-password"
              name="code"
              fullWidth
              label={t('sign_in_dialog.code')}
              required
            />
          </FormControls>
        </Dialog>
      )}
    </Formik>
  );
};
