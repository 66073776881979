import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { usersApi } from 'api';
import { ConfirmButton, ExternalLink, StatusLabel } from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';

type Props = {
  user: User;
};

export const Telegram: React.FC<Props> = ({ user }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.notifications.telegram',
  });

  const { user: currentUser } = useUser();
  const isProfile = useMemo(
    () => user.email === currentUser.email,
    [user, currentUser],
  );

  const { mutate: cancelTelegramNotifications } = useMutation(
    usersApi.cancelTelegramNotifications,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.Profile);
      },
    },
  );

  return (
    <div>
      <div className="tw-flex tw-items-center">
        <div className="tw-text-base tw-font-semibold tw-mb-2">
          {t('title')}
        </div>
      </div>
      <div className="tw-flex tw-items-center">
        <span className="tw-font-bold tw-mr-1">{`${t('status')}:`}</span>
        {user.telegramNotificationsLink || !isProfile ? (
          user.telegramNotificationsActive ? (
            <StatusLabel label={t('status_names.active')} status="active" />
          ) : (
            <StatusLabel label={t('status_names.inactive')} status="inactive" />
          )
        ) : (
          <StatusLabel label={t('status_names.unavailable')} status="error" />
        )}
        {user.telegramNotificationsActive && (
          <ConfirmButton
            variant="outlined"
            size="small"
            color="error"
            sx={{ ml: 2 }}
            onConfirm={() => cancelTelegramNotifications()}
          >
            {t('buttons.unsubscribe')}
          </ConfirmButton>
        )}
      </div>
      {user.telegramNotificationsLink && (
        <div className="tw-mt-1">
          <ExternalLink
            href={user.telegramNotificationsLink}
            label={
              user.telegramNotificationsActive
                ? t('buttons.subscribe_new')
                : t('buttons.subscribe')
            }
          />
        </div>
      )}
    </div>
  );
};
