import React from 'react';

import { FormikPatternField } from 'components';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { getTCommon, useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

const LAST_ACCOUNT_DIGITS_FORMAT = '####';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();

  return (
    <FormikPatternField
      name={name}
      label={t('fields.account_last_digits.label')}
      format={LAST_ACCOUNT_DIGITS_FORMAT}
      mask="_"
      required={required}
      disabled={disabled}
      fullWidth
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();
  return getBaseFieldValidator({ required })
    .matches(/^[0-9]{4}$/, tCommon('errors.invalid'))
    .nullable();
};
