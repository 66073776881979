import styled from '@emotion/styled';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components/StyledChip';
import { OperationType } from 'enums';
import { TranslationNamespace } from 'i18n';
import { FundsRequest } from 'types';

const getBackgroundColor = (props: any) => {
  switch (props.type) {
    case OperationType.Deposit:
      return props.theme.palette.success.main;
    case OperationType.Withdrawal:
      return props.theme.palette.grey[500];
  }
};

const StyledLabel = styled(StyledChip)<ChipProps & { type: OperationType }>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

type Props = {
  fundsRequest: FundsRequest;
};

export const OperationTypeLabel: React.FC<Props> = ({ fundsRequest }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'funds_request_operation',
  });

  switch (fundsRequest.operationType) {
    case OperationType.Deposit:
      return (
        <StyledLabel
          type={fundsRequest.operationType}
          label={
            fundsRequest.asset?.p2pProvider
              ? t('p2p_provider_deposit')
              : t('deposit')
          }
          color="success"
          icon={<AddIcon />}
        />
      );
    case OperationType.Withdrawal:
      return (
        <StyledLabel
          type={fundsRequest.operationType}
          label={t('withdrawal')}
          color="default"
          icon={<RemoveIcon />}
        />
      );
    default:
      return null;
  }
};
