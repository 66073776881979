import axios from 'axios';

import { env } from 'config';
import {
  CurrencyExchange,
  CurrencyExchangeRate,
  CurrencyExchangeRateHistory,
  PriceChartFilters,
} from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/currency-exchange`;

const getRates = async (): Promise<CurrencyExchangeRate[]> =>
  (await axios.get(`${url}/rates`)).data;

const getRatesHistory = async (
  body: PriceChartFilters,
): Promise<CurrencyExchangeRateHistory[]> =>
  (await axios.post(`${url}/rates/history`, body)).data;

export const currencyExchangeApi = {
  ...createCrudApi<CurrencyExchange>('/currency-exchange'),
  getRates,
  getRatesHistory,
};
