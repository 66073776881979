import { Cached as CachedIcon } from '@mui/icons-material';
import { CircularProgress, TablePagination, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataWrapper } from 'components';
import { DEFAULT_PAGINATION } from 'constants/pagination.constants';
import { PaginationCountAs } from 'enums';
import { UsePartialQueryResult } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PaginationOptions, PaginatedData } from 'types';

type Props = {
  queryResult: UsePartialQueryResult<PaginatedData<unknown>, unknown>;
  paginationOptions: PaginationOptions;
  onChange: (pagination: PaginationOptions) => void;
};

export const Pagination: React.FC<Props> = ({
  queryResult,
  paginationOptions,
  onChange,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.pagination',
  });

  const data = useMemo(() => queryResult.data, [queryResult]);

  const { page, take } = useMemo(
    () => paginationOptions || DEFAULT_PAGINATION,
    [paginationOptions],
  );
  const count = useMemo(() => data?.count || 0, [data]);
  const pages = useMemo(() => data?.pages || 0, [data]);

  const handleChangePage = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number,
    ) => onChange({ page: page + 1, take }),
    [take, onChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ page: 1, take: parseInt(event.target.value) });
    },
    [onChange],
  );

  const renderCount = useCallback(() => {
    if (queryResult?.paginationCountAs === PaginationCountAs.Cache) {
      return (
        <span className="tw-inline-flex tw-items-center tw-justify-center">
          <span>{`~${count} `}</span>
          <Tooltip
            title={
              <div>
                <div>{t('count_cached_1')}</div>
                <div>{t('count_cached_2')}</div>
              </div>
            }
          >
            <CachedIcon
              sx={{ ml: 0.5, cursor: 'pointer' }}
              fontSize="small"
              color="primary"
              onClick={() => queryResult.queryExactCount?.()}
            />
          </Tooltip>
        </span>
      );
    }
    return count;
  }, [count, queryResult, t]);

  const renderFrom = useCallback(() => {
    if (isNil(count)) {
      return null;
    }
    return (
      <span className="tw-inline-flex tw-items-center">
        <span className="tw-mr-1">{t('from')}</span>
        <DataWrapper
          queryResult={queryResult?.queryResultCount}
          loadingView={
            <CircularProgress size={12} classes={{ root: 'tw-text-center' }} />
          }
        >
          <Fragment>{renderCount()}</Fragment>
        </DataWrapper>
      </span>
    );
  }, [count, t, queryResult?.queryResultCount, renderCount]);

  const renderRange = useCallback(
    (pagination: { page: number }) => {
      const maxSize = (pagination.page + 1) * take;
      const startRow = maxSize - take + 1;
      const endRow = Math.min(maxSize, count);
      return (
        <span>
          {`${startRow} - ${endRow} `}
          {renderFrom()}
        </span>
      );
    },
    [count, renderFrom, take],
  );

  if (!pages) {
    return null;
  }
  return (
    <TablePagination
      component={'div'}
      count={count}
      rowsPerPage={take}
      page={page - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 25, 50]}
      labelRowsPerPage={`${t('result')}:`}
      labelDisplayedRows={renderRange}
      nextIconButtonProps={{ color: 'secondary' }}
      SelectProps={{
        inputProps: {
          'aria-label': 'page number',
        },
      }}
      showFirstButton
      showLastButton
    />
  );
};
