import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { MerchantActiveFundsRequests } from './ActiveFundsRequests';
import { MerchantArchiveFundsRequests } from './ArchiveFundsRequests';

enum AssetTab {
  ActiveFundsRequest = 'active',
  ArchiveFundsRequest = 'archive',
}

export const MerchantFundsRequestsPage = () => {
  const { t } = useTranslation(TranslationNamespace.Merchant, {
    keyPrefix: 'pages.assets.funds_requests',
  });

  return (
    <div>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={AssetTab}>
        <Tab value={AssetTab.ActiveFundsRequest} label={t('tabs.active')} />
        <Tab value={AssetTab.ArchiveFundsRequest} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={AssetTab.ActiveFundsRequest}>
        <MerchantActiveFundsRequests />
      </QueryTabPanel>
      <QueryTabPanel value={AssetTab.ArchiveFundsRequest}>
        <MerchantArchiveFundsRequests />
      </QueryTabPanel>
    </div>
  );
};
