import styled from '@emotion/styled';
import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';

const StyledChipInternal = styled(Chip)<ChipProps>`
  border: 0;

  & > .MuiChip-iconSmall {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
`;

export const StyledChip: FC<ChipProps> = (props: ChipProps) => (
  <StyledChipInternal size="small" variant="outlined" {...props} />
);
