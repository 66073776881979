import cx from 'classnames';
import React from 'react';

import { UserStatus } from 'enums';
import { userUtils } from 'utils';

type Props = {
  status: UserStatus;
};

// TODO: change colors to MUI
export const UserStatusLabel: React.FC<Props> = ({ status }) => (
  <span
    className={cx({
      'tw-text-green-500': status === UserStatus.Active,
      'tw-text-red-500': status === UserStatus.Disabled,
    })}
  >
    {userUtils.getStatusLabel(status)}
  </span>
);
