import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { orderAutomationApi } from 'api';
import {
  DataGridColumnDefinition,
  OrderAutomationStatusLabel,
} from 'components';
import {
  FilterDefinitionType,
  OrderAutomationFilterTypes,
  OrderAutomationStatus,
  QueryKey,
} from 'enums';
import { usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  OrderAutomation,
  FilterDefinition,
  OrderAutomationFilters,
} from 'types';
import { orderAutomationUtils } from 'utils';

import { ManageOrderAutomationList } from '../ManageOrderAutomationList';

export const AllOrderAutomation: React.FC = () => {
  const { role } = useUser();

  const { t: tColumns } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation.table.columns',
  });

  const queryResult = usePartialQuery(
    QueryKey.AllOrderAutomation,
    orderAutomationApi.getMessagesAsRole(role, OrderAutomationFilterTypes.All),
    { useCachedCount: true },
  );

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<OrderAutomation>[] => [
      {
        header: tColumns('status'),
        valueGetter: (item) => (
          <OrderAutomationStatusLabel status={item.status} />
        ),
      },
    ],
    [tColumns],
  );

  const additionalFilters: FilterDefinition<OrderAutomationFilters>[] = useMemo(
    () => [
      {
        label: tColumns('status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: OrderAutomationStatus,
        getDisplayName: orderAutomationUtils.getStatusLabel,
      },
    ],
    [tColumns],
  );

  return (
    <ManageOrderAutomationList
      queryResult={queryResult}
      additionalColumns={additionalColumns}
      additionalFilters={additionalFilters}
    />
  );
};
