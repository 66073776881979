import axios from 'axios';

import { env } from 'config';
import { TradeMethodPayoutPDFTemplate } from 'types';

import { createCrudApi } from './crud.api';

const path = '/payout-orders-files-validation/pdf-templates';
const url = `${env.apiUrl}${path}`;

const findTradeMethodPayoutPdfTemplates = async (id: string) =>
  (await axios.get(`${url}/trade-method/${id}`)).data;

const parsePdfTemplateText = async ({
  template,
  data,
}: {
  template?: TradeMethodPayoutPDFTemplate;
  data: FormData;
}): Promise<{ text: string; parseResult?: any }> =>
  template?.id
    ? (
        await axios.post(
          `${url}/trade-method/${template.tradeMethodId}/parse/${template.id}`,
          data,
        )
      ).data
    : (await axios.post(`${url}/parse`, data)).data;

const createTradeMethodPayoutPdfTemplate = async ({
  id,
  data,
}: {
  id: string;
  data: { template: string };
}) => (await axios.post(`${url}/trade-method/${id}`, data)).data;

export const payoutOrdersFilesValidationApi = {
  pdfTemplates: {
    findAllForTradeMethod: findTradeMethodPayoutPdfTemplates,
    createForTradeMethod: createTradeMethodPayoutPdfTemplate,
    parsePdfTemplateText,
    ...createCrudApi<TradeMethodPayoutPDFTemplate>(path),
  },
};
