import { Grid, List } from '@mui/material';
import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMeta } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { ListItemButton, Wrapper, ListItemText } from './styles';

export const Footer = () => {
  const { t } = useTranslation(TranslationNamespace.Common);

  const { telegramSupportBotUrl } = useMeta();

  const links: { title: string; link: string }[] = [
    ...(telegramSupportBotUrl
      ? [
          {
            title: 'Telegram',
            link: telegramSupportBotUrl,
          },
        ]
      : []),
  ];

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            {map(links, (item) => (
              <ListItemButton key={item.title} component="a" href={item.link}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <ListItemText
                primary={`${t('name')} © ${new Date().getFullYear()}`}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
