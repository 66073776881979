import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, Dialog } from 'components';
import { TranslationNamespace } from 'i18n';
import { AutomationSource } from 'types';

import {
  AutomationSourcesList,
  AutomationSourcesDetailsForm,
  AutomationSourcesProvider,
} from './';

export const AutomationSourcesPage = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.automation_sources',
  });

  const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedAutomationSource, setSelectedAutomationSource] =
    useState<AutomationSource | null>(null);

  const onEdit = useCallback((automationSource: AutomationSource) => {
    setDetailsDialogOpen(true);
    setSelectedAutomationSource(automationSource);
  }, []);

  const onCreate = useCallback(() => {
    setDetailsDialogOpen(true);
    setSelectedAutomationSource(null);
  }, []);

  const onClose = useCallback(() => {
    setDetailsDialogOpen(false);
    setSelectedAutomationSource(null);
  }, []);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        rightContentButton={{
          onClick: onCreate,
        }}
      />
      <AutomationSourcesProvider isExtended>
        <AutomationSourcesList onEdit={onEdit} />
        <Dialog
          title={
            selectedAutomationSource
              ? t('details_form.title.edit')
              : t('details_form.title.create')
          }
          open={isDetailsDialogOpen}
          onClose={onClose}
          maxWidth="sm"
          modal
        >
          <AutomationSourcesDetailsForm
            onSubmit={onClose}
            selectedAutomationSource={selectedAutomationSource}
            withType
          />
        </Dialog>
      </AutomationSourcesProvider>
    </Fragment>
  );
};
