export enum AssetChangeReason {
  FundsRequestDeposit = 'funds_request_deposit',
  FundsRequestWithdrawal = 'funds_request_withdrawal',
  CreateOrder = 'create_order',
  ChangeOrder = 'change_order',
  CancelOrder = 'cancel_order',
  CompleteOrder = 'complete_order',
  CancelledToDisputeOrder = 'cancelled_to_dispute_order',
  OrderCashback = 'order_cashback',
  MerchantReferralCashback = 'merchant_referral_cashback',
  TraderReferralCashback = 'trader_referral_cashback',
  PlatformCompensation = 'platform_compensation',
  DepositToBalance = 'deposit_to_balance',
}
