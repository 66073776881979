import React from 'react';

import { ExternalLink } from 'components';

type Props = {
  email: string;
};

export const MailLink: React.FC<Props> = ({ email }) => (
  <ExternalLink href={`mailto:${email}`} label={email} />
);
