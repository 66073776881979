import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultNS, resources, defaultRecourse } from './recources';

i18n.use(initReactI18next).init({
  lng: defaultRecourse,
  defaultNS,
  resources,
});
