import React, { Fragment } from 'react';

import { Timer } from 'components';
import { PayoutOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderDisputeColumn: React.FC<Props> = ({ order }) => (
  <Fragment>
    {order.statusDetails &&
      orderUtils.getPayoutStatusDetailsLabel(order.statusDetails)}
    <div>
      <Timer
        endTime={orderUtils.getMaxTimeoutAt(order)}
        hideTimeOver
        sx={{ mt: 1 }}
      />
    </div>
  </Fragment>
);
