import axios from 'axios';

import { env } from 'config';
import { UserWallet, WalletResourcesData } from 'types';

import { createCrudApi } from './crud.api';

const path = '/user-wallets';
const url = `${env.apiUrl}${path}`;

const getResources = async (id: string): Promise<WalletResourcesData> =>
  (await axios.get(`${url}/${id}/resources`)).data;

export const userWalletsApi = {
  getResources,
  ...createCrudApi<UserWallet>(path),
};
