import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect } from 'components';
import { DisputeStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import { orderUtils } from 'utils';

type Props = {
  name?: string;
};

export const DisputeSelect: React.FC<Props> = ({ name = 'statusDetails' }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });
  const options = useMemo(
    () =>
      [
        DisputeStatusDetails.DisputeNoPayment,
        DisputeStatusDetails.DisputeDifferentAmount,
      ].map((value) => ({
        value,
        label: orderUtils.getStatusDetailsLabel(value),
      })),
    [],
  );

  return (
    <FormikSelect
      label={t('dispute.reason')}
      name={name}
      noneOption={false}
      options={options}
      fullWidth
      size="small"
    />
  );
};
