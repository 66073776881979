export const NEW_ID = 'new';

export const IP_REGEX = /(^(\d{1,3}\.){3}(\d{1,3})$)/;

export const ERROR_MESSAGE = {
  ENTITY_IN_USE: 'Entity is in use and can not be processed',
};

export const TELEGRAM_ERROR_MESSAGE = {
  AUTH_RESTART: 'AUTH_RESTART',
  // https://core.telegram.org/method/auth.sendCode#possible-errors
  SESSION_PASSWORD_NEEDED: 'SESSION_PASSWORD_NEEDED',
  PHONE_NUMBER_FLOOD: 'PHONE_NUMBER_FLOOD',
  // https://core.telegram.org/method/auth.signIn#possible-errors
  PHONE_CODE_EXPIRED: 'PHONE_CODE_EXPIRED',
  PHONE_CODE_INVALID: 'PHONE_CODE_INVALID',
};

export const ROUND_DECIMALS = 5;
