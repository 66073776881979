import axios from 'axios';

import { env } from 'config';
import { RequisitesTelegramAutomation } from 'types';

import { createCrudApi } from './crud.api';

const path = '/requisites-telegram-automation';
const url = `${env.apiUrl}${path}`;

const login = async (id: string): Promise<RequisitesTelegramAutomation> =>
  (await axios.post(`${url}/trader/${id}/login`)).data;

const logout = async (id: string): Promise<RequisitesTelegramAutomation> =>
  (await axios.post(`${url}/trader/${id}/logout`)).data;

const signIn = async ({
  id,
  code,
}: {
  id: string;
  code: string;
}): Promise<RequisitesTelegramAutomation> =>
  (await axios.post(`${url}/trader/${id}/sign-in`, { code })).data;

export const requisitesTelegramAutomationApi = {
  ...createCrudApi<RequisitesTelegramAutomation>(path),
  login,
  logout,
  signIn,
};
