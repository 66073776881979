import { maxBy, minBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LineProps } from 'recharts';

import { LineChart } from 'components';
import { DateFormat } from 'enums';
import { PriceChartItem } from 'types';
import { formatUtils } from 'utils';

const DELTA_PERCENTAGE = 0.01;

type Props = {
  data: PriceChartItem[];
};

export const PriceChart: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const lines: LineProps[] = useMemo(
    () => [
      {
        type: 'monotone',
        dataKey: 'price',
        stroke: '#8884d8',
        dot: false,
        activeDot: { r: 5 },
        name: t('common.exchange'),
      },
    ],
    [t],
  );

  const dateFormatter = useCallback(
    (date: string) => formatUtils.formatDate(date, DateFormat.Short),
    [],
  );

  const domain = useMemo(() => {
    const minPrice = minBy(data, 'price')?.price || 0;
    const maxPrice = maxBy(data, 'price')?.price || 0;

    const minY = Math.max(
      Math.floor(minPrice - minPrice * DELTA_PERCENTAGE),
      0,
    );
    const maxY = Math.ceil(maxPrice + maxPrice * DELTA_PERCENTAGE);

    return [minY, maxY];
  }, [data]);

  return (
    <LineChart
      lines={lines}
      data={data}
      yAxis={{ domain }}
      xAxis={{ tickFormatter: dateFormatter, angle: -45, dy: 10 }}
    />
  );
};
