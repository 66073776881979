import { FormControlLabel } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'formik-mui';
import { get } from 'lodash';
import React from 'react';

import { Values } from '../ManageShop';

type Props = {
  name: string;
  label: string;
};

export const HiddenField: React.FC<Props> = ({ name, label }) => {
  const formik = useFormikContext<Values>();
  return (
    <FormControlLabel
      sx={{ display: 'flex' }}
      control={
        <Field
          component={Checkbox}
          type="checkbox"
          name={name}
          onChange={(e: any) => {
            e.target.checked = !e.target.checked;
            formik.handleChange(e);
          }}
          checked={!get(formik.values, name)}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
          }}
        />
      }
      label={label}
    />
  );
};
