import i18next from 'i18next';

import { CustomerDataCollectionOrder, ShopStatus } from 'enums';

const getShopStatusLabel = (status: ShopStatus) => {
  const { t } = i18next;
  const key = `features.shops.shop_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getCustomerDataCollectionOrder = (order: CustomerDataCollectionOrder) => {
  const { t } = i18next;
  const key = `features.shops.customer_data_collection_order.${order}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getShopStatusSelectTitle = () => {
  const { t } = i18next;
  const key = `components.shop_status_select.title`;
  return i18next.exists(key) ? t(key as any) : key;
};

export const shopUtils = {
  getShopStatusLabel,
  getShopStatusSelectTitle,
  getCustomerDataCollectionOrder,
};
