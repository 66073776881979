import { Navigate, Outlet } from 'react-router-dom';

import { DEFAULT_ROLE_PATH, ROUTE_PATH } from 'constants/routes';
import { UserRole } from 'enums';
import {
  AdminLayout,
  MerchantLayout,
  OperatorLayout,
  TechOperatorLayout,
  TraderLayout,
} from 'layouts';
import { authUtils } from 'utils';

const redirectToLogin = () => <Navigate to={ROUTE_PATH.PUBLIC.LOGIN} replace />;

const redirectToRoleDefault = () => {
  if (DEFAULT_ROLE_PATH[authUtils.getUserRole()]) {
    return <Navigate to={DEFAULT_ROLE_PATH[authUtils.getUserRole()]} replace />;
  }
  authUtils.resetAuthData();
  return redirectToLogin();
};

type AuthenticatedRoleRouteProps = {
  role: UserRole;
  children: React.ReactElement;
};
const AuthenticatedRoleRoute = (props: AuthenticatedRoleRouteProps) => {
  if (!authUtils.isLoggedIn()) {
    return redirectToLogin();
  }
  if (!authUtils.isUserRole(props.role)) {
    return redirectToRoleDefault();
  }
  return props.children;
};

export const AdminRoute = () => (
  <AuthenticatedRoleRoute role={UserRole.Admin}>
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  </AuthenticatedRoleRoute>
);

export const MerchantRoute = () => (
  <AuthenticatedRoleRoute role={UserRole.Merchant}>
    <MerchantLayout>
      <Outlet />
    </MerchantLayout>
  </AuthenticatedRoleRoute>
);
export const OperatorRoute = () => (
  <AuthenticatedRoleRoute role={UserRole.Operator}>
    <OperatorLayout>
      <Outlet />
    </OperatorLayout>
  </AuthenticatedRoleRoute>
);
export const TechOperatorRoute = () => (
  <AuthenticatedRoleRoute role={UserRole.TechOperator}>
    <TechOperatorLayout>
      <Outlet />
    </TechOperatorLayout>
  </AuthenticatedRoleRoute>
);
export const PublicRoute = () => {
  if (authUtils.isLoggedIn()) {
    return redirectToRoleDefault();
  }
  return <Outlet />;
};
export const TraderRoute = () => (
  <AuthenticatedRoleRoute role={UserRole.Trader}>
    <TraderLayout>
      <Outlet />
    </TraderLayout>
  </AuthenticatedRoleRoute>
);
