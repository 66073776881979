import { TextFieldProps, Typography, useTheme } from '@mui/material';
import { useField } from 'formik';
import { isEmpty, startsWith } from 'lodash';
import React, { useCallback } from 'react';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

import { useFormikError } from 'hooks';

const LEFT_MARGIN = 3.5;

type Props = {
  name: string;
  label: string;
  localization?: PhoneInputProps['localization'];
} & Partial<PhoneInputProps> &
  Partial<TextFieldProps>;

export const FormikPhoneInput: React.FC<Props> = ({
  name,
  label,
  localization,
  country = 'ru',
  ...rest
}) => {
  const theme = useTheme();

  const [field, , helpers] = useField(name);

  const { errorVisible, errorMessage } = useFormikError(name);

  const handleChange = useCallback(
    (
      phoneNumber: string,
      data: {} | CountryData,
      event: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string,
    ) => {
      const numberWithPlus =
        isEmpty(phoneNumber) || startsWith(phoneNumber, '+')
          ? phoneNumber
          : `+${phoneNumber}`;
      helpers.setValue(numberWithPlus);
    },
    [helpers],
  );

  return (
    <div>
      <PhoneInput
        {...rest}
        inputProps={{
          label,
          name: field.name,
          required: rest.required,
          autoFocus: true,
          style: {
            ...(rest.fullWidth && { width: '100%' }),
            ...(rest.disabled && {
              color: theme.palette.action.disabled,
            }),
            background: 'transparent',
          },
        }}
        localization={ru}
        country={country}
        jumpCursorToEnd
        value={field.value}
        placeholder={label}
        disabled={rest.disabled}
        onChange={handleChange}
      />
      {errorVisible && errorMessage && (
        <Typography marginLeft={LEFT_MARGIN} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
