import i18next from 'i18next';

import {
  NotificationEvent,
  NotificationEventGroup,
  UserRole,
  UserStatus,
} from 'enums';

const getRoleLabel = (role: UserRole) => {
  const { t } = i18next;
  const key = `role.${role}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusLabel = (status: UserStatus) => {
  const { t } = i18next;
  const key = `features.user.status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getNotificationEventLabel = (event: NotificationEvent) => {
  const key = `components.settings.notifications.event_names.${event}`;
  return i18next.t(key, { defaultValue: event });
};

const getNotificationGroupLabel = (event: NotificationEventGroup) => {
  const key = `components.settings.notifications.event_group.${event}`;
  return i18next.t(key, { defaultValue: event });
};

export const userUtils = {
  getRoleLabel,
  getStatusLabel,
  getNotificationEventLabel,
  getNotificationGroupLabel,
};
