import axios from 'axios';

import { env } from 'config';
import { AssetCurrencyExchange } from 'types';

import { createCrudApi } from './crud.api';

const prefix = '/asset-currency-exchange';
const url = `${env.apiUrl}${prefix}`;

const getUserAssetCurrencyExchange = async (params: {
  id: string;
  toAssetCurrencyId: string;
}): Promise<{ price: number }> =>
  (
    await axios.get(
      `${url}/asset/${params.id}/price/${params.toAssetCurrencyId}`,
    )
  ).data;

export const assetCurrencyExchangeApi = {
  ...createCrudApi<AssetCurrencyExchange>(prefix),
  getUserAssetCurrencyExchange,
};
