import { find } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TRADER_ORDER_STATUSES } from 'constants/order.constants';
import {
  AdditionalOrderColumn,
  FilterDefinitionType,
  OrderStatus,
  OrdersTab,
} from 'enums';
import { Orders } from 'features';
import { useCurrencies, useQueryFilters, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { OrderFilters, FilterDefinition } from 'types';
import { formUtils, orderUtils, requisitesUtils } from 'utils';

export const TraderAllOrders: React.FC = () => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const { paymentTypes, banks } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
    statuses: [OrderStatus.Completed, OrderStatus.Cancelled],
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      orderUtils.getStatusFilterDefinition(TRADER_ORDER_STATUSES),
      {
        label: tCommon('payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tCommon('bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tCommon('fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tCommon('amount'),
        name: 'amount',
        type: FilterDefinitionType.Numeric,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [banks, fiatCurrenciesOptions, getFiatCurrencyCode, paymentTypes, tCommon],
  );

  return (
    <Orders
      tab={OrdersTab.All}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.StatusActive,
        AdditionalOrderColumn.TraderCard,
        AdditionalOrderColumn.Customer,
      ]}
    />
  );
};
