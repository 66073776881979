import { filter } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { fiatCurrencyExchangeApi } from 'api';
import { QueryKey } from 'enums';
import { FiatCurrencyExchange } from 'types';

import { useCurrencies } from './use-currencies.hook';

type Options = {
  enabled: boolean;
  fiatCurrencyId?: string;
};

export const useCrossFiatCurrencyExchange = (
  { fiatCurrencyId, enabled }: Options = { enabled: true },
) => {
  const { getFiatCurrencyExchangeLabel } = useCurrencies();

  const queryResultCrossFiatExchange = useQuery(
    QueryKey.FiatCurrencyExchange,
    () => fiatCurrencyExchangeApi.getAll(),
    { enabled },
  );

  const crossFiatCurrencyExchange = useMemo(
    () => queryResultCrossFiatExchange.data,
    [queryResultCrossFiatExchange.data],
  );

  const crossFiatCurrencyExchangeOptions = useMemo(
    () =>
      filter(
        crossFiatCurrencyExchange,
        (exchange: FiatCurrencyExchange) =>
          exchange.enabled &&
          !!exchange.fiatCurrencyExchangeId &&
          !!exchange.crossFiatCurrencyExchangeId &&
          (!fiatCurrencyId || exchange.fiatCurrencyId === fiatCurrencyId),
      ).map((exchange) => ({
        value: exchange.id,
        label: getFiatCurrencyExchangeLabel(
          exchange.fiatCurrencyId,
          exchange.crossFiatCurrencyId,
          exchange.source,
          exchange.name,
        ),
      })),
    [getFiatCurrencyExchangeLabel, crossFiatCurrencyExchange, fiatCurrencyId],
  );

  return { crossFiatCurrencyExchange, crossFiatCurrencyExchangeOptions };
};
