import axios from 'axios';

import { env } from 'config';
import { TraderNotifications } from 'types';

const url = `${env.apiUrl}/notifications`;

const getTraderNotifications = async (): Promise<TraderNotifications> =>
  (await axios.get(`${url}/trader`)).data;

export const notificationsApi = {
  getTraderNotifications,
};
