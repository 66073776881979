import { Box, Tabs } from '@mui/material';
import { isEmpty, values } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryFilters, useQueryPagination, useQueryTab } from 'hooks';

type Props = {
  children: ReactNode;
  tabs?: any[];
  tabsEnum?: any;
  onChange?: (tab: any) => void;
};

export const QueryTabs: React.FC<Props> = ({
  tabs,
  tabsEnum,
  children,
  onChange,
}) => {
  const { resetFilters } = useQueryFilters();
  const { resetPagination } = useQueryPagination();
  const { tab, tabInitialized, setTab } = useQueryTab();
  const location = useLocation();

  const possibleValues = useMemo(() => {
    if (!isEmpty(tabs)) {
      return tabs;
    } else if (tabsEnum) {
      return values(tabsEnum);
    }
    return [];
  }, [tabs, tabsEnum]);

  const isValidTab = useMemo(
    () => possibleValues?.includes(tab),
    [possibleValues, tab],
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      onChange?.(newValue);
      setTab(newValue);
      resetFilters({ replace: true });
      resetPagination({ replace: true });
    },
    [onChange, setTab, resetFilters, resetPagination],
  );

  useEffect(() => {
    if (tabInitialized) {
      if (possibleValues?.includes(location.state?.tab)) {
        setTab(location.state.tab, { replace: true });
      } else if (!possibleValues?.includes(tab)) {
        setTab(possibleValues?.[0], { replace: true });
      }
    }
  }, [tabInitialized, location.state, possibleValues, setTab, tab]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {isValidTab && (
        <Tabs value={tab} onChange={handleTabChange}>
          {children}
        </Tabs>
      )}
    </Box>
  );
};
