import React, { Fragment } from 'react';

import { CopyTextId } from 'components/CopyTextId';

type Props = {
  id?: string;
  name?: string;
};

export const UserInfo: React.FC<Props> = ({ name, id }) => (
  <Fragment>
    {name && <div>{name}</div>}
    {id && <CopyTextId id={id} />}
  </Fragment>
);
