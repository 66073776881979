import { Telegram as TelegramIcon } from '@mui/icons-material';
import { Tab, Button } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageHeader,
  QueryTabPanel,
  QueryTabs,
  TelegramSupportDialog,
} from 'components';
import { OrdersTab } from 'enums';
import { useMeta, useQueryTab } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { TraderActiveOrders } from './ActiveOrders';
import { TraderAllOrders } from './AllOrders';
import { TraderCancelledOrders } from './CancelledOrders';
import { TraderCompletedOrders } from './CompletedOrders';
import { TraderDisputeOrders } from './DisputeOrders';

export const TraderPayinOrdersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { tab } = useQueryTab();

  const [telegramSupportDialogOpen, setTelegramSupportDialogOpen] =
    useState(false);

  const { telegramSupportBotUrl } = useMeta();

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        rightContent={
          tab === OrdersTab.Dispute &&
          telegramSupportBotUrl && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<TelegramIcon />}
              onClick={() => setTelegramSupportDialogOpen(true)}
            >
              {tCommon('buttons.telegram_support')}
            </Button>
          )
        }
        divider={false}
      />
      <QueryTabs tabsEnum={OrdersTab}>
        <Tab value={OrdersTab.Active} label={t('tabs.active')}></Tab>
        <Tab value={OrdersTab.Completed} label={t('tabs.completed')} />
        <Tab value={OrdersTab.Cancelled} label={t('tabs.cancelled')} />
        <Tab value={OrdersTab.Dispute} label={t('tabs.dispute')} />
        <Tab value={OrdersTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrdersTab.Active}>
        <TraderActiveOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Completed}>
        <TraderCompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Cancelled}>
        <TraderCancelledOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Dispute}>
        <TraderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.All}>
        <TraderAllOrders />
      </QueryTabPanel>
      {tab === OrdersTab.Dispute && (
        <TelegramSupportDialog
          open={telegramSupportDialogOpen}
          onClose={() => setTelegramSupportDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};
