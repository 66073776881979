import styled from '@emotion/styled';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import { FC, ReactNode } from 'react';

import { StyledChip } from 'components/StyledChip';

type Status = 'active' | 'inactive' | 'error';

type Props = {
  label: ReactNode;
  status: Status;
} & Partial<ChipProps>;

const getBackgroundColor = (props: any) => {
  switch (props.status) {
    case 'active':
      return props.theme.palette.success.main;
    case 'inactive':
      return props.theme.palette.grey[500];
    case 'error':
      return props.theme.palette.error.main;
  }
};

const StyledLabel = styled(StyledChip)<ChipProps & { status: Status }>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

export const StatusLabel: FC<Props> = ({ label, status, ...rest }) => (
  <StyledLabel
    label={label}
    status={status}
    color={status === 'active' ? 'success' : 'default'}
    {...rest}
  />
);
