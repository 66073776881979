import { DarkMode, Logout, Settings } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material';
import React, { Fragment, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_DARK_THEME, DEFAULT_LIGHT_THEME } from 'config/themes';
import { DEFAULT_ROLE_PATH } from 'constants/routes';
import { useTheme, useToggle } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';
import { authUtils } from 'utils';

import { Avatar, AvatarBadge, Popover } from './styles';

interface Props {
  user: User;
  active?: boolean;
  onLogout: () => void;
}

export function UserDropdown({ user, active, onLogout }: Props) {
  const navigate = useNavigate();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.navbar.user_dropdown',
  });

  const { theme, setTheme } = useTheme();

  const ref = useRef(null);

  const { isOpen: isDropdownOpen, open, close } = useToggle();

  const toggleDarkTheme = useCallback(
    () =>
      setTheme(
        theme === DEFAULT_LIGHT_THEME
          ? DEFAULT_DARK_THEME
          : DEFAULT_LIGHT_THEME,
      ),
    [setTheme, theme],
  );

  const navigateToProfile = useCallback(() => {
    navigate(`${DEFAULT_ROLE_PATH[authUtils.getUserRole()]}/settings`);
    close();
  }, [navigate, close]);

  return (
    <Fragment>
      <AvatarBadge
        ref={ref}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        color={active ? 'success' : 'warning'}
        variant="dot"
        onClick={open}
      >
        <Avatar alt={user.name} />
      </AvatarBadge>

      <Popover
        anchorOrigin={{
          vertical: 50,
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={close}
        open={isDropdownOpen}
      >
        <List disablePadding>
          <ListItem divider>
            <ListItemAvatar>
              <Avatar alt={user.name} />
            </ListItemAvatar>
            <ListItemText
              primary={user.name}
              secondary={user.email}
              primaryTypographyProps={{
                variant: 'subtitle2',
                color: 'textPrimary',
              }}
            />
          </ListItem>
          <ListItem onClick={navigateToProfile} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                checked={theme === DEFAULT_DARK_THEME}
                onChange={toggleDarkTheme}
              />
            }
            divider
          >
            <ListItemIcon>
              <DarkMode />
            </ListItemIcon>
            <ListItemText primary={t('dark_mode')} />
          </ListItem>
          <ListItem onClick={onLogout} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary={t('logout')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Fragment>
  );
}
