import { Badge, Chip } from '@mui/material';
import React, { useCallback } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

type Props = {
  label: string;
  to: string;
  badge?: number;
  className?: string;
};

export type ChipLinkProps = Props;

export const ChipLink: React.FC<Props> = ({ label, to, badge, className }) => {
  const location = useLocation();

  const renderChip = useCallback(
    () => (
      <Chip
        label={label}
        clickable
        color="primary"
        variant={matchPath(location.pathname, to) ? 'filled' : 'outlined'}
      />
    ),
    [label, location.pathname, to],
  );

  return (
    <Link to={to} className={className}>
      {badge ? (
        <Badge color="secondary" badgeContent={badge}>
          {renderChip()}
        </Badge>
      ) : (
        renderChip()
      )}
    </Link>
  );
};
