import {
  ChevronLeft as ArrowLeftIcon,
  ChevronRight as ArrowRightIcon,
} from '@mui/icons-material';
import { CssBaseline } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppState, useIsChanged, useToggle } from 'hooks';

import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';
import { DrawerToggle } from './Sidebar/styles';
import {
  GlobalStyle,
  AppContent,
  DRAWER_WIDTH,
  MainContent,
  Root,
  DRAWER_COLLAPSED_WIDTH,
  DRAWER_TRANSITION,
} from './styles';
import { LayoutMenuSection } from './types';

type Props = {
  menuSections: LayoutMenuSection[];
  children: React.ReactElement;
  onLogout: () => void;
};

export const MainLayout: React.FC<Props> = ({
  menuSections,
  children,
  onLogout,
}) => {
  const router = useLocation();
  const pathnameChanged = useIsChanged(router.pathname, router.pathname);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdUpChanged = useIsChanged(isMdUp, isMdUp);

  const { appState, mergeAppState } = useAppState();

  const [isSidebarHovered, setIsSidebarHovered] = useState(false);

  const {
    isOpen: drawerOpen,
    toggle: toggleDrawer,
    close: closeDrawer,
  } = useToggle(isMdUp && !appState?.drawer?.desktopCollapsed);

  const expanded = useMemo(
    () => drawerOpen || isSidebarHovered,
    [drawerOpen, isSidebarHovered],
  );

  useEffect(() => {
    if (pathnameChanged && !isMdUp) {
      closeDrawer();
    }
  }, [closeDrawer, isMdUp, pathnameChanged, router.pathname]);

  useEffect(() => {
    if (isMdUpChanged) {
      toggleDrawer(isMdUp && !appState?.drawer?.desktopCollapsed);
    }
  }, [toggleDrawer, isMdUp, isMdUpChanged, appState?.drawer?.desktopCollapsed]);

  const handleDrawerToggle = useCallback(() => {
    toggleDrawer();
    if (isMdUp) {
      mergeAppState({ drawer: { desktopCollapsed: drawerOpen } });
    }
  }, [isMdUp, drawerOpen, toggleDrawer, mergeAppState]);

  useEffect(() => {
    if (!drawerOpen) {
      setIsSidebarHovered(false);
    }
  }, [drawerOpen]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Sidebar
        open={drawerOpen}
        PaperProps={{
          style: {
            width: expanded ? DRAWER_WIDTH : DRAWER_COLLAPSED_WIDTH,
            willChange: 'width',
            transition: `width ${DRAWER_TRANSITION}`,
          },
        }}
        variant={isMdUp ? 'permanent' : 'temporary'}
        sections={menuSections}
        onMouseEnter={() => setIsSidebarHovered(true)}
        onMouseLeave={() => setIsSidebarHovered(false)}
        onClose={() => closeDrawer()}
      />

      {isMdUp && (
        <DrawerToggle
          expanded={expanded}
          visible={!drawerOpen || isSidebarHovered}
          {...(expanded &&
            !appState?.drawer?.desktopCollapsed && {
              onMouseEnter: () => setIsSidebarHovered(true),
              onMouseLeave: () => setIsSidebarHovered(false),
            })}
          onClick={() => handleDrawerToggle()}
        >
          {drawerOpen ? (
            <ArrowLeftIcon fontSize="small" />
          ) : (
            <ArrowRightIcon fontSize="small" />
          )}
        </DrawerToggle>
      )}

      <AppContent open={drawerOpen}>
        <Navbar onDrawerToggle={handleDrawerToggle} onLogout={onLogout} />
        <MainContent p={isMdUp ? 9 : 5}>{children}</MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};
