import axios, { AxiosRequestConfig } from 'axios';

const requestDownload = async <T>(
  config: AxiosRequestConfig<T>,
  filename: string,
) =>
  await axios
    .request({
      responseType: 'blob',
      ...config,
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });

export const apiUtils = {
  requestDownload,
};
