import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export const getTCommon = () => i18next.t;

export const useTFeature = () =>
  useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.form_builder',
  });
