import { Block as BlockIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';
import { formatUtils } from 'utils';

type Props = {
  statusDetails?: RequisitesStatusDetails;
  blockedMessage?: string;
  blockedBy?: Partial<User>;
  blockedAt?: string;
};

export const RequisitesBlocked: React.FC<Props> = ({
  statusDetails,
  blockedMessage,
  blockedBy,
  blockedAt,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common);

  const tooltipTitle = useMemo(() => {
    let title = t('requisites_status.blocked');
    const message = t(`requisites_status_details.${statusDetails!}`);
    return `${title}: ${message}`;
  }, [statusDetails, t]);

  const tooltipDate = useMemo(() => {
    const date = formatUtils.formatDate(blockedAt);
    return `${t('common.date')}: ${date}`;
  }, [blockedAt, t]);

  const tooltipContent = useMemo(
    () => (
      <div>
        <div>{tooltipTitle}</div>
        {blockedBy && <div>{blockedBy.name}</div>}
        {blockedMessage && <div>{blockedMessage}</div>}
        <div>{tooltipDate}</div>
      </div>
    ),
    [blockedMessage, blockedBy, tooltipDate, tooltipTitle],
  );

  return (
    <Tooltip title={tooltipContent}>
      <BlockIcon color="error" className={cx('tw-mx-2')} />
    </Tooltip>
  );
};
