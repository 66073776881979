import { ChipProps } from '@mui/material';
import React, { FC } from 'react';

import { StatusLabel } from 'components';
import { ShopStatus } from 'enums';
import { shopUtils } from 'utils';

type Props = {
  status?: ShopStatus;
} & Partial<ChipProps>;

export const ShopStatusLabel: FC<Props> = ({ status, ...rest }) =>
  status ? (
    <StatusLabel
      label={shopUtils.getShopStatusLabel(status)}
      status={status}
      {...rest}
    />
  ) : null;
