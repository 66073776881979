import React, { useMemo } from 'react';

import { PayoutOrderStatus } from 'enums';
import { orderUtils } from 'utils';

type Props = {
  status: PayoutOrderStatus;
};

export const PayoutOrderStatusLabel: React.FC<Props> = ({ status }) => {
  const textColorClassName = useMemo(() => {
    switch (status) {
      case PayoutOrderStatus.Requisites:
        return 'tw-text-violet-500';
      case PayoutOrderStatus.TraderAccept:
        return 'tw-text-amber-500';
      case PayoutOrderStatus.TraderPayment:
        return 'tw-text-green-500';
      case PayoutOrderStatus.Completed:
        return 'tw-text-green-700';
      case PayoutOrderStatus.Error:
        return 'tw-text-red-500';
    }
  }, [status]);

  return (
    <span className={textColorClassName}>
      {orderUtils.getStatusLabel(status)}
    </span>
  );
};
