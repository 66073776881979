import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShopList } from 'features/shop';
import { TranslationNamespace } from 'i18n';

export const MerchantShopsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Merchant, {
    keyPrefix: 'pages.shops',
  });

  return <ShopList title={t('title')} />;
};
