import { Button, Typography } from '@mui/material';
import { find } from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { tradersApi, statisticsApi } from 'api';
import {
  CopyTextId,
  CrudTable,
  DataGridColumnDefinition,
  DateLabel,
  QueryFilters,
  RequisitesInfo,
  StylizedNumber,
} from 'components';
import { FilterDefinitionType, OrderType, QueryKey } from 'enums';
import {
  useCurrencies,
  usePartialQuery,
  useQueryFilters,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  Requisites,
  PayoutRequisites,
  RequisitesWithStatistic,
  DateFilters,
} from 'types';
import { filtersUtils, formUtils, formatUtils, requisitesUtils } from 'utils';

import { ChartModal } from './ChartModal';

type RequisitesStatisticFilters = {
  traderId: string;
  bankId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
  assetCurrencyId: string;
} & DateFilters;

type Props = {
  orderType: OrderType;
};

export const RequisitesTab: React.FC<Props> = ({ orderType }) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics.requisites',
  });

  const { banks, paymentTypes } = useUserContext();
  const {
    getFiatCurrencyCode,
    fiatCurrenciesOptions,
    assetCurrenciesOptions,
    defaultAssetCurrency,
    getAssetCurrencyCode,
  } = useCurrencies();

  const { filters } = useQueryFilters<RequisitesStatisticFilters>({
    assetCurrencyId: defaultAssetCurrency?.id,
    from: moment().startOf('month').toISOString(),
  });
  const [graphModalRequisiteId, setGraphModalRequisiteId] = useState<
    null | string
  >(null);

  const { role, isAdmin, isTrader } = useUser();

  const queryResult = usePartialQuery(
    `${QueryKey.RequisitesStatistic}-${orderType}`,
    statisticsApi.getRequisites(orderType),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: isAdmin,
    },
  );

  const renderValue = useCallback(
    (value: number, percentage?: number, percentageOfText?: string) => (
      <div>
        <StylizedNumber value={value} variant="subtitle1" />
        {!!value && !!percentage && (
          <div className="tw-text-gray-400">
            <StylizedNumber value={percentage} unit="%" variant="caption" />
            <Typography sx={{ ml: 1 }} variant="caption">
              {percentageOfText}
            </Typography>
          </div>
        )}
      </div>
    ),
    [],
  );

  const filtersDefinitions: FilterDefinition<RequisitesStatisticFilters>[] =
    useMemo(
      () => [
        {
          label: tCommon('filters.trader'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          traders: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.user?.name,
          hidden: !isAdmin,
        },
        {
          label: tCommon('filters.bank'),
          name: 'bankId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(banks),
          getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
        },
        {
          label: tCommon('filters.payment_type'),
          name: 'paymentTypeId',
          type: FilterDefinitionType.Select,
          options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
          getDisplayName: (paymentType: string) =>
            find(paymentTypes, { id: paymentType })?.name,
        },
        {
          label: tCommon('filters.fiat_currency'),
          name: 'fiatCurrencyId',
          type: FilterDefinitionType.Select,
          options: fiatCurrenciesOptions,
          getDisplayName: getFiatCurrencyCode,
        },
        {
          label: tCommon('filters.asset_currency'),
          name: 'assetCurrencyId',
          type: FilterDefinitionType.Select,
          options: assetCurrenciesOptions,
          noneOption: false,
          removable: false,
          getDisplayName: getAssetCurrencyCode,
        },
      ],
      [
        tCommon,
        queryResultTraders.data,
        isAdmin,
        banks,
        paymentTypes,
        fiatCurrenciesOptions,
        getFiatCurrencyCode,
        assetCurrenciesOptions,
        getAssetCurrencyCode,
      ],
    );

  const columns = useMemo(
    (): DataGridColumnDefinition<
      RequisitesWithStatistic<Requisites | PayoutRequisites>
    >[] => [
      {
        header: t('columns.id'),
        valueGetter: (item) => (
          <div>
            <CopyTextId id={item.requisites.id} disableIconColor />
            <DateLabel>
              {formatUtils.formatDate(item.requisites.createdAt)}
            </DateLabel>
          </div>
        ),
      },
      {
        header: t('columns.trader'),
        valueGetter: (item) => item.requisites.user?.name,
        hidden: isTrader,
      },
      {
        header: t('columns.requisites'),
        valueGetter: (item) => (
          <RequisitesInfo requisite={item.requisites as any} />
        ),
      },
      {
        header: t('columns.total'),
        valueGetter: (item) => renderValue(item.statistic.totalCount),
      },
      {
        header: t('columns.completed'),
        valueGetter: (item) =>
          renderValue(
            item.statistic.completedCount,
            item.statistic.completedConversion,
            t('description.of_total'),
          ),
      },
      {
        header: t('columns.completed_by_automation'),
        valueGetter: (item) =>
          renderValue(
            item.statistic.completedByAutomationCount!,
            item.statistic.completedByAutomationdConversion,
            t('description.of_completed'),
          ),
        hidden: orderType === OrderType.Payout,
      },
      {
        header: t('columns.actions'),
        valueGetter: (item) => (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => setGraphModalRequisiteId(item.requisites.id)}
          >
            {t('buttons.chart')}
          </Button>
        ),
      },
    ],
    [isTrader, orderType, renderValue, t],
  );

  const handleGraphRequisiteModalClose = useCallback(() => {
    setGraphModalRequisiteId(null);
  }, []);

  return (
    <Fragment>
      <QueryFilters
        filtersDefinitions={[
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
        ]}
      />
      <CrudTable queryResult={queryResult} columns={columns} paginated />
      <ChartModal
        open={!!graphModalRequisiteId}
        orderType={orderType}
        requisiteId={graphModalRequisiteId}
        onClose={handleGraphRequisiteModalClose}
      />
    </Fragment>
  );
};
