import React, { Fragment } from 'react';

import { useCurrencies } from 'hooks';

type Props = {
  id: string;
};

export const AssetCurrencyCode: React.FC<Props> = ({ id }) => {
  const { getAssetCurrencyCode } = useCurrencies();
  return <Fragment>{getAssetCurrencyCode(id)}</Fragment>;
};
