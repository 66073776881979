import { find, isEmpty } from 'lodash';
import moment from 'moment';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { statisticsApi, usersApi } from 'api';
import { QueryFilters } from 'components';
import { FilterDefinitionType, OrderType, QueryKey } from 'enums';
import { useCurrencies, usePartialQuery, useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { DateFilters, FilterDefinition } from 'types';
import { formUtils } from 'utils';

import { SummaryCards } from '../SummaryCards';

type AgentsStatisticFilters = {
  traderUserId: string;
  assetCurrencyId: string;
} & DateFilters;

type Props = {
  orderType: OrderType;
};

export const TradersReferralsTab: React.FC<Props> = ({ orderType }) => {
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics.agents',
  });

  const { assetCurrenciesOptions, defaultAssetCurrency, getAssetCurrencyCode } =
    useCurrencies();

  useQueryFilters<AgentsStatisticFilters>({
    assetCurrencyId: defaultAssetCurrency?.id,
    from: moment().startOf('month').toISOString(),
  });

  const queryResultUserReferrals = useQuery(
    QueryKey.UserReferrals,
    usersApi.getReferralTraders,
  );

  const noData = useMemo(
    () => isEmpty(queryResultUserReferrals.data),
    [queryResultUserReferrals.data],
  );

  const queryResult = usePartialQuery(
    `${QueryKey.AgentsStatisticSummary}-${orderType}`,
    statisticsApi.getAgentsSummary(orderType),
    {
      enabled: !noData,
    },
  );

  const filtersDefinitions: FilterDefinition<AgentsStatisticFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.traderUser'),
          name: 'traderUserId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(
            queryResultUserReferrals.data || [],
            'name',
            'id',
          ),
          getDisplayName: (userId) =>
            find(
              queryResultUserReferrals.data,
              (referral) => referral.id === userId,
            )?.name,
        },
        {
          label: tCommon('filters.asset_currency'),
          name: 'assetCurrencyId',
          type: FilterDefinitionType.Select,
          options: assetCurrenciesOptions,
          noneOption: false,
          removable: false,
          getDisplayName: getAssetCurrencyCode,
        },
      ],
      [
        t,
        queryResultUserReferrals.data,
        tCommon,
        assetCurrenciesOptions,
        getAssetCurrencyCode,
      ],
    );

  return (
    <Fragment>
      {!noData && (
        <QueryFilters withCommon filtersDefinitions={filtersDefinitions} />
      )}
      <SummaryCards
        queryResult={queryResult}
        exclude={['traderCompensation', 'conversion', 'totalCount']}
      />
    </Fragment>
  );
};
