import { Grid } from '@mui/material';
import { includes, isNumber, map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  DataWrapper,
  InfoCard,
  StylizedMoney,
  StylizedNumber,
} from 'components';
import { useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { SummaryStatistic } from 'types';

type Props = {
  queryResult: UseQueryResult<SummaryStatistic>;
  exclude?: (keyof SummaryStatistic)[];
};

export const SummaryCards: React.FC<Props> = ({ queryResult, exclude }) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics.summary',
  });

  const { filters } = useQueryFilters<{ assetCurrencyId: string }>();

  const assetCurrencyId = useMemo(() => filters?.assetCurrencyId, [filters]);

  const statistic = useMemo(() => queryResult.data, [queryResult]);

  const cards = useMemo(() => {
    if (!statistic) {
      return [];
    }

    return [
      {
        value: (
          <StylizedMoney
            variant="h2"
            value={statistic.totalAmount}
            code
            assetCurrencyId={assetCurrencyId}
          />
        ),
        title: t('cards.total_amount.title'),
      },
      ...(isNumber(statistic.traderCompensation) &&
      !includes(exclude, 'traderCompensation')
        ? [
            {
              value: (
                <StylizedMoney
                  variant="h2"
                  value={statistic.traderCompensation}
                  code
                  assetCurrencyId={assetCurrencyId}
                />
              ),
              title: t('cards.cashback.title'),
            },
          ]
        : []),
      ...(isNumber(statistic.platformCompensation) &&
      !includes(exclude, 'platformCompensation')
        ? [
            {
              value: (
                <StylizedMoney
                  variant="h2"
                  value={statistic.platformCompensation}
                  code
                  assetCurrencyId={assetCurrencyId}
                />
              ),
              title: t('cards.platform_cashback.title'),
            },
          ]
        : []),
      ...(isNumber(statistic.tradersReferralsCompensation) &&
      !includes(exclude, 'tradersReferralsCompensation')
        ? [
            {
              value: (
                <StylizedMoney
                  variant="h2"
                  value={statistic.tradersReferralsCompensation}
                  code
                  assetCurrencyId={assetCurrencyId}
                />
              ),
              title: t('cards.traders_referrals_cashback.title'),
            },
          ]
        : []),
      ...(isNumber(statistic.totalCount) && !includes(exclude, 'totalCount')
        ? [
            {
              value: (
                <StylizedNumber
                  variant="h2"
                  value={statistic.totalCount}
                  unit={tCommon('units.items')}
                />
              ),
              title: t('cards.total_count.title'),
            },
          ]
        : []),
      {
        value: (
          <StylizedNumber
            variant="h2"
            value={statistic.completedCount}
            unit={tCommon('units.items')}
          />
        ),
        title: t('cards.completed_count.title'),
      },
      ...(isNumber(statistic.totalCount) && !includes(exclude, 'conversion')
        ? [
            {
              value: (
                <StylizedNumber
                  variant="h2"
                  value={statistic.conversion}
                  unit="%"
                />
              ),
              title: t('cards.conversion.title'),
            },
          ]
        : []),
      {
        value: (
          <StylizedMoney
            variant="h2"
            value={statistic.avgAmount}
            code
            assetCurrencyId={assetCurrencyId}
          />
        ),
        title: t('cards.avg_amount.title'),
      },
    ];
  }, [statistic, exclude, assetCurrencyId, t, tCommon]);

  return (
    <DataWrapper queryResult={queryResult}>
      <Grid container spacing={4}>
        {map(cards, (card) => (
          <Grid key={card.title} item xs={12} sm={6} lg={4} xl={3}>
            <InfoCard title={card.title} content={card.value} />
          </Grid>
        ))}
      </Grid>
    </DataWrapper>
  );
};
