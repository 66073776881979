import { useQuery } from 'react-query';

import { assetsApi } from 'api';
import { QueryKey } from 'enums';
import { InternalTransfersPage } from 'features/internal-transfers';

export const TraderInternalTransfersPage: React.FC = () => {
  const queryResultAssets = useQuery(QueryKey.MyAssets, assetsApi.getAllMy);

  return <InternalTransfersPage queryResultAssets={queryResultAssets} />;
};
