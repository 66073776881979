import { AxiosError } from 'axios';
import i18next from 'i18next';

import { StatusCode } from 'enums';

const getErrorMessage = (
  error: AxiosError<{ message: string | undefined }>,
) => {
  const status = error?.response?.status;
  if (status === StatusCode.BadRequest) {
    const message = error.response?.data?.message;
    if (message === 'Unable to create new account: no platform wallet') {
      return i18next.t('create_wallet.no_platform_wallet');
    } else if (message === 'Unable to create new account: transaction error') {
      return i18next.t('create_wallet.transaction_error');
    }
  }
};

export const walletsUtils = {
  getErrorMessage,
};
