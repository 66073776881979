import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { WebhookHistory } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/webhooks/archive`;

const resendAsRole = (role: UserRole) => async (webhookHistoryId: string) =>
  (await axios.post(`${url}/${role}/${webhookHistoryId}/resend`)).data;

export const webhooksHistoryApi = {
  ...createCrudApi<WebhookHistory>('/webhooks/archive'),
  resendAsRole,
};
