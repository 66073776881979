export enum AgentInfoType {
  Trader = 'trader',
  Merchant = 'merchant',
  Shop = 'shop',
}

export type OrderCashback = {
  orderId?: string;
  agentId: string;
  agent?: { name: string };
  amount: number;
  agentType: AgentInfoType;
};
