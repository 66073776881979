import i18next from 'i18next';
import { transform } from 'lodash';
import * as Yup from 'yup';

import { Math } from 'classes';

export const MAX_AGENTS = 5;

export const AGENTS_VALIDATION_SCHEMA = {
  agents: Yup.array()
    .of(
      Yup.object().shape({
        userId: Yup.string().required(i18next.t('errors.required')),
        payinCompensationPercentage: Yup.number().when('userId', {
          is: (agentId: string) => !!agentId,
          then: (schema) =>
            schema
              .required(i18next.t('errors.required'))
              .min(0, i18next.t('errors.percentage'))
              .lessThan(100, i18next.t('errors.percentage')),
        }),
        payoutCompensationPercentage: Yup.number().when('userId', {
          is: (agentId: string) => !!agentId,
          then: (schema) =>
            schema
              .required(i18next.t('errors.required'))
              .min(0, i18next.t('errors.percentage'))
              .lessThan(100, i18next.t('errors.percentage')),
        }),
      }),
    )
    .test(
      'payin-total-percentage',
      i18next.t('errors.max_commission'),
      (agents) => {
        const sum = transform(
          agents || [],
          (acc, agent) => {
            acc.plus(agent.payinCompensationPercentage || 0);
          },
          new Math(0),
        ).value;
        return sum < 100;
      },
    )
    .test(
      'payout-total-percentage',
      i18next.t('errors.max_commission'),
      (agents) => {
        const sum = transform(
          agents || [],
          (acc, agent) => {
            acc.plus(agent.payoutCompensationPercentage || 0);
          },
          new Math(0),
        ).value;
        return sum < 100;
      },
    )
    .max(MAX_AGENTS),
};
