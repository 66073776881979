import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'components';
import { ReferralInfo, ReferralList } from 'features';
import { TranslationNamespace } from 'i18n';

export const TraderReferralSystemPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.referral_system',
  });

  return (
    <div>
      <PageHeader title={t('title')} subtitle={t('description')} />
      <ReferralInfo />
      <ReferralList />
    </div>
  );
};
