import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { ActiveTab } from './ActiveTab';
import { ArchiveTab } from './ArchiveTab';

enum Tabs {
  Active = 'active',
  Archive = 'archive',
}

export const Webhooks: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.webhooks',
  });

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={Tabs}>
        <Tab value={Tabs.Active} label={t('tabs.active')}></Tab>
        <Tab value={Tabs.Archive} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={Tabs.Active}>
        <ActiveTab />
      </QueryTabPanel>
      <QueryTabPanel value={Tabs.Archive}>
        <ArchiveTab />
      </QueryTabPanel>
    </Fragment>
  );
};
