import { FC } from 'react';

import { UserRole } from 'enums';
import { useUser } from 'hooks';

import { TraderDropdown } from '../TraderDropdown';
import { UserDropdown } from '../UserDropdown';

interface Props {
  onLogout: () => void;
}

export const UserInfo: FC<Props> = ({ onLogout }) => {
  const { user } = useUser();

  if (user.role === UserRole.Trader) {
    return <TraderDropdown user={user} onLogout={onLogout} />;
  }

  return <UserDropdown user={user} onLogout={onLogout} active />;
};
