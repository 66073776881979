import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { Alert, Card, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { map, omit } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  requisitesApi,
  requisitesGroupApi,
  requisitesTelegramAutomationApi,
} from 'api';
import {
  ConfirmButton,
  CopyText,
  DataWrapper,
  EntityDetailsPage,
  EntityDetailsPageChildrenProps,
  FormControls,
  FormikPhoneInput,
  FormikRadioGroup,
  FormikTextField,
  RequisitesInfo,
} from 'components';
import { TELEGRAM_ERROR_MESSAGE } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, RequisitesTabs, StatusCode } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesTelegramAutomation } from 'types';

import { RequisitesTelegramAutomationSignInDialog } from './RequisitesTelegramAutomationSignInDialog';

enum AutomationOwner {
  Requisites,
  RequisitesGroup,
}

type Values = Pick<
  RequisitesTelegramAutomation,
  'name' | 'phone' | 'telegramPeer' | 'requisitesId' | 'requisitesGroupId'
> & {
  automationOwner: AutomationOwner;
};

export const TraderRequisitesTelegramAutomationDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites_telegram_automation',
  });
  const { t: tCommon } = useTranslation();

  const { role } = useUser();
  const [
    requisitesTelegramAutomationSignInDialogOpen,
    setRequisitesTelegramAutomationSignInDialogOpen,
  ] = useState(false);

  const queryResultRequisites = useQuery([QueryKey.Requisites, id], () =>
    requisitesApi.getAllMy(),
  );

  const queryResultRequisitesGroups = useQuery(QueryKey.RequisitesGroups, () =>
    requisitesGroupApi.getAllAsRole(role)(),
  );

  const loginMutation = useMutation(requisitesTelegramAutomationApi.login, {
    notifierType: 'execute',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (status === StatusCode.BadRequest) {
          const message = error.response?.data?.message;
          switch (message) {
            case TELEGRAM_ERROR_MESSAGE.AUTH_RESTART:
              return t('errors.auth_restart');
            case TELEGRAM_ERROR_MESSAGE.PHONE_NUMBER_FLOOD:
              return t('errors.phone_number_flood');
          }
        }
      },
    },
  });
  const logoutMutation = useMutation(requisitesTelegramAutomationApi.logout, {
    notifierType: 'execute',
  });

  const initialValues: Values = useMemo(
    () => ({
      name: '',
      phone: '',
      telegramPeer: '',
      requisitesId: '',
      requisitesGroupId: '',
      automationOwner: AutomationOwner.Requisites,
    }),
    [],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(tCommon('errors.required')),
  });

  const automationOwnerOptions = useMemo(
    () => [
      {
        label: t('fields.requisites'),
        value: AutomationOwner.Requisites,
      },
      {
        label: t('fields.requisites_group'),
        value: AutomationOwner.RequisitesGroup,
      },
    ],
    [t],
  );

  const requisitesOptions = useMemo(
    () =>
      map(queryResultRequisites.data, (requisite) => ({
        label: <RequisitesInfo compact canCopy={false} requisite={requisite} />,
        value: requisite.id,
      })),
    [queryResultRequisites.data],
  );

  const requisitesGroupsOptions = useMemo(
    () =>
      map(queryResultRequisitesGroups.data, (group) => ({
        label: group.name || group.id,
        value: group.id,
      })),
    [queryResultRequisitesGroups.data],
  );

  const handleLogin = useCallback(() => {
    loginMutation.mutate(id!, {
      onSuccess: () => {
        setRequisitesTelegramAutomationSignInDialogOpen(true);
      },
    });
  }, [id, loginMutation]);

  const handleLogout = useCallback(
    (queryResult: UseQueryResult) => {
      logoutMutation.mutate(id!, {
        onSuccess: () => {
          queryResult.refetch();
        },
      });
    },
    [id, logoutMutation],
  );

  const handleRequisitesTelegramAutomationSignInDialogClose =
    useCallback(() => {
      setRequisitesTelegramAutomationSignInDialogOpen(false);
    }, []);

  const initialValuesSelect = useCallback(
    (item: RequisitesTelegramAutomation): Values => ({
      ...item,
      automationOwner: item.requisitesGroupId
        ? AutomationOwner.RequisitesGroup
        : AutomationOwner.Requisites,
    }),
    [],
  );

  const onBeforeUpdateMutation = useCallback(
    ({
      id,
      data,
    }: {
      id: string;
      data: Values;
    }): { id: string; data: Partial<RequisitesTelegramAutomation> } => {
      let formattedData = omit(data, ['phone', 'automationOwner']);
      if (data.automationOwner === AutomationOwner.RequisitesGroup) {
        formattedData.requisitesId = null;
      } else {
        formattedData.requisitesGroupId = null;
      }
      return {
        id,
        data: formattedData,
      };
    },
    [],
  );

  return (
    <EntityDetailsPage
      title={t('title')}
      id={id!}
      api={requisitesTelegramAutomationApi}
      listUrl={ROUTE_PATH.TRADER.PAYIN_REQUISITES}
      tab={RequisitesTabs.TelegramAutomation}
      initialValues={initialValues}
      validationSchema={validationSchema}
      queryKey={QueryKey.RequisitesTelegramAutomation}
      initialValuesSelect={initialValuesSelect}
      onBeforeUpdateMutation={onBeforeUpdateMutation}
    >
      {({
        formik,
        queryResult,
      }: EntityDetailsPageChildrenProps<
        Values,
        RequisitesTelegramAutomation
      >) => (
        <div className="tw-max-w-xl">
          <Fragment>
            <FormikTextField
              name="name"
              fullWidth
              label={t('fields.name')}
              required
            />
            <FormControls sx={{ mt: 8 }}>
              <Typography variant="h5">
                {t('labels.telegram_account_settings')}
              </Typography>
              <FormikPhoneInput
                name="phone"
                label={t('fields.phone')}
                disabled
                fullWidth
              />
              {queryResult.data?.isAuthenticated ? (
                <div className="tw-flex tw-row">
                  <div className="tw-flex tw-items-center tw-mr-4">
                    <CheckCircleOutlineIcon color="success" />
                    <span className="tw-ml-2">{t('fields.logged_in')}</span>
                  </div>
                  <ConfirmButton
                    variant="outlined"
                    color="error"
                    size="small"
                    dialog={{
                      mutation: logoutMutation,
                    }}
                    onConfirm={() => handleLogout(queryResult)}
                  >
                    {t('buttons.logout')}
                  </ConfirmButton>
                </div>
              ) : (
                <div>
                  <ConfirmButton
                    variant="outlined"
                    size="small"
                    dialog={{
                      title: t('login_dialog.title'),
                      mutation: loginMutation,
                      maxWidth: 'sm',
                      children: (
                        <Alert severity="info">
                          <div className="tw-mb-4">
                            {t('labels.receive_code')}
                          </div>
                          <div className="tw-font-bold">
                            {t('labels.check_2fa')}
                          </div>
                        </Alert>
                      ),
                    }}
                    onConfirm={() => handleLogin()}
                  >
                    {t('buttons.login')}
                  </ConfirmButton>
                </div>
              )}
            </FormControls>
            <FormControls sx={{ mt: 8 }}>
              <Typography variant="h5">{t('labels.automation')}</Typography>
              <FormikTextField
                name="telegramPeer"
                fullWidth
                label={t('labels.automation')}
                helperText={
                  <div>
                    <div>{t('fields.telegram_peer_description')}</div>
                    <div>{`${t('labels.example')}:`}</div>
                    <div>
                      <CopyText text="@HUMOcardbot" />
                    </div>
                    <div>
                      <CopyText text="@CardXabarBot" />
                    </div>
                  </div>
                }
              />
            </FormControls>
            <FormControls sx={{ mt: 8 }}>
              <FormikRadioGroup
                label={t('fields.automation_owner')}
                name={'automationOwner'}
                options={automationOwnerOptions}
              ></FormikRadioGroup>
              {formik.values.automationOwner === AutomationOwner.Requisites && (
                <DataWrapper queryResult={queryResultRequisites}>
                  <Card sx={{ px: 4, py: 2, maxHeight: 300, overflow: 'auto' }}>
                    <FormikRadioGroup
                      sx={{ flexDirection: 'column' }}
                      formControlLabelSx={{ my: 2, width: '100%' }}
                      name="requisitesId"
                      options={requisitesOptions}
                    />
                  </Card>
                </DataWrapper>
              )}
              {formik.values.automationOwner ===
                AutomationOwner.RequisitesGroup && (
                <DataWrapper queryResult={queryResultRequisitesGroups}>
                  <Card sx={{ px: 4, py: 2, maxHeight: 300, overflow: 'auto' }}>
                    <FormikRadioGroup
                      sx={{ flexDirection: 'column' }}
                      formControlLabelSx={{ width: '100%' }}
                      name="requisitesGroupId"
                      options={requisitesGroupsOptions}
                    />
                  </Card>
                </DataWrapper>
              )}
            </FormControls>
          </Fragment>
          <RequisitesTelegramAutomationSignInDialog
            open={requisitesTelegramAutomationSignInDialogOpen}
            data={queryResult}
            onClose={handleRequisitesTelegramAutomationSignInDialogClose}
          />
        </div>
      )}
    </EntityDetailsPage>
  );
};
