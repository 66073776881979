import { AxiosError } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { sciDomainsApi } from 'api';
import {
  CloseFormikDialogData,
  CloseFormikDialogResult,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  PageHeader,
  dataGridColumns,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { SciDomain, SciDomainDto } from 'types';
import { validationUtils } from 'utils';

import { SciDomainsDialog } from './SciDomainsDialog';

export const SciDomainsPage: React.FC = () => {
  const queryClient = useQueryClient();
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.sci_domains',
  });

  const [createSciDomainDialogOpen, setCreateSciDomainDialogOpen] =
    useState(false);

  const queryResult = useQuery(
    [QueryKey.SciDomains],
    () => sciDomainsApi.getAll(),
    {},
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<SciDomain>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.url'),
        valueKey: 'url',
      },
      {
        header: t('fields.logo'),
        valueKey: 'logo',
      },
      {
        header: t('fields.loader'),
        valueKey: 'loader',
      },
      {
        header: t('fields.title'),
        valueKey: 'title',
      },
      {
        header: t('fields.copyright'),
        valueKey: 'copyright',
      },
      {
        header: t('fields.theme'),
        valueKey: 'theme',
      },
    ],
    [t],
  );

  const { mutate: update } = useMutation<
    SciDomain,
    AxiosError,
    { id: string; data: Partial<SciDomain> }
  >(sciDomainsApi.update);
  const handleUpdate = useCallback(
    (
      item: SciDomain,
      data: CloseFormikDialogData<Partial<SciDomain>>,
      closeDialog: () => void,
    ) => {
      update(
        { id: item.id, data: data?.values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.SciDomains);
            closeDialog();
          },
          onError: (error: AxiosError) => {
            data?.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [queryClient, update],
  );

  const { mutate: create } = useMutation<SciDomain, AxiosError, SciDomainDto>(
    sciDomainsApi.create,
  );
  const handleCreateClick = useCallback(() => {
    setCreateSciDomainDialogOpen(true);
  }, []);
  const handleCreateSciDomainDialogClose = useCallback(
    ({ data, ok }: CloseFormikDialogResult<SciDomainDto>) => {
      if (ok && data) {
        return create(data.values, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.SciDomains);
            data.formikHelpers.resetForm();
            setCreateSciDomainDialogOpen(false);
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        });
      }
      setCreateSciDomainDialogOpen(false);
    },
    [queryClient, create],
  );

  const { mutate: remove } = useMutation<SciDomain, AxiosError, string>(
    sciDomainsApi.remove,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.SciDomains);
      },
      notifierType: 'remove',
    },
  );

  return (
    <div>
      <PageHeader
        title={t('title')}
        rightContentButton={{
          onClick: handleCreateClick,
        }}
      />
      <CrudTable
        columns={columns}
        queryResult={queryResult}
        actions={[
          {
            type: CrudTableActionType.Details,
            renderDialog: SciDomainsDialog,
            onUpdate: handleUpdate,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ]}
      />
      <SciDomainsDialog
        open={createSciDomainDialogOpen}
        onClose={handleCreateSciDomainDialogClose}
      />
    </div>
  );
};
