import { TextField, TextFieldProps } from '@mui/material';
import { filter, toLower } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  items: any[];
  className?: string;
  labelGetter: (item: any) => string;
  children: (
    filteredItems: any[],
    renderInput: () => React.ReactNode,
  ) => React.ReactNode;
};

export const ListFilter: React.FC<Props> = ({
  items,
  className = 'tw-w-full',
  labelGetter,
  children,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const filteredItems = useMemo(() => {
    if (!value) {
      return items;
    }
    return filter(items, (item: any) =>
      toLower(labelGetter(item)).includes(toLower(value)),
    );
  }, [value, items, labelGetter]);

  const renderInput = useCallback(
    (props?: TextFieldProps) => (
      <TextField
        size="small"
        sx={{ mb: 3, maxWidth: 200 }}
        label={t('components.list_filter.label')}
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    ),
    [t, value],
  );

  return (
    <div className={className}>{children(filteredItems, renderInput)}</div>
  );
};
