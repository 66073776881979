import { findIndex } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  DataGrid,
  DataGridColumnDefinition,
  SidebarLayout,
  SidebarSize,
  OrderAutomationDetails,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { OrderAutomation, PaginatedData } from 'types';

type Props = {
  queryResult: UseQueryResult<PaginatedData<OrderAutomation>, unknown>;
  columns: DataGridColumnDefinition<OrderAutomation>[];
  selectedOrderAutomation: OrderAutomation | null;
  handleOrderAutomationSelection: (
    orderAutomation: OrderAutomation | null,
  ) => void;
};

export const OrderAutomationListWithAction: React.FC<Props> = ({
  queryResult,
  columns,
  selectedOrderAutomation,
  handleOrderAutomationSelection,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });

  const handleClose = useCallback(() => {
    handleOrderAutomationSelection(null);
  }, [handleOrderAutomationSelection]);

  const items = useMemo(() => queryResult.data?.items || [], [queryResult]);
  const selectedRowIndex = useMemo(
    () =>
      findIndex(items, {
        id: selectedOrderAutomation?.id,
      }),
    [items, selectedOrderAutomation?.id],
  );

  return (
    <SidebarLayout
      sidebarProps={{
        open: !!selectedOrderAutomation,
        title: t('details.title'),
        size: SidebarSize.md,
        onClose: handleClose,
        content: selectedOrderAutomation && (
          <OrderAutomationDetails
            orderAutomation={selectedOrderAutomation}
            queryResult={queryResult}
            onClose={handleClose}
          />
        ),
      }}
    >
      <DataGrid
        columns={columns}
        data={items}
        selectedRowIndex={selectedRowIndex}
        onRowClick={handleOrderAutomationSelection}
      />
    </SidebarLayout>
  );
};
