import React from 'react';

import { PayOutlet } from 'components';
import { ROUTE_PATH } from 'constants/routes';

export const MerchantOrdersPage: React.FC = () => (
  <PayOutlet
    payinTo={ROUTE_PATH.MERCHANT.PAYIN_ORDERS}
    payoutTo={ROUTE_PATH.MERCHANT.PAYOUT_ORDERS}
  />
);
