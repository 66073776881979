import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  FormControls,
} from 'components';
import { PAYOUT_DISPUTE_SELECT_DETAILS } from 'constants/order.constants';
import { PayoutDisputeStatusDetails } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';

import { DisputeSelect } from './DisputeSelect';

type Values = {
  statusDetails: PayoutDisputeStatusDetails;
};

type Props = {
  open: boolean;
  order: PayoutOrder;
  onClose: CloseDialogHandler<Values>;
};

export const DisputeDialog: React.FC<Props> = ({ open, order, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.dispute',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const prevOpen = usePrevious(open);

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        statusDetails: Yup.mixed<PayoutDisputeStatusDetails>()
          .oneOf(PAYOUT_DISPUTE_SELECT_DETAILS)
          .required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const defaultDetails = useMemo(
    () =>
      (PAYOUT_DISPUTE_SELECT_DETAILS.includes(
        order?.statusDetails as PayoutDisputeStatusDetails,
      )
        ? order.statusDetails
        : PAYOUT_DISPUTE_SELECT_DETAILS[0]) as PayoutDisputeStatusDetails,
    [order],
  );

  const [initialValues, setInitialValues] = useState<Values>({
    statusDetails: defaultDetails,
  });

  const handleClose = useCallback(
    (data: CloseDialogResult<Values>) => {
      onClose(data);
      if (data.data) {
        setInitialValues(data.data);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      handleClose({ ok: true, data: values });
    },
    [handleClose],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      setInitialValues({
        statusDetails: defaultDetails,
      });
    }
  }, [order, defaultDetails, open, prevOpen]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          okDisabled={!formik.isValid}
          data={formik.values}
          onClose={handleClose}
        >
          <Form>
            <FormControls>
              <DisputeSelect />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
