import React, { useCallback, useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { parsingMethodsApi } from 'api';
import { ParsingMethodType, QueryKey } from 'enums';
import { ParsingMethod, TradeMethod } from 'types';

const ParsingMethodsContext = React.createContext<{
  tradeMethod?: TradeMethod;
  parsingMethodsQuery: UseQueryResult<ParsingMethod[]>;
  type: ParsingMethodType;
} | null>(null);

export const ParsingMethodsProvider = ({
  children,
  tradeMethod,
  type,
}: {
  children: React.ReactNode;
  tradeMethod?: TradeMethod;
  type: ParsingMethodType;
}) => {
  const fetchParsingMethods = useCallback(() => {
    if (!tradeMethod) {
      return Promise.resolve([]);
    }

    return parsingMethodsApi.getAllByTradeMethodId({
      id: tradeMethod.id,
      type,
    });
  }, [tradeMethod, type]);

  const parsingMethodsQuery = useQuery<ParsingMethod[], unknown>({
    queryKey: [QueryKey.ParsingMethods, tradeMethod?.id, type],
    queryFn: () => fetchParsingMethods(),
    enabled: tradeMethod !== null,
  });

  return (
    <ParsingMethodsContext.Provider
      value={{
        tradeMethod,
        parsingMethodsQuery,
        type,
      }}
    >
      {children}
    </ParsingMethodsContext.Provider>
  );
};

export const useParsingMethods = () => {
  const context = useContext(ParsingMethodsContext);
  if (context === null) {
    throw new Error(
      'useParsingMethods must be used within a ParsingMethodsProvider',
    );
  }

  return context;
};
