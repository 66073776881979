import { SaveAsOutlined as SaveAsOutlinedIcon } from '@mui/icons-material';
import { SxProps, Tooltip } from '@mui/material';
import { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Network } from 'enums';
import { TranslationNamespace } from 'i18n';

import { UserWalletDetailsDialog } from '../UserWalletDetailsDialog';

type Props = {
  data?: {
    userId: string;
    address: string;
    network: Network;
  };
  sx?: SxProps;
};

export const CreateUserWalletIcon: React.FC<Props> = ({ data, sx }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.user_wallets.create_icon',
  });

  const [
    createUserWalletDetailsDialogOpen,
    setCreateUserWalletDetailsDialogOpen,
  ] = useState(false);

  return (
    <Fragment>
      <Tooltip title={t('title')}>
        <SaveAsOutlinedIcon
          fontSize="small"
          color="primary"
          className="tw-cursor-pointer"
          sx={sx}
          onClick={() => setCreateUserWalletDetailsDialogOpen(true)}
        />
      </Tooltip>
      <UserWalletDetailsDialog
        open={createUserWalletDetailsDialogOpen}
        initialData={data}
        onClose={() => setCreateUserWalletDetailsDialogOpen(false)}
      />
    </Fragment>
  );
};

type WrapperProps = Props & {
  enabled?: boolean;
  children?: ReactNode;
};

export const CreateUserWalletWrapper: React.FC<WrapperProps> = ({
  enabled = true,
  children,
  ...rest
}) =>
  enabled ? (
    <div className="tw-flex tw-items-center">
      <CreateUserWalletIcon sx={{ mr: 1 }} {...rest} />
      {children}
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
