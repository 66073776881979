import { Button, Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { filter, isNumber, keys, map, pick, some } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { payoutOrdersApi, payoutRequisitesApi } from 'api';
import {
  DataWrapper,
  FormikNumericField,
  PaymentTypeSelect,
  FormikSelect,
  FormikRadioGroup,
  RequisitesStatusLabel,
  FormControls,
  FormActions,
  BankSelect,
  RequisitesBlockedStatusLabel,
  FormikYesNoRadioGroup,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ACTIVE_REQUISITE_EXISTS_MESSAGE } from 'constants/requisites.constants';
import { ROUTE_PATH } from 'constants/routes';
import { DefaultPaymentTypeCode, QueryKey, RequisitesStatus } from 'enums';
import { useCurrencies, useMutation, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutRequisites } from 'types';
import {
  getAvailableValuesByTradeMethods,
  TradeMethodKeys,
  validationUtils,
} from 'utils';

type Props = {
  id: string;
};

type Values = Omit<PayoutRequisites, 'id' | 'createdAt'>;

const backUrl = ROUTE_PATH.TRADER.PAYOUT_REQUISITES;

export const PayoutRequisitesDetailsForm: React.FC<Props> = ({ id }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.requisites.details',
  });
  const { t: tList } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });

  const { t: tFields } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_details.fields',
  });

  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const navigate = useNavigate();

  const { fiatCurrenciesOptions } = useCurrencies();

  const { paymentTypes, tradeMethods, fiatCurrencies, banks } =
    useUserContext();

  const isNew = useMemo(() => id === NEW_ID, [id]);

  const [orderExistsByRequisites, setOrderExistsByRequisites] = useState(false);

  const limitFieldSchema = useMemo(
    () =>
      Yup.number()
        .moreThan(0, tCommon('errors.natural_number'))
        .required(tCommon('errors.required')),
    [tCommon],
  );

  const requisitesSchema = useMemo(
    () =>
      Yup.object().shape({
        paymentTypeId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        bankId: Yup.string().required(tCommon('errors.required')),
        anyBank: Yup.boolean().optional().nullable(),
        limits: Yup.object().shape({
          limitCount: limitFieldSchema,
          limitSum: limitFieldSchema,
        }),
        minTransactionSum: Yup.number()
          .required(tCommon('errors.required'))
          .min(0, tCommon('errors.natural_or_zero_number')),
        maxTransactionSum: limitFieldSchema.when('minTransactionSum', {
          is: (minTransactionSum: number) =>
            isNumber(minTransactionSum) && minTransactionSum > 0,
          then: (schema) =>
            schema.moreThan(
              Yup.ref('minTransactionSum'),
              tCommon('errors.more_minimum'),
            ),
        }),
        status: Yup.string().required(tCommon('errors.required')),
      }),
    [limitFieldSchema, tCommon],
  );

  const initialState = useMemo(
    () => ({
      status: RequisitesStatus.Inactive,
      limits: {
        limitCount: 0,
        limitSum: 0,
      },
      minTransactionSum: 0,
      maxTransactionSum: 0,
      paymentTypeId: '',
      fiatCurrencyId: '',
      bankId: '',
      anyBank: false,
    }),
    [],
  );

  const isTradeMethodSupported = useCallback(
    (values: Values) =>
      isNew ||
      some(tradeMethods, {
        fiatCurrencyId: values.fiatCurrencyId,
        paymentTypeId: values.paymentTypeId,
      }),
    [isNew, tradeMethods],
  );

  const [initialValues, setInitialValues] = useState<Values>(initialState);

  const queryResult = useQuery(
    [QueryKey.PayoutRequisites, id],
    () => payoutRequisitesApi.findOne(id as string),
    {
      enabled: !isNew,
      onSuccess: (data) => {
        const pickedData = pick(data, keys(initialState));
        setInitialValues(pickedData as Values);
      },
    },
  );

  const queryResultPayoutOrderExistsByRequisites = useQuery(
    [QueryKey.PayoutOrderExistsByRequisites, id],
    () => payoutOrdersApi.getTraderPayoutOrderExistsByRequisites(id),
    {
      enabled: !isNew,
      onSuccess: (data) => setOrderExistsByRequisites(data.exists),
    },
  );

  const isBlocked = useMemo(
    () => queryResult.data?.status === RequisitesStatus.Blocked,
    [queryResult],
  );

  const canChangeRequisites = useMemo(
    () =>
      isNew ||
      (!queryResultPayoutOrderExistsByRequisites.isLoading &&
        !orderExistsByRequisites),
    [
      isNew,
      orderExistsByRequisites,
      queryResultPayoutOrderExistsByRequisites.isLoading,
    ],
  );

  const isActiveRequisitesExistsError = useCallback(
    (error: AxiosError<any>) =>
      error.response?.data?.message === ACTIVE_REQUISITE_EXISTS_MESSAGE,
    [],
  );

  const { mutate: createRequisites } = useMutation<
    PayoutRequisites,
    AxiosError,
    Partial<PayoutRequisites>
  >(payoutRequisitesApi.create, {
    notifierMessages: {
      error: (error: AxiosError) => {
        if (isActiveRequisitesExistsError(error)) {
          return tList('messages.active_exists');
        }
      },
    },
  });

  const { mutate: updateRequisites } = useMutation<
    PayoutRequisites,
    AxiosError,
    { id: string; requisites: Partial<Values> }
  >(payoutRequisitesApi.update, {
    notifierMessages: {
      error: (error: AxiosError) => {
        if (isActiveRequisitesExistsError(error)) {
          return tList('messages.active_exists');
        }
      },
    },
  });

  const handleSubmit = useCallback(
    (requisites: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => navigate(backUrl),
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      if (isNew) {
        createRequisites(requisites, options);
      } else {
        updateRequisites({ id, requisites }, options);
      }
    },
    [isNew, navigate, createRequisites, updateRequisites, id],
  );

  const handleCancel = useCallback(() => navigate(backUrl), [navigate]);

  const requisitesOptions = useMemo(
    () =>
      map(
        [RequisitesStatus.Active, RequisitesStatus.Inactive],
        (status: RequisitesStatus.Active | RequisitesStatus.Inactive) => ({
          value: status,
          label: <RequisitesStatusLabel status={status} />,
        }),
      ),
    [],
  );

  const getAvailableValues = useCallback(
    <T extends { id: string }, V extends Values>(
      values: V,
      data: T[],
      property: TradeMethodKeys,
    ) =>
      getAvailableValuesByTradeMethods<T, V>(
        tradeMethods,
        values,
        data,
        property,
      ),
    [tradeMethods],
  );

  const getAvailablePaymentTypes = useCallback(
    (values: Values) =>
      getAvailableValues(values, paymentTypes, 'paymentTypeId'),
    [paymentTypes, getAvailableValues],
  );

  const anyBankPaymentTypesIds = useMemo(
    () =>
      filter(
        paymentTypes,
        (paymentType) =>
          paymentType.code === DefaultPaymentTypeCode.SBP ||
          paymentType.code === DefaultPaymentTypeCode.ERIP,
      ).map((paymentType) => paymentType.id),
    [paymentTypes],
  );

  const getAvailableFiatCurrencies = useCallback(
    (values: Values) =>
      getAvailableValues(values, fiatCurrencies, 'fiatCurrencyId'),
    [fiatCurrencies, getAvailableValues],
  );

  const getAvailableFiatCurrenciesOptions = useCallback(
    (values: Values) => {
      const availableItems = getAvailableFiatCurrencies(values);
      return filter(fiatCurrenciesOptions, (option) =>
        some(availableItems, (item) => item.id === option.value),
      );
    },
    [fiatCurrenciesOptions, getAvailableFiatCurrencies],
  );

  const getAvailableBanks = useCallback(
    (values: Values) => getAvailableValues(values, banks, 'bankId'),
    [banks, getAvailableValues],
  );

  const handlePaymentTypeChange = useCallback(
    (paymentTypeId: string, formik: FormikProps<Values>) => {
      const anyBankFieldMeta = formik.getFieldMeta('anyBank');
      if (!anyBankFieldMeta.touched) {
        formik.setFieldValue(
          'anyBank',
          anyBankPaymentTypesIds.includes(paymentTypeId),
        );
      }
    },
    [anyBankPaymentTypesIds],
  );

  return (
    <DataWrapper queryResult={!isNew ? queryResult : undefined}>
      <Formik
        initialValues={initialValues}
        validationSchema={requisitesSchema}
        onSubmit={handleSubmit}
        validateOnChange
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form className="tw-max-w-md">
            {!canChangeRequisites && (
              <Alert severity="warning" className="tw-mb-4">
                {t('requisites_in_use')}
              </Alert>
            )}
            {!isTradeMethodSupported(formik.values) && (
              <Alert severity="warning">
                {tCommon('features.requisites.error.trade_method_unavailable')}
              </Alert>
            )}
            <FormControls>
              {!isBlocked && (
                <FormikRadioGroup
                  label={tFields('status')}
                  name="status"
                  options={requisitesOptions}
                  disabled={isBlocked}
                />
              )}

              {isBlocked && queryResult.data && (
                <RequisitesBlockedStatusLabel
                  status={queryResult.data.status}
                  statusDetails={queryResult.data.statusDetails}
                  blockedAt={queryResult.data.blockedAt}
                />
              )}

              <FormikSelect
                label={tFields('currency')}
                name="fiatCurrencyId"
                options={getAvailableFiatCurrenciesOptions(formik.values)}
                disabled={!canChangeRequisites}
                noneOption
                required
              />

              <PaymentTypeSelect
                name="paymentTypeId"
                paymentTypes={getAvailablePaymentTypes(formik.values)}
                disabled={!canChangeRequisites}
                noneOption
                required
                onChange={(value) => {
                  handlePaymentTypeChange(value, formik);
                }}
              />

              <BankSelect
                name="bankId"
                banks={getAvailableBanks(formik.values)}
                disabled={!canChangeRequisites}
                required
                noneOption
              />
              <FormikYesNoRadioGroup
                label={tFields('any_bank')}
                name="anyBank"
              />

              <FormikNumericField
                name="minTransactionSum"
                label={tFields('limit_min_transaction_sum')}
                allowNegative={false}
                required
                fullWidth
              />
              <FormikNumericField
                name="maxTransactionSum"
                label={tFields('limit_max_transaction_sum')}
                allowNegative={false}
                required
                fullWidth
              />

              <FormikNumericField
                name="limits.limitSum"
                label={tFields('limit_sum')}
                allowNegative={false}
                required
                fullWidth
              />

              <FormikNumericField
                name="limits.limitCount"
                label={tFields('limit_count')}
                decimalScale={0}
                allowNegative={false}
                required
                fullWidth
              />
            </FormControls>
            <FormActions>
              <Button variant="outlined" onClick={handleCancel}>
                {tCommon('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formik.isSubmitting}
                onClick={formik.submitForm}
              >
                {tCommon('buttons.save')}
              </Button>
            </FormActions>
          </Form>
        )}
      </Formik>
    </DataWrapper>
  );
};
