export enum NotificationEventGroup {
  FundsRequests = 'funds_requests',
  PayinOrders = 'payin_orders',
  PayoutOrders = 'payout_orders',
  Automation = 'automation',
}

export enum NotificationEvent {
  FundsRequestCreate = 'funds_request_create',
  FundsRequestComplete = 'funds_request_complete',
  PayinOrderCancel = 'payin_order_cancel',
  PayinOrderComplete = 'payin_order_complete',
  PayinOrderCustomerConfirm = 'payin_order_customer_confirm',
  PayinOrderTraderConfirm = 'payin_order_trader_confirm',
  PayinOrderDispute = 'payin_order_dispute',
  PayinOrderCancelCompleted = 'payin_order_cancel_completed',
  PayinOrderCreatedDispute = 'payin_order_created_dispute',
  PayinOrderDisputeTraderConfirmTimeout = 'payin_order_dispute_trader_confirm_timeout',
  PayinOrderCancelledTraderConfirmTimeout = 'payin_order_cancel_trader_confirm_timeout',
  PayinOrderResolveDisputeComplete = 'payin_order_resolve_dispute_complete',
  PayinOrderResolveDisputeCancel = 'payin_order_resolve_dispute_cancel',
  PayinOrderCompletedByAutomation = 'payin_order_completed_by_automation',
  PayinDisableUserConsecutiveCancellations = 'payin_disable_user_consecutive_cancellations',
  PayinBlockTraderRequisites = 'payin_block_trader_requisites',
  PayinUnblockTraderRequisites = 'payin_unblock_trader_requisites',
  PayoutBlockTraderRequisites = 'payout_block_trader_requisites',
  PayoutUnblockTraderRequisites = 'payout_unblock_trader_requisites',
  PayoutDisableUserConsecutiveDisputes = 'payout_disable_user_consecutive_disputes',
  PayoutOrderCreatedDispute = 'payout_order_created_dispute',
  PayoutOrderTraderAccept = 'payout_order_trader_accept',
  PayoutOrderDispute = 'payout_order_dispute',
  PayoutOrderTraderAcceptTimeout = 'payout_order_trader_accept_timeout',
  PayoutOrderDisputeTraderPaymentTimeout = 'payout_order_dispute_trader_payment_timeout',
  PayoutOrderCancel = 'payout_order_cancel',
  PayoutOrderComplete = 'payout_order_complete',
  AutomationGroupDisconnected = 'automation_group_disconnected',
  RequisitesTelegramAutomationUnauthorized = 'requisites_telegram_automation_unauthorized',
}
