import axios from 'axios';

import { env } from 'config';
import { AutomationSource, PaginatedData, QueryParams } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/automation-sources`;

const getAllPaginatedByTradeMethodId =
  (id: string) =>
  async (params?: QueryParams): Promise<PaginatedData<AutomationSource>> =>
    (await axios.get(`${url}/trade-method/${id}`, { params })).data;

export const automationSourcesApi = {
  ...createCrudApi<AutomationSource>('/automation-sources'),
  getAllPaginatedByTradeMethodId,
};
