import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';

export const DRAWER_WIDTH = 265;
export const DRAWER_COLLAPSED_WIDTH = 16;
export const DRAWER_TRANSITION = '300ms cubic-bezier(0, 0, 0.2, 1) 0ms';

export const AppContent = styled.div<{ open: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  transition: padding-left ${DRAWER_TRANSITION};
  will-change: padding-left;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: ${(props) =>
      props.open ? DRAWER_WIDTH : DRAWER_COLLAPSED_WIDTH}px;
  }
`;

export const GlobalStyle = (props: any) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }
    `}
  />
);

export const MainContent = styled(styled(MuiPaper)(spacing))`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  overflow-x: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;
