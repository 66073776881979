import {
  AutoMode as AutoModeIcon,
  WarningAmber as WarningAmberIcon,
  InfoOutlined as InfoOutlinedIcon,
  Block as BlockIcon,
  CreditCard as CreditCardIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { find, map, some } from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions, useQuery, useQueryClient } from 'react-query';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { requisitesApi, tradersApi } from 'api';
import { requisitesGroupApi } from 'api/requisites-groups.api';
import {
  DataGridColumnDefinition,
  RequisitesInfo,
  dataGridColumns,
  Dialog,
  CloseDialogResult,
  DescriptionRow,
  RequisitesStatusInfo,
  DateLabel,
  CrudPage,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import {
  FilterDefinitionType,
  QueryKey,
  RequisitesStatus,
  StatusCode,
  OrderAutomationType,
} from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, Requisites } from 'types';
import { formUtils, formatUtils, requisitesUtils } from 'utils';

import { BlockRequisitesDialog } from '../BlockRequisitesDialog';
import { UnblockRequisitesDialog } from '../UnblockRequisitesDialog';

type RequisitesFilters = Partial<{
  traderId: string;
  status: RequisitesStatus;
  bankId: string;
  groupId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
}>;

type Values = {
  orderAutomationType: OrderAutomationType | undefined;
};

type Props = {
  archived?: boolean;
};

export const RequisitesList: FC<Props> = ({ archived }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();

  const {
    isOperator,
    isAdmin,
    isTrader,
    isTechOperator,
    isAdminOrTechOperator,
    isManager,
    role,
  } = useUser();
  const { tradeMethods, banks, paymentTypes } = useUserContext();

  const navigate = useNavigate();

  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const [selectedOrderAutomationType, setSelectedOrderAutomationType] =
    useState<OrderAutomationType>();

  const [orderAutomationDialogProps, setOrderAutomationDialogProps] = useState<{
    open: boolean;
    data?: Requisites;
  }>({ open: false });

  const [blockDialogProps, setBlockDialogProps] = useState<{
    open: boolean;
    requisites?: Requisites;
  }>({ open: false });

  const [unblockDialogProps, setUnblockDialogProps] = useState<{
    open: boolean;
    requisites?: Requisites;
  }>({ open: false });

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: Requisites;
  }>({ open: false });

  const detailsUrl = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.PAYIN_REQUISITES_DETAILS;
    }
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.PAYIN_REQUISITES_DETAILS;
    }
    if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES_DETAILS;
    }

    return '';
  }, [isTrader, isTechOperator, isAdmin]);

  const queryFn = useMemo(() => {
    if (isAdminOrTechOperator) {
      if (archived) {
        return requisitesApi.getAllArchived;
      }
      return requisitesApi.getAll;
    }

    if (isOperator) {
      if (archived) {
        return requisitesApi.getAllAsOperatorArchived;
      }
      return requisitesApi.getAllAsOperator;
    }

    return requisitesApi.getAllMyPaginated;
  }, [archived, isAdminOrTechOperator, isOperator]);

  const queryResult = usePartialQuery(QueryKey.Requisites, queryFn);

  const { data: groups = [] } = useQuery(QueryKey.RequisitesGroups, () =>
    requisitesGroupApi.getAllAsRole(role)(),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: isManager,
    },
  );

  const { mutate: archive, isLoading } = useMutation(requisitesApi.archive, {
    onSuccess: () => {
      setConfirmRemoveDialogProps({ open: false });
      queryClient.invalidateQueries(QueryKey.Requisites);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return t('remove_dialog.errors.in_use');
        }
      },
    },
  });

  const { mutate: updateStatus } = useMutation<
    Requisites,
    AxiosError,
    { id: string; requisites: Partial<Requisites> }
  >(requisitesApi.updateStatus, {
    notifierType: 'execute',
  });

  const getGroup = useCallback((id?: string) => find(groups, { id }), [groups]);

  const selectedRequisiteGroup = useMemo(
    () => getGroup(orderAutomationDialogProps?.data?.groupId),
    [getGroup, orderAutomationDialogProps?.data?.groupId],
  );

  const handleStatusChange = useCallback(
    (
      id: string,
      status: RequisitesStatus,
      options?: MutateOptions<any, any, any, any>,
    ) =>
      updateStatus(
        {
          id,
          requisites: { status },
        },
        options,
      ),
    [updateStatus],
  );

  const handleOrderAutomation = useCallback((item: Requisites) => {
    setOrderAutomationDialogProps({ open: true, data: item });
    setSelectedOrderAutomationType(item.orderAutomationType);
  }, []);

  const handleOpenDetails = useCallback(
    (requisitesId: string) => {
      const url = generatePath(detailsUrl, {
        id: requisitesId,
      });
      navigate(url);
    },
    [detailsUrl, navigate],
  );

  const handleRemove = useCallback((item: Requisites) => {
    setConfirmRemoveDialogProps({ open: true, data: item });
  }, []);

  const isTradeMethodSupported = useCallback(
    (requisites: Requisites) =>
      some(tradeMethods, {
        fiatCurrencyId: requisites.fiatCurrencyId,
        paymentTypeId: requisites.paymentTypeId,
        bankId: requisites.bankId,
      }),
    [tradeMethods],
  );

  const handleAutomationTypeChange = useCallback(
    (e: any, value: OrderAutomationType) => {
      if (value) {
        setSelectedOrderAutomationType(value);
      }
    },
    [setSelectedOrderAutomationType],
  );

  const handleCloseUnblockDialog = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok) {
        queryClient.invalidateQueries(QueryKey.Requisites);
      }
      setUnblockDialogProps({ open: false });
    },
    [queryClient],
  );

  const handleCloseBlockDialog = useCallback(
    (result: CloseDialogResult<Requisites>) => {
      if (result.ok) {
        queryClient.invalidateQueries(QueryKey.Requisites);
      }
      setBlockDialogProps({ open: false });
    },
    [queryClient],
  );

  const renderBlockRequisitesAction = useCallback(
    (requisites: Requisites) =>
      isAdminOrTechOperator &&
      requisites.status !== RequisitesStatus.Blocked && (
        <IconButton
          color="error"
          onClick={() => setBlockDialogProps({ open: true, requisites })}
        >
          <BlockIcon />
        </IconButton>
      ),
    [isAdminOrTechOperator],
  );

  const renderUnblockRequisitesAction = useCallback(
    (requisites: Requisites) =>
      isAdminOrTechOperator &&
      requisites.status === RequisitesStatus.Blocked && (
        <IconButton
          color="success"
          onClick={() => setUnblockDialogProps({ open: true, requisites })}
        >
          <CreditCardIcon />
        </IconButton>
      ),
    [isAdminOrTechOperator],
  );

  const renderRequisitesAutomationAction = useCallback(
    (requisites: Requisites) =>
      isTrader && (
        <IconButton
          color="primary"
          onClick={() => {
            handleOrderAutomation(requisites);
          }}
        >
          <AutoModeIcon />
        </IconButton>
      ),
    [isTrader, handleOrderAutomation],
  );

  const renderEditRequisitesAction = useCallback(
    (requisites: Requisites) => (
      <IconButton
        disabled={requisites.status === RequisitesStatus.Blocked}
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDetails(requisites.id);
        }}
      >
        <EditIcon />
      </IconButton>
    ),
    [handleOpenDetails],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Requisites>[] => [
      {
        header: t('table.columns.status'),
        valueGetter: (item) => (
          <div className="tw-flex tw-items-center tw-flex-nowrap">
            <RequisitesStatusInfo
              requisites={item}
              canChangeStatus={isTrader}
              updateStatus={handleStatusChange}
            />
            {item.inactiveStatusReason && (
              <Tooltip
                title={`${tCommon(
                  'features.requisites.error.inactive_status_reason.prefix',
                )}: ${tCommon(
                  `features.requisites.error.inactive_status_reason.${item.inactiveStatusReason}`,
                )}`}
              >
                <InfoOutlinedIcon color="warning" />
              </Tooltip>
            )}
            {!isTradeMethodSupported(item) && (
              <Tooltip
                title={tCommon(
                  'features.requisites.error.trade_method_unavailable',
                )}
              >
                <WarningAmberIcon color="warning" />
              </Tooltip>
            )}
          </div>
        ),
      },
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.trader_name'),
        valueGetter: (item) => item.user?.name,
        hidden: isTrader,
      },
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => <RequisitesInfo requisite={item} />,
      },
      ...requisitesUtils.getLimitsColumns(),
      {
        header: t('table.columns.group'),
        valueKey: 'groupId',
        valueFormatter: (value) => find(groups, { id: value })?.name,
        valueClassName: 'tw-w-[100px]',
      },
      {
        header: t('table.columns.order_automation_type'),
        valueGetter: (item) => {
          const group = getGroup(item.groupId);
          return (
            <Fragment>
              {!group && (
                <Fragment>
                  {requisitesUtils.getOrderAutomationTypeLabel(
                    item.orderAutomationType,
                  )}
                </Fragment>
              )}
              {group && (
                <Fragment>
                  {requisitesUtils.getOrderAutomationTypeLabel(
                    group.orderAutomationType,
                  )}
                  <div>{`(${t('table.columns.group')})`}</div>
                </Fragment>
              )}
            </Fragment>
          );
        },
      },
      {
        header: t('table.columns.completed_orders_in_minutes'),
        valueGetter: (item) =>
          item.completedOrdersInMinutes ? (
            <div>
              <div>{`${item.completedOrdersInMinutes} ${tCommon(
                'common.minutes',
              )}`}</div>
              <DateLabel>
                {formatUtils.formatDate(item.lastCompletedOrderAt)}
              </DateLabel>
            </div>
          ) : (
            tCommon('common.disabled')
          ),
        valueClassName: 'tw-w-[150px]',
      },
      dataGridColumns.getActionsColumn({
        hidden: archived,
        children: (item) => (
          <Fragment>
            {renderBlockRequisitesAction(item)}
            {renderUnblockRequisitesAction(item)}
            {renderRequisitesAutomationAction(item)}
            {renderEditRequisitesAction(item)}
          </Fragment>
        ),
        handleRemove,
      }),
    ],
    [
      archived,
      getGroup,
      groups,
      handleRemove,
      handleStatusChange,
      isTradeMethodSupported,
      isTrader,
      renderBlockRequisitesAction,
      renderRequisitesAutomationAction,
      renderEditRequisitesAction,
      renderUnblockRequisitesAction,
      t,
      tCommon,
    ],
  );

  const filtersDefinitions: FilterDefinition<RequisitesFilters>[] = useMemo(
    () => [
      {
        label: t('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.user?.name,
        hidden: isTrader,
      },
      {
        label: t('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Select,
        options: map(
          requisitesUtils.getStatusOptions(archived),
          (value: RequisitesStatus) => ({
            value,
            label: requisitesUtils.getStatusLabel(value),
          }),
        ),
        getDisplayName: requisitesUtils.getStatusLabel,
      },
      {
        label: t('filters.group'),
        name: 'groupId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(groups),
        getDisplayName: (groupId: string) =>
          find(groups, { id: groupId })?.name,
      },
      {
        label: t('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (paymentType: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: paymentType })!,
          ),
      },
      {
        label: t('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<RequisitesFilters>(),
    ],
    [
      t,
      queryResultTraders.data,
      isTrader,
      banks,
      archived,
      groups,
      paymentTypes,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
    ],
  );

  const { mutate: updateOrderAutomation } = useMutation<
    Requisites,
    AxiosError,
    { id: string; requisites: Values }
  >(requisitesApi.updateOrderAutomation);

  const handleCloseOrderAutomationDialog = useCallback(
    (result: CloseDialogResult<Requisites>) => {
      const options = {
        onSuccess: () => {
          setOrderAutomationDialogProps({ open: false });
          queryClient.invalidateQueries(QueryKey.Requisites);
        },
        onError: (error: AxiosError) => {}, // handle save error
      };

      if (result.ok && result.data) {
        updateOrderAutomation(
          {
            id: result.data.id,
            requisites: { orderAutomationType: selectedOrderAutomationType },
          },
          options,
        );
      } else {
        setOrderAutomationDialogProps({ open: false });
      }
    },
    [selectedOrderAutomationType, updateOrderAutomation, queryClient],
  );

  const handleCloseRemoveDialog = useCallback(
    (result: CloseDialogResult<Requisites>) => {
      if (result.ok && result.data) {
        archive(result.data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [archive],
  );

  return (
    <Fragment>
      <CrudPage
        filters={{ filtersDefinitions }}
        table={{ queryResult, paginated: true, columns }}
      />

      <Dialog
        title={t('order_automation_dialog.title')}
        disabled={isLoading}
        onClose={handleCloseOrderAutomationDialog}
        okLabel={tCommon('buttons.save')}
        {...orderAutomationDialogProps}
      >
        {orderAutomationDialogProps.data && (
          <Stack direction="column" spacing={6}>
            <DescriptionRow
              title={t('order_automation_dialog.api_token')}
              value={orderAutomationDialogProps.data.automationApiKey}
              canCopy
            />
            <Stack direction="column" spacing={0.5}>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                {t('order_automation_dialog.automation_type')}
              </Typography>
              {!selectedRequisiteGroup && (
                <ToggleButtonGroup
                  color="primary"
                  value={selectedOrderAutomationType}
                  exclusive
                  size="small"
                  onChange={handleAutomationTypeChange}
                >
                  <ToggleButton value={OrderAutomationType.Inactive}>
                    {t('order_automation_types.inactive')}
                  </ToggleButton>
                  <ToggleButton value={OrderAutomationType.Manual}>
                    {t('order_automation_types.manual')}
                  </ToggleButton>
                  <ToggleButton value={OrderAutomationType.Active}>
                    {t('order_automation_types.active')}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              {selectedRequisiteGroup && (
                <div>
                  {requisitesUtils.getOrderAutomationTypeLabel(
                    selectedRequisiteGroup.orderAutomationType,
                  )}
                  <span>{` (${t('table.columns.group')})`}</span>
                </div>
              )}
            </Stack>
          </Stack>
        )}
      </Dialog>

      {isAdminOrTechOperator && (
        <Fragment>
          <BlockRequisitesDialog
            {...blockDialogProps}
            onClose={handleCloseBlockDialog}
          />
          <UnblockRequisitesDialog
            {...unblockDialogProps}
            onClose={handleCloseUnblockDialog}
          />
        </Fragment>
      )}

      <Dialog
        title={t('remove_dialog.title')}
        disabled={isLoading}
        onClose={handleCloseRemoveDialog}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          {confirmRemoveDialogProps.data && (
            <RequisitesInfo
              bankId={confirmRemoveDialogProps.data.bankId}
              cardInfo={confirmRemoveDialogProps.data.cardInfo}
              phone={confirmRemoveDialogProps.data.phone}
              paymentTypeId={confirmRemoveDialogProps.data.paymentTypeId}
              cardholder={confirmRemoveDialogProps.data.cardholder}
              swiftBic={confirmRemoveDialogProps.data.swiftBic}
              bic={confirmRemoveDialogProps.data.bic}
              email={confirmRemoveDialogProps.data.email}
              idCard={confirmRemoveDialogProps.data.idCard}
              beneficiaryName={confirmRemoveDialogProps.data.beneficiaryName}
              accountNumber={confirmRemoveDialogProps.data.accountNumber}
              name={confirmRemoveDialogProps.data.name}
              expirationDate={confirmRemoveDialogProps.data.expirationDate}
              taxId={confirmRemoveDialogProps.data.taxId}
            />
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
