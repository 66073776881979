import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { groupsApi, operatorsApi, shopsApi, tradersApi } from 'api';
import {
  CloseDialogResult,
  CopyTextId,
  CrudPage,
  DataGridColumnDefinition,
  Dialog,
  dataGridColumns,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, Group } from 'types';

type GroupsFilters = {
  name: string;
  groupId: string;
  traderId: string;
  shopId: string;
  operatorId: string;
};

export const GroupsPage: React.FC = () => {
  // TODO: move tKeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.groups',
  });
  const { role, isAdmin, isTechOperator } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const queryResult = usePartialQuery(
    QueryKey.Groups,
    groupsApi.getAllPaginated,
  );

  const queryResultTraders = useQuery(QueryKey.Traders, () =>
    tradersApi.getAllAsRole(role)(),
  );
  const queryResultShops = useQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultOperators = useQuery(QueryKey.Operators, () =>
    operatorsApi.getAll(),
  );

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: Group;
  }>({
    open: false,
  });

  const { mutate: removeGroup, isLoading: isRemoveLoading } = useMutation(
    groupsApi.remove,
    {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.Groups);
      },
    },
  );

  const detailsPage = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.GROUP_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.GROUP_DETAILS;
    }
  }, [isAdmin, isTechOperator]);

  const handleOpenDetails = useCallback(
    (id: string) => {
      navigate(generatePath(detailsPage!, { id }));
    },
    [navigate, detailsPage],
  );

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<Group>) => {
      if (ok && data) {
        removeGroup(data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [removeGroup],
  );

  const handleRemove = useCallback((data: Group) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const columns = useMemo(
    (): DataGridColumnDefinition<Group>[] => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.traders'),
        multiValueRenderer: {
          itemsGetter: (item) => item.traders,
          valueGetter: (trader) => (
            <div key={trader.id}>{trader.user?.name}</div>
          ),
        },
      },
      {
        header: t('fields.shops'),
        multiValueRenderer: {
          itemsGetter: (item) => item.shops,
          valueGetter: (shop) => <div key={shop.id}>{shop.name}</div>,
        },
      },
      {
        header: t('fields.operators'),
        multiValueRenderer: {
          itemsGetter: (item) => item.operators,
          valueGetter: (operator) => (
            <div key={operator.id}>{operator.user?.name}</div>
          ),
        },
      },
      {
        header: t('fields.operations'),
        valueGetter: (item) => (
          <Fragment>
            {item.payin && <div>{t('fields.payin')}</div>}
            {item.payout && <div>{t('fields.payout')}</div>}
          </Fragment>
        ),
      },
      dataGridColumns.getActionsColumn({
        handleEdit: (item) => handleOpenDetails(item.id),
        handleRemove,
      }),
    ],
    [handleOpenDetails, handleRemove, t],
  );

  const filtersDefinitions: FilterDefinition<GroupsFilters>[] = useMemo(
    () => [
      {
        label: t('filters.id'),
        name: 'groupId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.name'),
        name: 'name',
        type: FilterDefinitionType.Text,
      },
      {
        label: t('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.user?.name,
      },
      {
        label: t('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        users: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: t('filters.operator'),
        name: 'operatorId',
        type: FilterDefinitionType.Operator,
        operators: queryResultOperators.data,
        getDisplayName: (operatorId: string) =>
          find(queryResultOperators.data, { id: operatorId })?.user?.name,
      },
    ],
    [
      queryResultShops.data,
      queryResultTraders.data,
      queryResultOperators.data,
      t,
    ],
  );

  const handleCreate = useCallback(() => {
    handleOpenDetails(NEW_ID);
  }, [handleOpenDetails]);

  return (
    <Fragment>
      <CrudPage
        header={{
          title: t('title'),
          rightContentButton: { onClick: handleCreate },
        }}
        filters={{ filtersDefinitions }}
        table={{ queryResult, columns, paginated: true }}
      />
      <Dialog
        title={t('remove_dialog.title')}
        onClose={handleCloseRemoveDialog}
        disabled={isRemoveLoading}
        {...confirmRemoveDialogProps}
      >
        <Fragment>
          {confirmRemoveDialogProps.data && (
            <div>{confirmRemoveDialogProps.data.name}</div>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
