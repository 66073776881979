import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { get, map, filter } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { MultiValue } from './MutliValue';
import { DataGridColumnDefinition } from './types';

type Props = {
  columns: DataGridColumnDefinition[];
  data?: any[];
  selectedRowIndex?: number;
  onRowClick?: (item?: any) => void;
};

export const DataGrid: React.FC<Props> = ({
  columns,
  data,
  selectedRowIndex,
  onRowClick,
}) => {
  const getValue = useCallback(
    (definition: DataGridColumnDefinition, item: any) => {
      if (definition.valueKey) {
        const value = get(item, definition.valueKey);
        return definition.valueFormatter
          ? definition.valueFormatter(value)
          : value;
      } else if (definition.valueGetter) {
        return definition.valueGetter(item, definition, data as any[]);
      } else if (definition.multiValueRenderer) {
        return (
          <MultiValue
            item={item}
            definition={definition}
            data={data as any[]}
          />
        );
      }
    },
    [data],
  );

  const visibleColumns = useMemo(
    () => filter(columns, (column) => !column.hidden),
    [columns],
  );

  const isRowClickable = useMemo(() => !!onRowClick, [onRowClick]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {map(visibleColumns, (column, index) => (
              <TableCell key={index}>{column.header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(data, (item, rowIndex) => (
            <TableRow
              sx={{ ...(isRowClickable && { cursor: 'pointer' }) }}
              key={rowIndex}
              onClick={() => {
                onRowClick?.(item);
              }}
              hover={isRowClickable}
              selected={selectedRowIndex === rowIndex}
            >
              {map(visibleColumns, (column, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  align={column.valueAlign}
                  className={column.valueClassName}
                  onClick={() => {
                    column.onClick?.(item);
                  }}
                >
                  {getValue(column, item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
