export enum AnomalyType {
  AssetBalanceHistoryBalanceSum = 'asset_balance_history_balance_sum',
  AssetBalanceHistoryHoldBalanceSum = 'asset_balance_history_hold_balance_sum',
  AssetBalanceHistoryBalanceSequence = 'asset_balance_history_balance_sequence',
  AssetBalanceHistoryHoldBalanceSequence = 'asset_balance_history_hold_balance_sequence',
  AssetBalanceShopReconciliation = 'asset_balance_shop_reconciliation',
  OrderPrevAmount = 'order_prev_amount',
  OrderSameStatus = 'order_same_status',
  InternalPlatformWalletWithdrawalTransaction = 'internal_platform_wallet_withdrawal_transaction',
}
