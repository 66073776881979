import { Tab } from '@mui/material';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { PageHeader, QueryTabs, QueryTabPanel } from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { RequisitesTabs } from 'enums';
import { useQueryTab, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { RequisitesGroupList } from './RequisitesGroupList';
import { RequisitesList } from './RequisitesList';
import {
  CreateTelegramAutomationDialog,
  RequisitesTelegramAutomation,
} from './RequisitesTelegramAutomation';

export const RequisitesPage: FC = () => {
  const { tab } = useQueryTab<RequisitesTabs>();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites',
  });

  const [telegramAutomationDialogOpen, setTelegramAutomationDialogOpen] =
    useState(false);

  const { isTrader } = useUser();

  const navigate = useNavigate();

  const handleCreateRequisites = useCallback(() => {
    navigate(
      generatePath(ROUTE_PATH.TRADER.PAYIN_REQUISITES_DETAILS, {
        id: NEW_ID,
      }),
    );
  }, [navigate]);

  const handleCreateRequisitesGroup = useCallback(() => {
    navigate(
      generatePath(ROUTE_PATH.TRADER.PAYIN_REQUISITES_GROUP_DETAILS, {
        id: NEW_ID,
      }),
    );
  }, [navigate]);

  const handleCreateTelegramAutomation = useCallback(() => {
    setTelegramAutomationDialogOpen(true);
  }, []);

  const handleCreateClick = useCallback(() => {
    if (tab === RequisitesTabs.Requisites) {
      handleCreateRequisites();
    } else if (tab === RequisitesTabs.Groups) {
      handleCreateRequisitesGroup();
    } else if (tab === RequisitesTabs.TelegramAutomation) {
      handleCreateTelegramAutomation();
    }
  }, [
    tab,
    handleCreateRequisites,
    handleCreateRequisitesGroup,
    handleCreateTelegramAutomation,
  ]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        {...(isTrader && {
          rightContentButton: { onClick: handleCreateClick },
        })}
      />
      <QueryTabs tabsEnum={RequisitesTabs}>
        <Tab
          value={RequisitesTabs.Requisites}
          label={t('tabs.requisites')}
        ></Tab>
        <Tab value={RequisitesTabs.Groups} label={t('tabs.groups')} />
        <Tab
          value={RequisitesTabs.TelegramAutomation}
          label={t('tabs.telegram_automation')}
        />
      </QueryTabs>
      <QueryTabPanel value={RequisitesTabs.Requisites}>
        <RequisitesList />
      </QueryTabPanel>
      <QueryTabPanel value={RequisitesTabs.Groups}>
        <RequisitesGroupList />
      </QueryTabPanel>
      <QueryTabPanel value={RequisitesTabs.TelegramAutomation}>
        <RequisitesTelegramAutomation />
      </QueryTabPanel>
      <CreateTelegramAutomationDialog
        open={telegramAutomationDialogOpen}
        onClose={() => setTelegramAutomationDialogOpen(false)}
      />
    </Fragment>
  );
};
