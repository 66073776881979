import { Button, Divider, FormControlLabel, Grid, Switch } from '@mui/material';
import { AxiosError } from 'axios';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutationOptions, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';

import { invitesApi, usersApi } from 'api';
import {
  CloseDialogResult,
  CopyText,
  DataWrapper,
  Dialog,
  PageHeader,
  ResetPasswordButton,
  ProfileForm,
  ExternalLink,
  UserStatusLabel,
  AgentsForm,
} from 'components';
import { Notifications } from 'components/Settings/Notifications';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, UserStatus } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Invite, User, UserProfile, UserAgentInfo } from 'types';
import { invitesUtils } from 'utils';

export const UserDetailsPage: React.FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.user_details',
  });
  const [user, setUser] = useState<User | null>(null);
  const {
    user: { email: currentUserEmail },
  } = useUser();

  const [confirmDisableTwoFADialogOpen, setConfirmDisableTwoFADialogOpen] =
    useState(false);

  const [confirmChangeStatusDialogProps, setConfirmChangeStatusDialogProps] =
    useState<{ open: boolean; data?: UserStatus }>({ open: false });

  const [initialValues, setInitialValues] = useState<UserProfile>({
    name: '',
    email: '',
    telegram: '',
  });

  const queryResult = useQuery<User, AxiosError>(
    [QueryKey.Users, id],
    () => usersApi.getOne(id as string),
    {
      onSuccess: (user) => {
        setInitialValues({
          name: user.name || '',
          email: user.email,
          telegram: user.telegram || '',
        });
        setUser(user);
      },
    },
  );

  const { data: invite } = useQuery<Invite, AxiosError>(
    [QueryKey.UserInvite, id],
    () => invitesApi.getOneForUser(id as string),
    { enabled: user?.status === UserStatus.Invited },
  );

  const isInvited = useMemo(() => user?.status === UserStatus.Invited, [user]);

  const status = useMemo(() => user?.status as UserStatus, [user]);
  const { mutate: disableTwoFA } = useMutation(usersApi.disableTwoFA, {
    onSuccess: (user) => {
      setUser(user);
      setConfirmDisableTwoFADialogOpen(false);
    },
  });
  const { mutate: updateUser } = useMutation<
    User,
    AxiosError,
    { id: string; data: Partial<UserProfile> },
    unknown
  >(usersApi.updateOneAdmin, {
    onSuccess: setUser,
  });

  const { mutate: updateStatus } = useMutation(usersApi.updateStatusAdmin, {
    onSuccess: (user) => {
      setUser(user);
      setConfirmChangeStatusDialogProps({ open: false });
    },
  });

  const { mutate: updateAgents } = useMutation<
    User,
    AxiosError,
    { id: string; data: UserAgentInfo },
    unknown
  >(usersApi.updateAgents, {
    onSuccess: setUser,
  });

  const handleUpdateAgents = useCallback(
    (
      data: UserAgentInfo,
      options: MutationOptions<User, AxiosError, any, unknown> | undefined,
    ) => {
      updateAgents({ id, data }, options);
    },
    [id, updateAgents],
  );

  const handleUpdateProfile = useCallback(
    (
      data: UserProfile,
      options:
        | MutationOptions<UserProfile, AxiosError, any, unknown>
        | undefined,
    ) => {
      updateUser({ id, data }, options);
    },
    [id, updateUser],
  );

  const handleDisableTwoFA = useCallback(() => {
    setConfirmDisableTwoFADialogOpen(true);
  }, []);

  const handleConfirmDisableTwoFADialogClose = useCallback(
    (data: CloseDialogResult) => {
      if (data.ok) {
        disableTwoFA(id);
        return;
      }
      setConfirmDisableTwoFADialogOpen(false);
    },
    [id, disableTwoFA],
  );

  const handleStatusButtonClick = useCallback((newStatus: UserStatus) => {
    setConfirmChangeStatusDialogProps({ open: true, data: newStatus });
  }, []);

  const renderStatusButton = useCallback(() => {
    if (status === UserStatus.Active) {
      return (
        <Button
          color="error"
          variant="outlined"
          onClick={() => handleStatusButtonClick(UserStatus.Disabled)}
        >
          {t('status.buttons.disable')}
        </Button>
      );
    } else if (status === UserStatus.Disabled) {
      return (
        <Button
          color="success"
          variant="outlined"
          onClick={() => handleStatusButtonClick(UserStatus.Active)}
        >
          {t('status.buttons.enable')}
        </Button>
      );
    } else if (status === UserStatus.Invited) {
      const link = invite ? invitesUtils.getLink(invite.token) : null;
      return link ? (
        <div className="tw-flex tw-justify-center tw-items-center">
          <ExternalLink label={t('status.buttons.open_invite')} href={link} />
          <CopyText iconOnly text={link} className="tw-ml-2" />
        </div>
      ) : null;
    }
  }, [t, status, invite, handleStatusButtonClick]);

  const handleConfirmStatusChangeDialogClose = useCallback(
    ({ ok, data }: CloseDialogResult) => {
      if (ok) {
        updateStatus({ id, data: { status: data } });
      } else {
        setConfirmChangeStatusDialogProps({ open: false });
      }
    },
    [id, updateStatus],
  );

  if (currentUserEmail === queryResult.data?.email) {
    return <Navigate to={ROUTE_PATH.ADMIN.SETTINGS} />;
  }

  return (
    <div>
      <PageHeader title={t('title')} />
      <DataWrapper queryResult={queryResult}>
        <Grid container spacing={{ md: 6 }}>
          <Grid item xs={12} md={6}>
            <div className="tw-text-xl tw-mb-4">{t('profile.title')}</div>
            <Fragment>
              {user?.id && (
                <div className="tw-flex tw-items-center tw-space-x-2 tw-my-4">
                  <span>
                    {t('id')}
                    {':'}
                  </span>
                  <CopyText text={user?.id} />
                </div>
              )}
              <ProfileForm
                invited={isInvited}
                initialValues={initialValues}
                onSubmit={handleUpdateProfile}
              />
            </Fragment>
            <Divider sx={{ marginY: 8 }} />
            {user && <Notifications user={user} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Divider
              sx={{ marginY: 8, display: { xs: 'block', md: 'none' } }}
            />
            <div>
              <div className="tw-text-xl tw-mb-4">{t('status.title')}</div>
              {user && (
                <div className="tw-flex tw-items-center">
                  <div className="tw-mr-4">
                    <UserStatusLabel status={user.status} />
                  </div>
                  {renderStatusButton()}
                </div>
              )}
            </div>
            {status !== UserStatus.Invited && (
              <Fragment>
                <Divider sx={{ marginY: 8 }} />
                <div>
                  <div className="tw-text-xl tw-mb-8">
                    {t('security.title')}
                  </div>
                  <div className="tw-mb-8">
                    <div className="tw-text-base tw-mb-2">
                      {t('security.two_fa.title')}
                    </div>
                    <FormControlLabel
                      disabled={!user?.twoFAEnabled}
                      control={
                        <Switch
                          checked={!!user?.twoFAEnabled}
                          onChange={handleDisableTwoFA}
                        />
                      }
                      label={
                        user?.twoFAEnabled
                          ? t('security.two_fa.enabled')
                          : t('security.two_fa.disabled')
                      }
                    />
                    <Dialog
                      open={confirmDisableTwoFADialogOpen}
                      title={t('security.two_fa.reset_two_fa_dialog.title')}
                      onClose={handleConfirmDisableTwoFADialogClose}
                    >
                      <div>
                        {t('security.two_fa.reset_two_fa_dialog.description')}
                      </div>
                    </Dialog>
                  </div>
                  <div>
                    {queryResult.data && (
                      <ResetPasswordButton user={queryResult.data} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            {user && (
              <Fragment>
                <Divider sx={{ marginY: 8 }} />
                <AgentsForm user={user} onSubmit={handleUpdateAgents} />
              </Fragment>
            )}
          </Grid>
        </Grid>
      </DataWrapper>
      <Dialog
        {...confirmChangeStatusDialogProps}
        title={t('status.confirm_dialog.title')}
        onClose={handleConfirmStatusChangeDialogClose}
      >
        <div>{t('status.confirm_dialog.description')}</div>
      </Dialog>
    </div>
  );
};
