import {
  SmartToy as SmartToyIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isBoolean } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
};

export const AutomationStatus: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });

  if (!isBoolean(order.completedByAutomation)) {
    return null;
  }

  if (order.completedByAutomation) {
    return (
      <Tooltip title={t('closed_by_automation')}>
        <SmartToyIcon color="success" />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t('closed_manually')}>
      <PersonIcon color="disabled" />
    </Tooltip>
  );
};
