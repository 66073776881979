import { MenuItem } from '@mui/material';
import { Field } from 'formik';
import { TextField, TextFieldProps } from 'formik-mui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TraderWorkStatus } from 'enums';
import { TranslationNamespace } from 'i18n';
import { traderUtils } from 'utils';

export const WorkStatusSelect: React.FC<Partial<TextFieldProps>> = ({
  disabled,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.status_select',
  });
  return (
    <Field
      component={TextField}
      name="workStatus"
      label={t('title')}
      disabled={disabled}
      size="small"
      select
    >
      {Object.values(TraderWorkStatus).map((status) => (
        <MenuItem key={status} value={status}>
          {traderUtils.getWorkStatusLabel(status)}
        </MenuItem>
      ))}
    </Field>
  );
};
