import React from 'react';

import { PayOutlet } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { useNotificationContext } from 'hooks';

export const TraderOrdersPage: React.FC = () => {
  const notifications = useNotificationContext();

  return (
    <PayOutlet
      payinTo={ROUTE_PATH.TRADER.PAYIN_ORDERS}
      payinBadge={notifications?.activePayinOrders}
      payoutTo={ROUTE_PATH.TRADER.PAYOUT_ORDERS}
      payoutBadge={notifications?.activePayoutOrders}
    />
  );
};
