import React from 'react';

import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { DisputeOrders } from '../DisputeOrders';

export const ProviderDisputeOrders: React.FC = () => (
  <DisputeOrders
    tab={OrdersTab.ProviderDispute}
    additionalColumns={[AdditionalOrderColumn.P2PProvider]}
  />
);
