import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';

import { shopsApi } from 'api';
import { MyAssetBalanceHistory } from 'components';
import { QueryKey } from 'enums';
import { useQueryFilters } from 'hooks';
import { AssetBalanceHistoryFilters } from 'types';
import { assetBalanceHistoryUtils } from 'utils';

export const MerchantBalanceHistoryPage: React.FC = () => {
  useQueryFilters<AssetBalanceHistoryFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const { data: shops = [] } = useQuery(QueryKey.Shops, shopsApi.getAllMy);
  return (
    <MyAssetBalanceHistory
      additionalFilters={assetBalanceHistoryUtils.getShopFilters(shops)}
      additionalColumns={assetBalanceHistoryUtils.getMerchantColumns(shops)}
    />
  );
};
