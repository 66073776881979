import { MenuItem, TextFieldProps } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { map, without } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleLabel } from 'components';
import { UserRole } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  name: string;
  excludeRoles?: UserRole[];
} & Partial<TextFieldProps>;

export const RoleSelect: React.FC<Props> = ({
  name,
  excludeRoles = [],
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.role_select',
  });

  const roles = useMemo(
    () => without(Object.values(UserRole), ...excludeRoles),
    [excludeRoles],
  );

  return (
    <Field
      {...rest}
      component={TextField}
      name={name}
      label={t('title')}
      select
    >
      {map(roles, (role: string) => (
        <MenuItem key={role} value={role}>
          <RoleLabel role={role as UserRole} />
        </MenuItem>
      ))}
    </Field>
  );
};
