import { Button, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { every, filter } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { internalTransferApi } from 'api';
import { DataWrapper, FormControls, FormikSelect, Money } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, InternalTransfer } from 'types';
import { assetUtils } from 'utils';

type Props = {
  internalTransfer: InternalTransfer;
  assets?: Asset[];
  onInternalTransferReceived: (internalTransfer: InternalTransfer) => void;
};

export const ReceiveInternalTransferForm: React.FC<Props> = ({
  internalTransfer,
  assets,
  onInternalTransferReceived,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const {
    mutate: receive,
    isLoading,
    error,
  } = useMutation(internalTransferApi.complete);

  const initialValues = useMemo(
    () => ({
      recipientAssetId: '',
    }),
    [],
  );

  const currencyId = useMemo(
    () => internalTransfer.senderAsset?.assetCurrencyId,
    [internalTransfer],
  );

  const filteredAssetsByCurrency = useMemo(
    () => filter(assets, (asset) => asset.assetCurrencyId === currencyId),
    [assets, currencyId],
  );

  const recipientAssetOptions = useMemo(
    () => assetUtils.getAssetOptions(filteredAssetsByCurrency),
    [filteredAssetsByCurrency],
  );

  const noAssetCreated = useMemo(
    () =>
      filteredAssetsByCurrency.length === 1 &&
      every(filteredAssetsByCurrency, (asset) => !asset.id),
    [filteredAssetsByCurrency],
  );

  const handleSubmit = useCallback(
    (values: { recipientAssetId: string }) => {
      receive(
        {
          id: internalTransfer.id,
          ...(values.recipientAssetId && {
            recipientAssetId: values.recipientAssetId,
          }),
        },
        {
          onSuccess: onInternalTransferReceived,
        },
      );
    },
    [internalTransfer, receive, onInternalTransferReceived],
  );

  const validationSchema = useMemo(
    () =>
      noAssetCreated
        ? null
        : Yup.object().shape({
            recipientAssetId: Yup.string()
              .uuid(tCommon('errors.invalid'))
              .required(tCommon('errors.required')),
          }),
    [noAssetCreated, tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <DataWrapper isLoading={isLoading}>
          <Fragment>
            <Typography variant="h6" color="textSecondary">
              {t('receive_modal.found_label')}
            </Typography>
            <div className="tw-grid tw-grid-cols-2 tw-gap-2 tw-my-4">
              <div>{t('fields.amount')}</div>
              <Money
                value={internalTransfer.amount}
                symbol
                assetCurrencyId={currencyId}
              />
              <div>{t('fields.sender')}</div>
              <div>{internalTransfer.senderUser?.name}</div>
            </div>
            <Form>
              <FormControls>
                {!noAssetCreated && (
                  <FormikSelect
                    label={t('receive_modal.asset')}
                    name="recipientAssetId"
                    options={recipientAssetOptions}
                  />
                )}
                {!!error && (
                  <div className="tw-text-error tw-mt-4 tw-text-center">
                    {t('receive_modal.error')}
                  </div>
                )}
                <Button variant="contained" onClick={() => formik.submitForm()}>
                  {tCommon('buttons.confirm')}
                </Button>
              </FormControls>
            </Form>
          </Fragment>
        </DataWrapper>
      )}
    </Formik>
  );
};
