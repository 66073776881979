import { Alert, CircularProgress } from '@mui/material';
import { isEmpty as _isEmpty, castArray, isUndefined, some } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { TranslationNamespace } from 'i18n';
import { PaginatedData } from 'types';

export type DataWrapperIgnoreState = {
  refetchLoading?: boolean;
  empty?: boolean;
};

enum DataState {
  Loading = 'loading',
  Empty = 'empty',
  Error = 'error',
  Success = 'success',
}

type DataWrapperProps = {
  state: DataState;
  loadingView: ReactElement;
  emptyView: ReactElement | null;
  errorView: ReactElement;
  children: ReactElement;
};

const DataWrapperView: React.FC<DataWrapperProps> = ({
  state,
  loadingView,
  emptyView,
  errorView,
  children,
}) => {
  switch (state) {
    case DataState.Loading:
      return loadingView;
    case DataState.Empty:
      return emptyView;
    case DataState.Error:
      return errorView;
    default:
      return children;
  }
};

type Props = {
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  queryResult?:
    | UseQueryResult<unknown, unknown>
    | UseQueryResult<unknown, unknown>[];
  children: ReactElement | ReactElement[];
  paginated?: boolean;
  handleRefetchLoading?: boolean;
  ignoreState?: DataWrapperIgnoreState;
  emptyViewText?: string;
} & Partial<Omit<DataWrapperProps, 'state'>>;

export const DataWrapper: React.FC<Props> = ({
  isLoading,
  isEmpty,
  isError,
  queryResult,
  children,
  paginated,
  ignoreState,
  emptyView,
  emptyViewText,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.data_wrapper',
  });

  const jointQueryResult = useMemo(() => {
    if (!queryResult) {
      return;
    }
    const queryResults = castArray(queryResult);
    return {
      data: queryResults[0].data,
      isError: some(queryResults, (q) => q.isError),
      isLoading: some(queryResults, (q) => q.isLoading),
      isRefetching: some(queryResults, (q) => q.isRefetching),
      isLoadingError: some(queryResults, (q) => q.isLoadingError),
      isRefetchError: some(queryResults, (q) => q.isRefetchError),
    };
  }, [queryResult]);

  const queryResultLoading = useMemo(
    () =>
      jointQueryResult?.isLoading ||
      (!ignoreState?.refetchLoading && jointQueryResult?.isRefetching),
    [
      ignoreState?.refetchLoading,
      jointQueryResult?.isLoading,
      jointQueryResult?.isRefetching,
    ],
  );

  const queryResultError = useMemo(
    () =>
      jointQueryResult?.isError ||
      jointQueryResult?.isLoadingError ||
      jointQueryResult?.isRefetchError,
    [
      jointQueryResult?.isError,
      jointQueryResult?.isLoadingError,
      jointQueryResult?.isRefetchError,
    ],
  );

  const queryResultEmpty = useMemo(() => {
    if (ignoreState?.empty) {
      return false;
    }
    const data = paginated
      ? (jointQueryResult?.data as PaginatedData<unknown>)?.items
      : jointQueryResult?.data;
    return _isEmpty(data);
  }, [ignoreState?.empty, jointQueryResult?.data, paginated]);

  const queryResultState = useMemo(() => {
    if (queryResultLoading) {
      return DataState.Loading;
    } else if (queryResultError) {
      return DataState.Error;
    } else if (queryResultEmpty) {
      return DataState.Empty;
    }
    return DataState.Success;
  }, [queryResultLoading, queryResultError, queryResultEmpty]);

  const state = useMemo(() => {
    if (queryResult) {
      return queryResultState;
    }
    if (isError) {
      return DataState.Error;
    } else if (isLoading) {
      return DataState.Loading;
    } else if (isEmpty) {
      return DataState.Empty;
    }
    return DataState.Success;
  }, [isEmpty, isError, isLoading, queryResult, queryResultState]);

  return (
    <DataWrapperView
      state={state}
      loadingView={
        rest.loadingView || (
          <div className="tw-text-center">
            <CircularProgress classes={{ root: 'tw-text-center' }} />
          </div>
        )
      }
      emptyView={
        isUndefined(emptyView) ? (
          <Alert severity="info">{emptyViewText || t('state.empty')}</Alert>
        ) : (
          emptyView
        )
      }
      errorView={<Alert severity="error">{t('state.error')}</Alert>}
    >
      {children}
    </DataWrapperView>
  );
};
