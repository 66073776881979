import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { find } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { requisitesTelegramAutomationApi, tradersApi } from 'api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DateLabel,
  RequisitesInfo,
  dataGridColumns,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  RequisitesTelegramAutomation as RequisitesTelegramAutomationType,
} from 'types';
import { formatUtils } from 'utils';

type Props = {
  archived?: boolean;
};

type RequisitesTelegramAutomationFilters = {
  name?: string;
  traderId?: string;
  requisitesId?: string;
  requisitesGroupId?: string;
  telegramPeer?: string;
  phone?: string;
};

export const RequisitesTelegramAutomation: React.FC<Props> = ({ archived }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites_telegram_automation',
  });
  const { role, isTrader, isManager } = useUser();

  const queryKey = useMemo(() => QueryKey.RequisitesTelegramAutomation, []);

  const queryResult = usePartialQuery(
    queryKey,
    archived
      ? requisitesTelegramAutomationApi.getAllArchivePaginatedAsRole(role)
      : requisitesTelegramAutomationApi.getAllPaginatedAsRole(role),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: isManager,
    },
  );

  const { mutate: archive } = useMutation(
    requisitesTelegramAutomationApi.archiveAsRole(role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      notifierType: 'remove',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<RequisitesTelegramAutomationType>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.user'),
        valueGetter: (item) => item.user?.name,
        hidden: isTrader,
      },
      { header: t('fields.name'), valueKey: 'name' },
      {
        header: t('fields.phone'),
        valueGetter: (item) => (
          <div className="tw-flex tw-items-center">
            {item.phone}
            {item.isAuthenticated && (
              <Tooltip title={t('fields.logged_in')}>
                <CheckCircleOutlineIcon
                  sx={{ ml: 1 }}
                  fontSize="small"
                  color="success"
                />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        header: t('fields.telegram_peer'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.telegramPeer}</div>
            {item.telegramPeer && item.telegramHistoryHandledAt && (
              <DateLabel>{`${t('fields.check_date')}: ${formatUtils.formatDate(
                item.telegramHistoryHandledAt,
              )}`}</DateLabel>
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.requisites'),
        valueGetter: (item) => <RequisitesInfo requisite={item.requisites} />,
      },
      {
        header: t('fields.requisites_group'),
        valueGetter: (item) => item.requisitesGroup?.name,
      },
    ],
    [t, isTrader],
  );

  const filtersDefinitions: FilterDefinition<RequisitesTelegramAutomationFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.user'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          traders: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.user?.name,
          hidden: isTrader,
        },
        {
          label: t('filters.name'),
          name: 'name',
          type: FilterDefinitionType.Text,
        },
        {
          label: t('filters.requisites_id'),
          name: 'requisitesId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: t('filters.requisites_group_id'),
          name: 'requisitesGroupId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: t('filters.telegram_peer'),
          name: 'telegramPeer',
          type: FilterDefinitionType.Text,
        },
        {
          label: t('filters.phone'),
          name: 'phone',
          type: FilterDefinitionType.Text,
        },
      ],
      [isTrader, queryResultTraders.data, t],
    );

  return (
    <CrudPage
      filters={{ filtersDefinitions }}
      table={{
        queryResult,
        paginated: true,
        columns,
        hideActions: !isTrader,
        actions: [
          {
            type: CrudTableActionType.Details,
            path: ROUTE_PATH.TRADER.REQUISITES_TELEGRAM_AUTOMATION_DETAILS,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              archive(item.id, { onSuccess: close }),
          },
        ],
      }}
    />
  );
};
