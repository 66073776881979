enum DisputeStatusDetailsBase {
  DisputeNoPayment = 'no_payment',
  DisputeDifferentAmount = 'different_amount',
  DisputeAdminCreated = 'admin_created',
  DisputeRevertCancelled = 'revert_cancelled',
}

enum CancelStatusDetailsBase {
  CancelledByShop = 'shop',
  CancelledByAdmin = 'admin',
  CancelledByOperator = 'operator',
  CancelledByCustomer = 'customer',
  CancelledNewTimeout = 'new_timeout',
  CancelledRequisitesTimeout = 'requisites_timeout',
  CancelledCustomerConfirmTimeout = 'customer_confirm_timeout',
}

enum CommonStatusDetails {
  TraderConfirmTimeout = 'trader_confirm_timeout',
}

export const DisputeStatusDetails = {
  ...DisputeStatusDetailsBase,
  ...CommonStatusDetails,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DisputeStatusDetails =
  | DisputeStatusDetailsBase
  | CommonStatusDetails;

export const CancelStatusDetails = {
  ...CancelStatusDetailsBase,
  ...CommonStatusDetails,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CancelStatusDetails = CancelStatusDetailsBase | CommonStatusDetails;

export const OrderStatusDetails = {
  ...DisputeStatusDetails,
  ...CancelStatusDetails,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OrderStatusDetails = DisputeStatusDetails | CancelStatusDetails;
