import { Stack } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  align?: 'start' | 'end';
};

export const FormActions: React.FC<Props> = ({ children, align }) => (
  <Stack direction="row" justifyContent={align} spacing={3} sx={{ mt: 4 }}>
    {children}
  </Stack>
);
