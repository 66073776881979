import React, { useMemo } from 'react';

import { useCurrencies } from 'hooks';
import { P2PProvider } from 'types';

import { StylizedNumber } from '../StylizedNumber';

type Props = {
  provider: P2PProvider;
};

export const P2PProviderNameInfo: React.FC<Props> = ({ provider }) => {
  const { getFiatCurrencyCode, getAssetCurrencyCode } = useCurrencies();

  const { name, payinConfig, fiatCurrencyId, assetCurrencyId } = provider;
  const expectedFee = payinConfig?.expectedFee;

  const currencyInfo = useMemo(() => {
    const fiatCode = getFiatCurrencyCode(fiatCurrencyId);
    const assetCode = getAssetCurrencyCode(assetCurrencyId);
    return [assetCode, fiatCode].filter(Boolean).join(' / ');
  }, [
    fiatCurrencyId,
    assetCurrencyId,
    getFiatCurrencyCode,
    getAssetCurrencyCode,
  ]);

  return (
    <div className="tw-leading-normal">
      <span className="tw-font-bold">{name}</span>
      <div className="tw-text-gray-400">{currencyInfo}</div>
      <StylizedNumber value={expectedFee} unit="%" />
    </div>
  );
};
