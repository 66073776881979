import { Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import {
  DataWrapper,
  Dialog,
  DialogProps,
  FormikDateTimePicker,
  PriceChart,
} from 'components';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PriceChartFilters, PriceChartItem } from 'types';
import { formatUtils } from 'utils';

type Props = {
  fetchMutation: UseMutationResult<
    PriceChartItem[],
    unknown,
    PriceChartFilters,
    unknown
  >;
} & DialogProps;

type Values = Omit<PriceChartFilters, 'id'>;

export const PriceChartDialog: React.FC<Props> = ({
  fetchMutation,
  open,
  ...rest
}) => {
  const { t: tCommon } = useTranslation();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.price_chart',
  });

  const prevOpen = usePrevious(open);

  const getInitialFilters = useCallback((): Omit<PriceChartFilters, 'id'> => {
    const now = moment().startOf('minute');
    return {
      from: now.clone().add(-1, 'h').toISOString(),
      to: now.toISOString(),
    };
  }, []);

  const [values, setValues] = useState<Values>(getInitialFilters());

  const handleSubmit = useCallback(
    (values: Values) => {
      setValues(values);
      fetchMutation.mutate({ ...values, id: rest.data?.id! });
    },
    [fetchMutation, rest.data?.id],
  );

  const data = useMemo(() => {
    if (!open) {
      return [];
    }
    const from = moment(values.from);
    const to = moment(values.to);
    const fetchedDataMap = keyBy(fetchMutation.data, 'date');
    const current = from;
    const chartData = [];
    let prevPrice = 0;

    do {
      const date = current.toDate().toISOString();
      const price = fetchedDataMap[date]?.price || prevPrice;
      prevPrice = price;
      const formattedDate = formatUtils.formatDate(date);
      chartData.push({ date: formattedDate, price });
      current.add(1, 'm');
    } while (current.isBefore(to));

    return chartData;
  }, [fetchMutation.data, values, open]);

  const isFormInvalid = useCallback((values: Values) => {
    const toMoment = moment(values.to);
    return (
      toMoment.isAfter(moment()) ||
      toMoment.clone().add(-1, 'd').isAfter(values.from) ||
      toMoment.isBefore(values.from)
    );
  }, []);

  useEffect(() => {
    if (!prevOpen && open) {
      const initialFilters = getInitialFilters();
      setValues(initialFilters);
      handleSubmit(initialFilters);
    }
  }, [fetchMutation, getInitialFilters, handleSubmit, open, prevOpen]);

  return (
    <Dialog open={open} title={rest.data?.name} modal maxWidth="lg" {...rest}>
      <Formik initialValues={values} enableReinitialize onSubmit={handleSubmit}>
        {(formik) => (
          <Form>
            <div className="tw-flex tw-justify-center">
              <div>
                <div>{t('dates_description')}</div>
                <div className="tw-flex tw-items-end tw-my-4">
                  <div className="tw-mr-4">
                    <FormikDateTimePicker
                      label={`${tCommon('common.date')} (${tCommon(
                        'common.from',
                      )})`}
                      name={'from'}
                      startOf={'minute'}
                      minDate={moment(formik.values.to).add(-1, 'd')}
                      maxDate={moment(formik.values.to)}
                    />
                  </div>
                  <div className="tw-mr-4">
                    <FormikDateTimePicker
                      label={`${tCommon('common.date')} (${tCommon(
                        'common.to',
                      )})`}
                      name={'to'}
                      startOf={'minute'}
                      minDate={moment(formik.values.from)}
                      maxDate={moment.min(
                        moment(formik.values.from).add(1, 'd'),
                        moment(),
                      )}
                    />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      variant="outlined"
                      size="small"
                      disabled={isFormInvalid(formik.values)}
                    >
                      {tCommon('buttons.refresh')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <DataWrapper
        isLoading={!fetchMutation.data && fetchMutation.isLoading}
        isError={fetchMutation.isError}
      >
        <PriceChart data={data || []} />
      </DataWrapper>
    </Dialog>
  );
};
