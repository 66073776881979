import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  IconButton,
} from '@mui/material';
import { Breakpoint } from '@mui/system';
import { FormikHelpers } from 'formik';
import React, { Fragment, PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';

export type CloseDialogResult<T = any> = {
  ok: boolean;
  data?: T;
};

export type CloseFormikDialogData<T> = {
  formikHelpers: FormikHelpers<T>;
  values: T;
};

export type CloseFormikDialogResult<T> = CloseDialogResult<
  CloseFormikDialogData<T>
>;

export type CloseDialogHandler<T = any> = (
  result: CloseDialogResult<T>,
) => void;

export type CloseDialogFormikHandler<T = any> = (
  result: CloseFormikDialogResult<T>,
) => void;

type Props<TData = any> = PropsWithChildren<{
  open: boolean;
  title?: string;
  hideOk?: boolean;
  hideCancel?: boolean;
  dialogProps?: MuiDialogProps;
  disabled?: boolean;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  okLabel?: string;
  data?: TData;
  modal?: boolean;
  onClose: CloseDialogHandler;
  maxWidth?: Breakpoint;
  rightContentButton?: {
    title?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  mutation?: UseMutationResult<any, any, any, any>;
}>;

export type DialogProps<TData = any> = Props<TData>;

export const Dialog: React.FC<Props> = ({
  open,
  title,
  hideOk,
  hideCancel,
  dialogProps,
  disabled,
  okDisabled,
  cancelDisabled,
  okLabel,
  data,
  children,
  modal,
  onClose,
  maxWidth = 'xs',
  rightContentButton,
  mutation,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.dialog',
  });
  const isOkDisabled = useMemo(
    () => disabled || okDisabled || mutation?.isLoading || mutation?.isSuccess,
    [disabled, okDisabled, mutation?.isLoading, mutation?.isSuccess],
  );
  const isCancelDisabled = useMemo(
    () =>
      disabled || cancelDisabled || mutation?.isLoading || mutation?.isSuccess,
    [disabled, cancelDisabled, mutation?.isLoading, mutation?.isSuccess],
  );
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (open && !prevOpen) {
      mutation?.reset();
    }
  }, [mutation, open, prevOpen]);

  return (
    <MuiDialog
      maxWidth={maxWidth}
      fullWidth
      {...dialogProps}
      open={open}
      onClose={() => onClose({ ok: false })}
      onClick={(event) => {
        event?.stopPropagation();
      }}
    >
      <Fragment>
        <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {title}
          {modal && (
            <IconButton
              size="small"
              sx={{ ml: 'auto' }}
              onClick={() => onClose({ ok: false })}
            >
              <CloseIcon sx={{ width: 16, height: 16 }} />
            </IconButton>
          )}
          {!modal && rightContentButton && (
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ ml: 'auto' }}
              onClick={rightContentButton.onClick}
            >
              {rightContentButton.title || t('right_btn')}
            </Button>
          )}
        </DialogTitle>
        {children && <DialogContent>{children}</DialogContent>}
        {!modal && (
          <DialogActions>
            {!hideCancel && (
              <Button
                disabled={isCancelDisabled}
                onClick={() => onClose({ ok: false, data })}
              >
                {t('cancel_btn')}
              </Button>
            )}
            {!hideOk && (
              <Button
                variant="contained"
                disabled={isOkDisabled}
                onClick={() => onClose({ ok: true, data })}
              >
                {okLabel || t('ok_btn')}
              </Button>
            )}
          </DialogActions>
        )}
      </Fragment>
    </MuiDialog>
  );
};
