import { Card, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { map, omit } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { requisitesApi, requisitesGroupApi } from 'api';
import {
  DataWrapper,
  EntityDetailsPage,
  FieldArrayCheckbox,
  FormikCheckbox,
  FormikTextField,
  RequisitesInfo,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, RequisitesTabs, UserRole } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesGroup, RequisitesGroupValues } from 'types';

export const RequisitesGroupDetails: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.group_details',
  });
  const { id } = useParams();
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { isAdmin, isTechOperator, role } = useUser();
  const canManage = useMemo(
    () => isTechOperator || isAdmin,
    [isTechOperator, isAdmin],
  );
  const listUrl = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.PAYIN_REQUISITES;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES;
    } else {
      return ROUTE_PATH.TRADER.PAYIN_REQUISITES;
    }
  }, [isAdmin, isTechOperator]);

  const queryResultRequisites = useQuery([QueryKey.Requisites, id], () =>
    id === NEW_ID && role === UserRole.Trader
      ? requisitesApi.getAllMy()
      : requisitesApi.getForGroupAsRole(role)(id!),
  );

  const initialValues: Partial<RequisitesGroupValues> = useMemo(
    () => ({
      name: '',
      requisites: [],
      autoDisconnect: false,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const initialValuesSelect = useCallback(
    (item: RequisitesGroup): RequisitesGroupValues => ({
      ...item,
      requisites: map(item.requisites, (item) => item.id),
    }),
    [],
  );

  const formatData = useCallback(
    (
      data: RequisitesGroupValues,
      skipFields: string[] = [],
    ): RequisitesGroup => {
      const result = {
        ...data,
        requisites: map(data.requisites, (item) => ({
          id: item,
        })),
      };

      if (canManage) {
        return result;
      }

      return omit(result, skipFields) as RequisitesGroup;
    },
    [canManage],
  );

  const onBeforeUpdateMutation = useCallback(
    ({
      id,
      data,
    }: {
      id: string;
      data: RequisitesGroupValues;
    }): { id: string; data: RequisitesGroup } => ({
      id,
      data: formatData(data, ['autoDisconnect']),
    }),
    [formatData],
  );

  return (
    <EntityDetailsPage
      id={id!}
      api={requisitesGroupApi}
      listUrl={listUrl}
      tab={RequisitesTabs.Groups}
      title={t(id === NEW_ID ? 'create.title' : 'edit.title')}
      initialValues={initialValues}
      queryKey={QueryKey.RequisitesGroups}
      initialValuesSelect={initialValuesSelect}
      onBeforeCreateMutation={(data) => formatData(data, ['autoDisconnect'])}
      onBeforeUpdateMutation={onBeforeUpdateMutation}
      validationSchema={validationSchema}
    >
      {() => (
        <Fragment>
          <FormikTextField
            name="name"
            label={t('fields.name')}
            className="tw-max-w-md"
          />
          <Typography variant="subtitle1">{t('fields.requisites')}</Typography>
          <div className="tw-text-xs tw-italic">{`*${t(
            'fields.requisites_description',
          )}`}</div>
          <DataWrapper queryResult={queryResultRequisites}>
            <Card sx={{ px: 4, py: 2, maxHeight: 300, overflow: 'auto' }}>
              <FieldArray name="requisites">
                {() =>
                  map(queryResultRequisites.data, (requisite) => (
                    <div key={requisite.id}>
                      <FieldArrayCheckbox
                        sx={{ my: 2, display: 'inline-flex' }}
                        name="requisites"
                        value={requisite.id}
                        disabled={
                          !!requisite.groupId && requisite.groupId !== id
                        }
                        label={
                          <RequisitesInfo
                            compact
                            canCopy={false}
                            requisite={requisite}
                          />
                        }
                      />
                    </div>
                  ))
                }
              </FieldArray>
            </Card>
          </DataWrapper>
          {id !== NEW_ID && (
            <FormikCheckbox
              name="autoDisconnect"
              disabled={!canManage}
              label={t('fields.auto_disconnect')}
            />
          )}
        </Fragment>
      )}
    </EntityDetailsPage>
  );
};
