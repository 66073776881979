import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { keys, pick } from 'lodash';
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { DataWrapper, FormActions, FormControls, PageHeader } from 'components';
import { NEW_ID } from 'constants/common.constants';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CrudApi } from 'types';
import { validationUtils } from 'utils';

export type EntityDetailsPageChildrenProps<F = any, Q = any> = {
  formik: FormikProps<F>;
  queryResult: UseQueryResult<Q>;
};

type Props<T = any> = {
  id: string;
  api: CrudApi<T>;
  listUrl: string;
  title: string;
  initialValues: T;
  queryKey: QueryKey;
  tab?: string;
  validationSchema?: Yup.ObjectSchema<any>;
  formClassName?: string;
  initialValuesSelect?: (data: any) => T;
  onBeforeCreateMutation?: (data: T) => any;
  onBeforeUpdateMutation?: (data: T) => any;
  children: (props: EntityDetailsPageChildrenProps) => ReactNode;
};

export const EntityDetailsPage: React.FC<Props> = ({
  id,
  api,
  listUrl,
  title,
  initialValues: initialValuesProp,
  queryKey,
  tab,
  validationSchema,
  formClassName,
  initialValuesSelect,
  onBeforeCreateMutation,
  onBeforeUpdateMutation,
  children,
}) => {
  const { role } = useUser();
  const isNew = useMemo(() => id === NEW_ID, [id]);
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState(initialValuesProp);

  const queryResult = useQuery(
    [queryKey, id],
    () => api.getOneAsRole(role)(id as string),
    {
      enabled: !isNew,
      select: initialValuesSelect,
      onSuccess: (data) => {
        const pickedData = pick(data, keys(initialValues));
        setInitialValues(pickedData as any);
      },
    },
  );

  const { mutate: create } = useMutation<any, AxiosError, Partial<any>>(
    api.createAsRole(role),
    { onBeforeMutation: onBeforeCreateMutation },
  );

  const { mutate: update } = useMutation<
    any,
    AxiosError,
    { id: string; data: Partial<any> }
  >(api.updateAsRole(role), { onBeforeMutation: onBeforeUpdateMutation });

  const navigateToList = useCallback(
    () => navigate(listUrl, { state: { tab } }),
    [listUrl, navigate, tab],
  );

  const handleSubmit = useCallback(
    (values: any, formikHelpers: FormikHelpers<any>) => {
      const options = {
        onSuccess: navigateToList,
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      if (isNew) {
        create(values, options);
      } else {
        update({ id, data: values }, options);
      }
    },
    [navigateToList, isNew, create, update, id],
  );

  const handleCancel = useCallback(() => navigateToList(), [navigateToList]);

  return (
    <Fragment>
      <PageHeader title={title} />
      <DataWrapper queryResult={!isNew ? queryResult : undefined}>
        <div className={formClassName}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            handleCancel={handleCancel}
            validateOnMount
            validateOnChange
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <FormControls>
                  {children?.({ formik, queryResult })}
                </FormControls>
                <FormActions>
                  <Button variant="outlined" onClick={handleCancel}>
                    {tCommon('buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={formik.submitForm}
                  >
                    {tCommon('buttons.save')}
                  </Button>
                </FormActions>
              </Form>
            )}
          </Formik>
        </div>
      </DataWrapper>
    </Fragment>
  );
};
