import { Menu as MenuIcon } from '@mui/icons-material';
import { Grid, IconButton, Toolbar } from '@mui/material';
import React from 'react';

import { CurrencyExchangeRates } from 'features';

import { AppBar } from './styles';
import { UserInfo } from './UserInfo';

type Props = {
  onDrawerToggle: () => void;
  onLogout: () => void;
};

export const Navbar: React.FC<Props> = ({ onDrawerToggle, onLogout }) => (
  <AppBar position="sticky" elevation={0}>
    <Toolbar>
      <Grid container alignItems="center">
        <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs sx={{ overflow: 'auto', mr: 4 }}>
          <CurrencyExchangeRates />
        </Grid>
        <Grid item>
          <UserInfo onLogout={onLogout} />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);
