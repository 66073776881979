import { Sync as SyncIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { WalletResourcesData } from 'types';
import { formatUtils } from 'utils';

type Props = {
  resources: WalletResourcesData;
  disabled?: boolean;
  getResources: () => void;
};

export const WalletResources: React.FC<Props> = ({
  resources,
  disabled,
  getResources,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets.resources',
  });

  return (
    <div className="tw-flex tw-items-center">
      {resources && (
        <div className="tw-mr-1">
          <div>{`${formatUtils.formatMoney(resources.balanceTRX)} TRX`}</div>
          <div>{`${formatUtils.formatMoney(
            resources.balanceUSDT || 0,
          )} USDT`}</div>
          <div>{`${t('bandwidth')}: ${resources.bandwidth} ${t('from')} ${
            (resources.freeNetLimit || 0) + (resources.NetLimit || 0)
          }`}</div>
          <div>{`${t('energy')}: ${
            (resources.EnergyLimit || 0) - (resources.EnergyUsed || 0)
          } ${t('from')} ${resources.EnergyLimit || 0}`}</div>
        </div>
      )}
      <Tooltip title={resources ? t('refresh') : t('fetch')}>
        <IconButton color="primary" disabled={disabled} onClick={getResources}>
          <SyncIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
