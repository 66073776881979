import { Button } from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { internalTransferApi } from 'api';
import { DataWrapper, FormControls, FormikTextField } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalTransfer } from 'types';

type Props = {
  onInternalTransferFound: (internalTransfer: InternalTransfer) => void;
};

export const FindInternalTransferForm: React.FC<Props> = ({
  onInternalTransferFound,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const {
    mutate: findOne,
    isLoading,
    error,
  } = useMutation(internalTransferApi.findOne);

  const initialValues = useMemo(
    () => ({
      id: '',
    }),
    [],
  );

  const handleSubmit = useCallback(
    (values: { id: string }, helpers: FormikHelpers<{ id: string }>) => {
      findOne(values.id, {
        onSuccess: onInternalTransferFound,
        onSettled: () => helpers.setSubmitting(false),
      });
    },
    [findOne, onInternalTransferFound],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        id: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
      }),
    [tCommon],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <DataWrapper isLoading={isLoading}>
          <Form>
            <FormControls>
              <FormikTextField label={t('fields.code')} name="id" fullWidth />
              {!!error && (
                <div className="tw-text-error">
                  {t('receive_modal.not_found')}
                </div>
              )}
              <Button variant="contained" onClick={() => formik.submitForm()}>
                {t('receive_modal.find_btn')}
              </Button>
            </FormControls>
          </Form>
        </DataWrapper>
      )}
    </Formik>
  );
};
