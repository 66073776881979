import styled from '@emotion/styled';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Chip, ChipProps, SxProps, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { rgba } from 'polished';
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

type Props = {
  startTime?: Date;
  endTime?: Date;
  duration?: number;
  showWhenLess?: number;
  hideTimeOver?: boolean;
  sx?: SxProps;
};

const TimerContainer = styled(Chip)<ChipProps>`
  background: ${(props) => rgba(props.theme.palette.secondary.main, 0.1)};
  border: 0;
`;

export const Timer: React.FC<Props> = ({
  startTime,
  endTime: endTimeProp,
  duration,
  showWhenLess,
  hideTimeOver,
  sx,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.count_down',
  });

  const endTime: number = useMemo(
    () => Number(endTimeProp) || Number(startTime) + Number(duration),
    [duration, endTimeProp, startTime],
  );

  const [initialized, setInitialized] = useState(false);
  const [now, setNow] = useState(Date.now());
  const timeLeft = useMemo(() => endTime - now, [endTime, now]);
  const timeOver = useMemo(() => timeLeft <= 0, [timeLeft]);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const splitTime = useCallback(
    (milliseconds: number) => ({
      hours: Math.floor(milliseconds / 1000 / 3600),
      minutes: Math.floor(((milliseconds / 1000) % 3600) / 60),
      seconds: Math.floor((milliseconds / 1000) % 60),
    }),
    [],
  );

  const stop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const tick = useCallback(() => {
    if (timeOver) {
      stop();
    }
    setNow(Date.now());
  }, [timeOver, stop]);

  const roundTime = useCallback(
    (time: number) => String(time).padStart(2, '0'),
    [],
  );

  const timerInfo = useMemo(() => {
    if (timeOver) {
      return t('timer_is_over');
    }
    const time = splitTime(timeLeft);
    const mmss = `${roundTime(time.minutes)}:${roundTime(time.seconds)}`;
    const hh = time.hours ? `${roundTime(time.hours)}:` : '';
    return `${hh}${mmss}`;
  }, [timeOver, timeLeft, t, splitTime, roundTime]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (!timeOver) {
        intervalRef.current = setInterval(tick, 1000);
      }
    }
  }, [initialized, timeOver, tick, stop]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stop, []);

  if (isNumber(showWhenLess) && timeLeft > showWhenLess) {
    return null;
  }

  if (hideTimeOver && timeOver) {
    return null;
  }

  return (
    <TimerContainer
      sx={sx}
      label={
        <div className="tw-flex">
          <AvTimerIcon color="secondary" />
          <Typography color="secondary">{timerInfo}</Typography>
        </div>
      }
      variant="outlined"
    ></TimerContainer>
  );
};
