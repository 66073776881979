import { Stack, Button } from '@mui/material';
import { Form, Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { pick } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { tradersApi } from 'api';
import {
  DataWrapper,
  FormikNumericField,
  FormikYesNoRadioGroup,
  WorkStatusSelect,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, TraderWorkStatus } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Trader } from 'types';

type Values = Pick<
  Trader,
  | 'compensation'
  | 'payoutCompensation'
  | 'workStatus'
  | 'insuranceDepositAmount'
  | 'payoutMaxActiveOrders'
  | 'insuranceDepositWithdrawalAllowed'
  | 'payinMinOrderAmount'
  | 'payinMaxOrderAmount'
> & { name?: string };

export const TraderDetailsPage: React.FC = () => {
  // TODO: move keys to common
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.traders',
  });

  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {});

  const { role, isAdmin, isTechOperator } = useUser();

  const backUrl = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.TRADERS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.TRADERS;
    }
  }, [isAdmin, isTechOperator]);

  const { id } = useParams();

  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<Values>({
    name: '',
    compensation: 0,
    payoutCompensation: 0,
    workStatus: TraderWorkStatus.Inactive,
    insuranceDepositAmount: 0,
    payoutMaxActiveOrders: 0,
    payinMinOrderAmount: 0,
    payinMaxOrderAmount: 0,
    insuranceDepositWithdrawalAllowed: false,
  });

  const queryResult = useQuery(
    [QueryKey.Traders, id],
    () => tradersApi.findOneAsRole(role)(id as string),
    {
      onSuccess: (data) => {
        setInitialValues({
          name: data.user?.name,
          compensation: data.compensation,
          payoutCompensation: data.payoutCompensation,
          workStatus: data.workStatus,
          insuranceDepositAmount: data.insuranceDepositAmount,
          payoutMaxActiveOrders: data.payoutMaxActiveOrders,
          insuranceDepositWithdrawalAllowed:
            data.insuranceDepositWithdrawalAllowed,
          payinMinOrderAmount: data.payinMinOrderAmount,
          payinMaxOrderAmount: data.payinMaxOrderAmount,
        });
      },
    },
  );

  const { mutate: updateTrader } = useMutation(tradersApi.updateByRole(role));

  const navigateBack = useCallback(() => {
    navigate(backUrl!);
  }, [backUrl, navigate]);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const trader = pick(values, [
        'compensation',
        'payoutCompensation',
        'workStatus',
        'insuranceDepositAmount',
        'payoutMaxActiveOrders',
        'insuranceDepositWithdrawalAllowed',
        'payinMinOrderAmount',
        'payinMaxOrderAmount',
      ]);
      updateTrader(
        { id: id as string, trader },
        {
          onSuccess: navigateBack,
          onSettled: () => formikHelpers.setSubmitting(false),
        },
      );
    },
    [updateTrader, id, navigateBack],
  );

  return (
    <DataWrapper queryResult={queryResult}>
      <div className="tw-max-w-md">
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formik: FormikProps<Values>) => (
            <Form>
              <Stack spacing={4}>
                <Field
                  component={TextField}
                  label={t('fields.name')}
                  size="small"
                  name="name"
                  disabled
                />
                <Stack direction="row" spacing={3}>
                  <FormikNumericField
                    name="compensation"
                    label={t('fields.compensation')}
                    allowNegative={false}
                    percentageSuffix
                    required
                    fullWidth
                  />
                  <FormikNumericField
                    name="payoutCompensation"
                    label={t('fields.payout_compensation')}
                    allowNegative={false}
                    percentageSuffix
                    required
                    fullWidth
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <FormikNumericField
                    name="payinMinOrderAmount"
                    label={t('fields.payin_min_order_amount')}
                    allowNegative={false}
                    fullWidth
                  />
                  <FormikNumericField
                    name="payinMaxOrderAmount"
                    label={t('fields.payin_max_order_amount')}
                    allowNegative={false}
                    fullWidth
                  />
                </Stack>
                <WorkStatusSelect />
                <FormikNumericField
                  name="insuranceDepositAmount"
                  label={t('fields.insurance_deposit_amount')}
                  allowNegative={false}
                  decimalScale={0}
                  fullWidth
                />
                <FormikYesNoRadioGroup
                  name="insuranceDepositWithdrawalAllowed"
                  label={t('fields.insurance_deposit_withdrawal_allowed')}
                />
                <FormikNumericField
                  name="payoutMaxActiveOrders"
                  label={t('fields.payout_max_actve_orders')}
                  allowNegative={false}
                  decimalScale={0}
                  defaultValue={0}
                  fullWidth
                />
                <Stack direction="row" justifyContent="end" spacing={3}>
                  <Button variant="outlined" onClick={navigateBack}>
                    {tCommon('buttons.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                    onClick={formik.submitForm}
                  >
                    {tCommon('buttons.save')}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </DataWrapper>
  );
};
