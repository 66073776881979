import React from 'react';

import { assetCurrencyApi } from 'api';
import { QueryKey } from 'enums';

import { CurrenciesTable } from '../CurrenciesTable';

export const AssetCurrencies: React.FC = () => (
  <CurrenciesTable api={assetCurrencyApi} queryKey={QueryKey.AssetCurrencies} />
);
