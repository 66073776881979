import { internalWalletsWithdrawalTransactionsApi } from 'api';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';

import { WithdrawalTransactions } from '../WithdrawalTansactionsTable';

export const WithdrawalActiveTransactions: React.FC = () => {
  const queryResult = usePartialQuery(
    QueryKey.InternalWalletTransactionsWithdrawalActive,
    internalWalletsWithdrawalTransactionsApi.getAllActivePaginated,
  );

  return <WithdrawalTransactions queryResult={queryResult} withActions />;
};
