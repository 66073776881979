import { Button, FormControlLabel, FormGroup } from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Checkbox } from 'formik-mui';
import { intersection, isEmpty, map, transform } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { usersApi } from 'api';
import { FormActions } from 'components';
import {
  NOTIFICATION_EVENTS_BY_ROLE,
  NOTIFICATION_EVENTS_GROUPS,
} from 'constants/user.constants';
import { NotificationEvent, NotificationEventGroup, QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User, UserNotifications } from 'types';
import { userUtils } from 'utils';

type Values = Pick<User, 'telegramNotifications'>;

type Props = {
  user: User;
};

export const Events: React.FC<Props> = ({ user }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.notifications',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const events = useMemo(() => NOTIFICATION_EVENTS_BY_ROLE[user.role], [user]);
  const groups = useMemo(
    () =>
      transform(
        NOTIFICATION_EVENTS_GROUPS,
        (acc, group) => {
          const groupEvents = intersection(group.events, events);
          if (!isEmpty(groupEvents)) {
            acc.push({ name: group.name, events: groupEvents });
          }
        },
        [] as { name: NotificationEventGroup; events: NotificationEvent[] }[],
      ),
    [events],
  );

  const { user: currentUser } = useUser();
  const isProfile = useMemo(
    () => user.email === currentUser.email,
    [user, currentUser],
  );

  const { mutate: updateProfile } = useMutation<
    UserNotifications,
    AxiosError,
    Partial<UserNotifications>,
    unknown
  >(usersApi.updateProfile);

  const handleSubmit = useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      updateProfile(values, {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.Profile);
        },
        onSettled: () => {
          helpers.setSubmitting(false);
        },
      });
    },
    [queryClient, updateProfile],
  );

  if (!events) {
    return null;
  }

  return (
    <div>
      <div className="tw-text-base tw-font-semibold tw-mb-2">
        {t('events.title')}
      </div>
      <Formik
        initialValues={{
          telegramNotifications: intersection(
            user.telegramNotifications,
            events,
          ),
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormGroup>
            {map(groups, (group) => (
              <div key={group.name} className="tw-mb-2">
                <div className="tw-font-bold">
                  {userUtils.getNotificationGroupLabel(group.name)}
                </div>
                {map(group.events, (item) => (
                  <div key={item}>
                    <FormControlLabel
                      sx={{ display: 'inline-flex' }}
                      control={
                        <Field
                          component={Checkbox}
                          type="checkbox"
                          name={'telegramNotifications'}
                          value={item}
                          sx={{ py: 1 }}
                          disabled={!isProfile}
                        />
                      }
                      label={userUtils.getNotificationEventLabel(item)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </FormGroup>
          {isProfile && (
            <FormActions align="start">
              <Button variant="outlined" type="submit">
                {tCommon('buttons.save')}
              </Button>
            </FormActions>
          )}
        </Form>
      </Formik>
    </div>
  );
};
