import { Link } from '@mui/material';
import React from 'react';

type Props = {
  href: string;
  label?: string;
};

export const ExternalLink: React.FC<Props> = ({ label, href }: Props) => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    {label || href}
  </Link>
);
