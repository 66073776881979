import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { matchPath } from 'react-router-dom';

import { eventEmitter } from 'config';
import { ROUTE_PATH } from 'constants/routes';
import { EventEmitterEvent, StatusCode, UserRole } from 'enums';

import { storageUtils } from './storage.utils';

const refreshAccessTokenExpiresIn = 30 * 1000; // 30 seconds

const KEY = 'spay-auth';

const ERROR_MESSAGE = {
  ACCOUNT_DISABLED: 'Account disabled',
};

export type AuthData = {
  accessToken: string;
  refreshToken: string;
};

type TokenPayload = {
  exp: number;
  email: string;
  role: UserRole;
  twoFA: boolean;
};

let authData: AuthData = null as any as AuthData;
let accessTokenExpiresIn: number | null = null;

const isAccountDisabledError = (error: AxiosError<{ message: string }>) =>
  error?.response?.status === StatusCode.Unauthorized &&
  error?.response?.data?.message === ERROR_MESSAGE.ACCOUNT_DISABLED;

const setAccessTokenExpiresIn = () => {
  accessTokenExpiresIn = getTokenPayload()?.exp * 1000;
};

const setAuthData = (data: AuthData) => {
  authData = data;
  setAccessTokenExpiresIn();
  storageUtils.setItem(KEY, authData);
};

const resetAuthData = () => {
  authData = null as any as AuthData;
  accessTokenExpiresIn = null;
  storageUtils.removeItem(KEY);
};

const getAuthData = () => authData || storageUtils.getItem(KEY);

const getAccessToken = () => getAuthData()?.accessToken;

const isLoggedIn = () => !!getAuthData();

const logout = (error?: any) => {
  const isAccountDisabled = isAccountDisabledError(error);
  const route = isLoggedIn()
    ? isAccountDisabled
      ? ROUTE_PATH.PUBLIC.ACCOUNT_DISABLED
      : ROUTE_PATH.PUBLIC.SESSION_EXPIRED
    : ROUTE_PATH.PUBLIC.LOGIN;
  const email = getTokenPayload()?.email;
  resetAuthData();
  if (!matchPath(route, window.location.pathname)) {
    eventEmitter.emit(EventEmitterEvent.Navigate, {
      to: route,
      options: { state: { email } },
    });
  }
};

const getTokenPayload = (): TokenPayload => {
  const token = getAccessToken();
  return (token ? jwtDecode(token) : null) as TokenPayload;
};

const getUserRole = () => getTokenPayload().role;
const getUserEmail = () => getTokenPayload().email;
const getTwoFAVerified = () => getTokenPayload().twoFA;

const isUserRole = (role: UserRole) => getUserRole() === role;

const getAccessTokenExpiresIn = () => {
  if (!accessTokenExpiresIn) {
    setAccessTokenExpiresIn();
  }
  return accessTokenExpiresIn;
};

const accessTokenExpiresSoon = () => {
  const expiresIn = getAccessTokenExpiresIn();
  return !!expiresIn && expiresIn - Date.now() < refreshAccessTokenExpiresIn;
};

export const authUtils = {
  isLoggedIn,
  getAuthData,
  getAccessToken,
  getUserRole,
  getUserEmail,
  setAuthData,
  resetAuthData,
  logout,
  isUserRole,
  getTwoFAVerified,
  accessTokenExpiresSoon,
  isAccountDisabledError,
};
