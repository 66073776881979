import { Stack, Typography } from '@mui/material';
import { find, map } from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { StylizedNumber } from 'components';
import { TranslationNamespace } from 'i18n';
import { Shop, User } from 'types';

type Props = {
  shop: Shop;
  users?: User[];
};

export const ShopAgents: React.FC<Props> = ({ shop, users }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shop_details',
  });

  return (
    <Fragment>
      <Typography variant="subtitle2">{t('rows.agents')}</Typography>
      {map(shop?.agents, (agent) => (
        <div key={agent.userId} className="tw-flex">
          <div className="tw-mr-2">
            {find(users, (user) => user.id === agent.userId)?.name ||
              agent.userId}
          </div>
          <Stack direction="row" spacing={1}>
            <StylizedNumber
              value={agent.payinCompensationPercentage}
              unit="%"
            />
            <span>{'/'}</span>
            <StylizedNumber
              value={agent.payoutCompensationPercentage}
              unit="%"
            />
          </Stack>
        </div>
      ))}
    </Fragment>
  );
};
