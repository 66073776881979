import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { map } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { automationSourcesApi } from 'api';
import {
  FormActions,
  FormControls,
  FormikSelect,
  FormikTextField,
} from 'components';
import { AutomationSourceType } from 'enums';
import { useMutation, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AutomationSource, CreateAutomationSourceDto } from 'types';
import { tradeMethodsUtils, validationUtils } from 'utils';

import { useAutomationSources } from './AutomationSourcesList.context';

type Values = CreateAutomationSourceDto;

type Props = {
  onSubmit?: (values: AutomationSource) => void;
  selectedAutomationSource?: AutomationSource | null;
  withType?: Boolean;
};

export const AutomationSourcesDetailsForm: React.FC<Props> = ({
  onSubmit,
  selectedAutomationSource = null,
  withType = false,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.automation_sources',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { tradeMethod, automationSourcesQuery } = useAutomationSources();
  const { banks, paymentTypes, tradeMethods, fiatCurrencies } =
    useUserContext();

  const initialValues: Values = useMemo(
    () => ({
      name: selectedAutomationSource?.name ?? '',
      tradeMethodId:
        tradeMethod?.id || selectedAutomationSource?.tradeMethodId || '',
      type: selectedAutomationSource?.type ?? AutomationSourceType.Payin,
    }),
    [selectedAutomationSource, tradeMethod],
  );

  const typeOptions = useMemo(
    () =>
      map(AutomationSourceType, (type) => ({
        value: type,
        label: t(`types.${type}`),
      })),
    [t],
  );

  const tradeMethodsOptions = useMemo(
    () =>
      map(tradeMethods, ({ id }) => ({
        value: id,
        label: tradeMethodsUtils.getTradeMethodLabel({
          tradeMethodId: id,
          tradeMethods,
          banks,
          paymentTypes,
          fiatCurrencies,
        }),
      })),
    [banks, paymentTypes, tradeMethods, fiatCurrencies],
  );

  const createAutomationSource = useMutation<
    AutomationSource,
    AxiosError,
    Values
  >((automationSource) =>
    automationSourcesApi.create({
      ...automationSource,
      tradeMethodId:
        automationSource.type === AutomationSourceType.Payin
          ? automationSource.tradeMethodId
          : null,
    }),
  );

  const updateAutomationSource = useMutation<
    AutomationSource,
    AxiosError,
    Values
  >((automationSource) =>
    automationSourcesApi.update({
      id: selectedAutomationSource!.id,
      data: {
        ...automationSource,
        tradeMethodId:
          automationSource.type === AutomationSourceType.Payin
            ? automationSource.tradeMethodId
            : null,
      },
    }),
  );

  const doSubmit = useMemo(() => {
    if (selectedAutomationSource) {
      return updateAutomationSource;
    }
    return createAutomationSource;
  }, [
    selectedAutomationSource,
    createAutomationSource,
    updateAutomationSource,
  ]);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      doSubmit.mutate(values, {
        onSuccess: (values) => {
          automationSourcesQuery.refetch();
          onSubmit?.(values);
        },
        onError: (error: AxiosError) => {
          formikHelpers.setErrors(validationUtils.getFormErrors(error));
        },
        onSettled: () => {
          formikHelpers.setSubmitting(false);
        },
      });
    },
    [onSubmit, automationSourcesQuery, doSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormControls>
            <FormikTextField
              label={t('details_form.fields.name')}
              name="name"
              required
            />
            {withType && (
              <FormikSelect
                label={t('details_form.fields.type')}
                name="type"
                options={typeOptions}
                required
              />
            )}
            {withType && values.type === AutomationSourceType.Payin && (
              <FormikSelect
                label={t('details_form.fields.trade_method')}
                name="tradeMethodId"
                options={tradeMethodsOptions}
                required
              />
            )}
          </FormControls>
          <FormActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              {tCommon('buttons.save')}
            </Button>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};
