import i18next from 'i18next';
import { find } from 'lodash';

import { FilterDefinitionType, OperationType } from 'enums';
import {
  FilterDefinition,
  FundsRequestFilters,
  P2PProvider,
  Shop,
  User,
} from 'types';
import { formUtils } from 'utils';

const getOperationLabel = (operation: OperationType) => {
  const { t } = i18next;
  const key = `funds_request_operation.${operation}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getCommonFilters = (
  users?: User[],
  shops?: Shop[],
  p2pProviders?: P2PProvider[],
): FilterDefinition<Partial<FundsRequestFilters>>[] => {
  const { t } = i18next;
  const filters: FilterDefinition<Partial<FundsRequestFilters>>[] = [
    {
      label: t('features.funds_request.filters.operation_type'),
      name: 'operationType',
      type: FilterDefinitionType.Enum,
      enum: OperationType,
      getDisplayName: fundsRequestUtils.getOperationLabel,
    },
  ];
  if (users) {
    filters.push({
      label: t('features.funds_request.filters.user'),
      name: 'userId',
      type: FilterDefinitionType.User,
      users,
      getDisplayName: (userId: string) => find(users, { id: userId })?.name,
    });
  }
  if (shops) {
    filters.push({
      label: t('features.funds_request.filters.shop'),
      name: 'shopId',
      type: FilterDefinitionType.Shop,
      shops,
      getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
    });
    if (p2pProviders) {
      filters.push({
        label: t('features.funds_request.filters.p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(p2pProviders),
        getDisplayName: (id: string) => find(p2pProviders, { id })?.name,
      });
    }
  }
  return filters;
};

export const fundsRequestUtils = {
  getOperationLabel,
  getCommonFilters,
};
