import React from 'react';

import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { DisputeOrders } from '../DisputeOrders';

export const TraderDisputeOrders: React.FC = () => (
  <DisputeOrders
    tab={OrdersTab.TraderDispute}
    additionalColumns={[AdditionalOrderColumn.Trader]}
  />
);
