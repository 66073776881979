import axios from 'axios';

import { env } from 'config';
import { FiatCurrencyExchangeRate, FiatCurrencyExchange } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/fiat-currency-exchange`;

const getRates = async (): Promise<FiatCurrencyExchangeRate[]> =>
  (await axios.get(`${url}/rates`)).data;

export const fiatCurrencyExchangeApi = {
  ...createCrudApi<FiatCurrencyExchange>('/fiat-currency-exchange'),
  getRates,
};
