import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { findIndex } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { shopsApi, usersApi } from 'api';
import {
  CloseDialogResult,
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  Dialog,
  ExternalLink,
  PageHeader,
  SidebarLayout,
  SidebarSize,
  StylizedNumber,
  dataGridColumns,
} from 'components';
import { ERROR_MESSAGE, NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey, StatusCode } from 'enums';
import { useCurrencies, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Shop } from 'types';

import { ShopDetails } from '../ShopDetails';
import { ShopStatusLabel } from '../ShopStatusLabel';

const queryKey = QueryKey.Shops;

type Props = {
  title: string;
};

export const ShopList: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shops_list.table.columns',
  });
  const { t: tRemoveDialog } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.shops_list.remove_dialog',
  });

  const { getFiatCurrencyCode } = useCurrencies();

  const { role, isAdmin, isTechOperator, isMerchant } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const queryClient = useQueryClient();
  const queryResult = useQuery(
    queryKey,
    canManage ? shopsApi.getAllAsRole(role) : shopsApi.getAllMy,
  );
  const { data: shops = [] } = queryResult || [];
  const usersQuery = useQuery(QueryKey.Users, usersApi.getAll, {
    enabled: canManage,
  });

  const { mutate: remove } = useMutation(shopsApi.remove, {
    onSuccess: () => {
      setConfirmRemoveDialogProps({ open: false });
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return tRemoveDialog('error.in_use');
        }
      },
    },
  });

  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const handleOpenShop = useCallback((shop: Shop) => setSelectedShop(shop), []);
  const handleCloseShop = useCallback(() => setSelectedShop(null), []);

  const shopDetailsPage = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.SHOP_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.SHOP_DETAILS;
    } else if (isMerchant) {
      return ROUTE_PATH.MERCHANT.SHOP_DETAILS;
    }
  }, [isAdmin, isMerchant, isTechOperator]);

  const handleCreateOrEdit = useCallback(
    (id: string) => navigate(generatePath(shopDetailsPage!, { id })),
    [shopDetailsPage, navigate],
  );

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: Shop;
  }>({
    open: false,
  });

  const handleRemove = useCallback((data: Shop) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<Shop>) => {
      if (!ok || !data) {
        setConfirmRemoveDialogProps({ open: false });
        return;
      }
      remove(data, {
        onSuccess() {
          if (data.id === selectedShop?.id) {
            setSelectedShop(null);
          }
        },
      });
    },
    [remove, selectedShop?.id],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<Shop>[] => [
      {
        header: t('name'),
        valueGetter: (item) => item.name || '',
      },
      {
        header: t('website_url'),
        valueGetter: (item) => <ExternalLink href={item.webSiteUrl} />,
      },
      {
        header: t('website_ip'),
        valueKey: 'webSiteIp',
      },
      {
        header: t('currency'),
        valueKey: 'fiatCurrencyId',
        valueFormatter: getFiatCurrencyCode,
      },
      {
        header: t('merchant_name'),
        valueGetter: (item) => item.user?.name || '',
        hidden: !canManage,
      },
      {
        header: t('commission'),
        valueGetter: (item) => (
          <Stack direction="row" spacing={1}>
            <StylizedNumber value={item.commission} unit="%" />
            <span>{'/'}</span>
            <StylizedNumber value={item.payoutCommission} unit="%" />
          </Stack>
        ),
      },
      {
        header: t('status'),
        valueGetter: (item) => <ShopStatusLabel status={item.status} />,
      },
      {
        header: t('groups'),
        multiValueRenderer: {
          itemsGetter: (item) => item.groups,
          valueGetter: (group) => <div key={group.id}>{group?.name}</div>,
        },
        hidden: !canManage,
      },
      dataGridColumns.getActionsColumn({
        handleEdit: (item) => handleCreateOrEdit(item.id),
        ...(!canManage && { handleRemove }),
      }),
    ],
    [getFiatCurrencyCode, handleCreateOrEdit, handleRemove, canManage, t],
  );

  return (
    <Fragment>
      <SidebarLayout
        sidebarProps={{
          size: SidebarSize.md,
          open: !!selectedShop,
          onClose: handleCloseShop,
          title: selectedShop && (
            <Fragment>
              {selectedShop.name}
              <ShopStatusLabel sx={{ ml: 1 }} status={selectedShop.status} />
            </Fragment>
          ),
          content: (
            <ShopDetails
              shop={selectedShop}
              users={usersQuery.data}
              adminView={canManage}
            />
          ),
        }}
      >
        <PageHeader
          title={title}
          rightContentButton={
            !canManage && {
              onClick: () => handleCreateOrEdit(NEW_ID),
            }
          }
        />

        <DataWrapper queryResult={queryResult}>
          <DataGrid
            columns={columns}
            data={shops}
            onRowClick={handleOpenShop}
            selectedRowIndex={findIndex(shops, {
              id: selectedShop?.id,
            })}
          />
        </DataWrapper>
      </SidebarLayout>

      <Dialog
        title={tRemoveDialog('title')}
        onClose={handleCloseRemoveDialog}
        {...confirmRemoveDialogProps}
      >
        {confirmRemoveDialogProps.data && (
          <div>{confirmRemoveDialogProps.data.name}</div>
        )}
      </Dialog>
    </Fragment>
  );
};
