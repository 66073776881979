import * as Yup from 'yup';

import { FormField } from 'types';

import { fieldsList } from './common';
import { getBaseFieldValidator } from './form.validators';

/**
 * Build form validator
 * @param fields form fields metadata
 * @returns
 */
export const buildFormValidator = (fields?: FormField[] | null) => {
  if (!fields || !fields.length) {
    return Yup.object().shape({});
  }
  const schema: Record<
    string,
    Yup.StringSchema<string | null | undefined, Yup.AnyObject, undefined, ''>
  > = {};
  for (const field of fields) {
    const { type, required, name } = field;
    const getValidator = fieldsList[type].getValidator || getBaseFieldValidator;
    schema[name] = getValidator({ required });
  }
  return Yup.object().shape(schema);
};
