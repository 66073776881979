import React, { useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

import { eventEmitter } from 'config';
import { EventEmitterEvent } from 'enums';

export const NavigateListener: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    eventEmitter.on(
      EventEmitterEvent.Navigate,
      (data: { to: To; options?: NavigateOptions }) => {
        navigate(data.to, data.options);
      },
    );
    return () => {
      eventEmitter.off(EventEmitterEvent.Navigate);
    };
  }, [navigate]);
  return null;
};
