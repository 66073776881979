import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { parsingMethodsApi } from 'api';
import {
  CopyTextId,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
} from 'components';
import { ParsingMethodType } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { ParsingMethod } from 'types';

import { useParsingMethods } from './ParsingMethods.context';

type Props = {
  onEdit?: (item: ParsingMethod) => void;
};

export const ParsingMethodsList: React.FC<Props> = ({ onEdit }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });
  const { parsingMethodsQuery, type } = useParsingMethods();

  const removeParsingMethodMutation = useMutation<
    ParsingMethod,
    AxiosError,
    string
  >(parsingMethodsApi.remove, {
    onSuccess: () => {
      parsingMethodsQuery.refetch();
    },
    notifierType: 'remove',
  });

  const columns: DataGridColumnDefinition<ParsingMethod>[] = useMemo(() => {
    const baseColumns: DataGridColumnDefinition<ParsingMethod>[] = [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.template'),
        valueKey: 'template',
      },
    ];

    if (type === ParsingMethodType.Technical) {
      baseColumns.push({
        header: t('fields.description'),
        valueKey: 'description',
      });
    }

    return baseColumns;
  }, [t, type]);

  return (
    <CrudTable
      columns={columns}
      queryResult={parsingMethodsQuery}
      actions={[
        {
          type: CrudTableActionType.Details,
          onClick: onEdit,
        },
        {
          type: CrudTableActionType.Remove,
          onRemove: (item: ParsingMethod, { close }) =>
            removeParsingMethodMutation.mutate(item.id, { onSuccess: close }),
        },
      ]}
    />
  );
};
