import { currencyExchangeApi } from 'api';
import { DialogProps, PriceChartDialog } from 'components';
import { useMutation } from 'hooks';
import { CurrencyExchange } from 'types';

type Props = DialogProps<CurrencyExchange>;

export const CurrencyExchangePriceChartDialog: React.FC<Props> = (props) => {
  const fetchMutation = useMutation(currencyExchangeApi.getRatesHistory, {
    notifierType: 'none',
  });

  return <PriceChartDialog fetchMutation={fetchMutation} {...props} />;
};
