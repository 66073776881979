import { Box } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  currentValue: any;
  value: any;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  currentValue,
  ...other
}) => (
  <div hidden={value !== currentValue} {...other}>
    {value === currentValue && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);
