import { Delete as DeleteIcon, Image as ImageIcon } from '@mui/icons-material';
import {
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { isEmpty, map, xor } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQueryClient } from 'react-query';

import { payoutOrdersApi } from 'api';
import { DataWrapper, ReceiptLabel } from 'components';
import { OrderType, QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder, OrderReceipt } from 'types';

import { UploadReceiptDialog } from '../UploadReceiptDialog';

type Props = {
  order: PayoutOrder;
  queryResultReceipts: UseQueryResult<OrderReceipt[], unknown>;
};

export const ManageReceipts: React.FC<Props> = ({
  order,
  queryResultReceipts,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_payment_dialog',
  });
  const queryClient = useQueryClient();
  const { role } = useUser();
  const [deleteInProgress, setDeleteInProgress] = useState<string[]>([]);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const queryKey = useMemo(
    () => [QueryKey.PayoutOrderReceipts, order?.id],
    [order],
  );

  const receipts = useMemo(
    () => queryResultReceipts.data,
    [queryResultReceipts.data],
  );

  const { mutate: deleteReceipt } = useMutation(
    payoutOrdersApi.removeReceiptAsRole(role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      notifierType: 'remove',
    },
  );

  const handleDelete = useCallback(
    (filename: string) => {
      setDeleteInProgress((prev) => xor(prev, [filename]));
      deleteReceipt(
        { orderId: order.id, body: { filename } },
        {
          onSettled: () => {
            setDeleteInProgress((prev) => xor(prev, [filename]));
          },
        },
      );
    },
    [order, deleteReceipt],
  );

  return (
    <Fragment>
      <div>
        <Typography variant="subtitle1">{t('added_payments')}</Typography>
        <div className="tw-my-4">
          <DataWrapper
            queryResult={queryResultReceipts}
            emptyViewText={t('empty_text')}
          >
            <Fragment>
              {map(receipts, ({ filename }) => (
                <List key={filename} dense disablePadding>
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <IconButton
                        color="error"
                        disabled={deleteInProgress.includes(filename)}
                        onClick={() => handleDelete(filename)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar sx={{ minWidth: 30 }}>
                      <ImageIcon color="info" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <ReceiptLabel
                          orderId={order?.id}
                          orderType={OrderType.Payout}
                          filename={filename}
                        />
                      }
                    />
                  </ListItem>
                </List>
              ))}
            </Fragment>
          </DataWrapper>
        </div>
      </div>
      {!queryResultReceipts.isLoading &&
        !queryResultReceipts.isRefetching &&
        !queryResultReceipts.error && (
          <Button variant="outlined" onClick={() => setUploadModalOpen(true)}>
            {isEmpty(receipts)
              ? tCommon('buttons.load')
              : tCommon('buttons.load_more')}
          </Button>
        )}
      <UploadReceiptDialog
        open={uploadModalOpen}
        order={order}
        onClose={() => setUploadModalOpen(false)}
      />
    </Fragment>
  );
};
