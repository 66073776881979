import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { payoutRequisitesApi, requisitesApi } from 'api';
import { CloseDialogHandler, CloseDialogResult, Dialog } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutRequisites, Requisites } from 'types';

type Props = {
  open: boolean;
  isPayout?: boolean;
  requisites?: Requisites | PayoutRequisites;
  onClose: CloseDialogHandler;
};

export const UnblockRequisitesDialog: React.FC<Props> = ({
  open,
  isPayout,
  requisites,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });

  const unblockFn: (id: string) => Promise<any> = useMemo(
    () => (isPayout ? payoutRequisitesApi.unblock : requisitesApi.unblock),
    [isPayout],
  );

  const unblockMutation = useMutation<
    Requisites | PayoutRequisites,
    AxiosError,
    string
  >(unblockFn, {
    onSuccess: () => {
      onClose({ ok: true });
    },
    notifierType: 'execute',
  });

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok && requisites) {
        unblockMutation.mutate(requisites.id);
      } else {
        onClose({ ok: false });
      }
    },
    [requisites, unblockMutation, onClose],
  );

  return (
    <Dialog
      open={open}
      title={t('unblock_dialog.title')}
      mutation={unblockMutation}
      onClose={handleClose}
    />
  );
};
