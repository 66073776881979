import { map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps, RequisitesInfo } from 'components';
import { TranslationNamespace } from 'i18n';
import { PayoutRequisites } from 'types';

type Props = {
  name: string;
  label?: string;
  requisites?: PayoutRequisites[];
  labelClassName?: string;
  fiatCurrencyId?: string;
  paymentTypeId?: string;
  bankId?: string;
} & Partial<FormikSelectProps>;

export const PayoutRequisitesSelect: React.FC<Props> = ({
  name,
  requisites = [],
  label,
  labelClassName,
  fiatCurrencyId,
  paymentTypeId,
  bankId,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.requisites_select',
  });

  const options = useMemo(
    () =>
      map(requisites, (requisite) => ({
        value: requisite.id,
        label: (
          <RequisitesInfo
            compact
            canCopy={false}
            className={labelClassName}
            fiatCurrencyId={requisite.fiatCurrencyId}
            paymentTypeId={requisite.paymentTypeId}
            bankId={requisite.bankId}
          />
        ),
        disabled:
          (!!fiatCurrencyId && requisite.fiatCurrencyId !== fiatCurrencyId) ||
          (!!paymentTypeId && requisite.paymentTypeId !== paymentTypeId) ||
          (!!bankId && requisite.bankId !== bankId),
      })),
    [requisites, labelClassName, fiatCurrencyId, paymentTypeId, bankId],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
