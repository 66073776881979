import { Link } from '@mui/material';
import { map, take } from 'lodash';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

import { DataGridColumnDefinition } from './types';

const DEFAULT_MAX_ITEMS = 3;

type Props = {
  definition: DataGridColumnDefinition;
  item: any;
  data: any[];
};

export const MultiValue: React.FC<Props> = ({ definition, item, data }) => {
  const { t } = useTranslation(TranslationNamespace.Common);
  const [expanded, setExpanded] = useState(false);

  const { subItems, maxItemsExceeded, itemsLength } = useMemo(() => {
    const initialSubItems =
      definition.multiValueRenderer?.itemsGetter(
        item,
        definition,
        data as any[],
      ) || [];

    let resultSubItems = initialSubItems;
    const itemsLength = initialSubItems?.length;

    const maxItems =
      definition.multiValueRenderer?.maxItems === false
        ? initialSubItems?.length
        : DEFAULT_MAX_ITEMS;
    const maxItemsExceeded = itemsLength > maxItems && !expanded;

    if (maxItemsExceeded) {
      resultSubItems = take(initialSubItems, maxItems);
    }
    return { subItems: resultSubItems, maxItemsExceeded, itemsLength };
  }, [data, definition, expanded, item]);

  return (
    <div>
      {map(subItems, (subItem) =>
        definition.multiValueRenderer?.valueGetter(
          subItem,
          item,
          definition,
          data as any[],
        ),
      )}
      {maxItemsExceeded && (
        <Link
          component="button"
          variant="body2"
          underline="none"
          onClick={() => {
            setExpanded(true);
          }}
        >
          {`${t('buttons.show_all')} (${itemsLength})`}
        </Link>
      )}
    </div>
  );
};
