import { Button, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { internalWalletsApi } from 'api';
import { CopyText, Dialog, DialogProps, FormControls } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet } from 'types';

type Props = DialogProps<InternalWallet>;

export const WalletInformationDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets.information_modal',
  });

  const [privateKeyPassword, setPrivateKeyPassword] = useState('');

  const {
    mutate: getPrivateKey,
    data: privateKeyData,
    isLoading: isPrivateKeyLoading,
    reset: resetPrivateKey,
  } = useMutation<
    { privateKey: string },
    AxiosError,
    { id: string; data: { password: string } }
  >(internalWalletsApi.getPrivateKey, { notifierType: 'execute' });

  const handleClose = useCallback(() => {
    resetPrivateKey();
    setPrivateKeyPassword('');
    onClose({ ok: true });
  }, [resetPrivateKey, onClose]);

  return (
    <Dialog open={open} title={t('title')} onClose={handleClose} modal>
      <div className="tw-mb-4">
        <Typography variant="h5" sx={{ mb: 2 }}>
          {t('address')}
        </Typography>
        <CopyText className="tw-break-all" text={data?.address || ''} />
      </div>
      <div className="tw-mb-4">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('private_key')}
        </Typography>
        {privateKeyData ? (
          <CopyText
            className="tw-break-all"
            text={privateKeyData.privateKey}
            hidden
            truncateLength={24}
          />
        ) : (
          <FormControls row>
            <TextField
              type="password"
              name="private-key-password"
              value={privateKeyPassword}
              variant="outlined"
              size="small"
              label={t('password')}
              required
              onChange={(e) => setPrivateKeyPassword(e.target.value)}
            />
            <Button
              variant="outlined"
              disabled={isPrivateKeyLoading || !privateKeyPassword}
              onClick={() =>
                getPrivateKey({
                  id: data!.id,
                  data: { password: privateKeyPassword },
                })
              }
            >
              {t('request_private_key')}
            </Button>
          </FormControls>
        )}
      </div>
    </Dialog>
  );
};
