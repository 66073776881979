import cx from 'classnames';
import { isNil } from 'lodash';
import React, { Fragment, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CopyText,
  CrudPage,
  DataGridColumnDefinition,
  TronscanLink,
} from 'components';
import { AnomalyType, FilterDefinitionType } from 'enums';
import { useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  AnomaliesFilters,
  PaginatedData,
  Anomaly,
  FilterDefinition,
} from 'types';
import { anomaliesUtils, filtersUtils, formatUtils, orderUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<PaginatedData<Anomaly>, unknown>;
  additionalColumns?: DataGridColumnDefinition<Anomaly>[];
};

export const AnomaliesTable: React.FC<Props> = ({
  queryResult,
  additionalColumns = [],
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.anomalies',
  });
  const { filters } = useQueryFilters<AnomaliesFilters>();

  const renderRelation = useCallback(
    (text: string, label: ReactNode, margin?: boolean) =>
      text && (
        <div className={cx({ 'tw-mb-2': margin })}>
          <div>{label}</div>
          <CopyText text={text} truncateLength={10} />
        </div>
      ),
    [],
  );

  const formatData = useCallback((item: Anomaly, data: string) => {
    switch (item.type) {
      case AnomalyType.OrderSameStatus:
        return orderUtils.getStatusLabel(data as any);
      case AnomalyType.InternalPlatformWalletWithdrawalTransaction:
        return <TronscanLink hash={data} />;
      case AnomalyType.AssetBalanceHistoryBalanceSequence:
      case AnomalyType.AssetBalanceHistoryBalanceSum:
      case AnomalyType.AssetBalanceHistoryHoldBalanceSequence:
      case AnomalyType.AssetBalanceHistoryHoldBalanceSum:
      case AnomalyType.OrderPrevAmount:
      case AnomalyType.AssetBalanceShopReconciliation:
        return formatUtils.formatMoney(data as any);
      default:
        return data;
    }
  }, []);

  const columns = useMemo(
    (): DataGridColumnDefinition<Anomaly>[] => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyText text={item.id} truncateLength={10} />,
      },
      {
        header: t('fields.created_at'),
        valueKey: 'createdAt',
        valueFormatter: formatUtils.formatDate,
      },
      {
        header: t('fields.data'),
        valueGetter: (item) => (
          <Fragment>
            <div>{`${t('fields.value')}:`}</div>
            <div>{formatData(item, item.value)}</div>
            {!isNil(item.expected) && (
              <div>{`${t('fields.expected')}: ${formatData(
                item,
                item.expected,
              )}`}</div>
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.relations'),
        valueGetter: (item) => (
          <Fragment>
            {renderRelation(item.internalWalletId, t('fields.wallet'))}
            {renderRelation(
              item.shopId,
              <Fragment>
                <div>{t('fields.shop')}</div>
                <div>{item.shop?.name}</div>
              </Fragment>,
              !!item.assetId,
            )}
            {renderRelation(
              item.orderId,
              t('fields.order'),
              !!(item.assetId || item.assetBalanceHistoryId),
            )}
            {renderRelation(
              item.assetId,
              t('fields.asset'),
              !!item.assetBalanceHistoryId,
            )}
            {renderRelation(
              item.assetBalanceHistoryId,
              t('fields.asset_balance_history'),
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.type'),
        valueKey: 'type',
        valueFormatter: anomaliesUtils.getAnomalyTypeLabel,
        valueClassName: 'tw-w-[240px]',
      },
    ],
    [t, renderRelation, formatData],
  );

  const filtersDefinitions: FilterDefinition<AnomaliesFilters>[] = useMemo(
    () => [
      {
        label: t('filters.type'),
        name: 'type',
        type: FilterDefinitionType.Enum,
        enum: Object.values(AnomalyType),
        getDisplayName: (value: AnomalyType) =>
          anomaliesUtils.getAnomalyTypeLabel(value),
      },
      {
        label: t('filters.order_id'),
        name: 'orderId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.asset_id'),
        name: 'assetId',
        type: FilterDefinitionType.Text,
      },
    ],
    [t],
  );

  return (
    <CrudPage
      filters={{
        filtersDefinitions: [
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
        ],
      }}
      table={{
        queryResult,
        paginated: true,
        columns: [...columns, ...additionalColumns],
      }}
    />
  );
};
