export enum RequisitesType {
  AccountNumber = 'account_number',
  PhoneNumber = 'phone_number',
  IDCard = 'id_card',
  AccountNumberIBAN = 'account_number_iban',
  AccountNumberSEPA = 'account_number_sepa',
  UPI = 'upi',
  IMPS = 'imps',
  PhonePe = 'phone_pe',
  PhoneTM = 'phone_tm',
  CardByPhone = 'card-by-phone',
  CardNumber = 'card',
  ERIP = 'erip',
}
