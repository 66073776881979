import { Stack, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  row?: boolean;
  sx?: SxProps;
};

export const FormControls: React.FC<Props> = ({ children, row, sx }) => (
  <Stack spacing={4} direction={row ? 'row' : 'column'} sx={sx}>
    {children}
  </Stack>
);
