import axios from 'axios';

import { env } from 'config';
import { AssetCurrencyExchangeRequest } from 'types';

import { createCrudApi } from './crud.api';

const prefix = '/asset-currency-exchange-requests';
const url = `${env.apiUrl}${prefix}`;

const exchange = async (params: {
  id: string;
  toAssetCurrencyId: string;
  amount: number;
  assetAmount: number;
  price: number;
  isPlatform?: boolean;
  assetCurrencyExchangeId?: string;
}): Promise<void> =>
  (
    await axios.post(
      `${url}${params.isPlatform ? '/platform' : ''}/asset/${
        params.id
      }/exchange/${params.toAssetCurrencyId}`,
      {
        amount: params.amount,
        price: params.price,
        assetAmount: params.assetAmount,
        ...(params.isPlatform && {
          assetCurrencyExchangeId: params.assetCurrencyExchangeId,
        }),
      },
    )
  ).data;

export const assetCurrencyExchangeRequestsApi = {
  ...createCrudApi<AssetCurrencyExchangeRequest>(prefix),
  exchange,
};
