import { useField } from 'formik';
import { useMemo } from 'react';

export const useFormikError = (name: string) => {
  const [, meta] = useField(name);

  const errorVisible = useMemo<boolean>(
    () => !!meta.error && (meta.touched || meta.value !== meta.initialValue),
    [meta],
  );

  const errorMessage = useMemo(
    () => (errorVisible ? meta.error : null),
    [errorVisible, meta.error],
  );

  return {
    errorVisible,
    errorMessage,
  };
};
