import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { QueryFilters } from 'components';
import { OrdersTab } from 'enums';
import { useUser } from 'hooks';
import { FilterDefinition } from 'types';

import { ExportPayoutOrders } from '../ExportOrders';

type Props = {
  tab: OrdersTab;
  queryResult: any;
  filtersDefinitions: FilterDefinition[];
};

export const OrdersActions: React.FC<Props> = ({
  tab,
  queryResult,
  filtersDefinitions,
}) => {
  const { isAdmin, isTechOperator, isTrader, isMerchant } = useUser();
  const exportEnabled = useMemo(() => {
    if (isEmpty(queryResult.data?.items)) {
      return false;
    }
    return (
      isAdmin ||
      isTechOperator ||
      ((isTrader || isMerchant) && tab === OrdersTab.All)
    );
  }, [isAdmin, isTechOperator, isTrader, isMerchant, queryResult, tab]);

  return (
    <div className="tw-flex tw-items-center tw-justify-end">
      <div className="tw-flex-1">
        <QueryFilters filtersDefinitions={filtersDefinitions} />
      </div>
      {exportEnabled && <ExportPayoutOrders tab={tab} />}
    </div>
  );
};
