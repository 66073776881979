import { Stack } from '@mui/material';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import { tradersApi } from 'api';
import {
  TraderInfo,
  MyAssets,
  DataWrapper,
  InternalUserWallet,
} from 'components';
import { QueryKey, UserRole } from 'enums';

export const TraderMainPage: React.FC = () => {
  const queryResult = useQuery(QueryKey.MyInfo, tradersApi.getMyInfo);

  return (
    <Stack direction="column" spacing={8}>
      <DataWrapper queryResult={queryResult}>
        <Fragment>
          <TraderInfo trader={queryResult.data} />
          <MyAssets role={UserRole.Trader} trader={queryResult.data} />
        </Fragment>
      </DataWrapper>
      <InternalUserWallet />
    </Stack>
  );
};
