import { useCallback } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { useQueryPagination } from 'hooks';

import { useQueryData } from './use-query-data.hook';

export function useQueryFilters<T>(initialData?: Partial<T>) {
  const {
    data,
    dataQuery,
    setData,
    reset,
    setProperty,
    resetProperty,
    initialized,
  } = useQueryData<T>('filters', initialData);

  const { resetPagination } = useQueryPagination();

  const handleFiltersChange = useCallback(() => {
    resetPagination();
  }, [resetPagination]);

  const setFilters = useCallback(
    (data: T) => {
      handleFiltersChange();
      setData(data);
    },
    [handleFiltersChange, setData],
  );

  const setFilter = useCallback(
    (key: keyof T, value: any) => {
      setProperty(key, value);
    },
    [setProperty],
  );

  const resetFilters = useCallback(
    (navigateOptions?: NavigateOptions) => {
      handleFiltersChange();
      reset(navigateOptions);
    },
    [handleFiltersChange, reset],
  );

  const resetFilter = useCallback(
    (name: string) => {
      handleFiltersChange();
      resetProperty(name);
    },
    [handleFiltersChange, resetProperty],
  );

  return {
    initialized,
    filters: data,
    filtersQuery: dataQuery,
    setFilters,
    setFilter,
    resetFilters,
    resetFilter,
  };
}
