import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { CurrencyExchangeRequests } from './CurrencyExchangeRequests';

enum Tabs {
  Active = 'active',
  Archive = 'archive',
}

export const CurrencyExchangeRequestsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.currency_exchange',
  });

  return (
    <div>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={Tabs}>
        <Tab value={Tabs.Active} label={t('tabs.active')} />
        <Tab value={Tabs.Archive} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={Tabs.Active}>
        <CurrencyExchangeRequests />
      </QueryTabPanel>
      <QueryTabPanel value={Tabs.Archive}>
        <CurrencyExchangeRequests archive />
      </QueryTabPanel>
    </div>
  );
};
