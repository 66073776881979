import styled from '@emotion/styled';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlinedIcon,
  Block as BlockIcon,
  AccessTime as AccessTimeIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components';
import { AssetCurrencyExchangeRequestStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: AssetCurrencyExchangeRequestStatus;
};

const getBackgroundColor = (props: any) => {
  switch (props.status) {
    case AssetCurrencyExchangeRequestStatus.Completed:
      return props.theme.palette.success.main;
    case AssetCurrencyExchangeRequestStatus.Cancelled:
      return props.theme.palette.error.main;
    case AssetCurrencyExchangeRequestStatus.New:
    case AssetCurrencyExchangeRequestStatus.Revoked:
      return props.theme.palette.grey[500];
  }
};

const StyledLabel = styled(StyledChip)<
  ChipProps & { status: AssetCurrencyExchangeRequestStatus }
>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

export const CurrencyExchangeStatusLabel: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'asset_currency_exchange_request_status',
  });

  switch (status) {
    case AssetCurrencyExchangeRequestStatus.Completed:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="success"
          icon={<CheckCircleOutlinedIcon />}
        ></StyledLabel>
      );
    case AssetCurrencyExchangeRequestStatus.Cancelled:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="error"
          icon={<BlockIcon />}
        ></StyledLabel>
      );
    case AssetCurrencyExchangeRequestStatus.Revoked:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="default"
          icon={<ClearIcon />}
        ></StyledLabel>
      );
    case AssetCurrencyExchangeRequestStatus.New:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="secondary"
          icon={<AccessTimeIcon />}
        ></StyledLabel>
      );
    default:
      return null;
  }
};
