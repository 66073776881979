import { useCallback } from 'react';

import { ParsingMethodsForm } from 'components/ParsingMethodsForm';
import { ParsingMethodType } from 'enums';
import { ParsingMethod } from 'types';

import { useParsingMethods } from './ParsingMethods.context';

type Props = {
  selectedParsingMethod?: ParsingMethod | null;
  withDescription?: boolean;
  tab: ParsingMethodType;
  onSubmit?: (values: ParsingMethod) => void;
};

export const ParsingMethodsDetailsForm: React.FC<Props> = ({
  selectedParsingMethod = null,
  tab,
  onSubmit,
}) => {
  const { tradeMethod, parsingMethodsQuery } = useParsingMethods();

  const handleSuccess = useCallback(
    (values: ParsingMethod) => {
      parsingMethodsQuery.refetch();
      onSubmit?.(values);
    },
    [onSubmit, parsingMethodsQuery],
  );

  return (
    <ParsingMethodsForm
      tradeMethod={tradeMethod}
      selectedParsingMethod={selectedParsingMethod}
      tab={tab}
      onSuccess={handleSuccess}
    />
  );
};
