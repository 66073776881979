import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, useState } from 'react';

import { InternalWallet } from 'types';

import { WalletInformationDialog } from './WalletInformationModal';

type Props = {
  item: InternalWallet;
};

export const WalletInformationIcon: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <IconButton color="primary" onClick={() => setOpen(true)}>
        <InfoOutlinedIcon />
      </IconButton>
      <WalletInformationDialog
        open={open}
        data={item}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
