import axios from 'axios';

import { env } from 'config';
import { Invite, InviteToAccept, AcceptInviteData } from 'types';

const url = `${env.apiUrl}/invites`;

const getInviteToAccept = async (token: string): Promise<InviteToAccept> =>
  (await axios.get(`${url}/accept/${token}`)).data;

const accept = async ({
  token,
  data,
}: {
  token: string;
  data: AcceptInviteData;
}): Promise<void> => (await axios.post(`${url}/accept/${token}`, data)).data;

const getAll = async (): Promise<Invite[]> => (await axios.get(url)).data;

const getOneForUser = async (userId: string): Promise<Invite> =>
  (await axios.get(`${url}/user/${userId}`)).data;

const create = async (invite: Partial<Invite>): Promise<Invite> =>
  (await axios.post(url, invite)).data;

const remove = async (invite: Invite): Promise<void> =>
  (await axios.delete(`${url}/${invite.id}`)).data;

export const invitesApi = {
  getInviteToAccept,
  getOneForUser,
  accept,
  getAll,
  create,
  remove,
};
