import { get, map } from 'lodash';

const getOptions = <T>(data: T[], labelKey?: keyof T, valueKey?: keyof T) =>
  map(data, (item) => ({
    label: get(item, labelKey || 'name'),
    value: get(item, valueKey || 'id'),
  }));

export const formUtils = {
  getOptions,
};
