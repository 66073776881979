import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import {
  NumberFormatValues,
  PatternFormat,
  PatternFormatProps,
} from 'react-number-format';

import { useFormikError } from 'hooks';

type Props = {
  name: string;
  label: string;
  format: string;
} & Partial<TextFieldProps> &
  Partial<PatternFormatProps>;

export const FormikPatternField: React.FC<Props> = ({
  name,
  label,
  ...rest
}) => {
  const [field, , helpers] = useField(name);

  const { errorVisible, errorMessage } = useFormikError(name);

  const handleChange = useCallback(
    ({ value }: NumberFormatValues) => helpers.setValue(value),
    [helpers],
  );

  const handleBlur = useCallback(() => helpers.setTouched(true), [helpers]);

  return (
    <PatternFormat
      {...rest}
      label={label}
      name={name}
      value={field.value}
      onValueChange={handleChange}
      customInput={TextField}
      error={errorVisible}
      helperText={errorMessage}
      size="small"
      onBlur={handleBlur}
    />
  );
};
