import axios from 'axios';

import { env } from 'config';
import { RequisitesDistribution, PaginatedData, QueryParams } from 'types';

const url = `${env.apiUrl}/requisites-distribution`;

const getByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<RequisitesDistribution>> =>
    (await axios.get(url, { params })).data;

const getCache = getByUrl(`${url}/cache`);

export const requisitesDistributionApi = {
  getCache,
};
