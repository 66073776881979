import { Tab, Button } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { anomaliesApi } from 'api';
import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { formatUtils } from 'utils';

import { NewAnomalies } from './NewAnomalies';
import { ReviewedAnomalies } from './ReviewedAnomalies';

enum AnomaliesTab {
  New = 'new',
  Reviewed = 'reviewed',
}

export const AnomaliesPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.anomalies',
  });

  const queryClient = useQueryClient();

  const { data: { date: lastSearchAt } = { date: null } } = useQuery(
    QueryKey.AnomaliesLastSearchAt,
    () => anomaliesApi.getLastSearchAt(),
  );

  const { mutate: search } = useMutation(anomaliesApi.search, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.NewAnomalies);
      queryClient.invalidateQueries(QueryKey.AnomaliesLastSearchAt);
    },
    notifierType: 'execute',
  });

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContent={
          <div className="tw-text-right">
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => search()}
            >
              {t('buttons.search')}
            </Button>
            {lastSearchAt && (
              <div className="tw-text-xs tw-mt-1">{`${t(
                'fields.searched',
              )}: ${formatUtils.formatDate(lastSearchAt)}`}</div>
            )}
          </div>
        }
      />
      <QueryTabs tabsEnum={AnomaliesTab}>
        <Tab value={AnomaliesTab.New} label={t('tabs.new')} />
        <Tab value={AnomaliesTab.Reviewed} label={t('tabs.reviewed')} />
      </QueryTabs>
      <QueryTabPanel value={AnomaliesTab.New}>
        <NewAnomalies />
      </QueryTabPanel>
      <QueryTabPanel value={AnomaliesTab.Reviewed}>
        <ReviewedAnomalies />
      </QueryTabPanel>
    </Fragment>
  );
};
