import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

import { ExpirationDateInput } from 'components';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { getTCommon, useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();

  return (
    <div className="tw-flex tw-items-center">
      <ExpirationDateInput
        name={name}
        label={t('fields.expiration_date.label')}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <Tooltip title={t('fields.expiration_date.tooltip')}>
        <InfoOutlinedIcon sx={{ ml: 2 }} fontSize="small" color="primary" />
      </Tooltip>
    </div>
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();
  return getBaseFieldValidator({
    required,
  })
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, tCommon('errors.invalid'))
    .nullable();
};
