import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserRole } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  role: UserRole;
};

export const RoleLabel: React.FC<Props> = ({ role }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'role',
  });
  return <span>{t(role)}</span>;
};
