import {
  Card,
  CardContent,
  Typography,
  Stack,
  CardActions,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyText } from 'components';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Props = {
  children?: ReactElement | ReactElement[];
};

export const ReferralInfo: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.referral_system',
  });
  const { user } = useUser();

  return (
    <Card sx={{ padding: 3, maxWidth: 550, marginBottom: 10 }}>
      <CardContent>
        <Stack spacing={2} direction="column">
          <Typography variant="h5">
            {`${t('fields.referral_token')}:`}
          </Typography>
          <div className="tw-flex tw-items-center">
            <Typography variant="subtitle1">{user.referralToken}</Typography>
            <CopyText text={user.referralToken} iconOnly />
          </div>
        </Stack>
      </CardContent>
      <CardActions>{children}</CardActions>
    </Card>
  );
};
