import { reduce } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { reduceChildRoutes } from './utils';
import { LayoutMenuItem } from '../../../types';

type Props = {
  depth: number;
  items: LayoutMenuItem[];
};

export const NavList: React.FC<Props> = ({ items, depth }) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = reduce(
    items,
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    [] as JSX.Element[],
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};
