import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ChipLinkList } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { useUser } from 'hooks';

export const TransactionsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isAdmin, isTechOperator } = useUser();

  const paths = useMemo(() => {
    if (isAdmin) {
      return {
        deposit: ROUTE_PATH.ADMIN.DEPOSIT_TRANSACTIONS,
        withdrawal: ROUTE_PATH.ADMIN.WITHDRAWAL_TRANSACTIONS,
      };
    } else if (isTechOperator) {
      return {
        deposit: ROUTE_PATH.TECH_OPERATOR.DEPOSIT_TRANSACTIONS,
        withdrawal: ROUTE_PATH.TECH_OPERATOR.WITHDRAWAL_TRANSACTIONS,
      };
    }
  }, [isAdmin, isTechOperator]);

  return (
    <Outlet
      context={{
        headerSubcontent: (
          <ChipLinkList
            links={[
              {
                label: t('common.deposit'),
                to: paths!.deposit,
              },
              {
                label: t('common.withdrawal'),
                to: paths!.withdrawal,
              },
            ]}
          />
        ),
      }}
    />
  );
};
