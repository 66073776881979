import axios from 'axios';

import { env } from 'config';
import { OrderAutomationFilterTypes, UserRole } from 'enums';
import { OrderAutomation, PaginatedData, QueryParams } from 'types';

import { createCrudApi } from './crud.api';
const url = `${env.apiUrl}/order-automation`;

const getMessagesAsRole =
  (role: UserRole, messageType: OrderAutomationFilterTypes) =>
  async (params?: QueryParams): Promise<PaginatedData<OrderAutomation>> =>
    (await axios.get(`${url}/${role}/${messageType}`, { params })).data;

const applyErrorTemplateAsRole =
  (role: UserRole) =>
  async (data: {
    parsingMethodId: string;
    orderAutomationId?: string;
    applyToAll?: boolean;
  }) =>
    (await axios.post(`${url}/${role}/template-error/apply`, data)).data;

export const orderAutomationApi = {
  ...createCrudApi<OrderAutomation>('/order-automation'),
  getMessagesAsRole,
  applyErrorTemplateAsRole,
};
