import styled from '@emotion/styled';

import { DRAWER_COLLAPSED_WIDTH } from 'components/MainLayout/styles';

export const ListItems = styled.div`
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
  margin-left: ${DRAWER_COLLAPSED_WIDTH}px;
  margin-right: ${DRAWER_COLLAPSED_WIDTH}px;
`;

export const Scrollbar = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
  overflow: auto;
`;
