import React, { useMemo } from 'react';

import { OrderStatus } from 'enums';
import { orderUtils } from 'utils';

type Props = {
  status: OrderStatus;
};

export const OrderStatusLabel: React.FC<Props> = ({ status }) => {
  const textColorClassName = useMemo(() => {
    switch (status) {
      case OrderStatus.New:
        return 'tw-text-cyan-400';
      case OrderStatus.Requisites:
        return 'tw-text-violet-500';
      case OrderStatus.CustomerConfirm:
        return 'tw-text-gray-500';
      case OrderStatus.TraderConfirm:
        return 'tw-text-amber-500';
      case OrderStatus.Completed:
        return 'tw-text-green-700';
      case OrderStatus.Error:
        return 'tw-text-red-500';
      case OrderStatus.Dispute:
        return 'tw-text-red-400';
    }
  }, [status]);

  return (
    <span className={textColorClassName}>
      {orderUtils.getStatusLabel(status)}
    </span>
  );
};
