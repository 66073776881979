import { isFunction } from 'lodash';
import React, { Fragment, ReactNode, useCallback, useMemo } from 'react';

import { UnitPosition } from 'enums';
import { useCurrencies } from 'hooks';
import { formatUtils } from 'utils';

type ChildrenProp = {
  value: number;
  formattedValue: string;
  unit?: string;
  unitPosition?: UnitPosition;
};

type Props = {
  value: number;
  symbol?: boolean;
  code?: boolean;
  assetCurrencyId?: string;
  fiatCurrencyId?: string;
  children?: (props: ChildrenProp) => ReactNode;
};

export type { Props as MoneyProps };

export const Money: React.FC<Props> = ({
  value,
  symbol,
  code,
  assetCurrencyId,
  fiatCurrencyId,
  children,
}) => {
  const {
    getAssetCurrency,
    getFiatCurrency,
    getAssetCurrencyCode,
    getAssetCurrencySymbol,
    getFiatCurrencyCode,
    getFiatCurrencySymbol,
  } = useCurrencies();

  const assetCurrency = useMemo(
    () => getAssetCurrency(assetCurrencyId),
    [assetCurrencyId, getAssetCurrency],
  );

  const fiatCurrency = useMemo(
    () => getFiatCurrency(fiatCurrencyId),
    [fiatCurrencyId, getFiatCurrency],
  );

  const unit = useMemo(() => {
    if (assetCurrencyId) {
      if (symbol) {
        return getAssetCurrencySymbol(assetCurrencyId);
      } else if (code) {
        return getAssetCurrencyCode(assetCurrencyId);
      }
    } else if (fiatCurrencyId) {
      if (symbol) {
        return getFiatCurrencySymbol(fiatCurrencyId);
      } else if (code) {
        return getFiatCurrencyCode(fiatCurrencyId);
      }
    }
  }, [
    symbol,
    code,
    fiatCurrencyId,
    assetCurrencyId,
    getAssetCurrencySymbol,
    getAssetCurrencyCode,
    getFiatCurrencySymbol,
    getFiatCurrencyCode,
  ]);

  const formattedValue = useMemo(() => formatUtils.formatMoney(value), [value]);
  const unitPosition = useMemo(
    () => assetCurrency?.symbolPosition || fiatCurrency?.symbolPosition,
    [assetCurrency?.symbolPosition, fiatCurrency?.symbolPosition],
  );

  const renderFormattedValue = useCallback(() => {
    if (unitPosition === UnitPosition.Before) {
      return (unit ? `${unit} ` : '') + formattedValue;
    } else if (unitPosition === UnitPosition.After) {
      return formattedValue + (unit ? ` ${unit}` : '');
    }
    return formattedValue;
  }, [formattedValue, unitPosition, unit]);

  return (
    <Fragment>
      {isFunction(children)
        ? children({
            value,
            formattedValue,
            unit,
            unitPosition,
          })
        : renderFormattedValue()}
    </Fragment>
  );
};
