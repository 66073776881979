import { Stack } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { assetsApi } from 'api';
import {
  DataWrapper,
  InternalUserWallet,
  MyAssets,
  PageHeader,
  TotalBalance,
} from 'components';
import { QueryKey, UserRole } from 'enums';
import { useCalculateAssetBalances } from 'hooks';
import { TranslationNamespace } from 'i18n';

export const MerchantMainPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Merchant, {
    keyPrefix: 'pages.assets.balances',
  });

  const queryResult = useQuery(QueryKey.ShopAssets, assetsApi.getAllShops);
  const shopAssets = useMemo(() => queryResult.data || [], [queryResult.data]);
  const { balances } = useCalculateAssetBalances(shopAssets);
  return (
    <Fragment>
      <PageHeader title={t('title')} />
      <Stack direction="column" spacing={8}>
        <DataWrapper queryResult={queryResult}>
          <TotalBalance balances={balances} />
        </DataWrapper>
        <PageHeader title={t('my_shops')} divider={false} />
        <MyAssets role={UserRole.Merchant} />
        <PageHeader title={t('my_wallets')} divider={false} />
        <InternalUserWallet />
      </Stack>
    </Fragment>
  );
};
