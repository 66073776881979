import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';

type Props = {
  name: string;
  label: string;
} & Partial<TextFieldProps> &
  Partial<NumberFormatBaseProps>;

export const ExpirationDateInput: React.FC<Props> = ({
  name,
  label,
  ...rest
}) => {
  const format = useCallback((value?: string | null) => {
    if (!value) {
      return '';
    }

    let cleanInput = value.replace(/[^0-9]/g, '');

    if (cleanInput === '0' || cleanInput === '1') {
      return cleanInput;
    }

    let month = cleanInput.substring(0, 2);
    let year = cleanInput.substring(2, 4);

    let monthNum = parseInt(month, 10);
    if (isNaN(monthNum) || monthNum < 1) {
      return '';
    } else if (monthNum > 12) {
      month = '12';
    }

    return year ? `${month}/${year}` : month;
  }, []);

  const [field, , helpers] = useField(name);

  const handleChange = useCallback(
    ({ formattedValue }: any) => helpers.setValue(formattedValue),
    [helpers],
  );

  const handleBlur = useCallback(() => helpers.setTouched(true), [helpers]);

  const incrementMonth = useCallback((month: number, year: number) => {
    month++;
    if (month > 12) {
      month = 1;
      year++;
    }
    return { month, year };
  }, []);

  const decrementMonth = useCallback((month: number, year: number) => {
    month--;
    if (month < 1) {
      month = 12;
      year--;
    }
    return { month, year };
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
      e.preventDefault();
      let [month, year] = field.value.split('/').map(Number);

      if (e.key === 'ArrowUp') {
        ({ month, year } = incrementMonth(month, year));
      } else if (e.key === 'ArrowDown') {
        ({ month, year } = decrementMonth(month, year));
      }

      handleChange({
        formattedValue: `${month.toString().padStart(2, '0')}/${year}`,
      });
    },
    [field.value, handleChange, incrementMonth, decrementMonth],
  );

  return (
    <NumberFormatBase
      {...rest}
      label={label}
      name={name}
      format={format}
      customInput={TextField}
      value={field.value}
      onValueChange={handleChange}
      onBlur={handleBlur}
      size="small"
      placeholder="MM/YY"
      onKeyDown={handleKeyDown}
    />
  );
};
