import moment from 'moment';
import { useMemo } from 'react';

import { TRADER_ORDER_STATUSES } from 'constants/order.constants';
import { AdditionalOrderColumn, OrderStatus, OrdersTab } from 'enums';
import { Orders } from 'features/payout-orders';
import { useQueryFilters } from 'hooks';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

export const TraderAllOrders: React.FC = () => {
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
    statuses: [OrderStatus.Completed, OrderStatus.Cancelled],
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [orderUtils.getStatusFilterDefinition(TRADER_ORDER_STATUSES)],
    [],
  );

  return (
    <Orders
      tab={OrdersTab.All}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.StatusActive,
        AdditionalOrderColumn.CustomerRequisites,
      ]}
    />
  );
};
