import React, { useMemo } from 'react';

import { WebhookStatus } from 'enums';
import { webhooksUtils } from 'utils';

type Props = {
  status: WebhookStatus;
};

export const WebhookStatusLabel: React.FC<Props> = ({ status }) => {
  const textColorClassName = useMemo(() => {
    switch (status) {
      case WebhookStatus.Cancelled:
        return 'tw-text-orange-400';
      case WebhookStatus.Success:
        return 'tw-text-green-700';
      case WebhookStatus.Error:
        return 'tw-text-red-500';
    }
  }, [status]);

  return (
    <span className={textColorClassName}>
      {webhooksUtils.getStatusLabel(status)}
    </span>
  );
};
