import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { Stack, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { find, isEmpty, map } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import { internalWalletsApi, shopsApi } from 'api';
import { ConfirmButton, CopyText, DataWrapper, InfoCard } from 'components';
import { Network, QueryKey, StatusCode } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalWallet } from 'types';
import { formatUtils } from 'utils';

export const InternalUserWallet: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.internal_user_wallet',
  });

  const queryClient = useQueryClient();

  const { isMerchant, isTrader } = useUser();

  const queryResultShops = useQuery(QueryKey.Shops, shopsApi.getAllMy, {
    enabled: isMerchant,
  });

  const queryResult = useQuery(
    QueryKey.InternalUsersWallets,
    internalWalletsApi.getMy,
  );

  const wallets = useMemo(() => {
    if (isMerchant) {
      return map(
        queryResultShops.data,
        (shop) =>
          ({
            ...find(queryResult.data, { shopId: shop.id }),
            shopId: shop.id,
            shop: {
              id: shop.id,
              name: shop.name,
            },
          } as InternalWallet),
      );
    }
    return !isEmpty(queryResult.data) ? queryResult.data : [null];
  }, [isMerchant, queryResult.data, queryResultShops]);

  const { mutate: createWallet, isLoading: isCreateLoading } = useMutation(
    internalWalletsApi.create,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.InternalUsersWallets);
      },
      notifierType: 'execute',
      notifierMessages: {
        error: (error: AxiosError<{ message: string | undefined }>) => {
          const status = error?.response?.status;
          const message = error.response?.data?.message?.[0] as any;
          if (
            status === StatusCode.BadRequest &&
            message?.constraints?.too_often &&
            message?.property === 'createdAt'
          ) {
            return t('create_too_often');
          }
        },
      },
    },
  );

  const isLoading = useMemo(
    () =>
      queryResult.isLoading ||
      queryResult.isRefetching ||
      queryResultShops.isLoading ||
      isCreateLoading,
    [isCreateLoading, queryResult, queryResultShops],
  );

  const queryResults = useMemo(() => {
    const results: UseQueryResult[] = [queryResult];
    if (isMerchant) {
      results.unshift(queryResultShops);
    }
    return results;
  }, [isMerchant, queryResult, queryResultShops]);

  return (
    <DataWrapper queryResult={queryResults} ignoreState={{ empty: isTrader }}>
      <Grid container spacing={3}>
        {map(wallets, (wallet, i) => (
          <Grid key={i} item xs={12} sm={6} lg={4}>
            <InfoCard
              title={<Typography variant="subtitle1">{t('title')}</Typography>}
              content={
                <DataWrapper isLoading={isLoading}>
                  <Stack direction="column" spacing={4}>
                    {wallet?.shop?.name && (
                      <div>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                          {t('shop')}
                        </Typography>
                        <div className="tw-uppercase">{wallet.shop.name}</div>
                      </div>
                    )}
                    <div>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {t('network')}
                      </Typography>
                      <div className="tw-uppercase">
                        {wallet?.network || Network.TRC20}
                      </div>
                    </div>
                    <div>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {t('address')}
                      </Typography>
                      <CopyText
                        text={wallet?.address || t('not_created')}
                        disabled={!wallet?.address}
                      />
                      {wallet?.createdAt && (
                        <Typography
                          variant="caption"
                          color="GrayText"
                          component="div"
                        >
                          {formatUtils.formatDate(wallet.createdAt)}
                        </Typography>
                      )}
                    </div>
                  </Stack>
                </DataWrapper>
              }
              footer={
                !queryResult.isLoading &&
                !queryResult.isRefetching && (
                  <Stack direction="row" justifyContent="center" spacing={3}>
                    <ConfirmButton
                      fabProps={{
                        variant: 'extended',
                        color: 'primary',
                        size: 'medium',
                      }}
                      disabled={isCreateLoading}
                      onConfirm={() => createWallet(wallet?.shopId)}
                    >
                      {wallet ? (
                        <Fragment>
                          <RefreshIcon />
                          {t('create_new')}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <AddIcon />
                          {t('create')}
                        </Fragment>
                      )}
                    </ConfirmButton>
                  </Stack>
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </DataWrapper>
  );
};
