import i18next from 'i18next';
import { encode, decode } from 'js-base64';
import moment from 'moment';

import { DateFormat, FilterDefinitionType } from 'enums';
import { DateFilters, FilterDefinition } from 'types';
import { formatUtils } from 'utils';

const getCommonFilters = (
  filters: DateFilters,
  formatType?: FilterDefinitionType.Date | FilterDefinitionType.DateTime,
): FilterDefinition<DateFilters>[] => {
  const { t } = i18next;
  const type = formatType ? formatType : FilterDefinitionType.Date;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
  ];
};

const getCommonDataTimeFilters = (
  filters: DateFilters,
): FilterDefinition<DateFilters>[] => {
  const { t } = i18next;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type: FilterDefinitionType.DateTime,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type: FilterDefinitionType.DateTime,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
  ];
};

export const filtersUtils = {
  getCommonDataTimeFilters,
  getCommonFilters,
  encode,
  decode,
};
