import { Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { fundsRequestsApi, shopsApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import { FundsRequestStatus, QueryKey } from 'enums';
import { FundsRequests } from 'features';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FundsRequest } from 'types';

type Props = {
  active?: boolean;
};

export const MyFundsRequests: React.FC<Props> = ({ active }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });
  const { isMerchant } = useUser();

  const { data: shops = isMerchant ? [] : undefined } = useQuery(
    QueryKey.Shops,
    shopsApi.getAllMy,
    { enabled: isMerchant },
  );

  const queryResult = usePartialQuery(
    QueryKey.MyFundsRequests,
    active ? fundsRequestsApi.getMyActive : fundsRequestsApi.getMyArchive,
  );

  const queryClient = useQueryClient();

  const { mutate: cancel } = useMutation(fundsRequestsApi.revoke, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.MyFundsRequests);
    },
  });

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<FundsRequest>[] => [
      {
        header: t('funds_requests.fields.actions'),
        valueGetter: (item) =>
          item.status === FundsRequestStatus.New && (
            <div>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => cancel(item)}
              >
                {t('funds_requests.fields.revoke')}
              </Button>
            </div>
          ),
        hidden: !active,
      },
    ],
    [cancel, active, t],
  );

  return (
    <FundsRequests
      queryResult={queryResult}
      additionalColumns={additionalColumns}
      shops={shops}
      hideTitle
    />
  );
};
