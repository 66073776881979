import React from 'react';
import { useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import { QueryKey } from 'enums';
import { ProcessFundsRequests } from 'features';

export const ActiveFundsRequests: React.FC = () => {
  const { data: users = [] } = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );

  const { data: shops = [] } = useQuery(QueryKey.Shops, shopsApi.getAll);

  return <ProcessFundsRequests hideTitle users={users} shops={shops} />;
};
