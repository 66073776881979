import { useCallback, useEffect, useState } from 'react';

export const useStorage = <T>(key: string, defaultValue: T) => {
  const getStorageValue = useCallback((key: string, defaultValue: T): T => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      try {
        return JSON.parse(savedValue);
      } catch {
        return defaultValue;
      }
    }

    return defaultValue;
  }, []);

  const [value, setValue] = useState<T>(getStorageValue(key, defaultValue));

  const merge = useCallback((data: Partial<T>) => {
    setValue((prev) => ({
      ...prev,
      ...data,
    }));
  }, []);

  const remove = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return {
    value,
    set: setValue,
    merge,
    remove,
  };
};
