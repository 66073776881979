import axios from 'axios';

import { env } from 'config';
import { Meta } from 'types';

const getAll = async (): Promise<Meta[]> =>
  (await axios.get(`${env.apiUrl}/meta`)).data;

export const metaApi = {
  getAll,
};
