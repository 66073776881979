import { map, toUpper } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps } from 'components';
import { Network } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  name: string;
  label?: string;
} & Partial<FormikSelectProps>;

export const NetworkSelect: React.FC<Props> = ({ name, label, ...rest }) => {
  const { t } = useTranslation(TranslationNamespace.Common);

  const options = useMemo(
    () =>
      map(Object.values(Network), (network) => ({
        value: network,
        label: toUpper(network),
      })),
    [],
  );

  return (
    <FormikSelect
      label={label || t('components.network_select.title')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
