import { Telegram as TelegramIcon } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogProps,
  FormActions,
  FormControls,
  FormikTextField,
} from 'components';
import { useMeta } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Values = {
  orderId: string;
};

type Props = DialogProps;

export const TelegramSupportDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.telegram_support_dialog',
  });
  const initialValues = useMemo(
    () => ({
      orderId: '',
    }),
    [],
  );

  const { telegramSupportBotUrl } = useMeta();

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      let url = telegramSupportBotUrl;
      if (values.orderId) {
        url += `?start=order_${values.orderId}`;
      }
      window.open(url, '_blank');
      formikHelpers.resetForm();
      onClose({ ok: true });
    },
    [telegramSupportBotUrl, onClose],
  );

  return (
    <Dialog open={open} title={t('title')} modal onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <FormControls>
            <Alert severity="info">{t('info')}</Alert>
            <FormikTextField
              name="orderId"
              label={t('fields.order_id')}
              fullWidth
            />
          </FormControls>
          <FormActions>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<TelegramIcon />}
              type="submit"
            >
              {t('open_chat_btn')}
            </Button>
          </FormActions>
        </Form>
      </Formik>
    </Dialog>
  );
};
