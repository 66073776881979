import { FormControl, MenuItem, TextFieldProps } from '@mui/material';
import { Field, useField } from 'formik';
import { Select } from 'formik-mui';
import { find, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelectOption } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  label: React.ReactNode | string;
  name: string;
  options: FormikSelectOption[];
} & Partial<TextFieldProps>;

export const FormikMultiSelect: React.FC<Props> = ({
  label,
  name,
  options,
  variant = 'outlined',
  size = 'small',
  fullWidth,
  ...rest
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [field] = useField(name);

  const isSelectedDisabled = useMemo(
    () => find(options, { value: field.value })?.disabled,
    [field.value, options],
  );

  return (
    <FormControl fullWidth={fullWidth}>
      <Field
        {...rest}
        variant={variant}
        component={Select}
        inputLabel={{ variant, size }}
        name={name}
        multiple
        label={label}
        size={size}
        {...(isSelectedDisabled && {
          error: true,
          helperText: tCommon('errors.invalid'),
        })}
      >
        {map(sortBy(options, 'disabled'), (option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};
