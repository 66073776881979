import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { DepositActiveTransactions } from './DepositActiveTransactions';
import { DepositArchiveTransactions } from './DepositArchiveTransactions';

enum WalletsTabs {
  Active = 'active',
  Archive = 'archive',
}

export const DepositTransactionsPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets_transactions.title',
  });

  return (
    <Fragment>
      <PageHeader title={t('common')} divider={false} />
      <QueryTabs tabsEnum={WalletsTabs}>
        <Tab value={WalletsTabs.Active} label={t('active')} />
        <Tab value={WalletsTabs.Archive} label={t('archive')} />
      </QueryTabs>
      <QueryTabPanel value={WalletsTabs.Active}>
        <DepositActiveTransactions />
      </QueryTabPanel>
      <QueryTabPanel value={WalletsTabs.Archive}>
        <DepositArchiveTransactions />
      </QueryTabPanel>
    </Fragment>
  );
};
