import { Fragment } from 'react';

type Props = {
  text: string;
};

export const Json: React.FC<Props> = ({ text }) => {
  if (text) {
    try {
      return <pre>{JSON.stringify(JSON.parse(text), null, 2)}</pre>;
    } catch {
      return <Fragment>{text}</Fragment>;
    }
  }
  return null;
};
