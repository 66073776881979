import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { assetsApi } from 'api';
import { CloseDialogHandler, CloseDialogResult, Dialog } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset } from 'types';

type Props = {
  open: boolean;
  asset?: Asset;
  onClose: CloseDialogHandler;
};

export const InsuranceDepositWithdrawalDialog: React.FC<Props> = ({
  open,
  asset,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.insurance_deposit_withdrawal_dialog',
  });

  const { mutate: withdrawInsuranceDeposit } = useMutation(
    () => assetsApi.withdrawInsuranceDeposit(asset?.assetCurrencyId!),
    {
      onSuccess: () => onClose({ ok: true }),
    },
  );

  const handleClose = useCallback(
    ({ ok }: CloseDialogResult) => {
      if (ok && asset) {
        withdrawInsuranceDeposit();
      } else {
        onClose({ ok });
      }
    },
    [asset, onClose, withdrawInsuranceDeposit],
  );

  return (
    <Dialog open={open} title={t('title')} onClose={handleClose}>
      <div>{t('message')}</div>
    </Dialog>
  );
};
