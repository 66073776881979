import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { internalWalletsWithdrawalTransactionsApi } from 'api';
import { ConfirmButton, Dialog, DialogProps } from 'components';
import { QueryKey, TronTransactionStatus } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalPlatformWalletDepositTransaction } from 'types';

type Props = DialogProps<InternalPlatformWalletDepositTransaction>;

export const WithdrawalTransactionDetailsModal: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const queryClient = useQueryClient();
  // TODO: move tKeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets_transactions.transaction_details_modal',
  });
  const { mutate: statusToWaiting } = useMutation(
    internalWalletsWithdrawalTransactionsApi.statusToWaiting,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.InternalWalletTransactionsWithdrawalActive,
        );
        onClose({ ok: true });
      },
      notifierType: 'execute',
    },
  );
  const { mutate: statusToError } = useMutation(
    internalWalletsWithdrawalTransactionsApi.statusToError,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.InternalWalletTransactionsWithdrawalActive,
        );
        onClose({ ok: true });
      },
      notifierType: 'execute',
    },
  );

  const isError = useMemo(
    () => data?.status === TronTransactionStatus.Error,
    [data],
  );

  const onConfirmToWaiting = useCallback(() => {
    statusToWaiting(data?.id!);
  }, [data?.id, statusToWaiting]);

  const onConfirmToError = useCallback(() => {
    statusToError(data?.id!);
  }, [data?.id, statusToError]);

  return (
    <Dialog open={open} title={t('title')} modal onClose={onClose}>
      {isError && (
        <div className="tw-mb-4">
          <ConfirmButton variant="outlined" onConfirm={onConfirmToWaiting}>
            {t('to_waiting')}
          </ConfirmButton>
          <Typography variant="caption" component="div" color="textSecondary">
            {t('execute_by_schedule')}
          </Typography>
        </div>
      )}
      {!isError && (
        <div className="tw-mb-4">
          <ConfirmButton
            variant="outlined"
            color="error"
            onConfirm={onConfirmToError}
          >
            {t('to_error')}
          </ConfirmButton>
          <Typography variant="caption" component="div">
            {t('to_error_description')}
          </Typography>
        </div>
      )}
    </Dialog>
  );
};
