import { useCallback, useState } from 'react';
import { MutateOptions } from 'react-query';

import { RequisitesStatusToggle } from 'components';
import { RequisitesStatus } from 'enums';
import { PayoutRequisites, Requisites } from 'types';

import { RequisitesBlocked } from './RequisitesBlocked';

type Props = {
  requisites: Requisites | PayoutRequisites;
  canChangeStatus?: boolean;
  updateStatus: (
    id: string,
    status: RequisitesStatus,
    options: MutateOptions<any, any, any, any>,
  ) => void;
};

export const RequisitesStatusInfo: React.FC<Props> = ({
  requisites,
  canChangeStatus,
  updateStatus,
}) => {
  const [status, setStatus] = useState(requisites.status);

  const handleStatusChange = useCallback(
    (updatedStatus: RequisitesStatus) =>
      updateStatus(requisites.id, updatedStatus, {
        onSuccess: () => setStatus(updatedStatus),
      }),
    [updateStatus, requisites.id],
  );

  if (status === RequisitesStatus.Blocked) {
    return (
      <RequisitesBlocked
        blockedBy={requisites.blockedBy}
        blockedMessage={requisites.blockedMessage}
        blockedAt={requisites.blockedAt}
        statusDetails={requisites.statusDetails}
      />
    );
  }

  return (
    <RequisitesStatusToggle
      status={status}
      disabled={!canChangeStatus}
      updateStatus={handleStatusChange}
    />
  );
};
