import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { Merchant, QueryParams } from 'types';

const url = `${env.apiUrl}/merchants`;

const getAllAsRole =
  (role: UserRole) =>
  async (params?: Pick<QueryParams, 'filters'>): Promise<Merchant[]> =>
    (await axios.get(`${url}/${role}`, { params })).data;

export const merchantsApi = {
  getAllAsRole,
};
