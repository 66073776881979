import { TextFieldProps } from '@mui/material';
import React from 'react';

import { FormikPatternField } from 'components';

type Props = {
  name: string;
  label: string;
} & Pick<TextFieldProps, 'disabled' | 'required' | 'fullWidth'>;

const CARD_NUMBER_FORMAT = '#### #### #### ####';

export const AccountNumberInput: React.FC<Props> = ({
  name,
  label,
  ...rest
}: Props) => (
  <FormikPatternField
    {...rest}
    name={name}
    label={label}
    format={CARD_NUMBER_FORMAT}
    mask="_"
  />
);
