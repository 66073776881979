import { TextField } from '@mui/material';
import { debounce, map } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { parsingMethodsApi } from 'api';
import { FormControls } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  template: string;
  message?: string;
};

export const ParsingMethodsTestForm: React.FC<Props> = ({
  template,
  message = '',
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });

  const [testMessage, setTestMessage] = useState<string>(message);

  const query = useQuery(
    QueryKey.ParsingMethodsParse,
    () => parsingMethodsApi.parseWithTemplate(template, testMessage),
    {
      enabled: false,
    },
  );

  const parsedData = useMemo(() => {
    if (!query.data) {
      return '';
    }

    return map(query.data, (value, key) => `${key}: ${value}`).join('\n');
  }, [query.data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce(() => query.refetch(), 500),
    [query],
  );

  // Debounced fetch on input change
  useEffect(() => {
    debouncedFetch();
    return debouncedFetch.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testMessage, template]);

  /**
   * Debounced function to parse data
   * @param e
   */
  const handleOnInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const value = e.target.value;
      setTestMessage(value);
    },
    [],
  );

  return (
    <FormControls>
      <TextField
        label={t('test_form.fields.text')}
        value={testMessage}
        onChange={handleOnInputChange}
        fullWidth
        multiline
        rows={4}
        variant="outlined"
      />
      <TextField
        label={t('test_form.fields.result')}
        value={parsedData}
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        disabled
      />
    </FormControls>
  );
};
