import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { assign, filter, keys, map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { fiatCurrencyExchangeApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
  FormikYesNoRadioGroup,
} from 'components';
import { FiatCurrencyExchangeSource, QueryKey } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FiatCurrencyExchange, FiatCurrencyExchangeDto } from 'types';
import { currencyExchangeUtils, validationUtils } from 'utils';

type Values = FiatCurrencyExchangeDto;

type Props = DialogProps<FiatCurrencyExchange>;

export const FiatCurrencyExchangeDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const queryClient = useQueryClient();
  // TODO: move tKeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies.fiat_currency_exchange_details',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { fiatCurrenciesOptions, getCurrencyExchangeOptions } = useCurrencies();

  const { mutate: createFiatCurrencyExchange } = useMutation<
    FiatCurrencyExchange,
    AxiosError,
    Partial<FiatCurrencyExchange>
  >(fiatCurrencyExchangeApi.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.FiatCurrencyExchange);
      onClose({ ok: true });
    },
  });

  const { mutate: updateFiatCurrencyExchange } = useMutation<
    FiatCurrencyExchange,
    AxiosError,
    { id: string; data: Partial<FiatCurrencyExchange> }
  >(fiatCurrencyExchangeApi.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.FiatCurrencyExchange);
      onClose({ ok: true });
    },
  });

  const title = useMemo(
    () => (data ? t('title.edit') : t('title.create')),
    [t, data],
  );

  const getInitialValues = useCallback(
    (): Values => ({
      name: '',
      source: FiatCurrencyExchangeSource.VamPrivet,
      fiatCurrencyId: '',
      crossFiatCurrencyId: '',
      price: 0,
      deviationPercentage: 0,
      refresh: true,
      enabled: true,
      fiatCurrencyExchangeId: '',
      crossFiatCurrencyExchangeId: '',
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string(),
        source: Yup.mixed<FiatCurrencyExchangeSource>()
          .oneOf(Object.values(FiatCurrencyExchangeSource))
          .required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        crossFiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        deviationPercentage: Yup.number()
          .moreThan(-100, tCommon('errors.percentage_absolute'))
          .lessThan(100, tCommon('errors.percentage_absolute')),
        price: Yup.number().when('refresh', {
          is: false,
          then: (schema) =>
            schema
              .moreThan(0, tCommon('errors.natural_number'))
              .required(tCommon('errors.required')),
        }),
        enabled: Yup.boolean().required(tCommon('errors.required')),
        refresh: Yup.boolean().required(tCommon('errors.required')),
        fiatCurrencyExchangeId: Yup.string().notRequired(),
        crossFiatCurrencyExchangeId: Yup.string().notRequired(),
      }),
    [tCommon],
  );

  const sourceOptions = useMemo(
    () =>
      map(FiatCurrencyExchangeSource, (source) => ({
        value: source,
        label: currencyExchangeUtils.getFiatCurrencyExchangeSourceLabel(source),
      })),
    [],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result?.data?.formikHelpers.resetForm({ values: getInitialValues() });
        onClose(result);
        return;
      }
      if (result.data?.values) {
        const options = {
          onSuccess: () => {
            result?.data?.formikHelpers.resetForm({
              values: getInitialValues(),
            });
          },
          onError: (error: AxiosError) => {
            result.data?.formikHelpers.setErrors(
              validationUtils.getFormErrors(error),
            );
          },
        };
        if (!data) {
          createFiatCurrencyExchange(result.data.values, options);
        } else {
          updateFiatCurrencyExchange(
            {
              id: data.id!,
              data: result.data.values,
            },
            options,
          );
        }
      }
    },
    [
      data,
      getInitialValues,
      onClose,
      createFiatCurrencyExchange,
      updateFiatCurrencyExchange,
    ],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  useEffect(() => {
    const _initialValues = getInitialValues();
    setInitialValues(assign(_initialValues, pick(data, keys(_initialValues))));
  }, [data, getInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          okDisabled={!formik.isValid}
          onClose={handleClose}
          {...rest}
        >
          <Form>
            <FormControls>
              <FormikYesNoRadioGroup
                label={t('fields.enabled')}
                name="enabled"
              />
              <FormikTextField label={t('fields.name')} name="name" />
              <FormikSelect
                label={t('fields.source')}
                name="source"
                options={sourceOptions}
              />
              <FormikSelect
                label={t('fields.fiat_currency')}
                name="fiatCurrencyId"
                disabled={!!data}
                options={filter(
                  fiatCurrenciesOptions,
                  (option) =>
                    option.value !== formik.values.crossFiatCurrencyId,
                )}
              />
              <FormikSelect
                label={t('fields.fiat_currency_exchange')}
                name="fiatCurrencyExchangeId"
                options={getCurrencyExchangeOptions({
                  fiatCurrencyId: formik.values?.fiatCurrencyId,
                })}
                noneOption
              />
              <FormikSelect
                label={t('fields.cross_fiat_currency')}
                name="crossFiatCurrencyId"
                disabled={!!data}
                options={filter(
                  fiatCurrenciesOptions,
                  (option) => option.value !== formik.values.fiatCurrencyId,
                )}
              />
              <FormikSelect
                label={t('fields.cross_fiat_currency_exchange')}
                name="crossFiatCurrencyExchangeId"
                options={getCurrencyExchangeOptions({
                  fiatCurrencyId: formik.values?.crossFiatCurrencyId,
                })}
                noneOption
              />
              <FormikYesNoRadioGroup
                label={t('fields.refresh')}
                name="refresh"
              />
              <FormikNumericField
                name="deviationPercentage"
                defaultValue={0}
                label={t('fields.deviation')}
                percentageSuffix
              />
              <FormikNumericField
                label={t('fields.price')}
                name="price"
                allowNegative={false}
                required
                disabled={formik.values.refresh}
              />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
