import React from 'react';

import { orderAutomationApi } from 'api';
import { QueryKey, OrderAutomationFilterTypes } from 'enums';
import { usePartialQuery, useUser } from 'hooks';

import { ManageOrderAutomationList } from '../ManageOrderAutomationList';

export const TemplateErrorOrderAutomation: React.FC = () => {
  const { role } = useUser();

  const queryResult = usePartialQuery(
    QueryKey.TemplateErrorOrderAutomation,
    orderAutomationApi.getMessagesAsRole(
      role,
      OrderAutomationFilterTypes.TemplateError,
    ),
    { useCachedCount: true },
  );

  return <ManageOrderAutomationList queryResult={queryResult} />;
};
