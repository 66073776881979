import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import {
  CloseFormikDialogData,
  CrudTable,
  CrudTableActionType,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { QueryKey, StatusCode } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CrudApi, Currency } from 'types';
import { currenciesUtils, validationUtils } from 'utils';

import { CurrencyDetailsDialog } from '../CurrencyDetailsDialog';

type Props = {
  api: CrudApi<Currency>;
  queryKey: QueryKey;
};

export const CurrenciesTable: React.FC<Props> = ({ api, queryKey }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies',
  });
  const queryClient = useQueryClient();

  const queryResult = useQuery(queryKey, () => api.getAll(), {});

  const { mutate: remove } = useMutation(api.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return t('error.in_use');
        }
      },
    },
  });

  const { mutate: update } = useMutation<
    Currency,
    AxiosError,
    { id: string; data: Currency }
  >(api.update);

  const handleUpdate = useCallback(
    (
      item: Currency,
      data: CloseFormikDialogData<Currency>,
      closeDialog: () => void,
    ) => {
      update(
        { id: item.id, data: data?.values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            closeDialog();
          },
          onError: (error: AxiosError) => {
            data?.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [queryClient, queryKey, update],
  );

  return (
    <CrudTable
      queryResult={queryResult}
      columns={currenciesUtils.getCurrencyColumns()}
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: CurrencyDetailsDialog,
          onUpdate: handleUpdate,
        },
        {
          type: CrudTableActionType.Remove,
          onRemove: (item, { close }) => remove(item.id, { onSuccess: close }),
        },
      ]}
    />
  );
};
