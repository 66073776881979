import { List } from '@mui/material';
import { map } from 'lodash';
import React, { FC } from 'react';

import { NavListSection } from './NavListSection';
import { ListItems, Scrollbar } from './styles';
import { LayoutMenuSection } from '../../types';

type Props = {
  sections: LayoutMenuSection[];
};

export const Sidenav: FC<Props> = ({ sections }) => (
  <Scrollbar>
    <List disablePadding>
      <ListItems>
        {map(sections, (section, index) => (
          <NavListSection
            key={section.title}
            index={index}
            items={section.items}
            title={section.title}
          />
        ))}
      </ListItems>
    </List>
  </Scrollbar>
);
