import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

import { UnitPosition } from 'enums';

import { UnitWrapper } from './styled';

interface Props {
  value?: number | string;
  unit?: string;
  unitPosition?: UnitPosition;
  variant?: Variant;
  valueClassName?: string;
}

export const StylizedNumber: FC<Props> = ({
  value,
  unit,
  variant,
  unitPosition = UnitPosition.After,
  valueClassName,
}) => (
  <Typography variant={variant} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
    {unitPosition === UnitPosition.Before && <UnitWrapper>{unit}</UnitWrapper>}
    <span className={valueClassName}>{value}</span>
    {unitPosition === UnitPosition.After && <UnitWrapper>{unit}</UnitWrapper>}
  </Typography>
);
