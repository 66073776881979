import React from 'react';
import { useTranslation } from 'react-i18next';

import { PayoutRequisitesPage } from 'features/payout-requisites';
import { TranslationNamespace } from 'i18n';

export const AdminPayoutRequisitesPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites',
  });
  return <PayoutRequisitesPage title={t('title')} />;
};
