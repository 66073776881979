import i18next from 'i18next';

import { CopyTextId, DateLabel } from 'components';
import { formatUtils } from 'utils';

import { DataGridColumnDefinition } from '../types';

export function getIdColumn<
  T extends { id: string; createdAt: string },
>(props?: { dateKeyOverride?: string }): DataGridColumnDefinition<T> {
  const { t } = i18next;
  return {
    header: t('columns.id'),
    valueGetter: (item: T) => (
      <div>
        <CopyTextId id={item.id} />
        <DateLabel>
          {formatUtils.formatDate(
            props?.dateKeyOverride
              ? (item as any)[props.dateKeyOverride]
              : item.createdAt,
          )}
        </DateLabel>
      </div>
    ),
  };
}
