import { Stack, Typography } from '@mui/material';
import React from 'react';

import { CardContainer } from './styles';

interface Props {
  title: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
}

export const InfoCard: React.FC<Props> = ({ title, content, footer }) => (
  <CardContainer>
    <Stack direction="column" spacing={6}>
      <Typography variant="subtitle2">{title}</Typography>
      {content}
      {footer}
    </Stack>
  </CardContainer>
);
