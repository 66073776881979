import admin from './locales/ru/admin.json';
import common from './locales/ru/common.json';
import merchant from './locales/ru/merchant.json';
import operator from './locales/ru/operator.json';
import techOperator from './locales/ru/tech-operator.json';
import trader from './locales/ru/trader.json';

export type DefaultRecourse = 'ru';
export const defaultRecourse: DefaultRecourse = 'ru';

export enum TranslationNamespace {
  Common = 'common',
  Admin = 'admin',
  Merchant = 'merchant',
  Operator = 'operator',
  TechOperator = 'tech_operator',
  Trader = 'trader',
}

export const defaultNS = 'common';

export const resources = {
  [defaultRecourse]: {
    [TranslationNamespace.Common]: common,
    [TranslationNamespace.Admin]: admin,
    [TranslationNamespace.Merchant]: merchant,
    [TranslationNamespace.Operator]: operator,
    [TranslationNamespace.TechOperator]: techOperator,
    [TranslationNamespace.Trader]: trader,
  },
};
