import { useQueryData } from './use-query-data.hook';

export function useQueryTab<T>() {
  const { data, initialized, setData, reset } = useQueryData<T>(
    'tab',
    undefined,
    {
      encode: false,
      plain: true,
    },
  );

  return {
    tab: data,
    tabInitialized: initialized,
    setTab: setData,
    resetTab: reset,
  };
}
