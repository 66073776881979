import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { FormControls, FormikNumericField, UserSelect } from 'components';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';

type FormFieldProps = {
  canChangeCompensation: boolean;
  users?: User[];
};

export const REFERRER_VALIDATION_SCHEMA = {
  referralCompensationPercentage: Yup.number().when('referralUserId', {
    is: (referralUserId: string) => !!referralUserId,
    then: (schema) =>
      schema
        .required(i18next.t('errors.required'))
        .min(0, i18next.t('errors.percentage'))
        .lessThan(100, i18next.t('errors.percentage')),
  }),
  referralPayoutCompensationPercentage: Yup.number().when('referralUserId', {
    is: (referralUserId: string) => !!referralUserId,
    then: (schema) =>
      schema
        .required(i18next.t('errors.required'))
        .min(0, i18next.t('errors.percentage'))
        .lessThan(100, i18next.t('errors.percentage')),
  }),
};

// TODO: fix for SPAY-1480. To be removed after migration
export const UserReferrerFormFields: React.FC<FormFieldProps> = ({
  canChangeCompensation,
  users,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.agents_form',
  });

  return (
    <div className="tw-grid tw-gap-4">
      <UserSelect
        name="referralUserId"
        label={t('fields.agent')}
        users={users}
      />
      {canChangeCompensation && (
        <FormControls row>
          <FormikNumericField
            name="referralCompensationPercentage"
            label={t('fields.payin_cashback')}
            required
            fullWidth
            percentageSuffix
          />
          <FormikNumericField
            name="referralPayoutCompensationPercentage"
            label={t('fields.payout_cashback')}
            required
            fullWidth
            percentageSuffix
          />
        </FormControls>
      )}
    </div>
  );
};
