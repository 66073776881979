import { Button } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { internalTransferApi } from 'api';
import {
  CloseFormikDialogResult,
  CopyText,
  Dialog,
  DialogProps,
  FormActions,
  FormControls,
  FormikNumericField,
  FormikSelect,
} from 'components';
import { QueryKey } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset } from 'types';
import { assetUtils } from 'utils';

type Values = {
  senderAssetId: string;
  amount: number;
};

type Props = DialogProps<CloseFormikDialogResult<Values>> & {
  assets?: Asset[];
};

export const CreateInternalTransferDialog: React.FC<Props> = ({
  open,
  assets,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [internalTransferCode, setInternalTransferCode] = useState('');
  const queryClient = useQueryClient();

  const initialValues: Values = useMemo(
    () => ({
      amount: 0,
      senderAssetId: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .positive(tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
        senderAssetId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
      }),
    [tCommon],
  );

  const senderAssetOptions = useMemo(
    () => assetUtils.getAssetOptions(assets),
    [assets],
  );

  const {
    mutate: create,
    error,
    reset,
  } = useMutation(internalTransferApi.create);

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<Values>) => {
      if (internalTransferCode) {
        queryClient.invalidateQueries(QueryKey.InternalTransfers);
        setInternalTransferCode('');
      }
      reset();
      onClose(data);
    },
    [reset, internalTransferCode, queryClient, onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      create(
        {
          senderAssetId: values.senderAssetId,
          amount: +values.amount,
        },
        {
          onSettled: () => {
            helpers.setSubmitting(false);
          },
          onSuccess: (data) => {
            setInternalTransferCode(data.id);
            queryClient.invalidateQueries(QueryKey.MyAssets);
            queryClient.invalidateQueries(QueryKey.MerchantsAssets);
            helpers.resetForm();
          },
        },
      );
    },
    [queryClient, create],
  );

  const { getAssetCurrencySymbol } = useCurrencies();
  const getAssetCurrencySymbolByAssetId = useCallback(
    (assetId: string) => {
      const selectedAsset = find(assets, { id: assetId });
      return getAssetCurrencySymbol(selectedAsset?.assetCurrencyId);
    },
    [assets, getAssetCurrencySymbol],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('create_modal.title')}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
          modal
          {...rest}
        >
          <Form>
            {!internalTransferCode && (
              <Fragment>
                <FormControls>
                  <FormikNumericField
                    label={t('fields.amount')}
                    name="amount"
                    allowNegative={false}
                    suffix={getAssetCurrencySymbolByAssetId(
                      formik.values.senderAssetId,
                    )}
                    required
                  />
                  <FormikSelect
                    label={t('fields.sender_asset')}
                    name="senderAssetId"
                    options={senderAssetOptions}
                  />
                </FormControls>
                <FormActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!!internalTransferCode || formik.isSubmitting}
                  >
                    {tCommon('buttons.create')}
                  </Button>
                </FormActions>
              </Fragment>
            )}
            {!!error && (
              <div className="tw-text-error tw-mt-4 tw-text-center">
                {t('create_modal.error')}
              </div>
            )}
            {internalTransferCode && (
              <div className="tw-mt-4 tw-text-center">
                <div>{t('fields.code')}</div>
                <CopyText text={internalTransferCode} />
              </div>
            )}
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
