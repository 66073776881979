import { IconProps, TypographyProps } from '@mui/material';
import { truncate } from 'lodash';
import React from 'react';

import { CopyText } from 'components';

type Props = {
  id: string;
  iconColor?: IconProps['color'];
  labelTextColor?: TypographyProps['color'];
  disableIconColor?: boolean;
  truncateLength?: number;
};

export const CopyTextId: React.FC<Props> = ({
  id,
  iconColor = 'secondary',
  disableIconColor,
  labelTextColor = 'textSecondary',
  truncateLength = 10,
}) => (
  <CopyText
    text={id}
    iconColor={disableIconColor ? 'disabled' : iconColor}
    labelTextColor={labelTextColor}
    label={`#${truncate(id, { length: truncateLength })}`}
  />
);
