import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { IconProps, Tooltip } from '@mui/material';
import { truncate } from 'lodash';
import React from 'react';

import { ExternalLink } from 'components';
import { IconSize, ICON_SIZE_MAP } from 'constants/icon-size.constants';
import { TRONSCAN_TRANSACTION_LINK } from 'constants/tronscan';

type Props = {
  hash: string;
  iconColor?: IconProps['color'];
  iconSize?: IconSize;
};

export const TronscanLink: React.FC<Props> = ({
  hash,
  iconColor = 'secondary',
  iconSize = 'sm',
}) =>
  hash ? (
    <Tooltip title={hash}>
      <span className="tw-inline-flex tw-items-center tw-cursor-pointer">
        <ExternalLink
          href={`${TRONSCAN_TRANSACTION_LINK}/${hash}`}
          label={truncate(hash, { length: 24 })}
        />
        <OpenInNewIcon
          sx={{
            fontSize: ICON_SIZE_MAP[iconSize],
          }}
          color={iconColor}
        />
      </span>
    </Tooltip>
  ) : null;
