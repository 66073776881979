import { Variant } from '@mui/material/styles/createTypography';
import React from 'react';

import { Money, MoneyProps, StylizedNumber } from 'components';

type Props = { variant?: Variant; valueClassName?: string } & MoneyProps;

export const StylizedMoney: React.FC<Props> = ({
  variant,
  valueClassName,
  ...rest
}) => (
  <Money {...rest}>
    {({ formattedValue, unit, unitPosition }) => (
      <StylizedNumber
        variant={variant}
        value={formattedValue}
        unit={unit}
        unitPosition={unitPosition}
        valueClassName={valueClassName}
      />
    )}
  </Money>
);
