import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { currencyExchangeApi } from 'api';
import { Math } from 'classes';
import { DataWrapper } from 'components';
import { TIME_INTERVAL } from 'constants/time-interval.constants';
import { QueryKey } from 'enums';
import { useCurrencies, usePrevious } from 'hooks';
import { CurrencyExchangeRate } from 'types';
import { currencyExchangeUtils, formatUtils } from 'utils';

type CurrencyExchangeRateValueProps = {
  value: CurrencyExchangeRate;
};

const CurrencyExchangeRateValue: React.FC<CurrencyExchangeRateValueProps> = ({
  value,
}) => {
  const [previous, setPrevious] = useState(value);
  const previousOnRender = usePrevious(value);
  const { getAssetCurrencyCode, getFiatCurrencyCode } = useCurrencies();
  const change = useMemo(
    () =>
      value?.price && previous?.price
        ? new Math(value?.price).minus(previous?.price).value
        : 0,
    [previous, value],
  );

  useEffect(() => {
    if (
      previousOnRender &&
      (previousOnRender.date !== value.date ||
        previousOnRender.price !== value.price)
    ) {
      setPrevious(previousOnRender);
    }
  }, [previousOnRender, value]);

  return (
    <Tooltip
      title={
        <div>
          <div>{formatUtils.formatDate(value.date)}</div>
          <div>
            {`${currencyExchangeUtils.getCurrencyExchangeSourceLabel(
              value.source,
            )} ${value.name ? `(${value.name})` : ''}`}
          </div>
        </div>
      }
    >
      <Chip
        size="small"
        label={
          <Fragment>
            <span>
              {getAssetCurrencyCode(value.assetCurrencyId)}
              {`/`}
              {getFiatCurrencyCode(value.fiatCurrencyId)}
              {':'}
            </span>
            <span
              className={cx({
                'tw-text-red-500': change < 0,
                'tw-text-green-500': change > 0,
              })}
            >
              {previous && (
                <span>
                  {change > 0 && <ArrowDropUpIcon />}
                  {change < 0 && <ArrowDropDownIcon />}
                </span>
              )}
              <span className={cx({ 'tw-ml-0.5': !change })}>
                {formatUtils.formatMoney(value.price)}
              </span>
            </span>
          </Fragment>
        }
        variant="outlined"
      />
    </Tooltip>
  );
};

export const CurrencyExchangeRates: React.FC = () => {
  const queryResult = useQuery(
    QueryKey.CurrencyExchangeRates,
    () => currencyExchangeApi.getRates(),
    {
      refetchInterval: TIME_INTERVAL.ONE_MINUTE,
      refetchIntervalInBackground: true,
    },
  );

  return (
    <div className="tw-inline-block tw-py-2">
      <DataWrapper isEmpty={isEmpty(queryResult.data)} emptyView={null}>
        <div className="tw-grid tw-grid-flow-col tw-auto-cols-max tw-gap-2">
          {map(queryResult.data, (currencyExchange) => (
            <CurrencyExchangeRateValue
              key={currencyExchange.id}
              value={currencyExchange}
            />
          ))}
        </div>
      </DataWrapper>
    </div>
  );
};
