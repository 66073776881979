import { Tab } from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { balanceReconciliationApi } from 'api';
import {
  ConfirmButton,
  PageHeader,
  QueryTabPanel,
  QueryTabs,
} from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { PlatformTab } from './PlatformTab';
import { UsersTab } from './UsersTab';

enum BalanceReconciliationTab {
  Platform = 'platform',
  Users = 'users',
}

export const BalanceReconciliationPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.balance_reconciliation',
  });

  const refreshMutation = useMutation(
    () => balanceReconciliationApi.refresh(),
    {
      notifierType: 'execute',
      notifierMessages: {
        success: t('refresh.success'),
      },
    },
  );

  const handleConfirmRefresh = useCallback(() => {
    refreshMutation.mutate();
  }, [refreshMutation]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContent={
          <ConfirmButton
            variant="outlined"
            dialog={{ children: t('refresh.content') }}
            disabled={refreshMutation.isLoading}
            onConfirm={handleConfirmRefresh}
          >
            {t('refresh.button')}
          </ConfirmButton>
        }
      />
      <QueryTabs tabsEnum={BalanceReconciliationTab}>
        <Tab
          value={BalanceReconciliationTab.Platform}
          label={t('tabs.platform')}
        ></Tab>
        <Tab
          value={BalanceReconciliationTab.Users}
          label={t('tabs.users')}
        ></Tab>
      </QueryTabs>
      <QueryTabPanel value={BalanceReconciliationTab.Platform}>
        <PlatformTab />
      </QueryTabPanel>
      <QueryTabPanel value={BalanceReconciliationTab.Users}>
        <UsersTab />
      </QueryTabPanel>
    </Fragment>
  );
};
