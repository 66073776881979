import { Tab } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabs, QueryTabPanel } from 'components';
import { OrdersTab } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { CreateOrderDialog } from './CreateOrderDialog';
import { ProviderDisputeOrders } from './ProviderDisputeOrders';
import { TraderDisputeOrders } from './TraderDisputeOrders';

import { ActiveOrders, AllOrders, CompletedOrders, CancelledOrders } from '.';

export const ManageOrders: React.FC = () => {
  const [createOrderDialogOpen, setCreateOrderDialogOpen] = useState(false);

  const { isAdmin, isTechOperator } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const handleCreate = useCallback(() => {
    setCreateOrderDialogOpen(true);
  }, []);

  const rightContentButton = useMemo(
    () =>
      canManage
        ? {
            onClick: handleCreate,
          }
        : undefined,
    [handleCreate, canManage],
  );

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContentButton={rightContentButton}
      />
      <QueryTabs tabsEnum={OrdersTab}>
        <Tab value={OrdersTab.Active} label={t('tabs.active')} />
        <Tab value={OrdersTab.Completed} label={t('tabs.completed')} />
        <Tab value={OrdersTab.Cancelled} label={t('tabs.cancelled')} />
        <Tab value={OrdersTab.TraderDispute} label={t('tabs.trader_dispute')} />
        <Tab
          value={OrdersTab.ProviderDispute}
          label={t('tabs.provider_dispute')}
        />
        <Tab value={OrdersTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrdersTab.Active}>
        <ActiveOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Completed}>
        <CompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Cancelled}>
        <CancelledOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.TraderDispute}>
        <TraderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.ProviderDispute}>
        <ProviderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.All}>
        <AllOrders />
      </QueryTabPanel>
      {canManage && (
        <CreateOrderDialog
          open={createOrderDialogOpen}
          onClose={() => setCreateOrderDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};
