import { Asset } from './asset.type';

export enum InternalTransferStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export type InternalTransfer = {
  id: string;
  senderUserId: string;
  senderUser: { name: string };
  senderAssetId: string;
  senderAsset?: Asset;
  recipientUserId?: string;
  recipientUser?: { name: string };
  recipientAssetId?: string;
  recipientAsset?: Asset;
  createdAt: string;
  amount: number;
  status: InternalTransferStatus;
};
