import moment from 'moment';

import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { Orders } from 'features/payout-orders';
import { useQueryFilters } from 'hooks';
import { OrderFilters } from 'types';

export const TraderCompletedOrders: React.FC = () => {
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  return (
    <Orders
      tab={OrdersTab.Completed}
      additionalColumns={[
        AdditionalOrderColumn.TraderPayment,
        AdditionalOrderColumn.CustomerRequisites,
      ]}
    />
  );
};
