import { Typography } from '@mui/material';
import { filter, isEmpty, map } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { cashbackApi } from 'api';
import { DataWrapper, DescriptionRow, Money } from 'components';
import { QueryKey, OrderType, OrderStatus } from 'enums';
import { TranslationNamespace } from 'i18n';
import { AgentInfoType, Order, OrderCashback, PayoutOrder } from 'types';

type Props = {
  order: Order | PayoutOrder;
  operation: OrderType;
};

export const OrderAgentCashback: React.FC<Props> = ({ order, operation }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.order_agent_cashback',
  });

  const queryKey = useMemo(() => [QueryKey.OrderCashback, order?.id], [order]);
  const queryFn = useMemo(
    () =>
      operation === OrderType.Payin
        ? cashbackApi.getForOrder
        : cashbackApi.getForPayoutOrder,
    [operation],
  );

  const agentsVisible: boolean = useMemo(
    () =>
      !!order.id &&
      order.status !== OrderStatus.Cancelled &&
      !!order.agentsCompensation,
    [order],
  );

  const queryResult = useQuery(queryKey, () => queryFn(order.id), {
    enabled: agentsVisible,
  });

  const traderAgents = useMemo(
    () =>
      filter(
        queryResult.data,
        (cashbackItem) => cashbackItem.agentType === AgentInfoType.Trader,
      ),
    [queryResult],
  );

  const merchantAgents = useMemo(
    () =>
      filter(queryResult.data, (cashbackItem) =>
        [AgentInfoType.Shop, AgentInfoType.Merchant].includes(
          cashbackItem.agentType,
        ),
      ),
    [queryResult],
  );

  const renderAgentGroup = useCallback(
    (cashback: OrderCashback[]) =>
      map(cashback, (cashbackItem) => (
        <div className="tw-flex" key={cashbackItem.agentId}>
          <div className="tw-mr-2">
            {cashbackItem.agent?.name || cashbackItem.agentId}
          </div>
          <Money
            value={cashbackItem.amount}
            assetCurrencyId={order.assetCurrencyId}
            symbol
          />
        </div>
      )),
    [order],
  );

  return agentsVisible ? (
    <DataWrapper isLoading={queryResult.isLoading}>
      <DescriptionRow
        title={t('title')}
        value={
          <Fragment>
            {!isEmpty(traderAgents) && (
              <Fragment>
                <Typography variant="subtitle2">
                  {t('sections.trader')}
                </Typography>
                {renderAgentGroup(traderAgents)}
              </Fragment>
            )}
            {!isEmpty(merchantAgents) && (
              <Fragment>
                <Typography variant="subtitle2">
                  {t('sections.merchant')}
                </Typography>
                {renderAgentGroup(merchantAgents)}
              </Fragment>
            )}
            <div className="tw-flex tw-items-center">
              <Typography className="tw-pr-2" variant="subtitle2">
                {t('total')}
              </Typography>
              <Money
                value={order.agentsCompensation}
                assetCurrencyId={order.assetCurrencyId}
                symbol
              />
            </div>
          </Fragment>
        }
      />
    </DataWrapper>
  ) : null;
};
