import { Image as ImageIcon } from '@mui/icons-material';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { map } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CloseDialogResult,
  DataWrapper,
  Dialog,
  ReceiptLabel,
} from 'components';
import { OrderType } from 'enums';
import { TranslationNamespace } from 'i18n';
import { OrderReceipt } from 'types';

type Props = {
  open: boolean;
  orderId: string;
  orderType: OrderType;
  queryResult: UseQueryResult<OrderReceipt[]>;
  onClose: (result: CloseDialogResult) => void;
};

export const ReceiptsModal: React.FC<Props> = ({
  open,
  orderId,
  orderType,
  queryResult,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.receipts_modal',
  });

  const receipts = useMemo(() => queryResult.data, [queryResult.data]);

  return (
    <Dialog open={open} title={t('title')} modal onClose={onClose}>
      <DataWrapper queryResult={queryResult}>
        <Fragment>
          {map(receipts, ({ filename }) => (
            <List key={filename} dense disablePadding>
              <ListItem disableGutters>
                <ListItemAvatar sx={{ minWidth: 30 }}>
                  <ImageIcon color="info" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <ReceiptLabel
                      orderId={orderId}
                      orderType={orderType}
                      filename={filename}
                    />
                  }
                />
              </ListItem>
            </List>
          ))}
        </Fragment>
      </DataWrapper>
    </Dialog>
  );
};
