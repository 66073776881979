import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { CloseFormikDialogResult, Dialog, DialogProps } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { Asset, InternalTransfer, InternalTransferStatus } from 'types';

import { FindInternalTransferForm } from './FindInternalTransferForm';
import { ReceiveInternalTransferForm } from './ReceiveInternalTransferForm';

type Values = {
  id: string;
  recipientAssetId: string;
};

type Props = DialogProps<CloseFormikDialogResult<Values>> & {
  assets?: Asset[];
};

export const ReceiveInternalTransferDialog: React.FC<Props> = ({
  open,
  assets,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const [internalTransfer, setInternalTransfer] = useState<InternalTransfer>();
  const queryClient = useQueryClient();

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (internalTransfer) {
        queryClient.invalidateQueries(QueryKey.InternalTransfers);
        setInternalTransfer(undefined);
      }
      onClose(result);
    },
    [onClose, internalTransfer, queryClient],
  );

  return (
    <Dialog
      open={open}
      title={t('receive_modal.title')}
      onClose={handleClose}
      modal
      {...rest}
    >
      {!internalTransfer && (
        <FindInternalTransferForm
          onInternalTransferFound={setInternalTransfer}
        />
      )}
      {internalTransfer?.status === InternalTransferStatus.Active && (
        <ReceiveInternalTransferForm
          assets={assets}
          internalTransfer={internalTransfer}
          onInternalTransferReceived={setInternalTransfer}
        />
      )}
      {internalTransfer?.status === InternalTransferStatus.Completed && (
        <div>{t('receive_modal.completed')}</div>
      )}
    </Dialog>
  );
};
