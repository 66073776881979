import React, { useMemo } from 'react';

import { CallbackUrlStatus } from 'enums';
import { orderUtils } from 'utils';

type Props = {
  status: CallbackUrlStatus;
};

export const CallbackUrlStatusLabel: React.FC<Props> = ({ status }) => {
  const textColorClassName = useMemo(() => {
    switch (status) {
      case CallbackUrlStatus.InProgress:
        return 'tw-text-cyan-400';
      case CallbackUrlStatus.Success:
        return 'tw-text-green-700';
      case CallbackUrlStatus.Error:
        return 'tw-text-red-500';
    }
  }, [status]);

  return (
    <span className={textColorClassName}>
      {orderUtils.getCallbackUrlStatusLabel(status)}
    </span>
  );
};
