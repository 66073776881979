import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { Container, CssBaseline, Box, Avatar, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTE_PATH } from 'constants/routes';
import { TranslationNamespace } from 'i18n';

export const AccountDisabledPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'pages.account_disabled',
  });

  const location = useLocation();
  const email = useMemo(() => location.state?.email, [location]);

  const navigate = useNavigate();

  const openLogin = useCallback(() => {
    navigate(ROUTE_PATH.PUBLIC.LOGIN, { state: { email } });
  }, [email, navigate]);

  useEffect(() => {
    if (!email) {
      openLogin();
    }
  }, [email, openLogin]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
          <AccessTimeIcon />
        </Avatar>
        <div className="tw-my-8 tw-text-center">
          <div className="tw-text-xl">{t('title')}</div>
          <div className="tw-mt-4">{t('subtitle')}</div>
        </div>
        <div>
          <Button color="primary" variant="contained" onClick={openLogin}>
            {t('buttons.open_login')}
          </Button>
        </div>
      </Box>
    </Container>
  );
};
