import { useQuery } from 'react-query';

import { assetsApi, shopsApi } from 'api';
import { QueryKey } from 'enums';
import { InternalTransfersPage } from 'features/internal-transfers';
export const MerchantInternalTransfersPage: React.FC = () => {
  const queryResultAssets = useQuery(
    QueryKey.MerchantsAssets,
    assetsApi.getMerchantAllMy,
  );

  const queryResultShops = useQuery(QueryKey.Shops, shopsApi.getAllMy);

  return (
    <InternalTransfersPage
      queryResultShops={queryResultShops}
      queryResultAssets={queryResultAssets}
    />
  );
};
