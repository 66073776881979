import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Tooltip, IconProps, Typography, TypographyProps } from '@mui/material';
import cx from 'classnames';
import { fill, range, truncate as _truncate } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IconSize, ICON_SIZE_MAP } from 'constants/icon-size.constants';
import { TranslationNamespace } from 'i18n';

type Props = {
  text: string;
  label?: ReactNode;
  iconOnly?: boolean;
  disabled?: boolean;
  className?: string;
  truncateLength?: number;
  truncate?: number | boolean;
  iconColor?: IconProps['color'];
  labelTextColor?: TypographyProps['color'];
  iconSize?: IconSize;
  hidden?: boolean;
  children?: React.ReactNode;
};

export const CopyText: React.FC<Props> = ({
  text,
  truncate,
  truncateLength: truncateLengthProp,
  label: labelProp,
  iconOnly,
  className,
  children,
  disabled,
  iconColor = 'secondary',
  labelTextColor,
  iconSize = 'sm',
  hidden,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.copy_text',
  });

  const truncateLength = useMemo(
    () => (truncate ? truncateLengthProp || 10 : truncateLengthProp),
    [truncate, truncateLengthProp],
  );

  const label = useMemo(
    () =>
      labelProp ||
      (truncateLength
        ? _truncate(text, { length: truncateLength || 10 })
        : text),
    [labelProp, text, truncateLength],
  );

  const content = useMemo(() => {
    if (hidden) {
      return fill(range(truncateLength || text.length), '*');
    }
    return label || text;
  }, [hidden, label, text, truncateLength]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (!disabled) {
        event.stopPropagation();
      }
    },
    [disabled],
  );

  const onCopy = useCallback(() => {
    if (!disabled) {
      toast(t('notification'));
    }
  }, [disabled, t]);

  return (
    <span className={className} onClick={onClick}>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Tooltip title={disabled ? null : t('title')}>
          <span>
            {children || (
              <span
                className={cx('tw-inline-flex tw-items-center', {
                  'tw-cursor-pointer': !disabled,
                })}
              >
                {!iconOnly && (
                  <Typography
                    color={labelTextColor}
                    marginRight={!iconOnly ? 1 : 0}
                  >
                    {content}
                  </Typography>
                )}
                {!disabled && (
                  <ContentCopyIcon
                    sx={{
                      fontSize: ICON_SIZE_MAP[iconSize],
                    }}
                    color={iconColor}
                  />
                )}
              </span>
            )}
          </span>
        </Tooltip>
      </CopyToClipboard>
    </span>
  );
};
