import { SettingsBackupRestoreOutlined as SettingsBackupRestoreOutlinedIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { ordersApi } from 'api';
import { CloseDialogResult, Dialog } from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
};

export const OrderCancelledToDisputeColumn: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.cancelled_to_dispute_dialog',
  });
  const queryClient = useQueryClient();
  const { role } = useUser();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onStatusChange = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.CancelledOrders);
    setDialogOpen(false);
  }, [queryClient]);

  const { mutate: cancelledToDispute } = useMutation<
    PayinOrder,
    AxiosError,
    string
  >(ordersApi.cancelledToDisputeAsRole(role), {
    onSuccess: onStatusChange,
  });

  const handleDialogClose = useCallback(
    ({ ok, data }: CloseDialogResult<PayinOrder>) => {
      if (ok && data) {
        cancelledToDispute(data.id);
      } else {
        setDialogOpen(false);
      }
    },
    [cancelledToDispute],
  );

  const handleClick = useCallback((event: any) => {
    (event as MouseEvent)?.stopPropagation();
    (event as MouseEvent)?.preventDefault();
    setDialogOpen(true);
  }, []);

  if (!order?.traderId && !order?.p2pProviderOrderId) {
    return null;
  }

  return (
    <Fragment>
      <IconButton color="inherit" onClick={handleClick}>
        <SettingsBackupRestoreOutlinedIcon color="primary" />
      </IconButton>
      <Dialog
        open={dialogOpen}
        data={order}
        title={t('title')}
        onClose={handleDialogClose}
      >
        {t('description')}
      </Dialog>
    </Fragment>
  );
};
