import { internalWalletsDepositTransactionsApi } from 'api';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';

import { DepositTransactionsTable } from '../DepositTransactionsTable';

export const DepositArchiveTransactions: React.FC = () => {
  const queryResult = usePartialQuery(
    QueryKey.InternalWalletTransactionsDepositArchive,
    internalWalletsDepositTransactionsApi.getAllArchivePaginated,
  );

  return <DepositTransactionsTable queryResult={queryResult} />;
};
