import { AxiosError } from 'axios';
import { findIndex, omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { p2pProvidersApi } from 'api';
import {
  CloseFormikDialogData,
  CloseFormikDialogResult,
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DateLabel,
  SidebarLayout,
  dataGridColumns,
  SidebarSize,
  P2PProviderNameInfo,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider, P2PProviderDto } from 'types';
import { formatUtils, validationUtils } from 'utils';

import { P2PProviderDetails } from './P2PProviderDetails';
import { P2PProviderDialog } from './P2PProviderDialog';

type Props = {
  archived?: boolean;
};

export const P2PProvidersPage: React.FC<Props> = ({ archived }) => {
  const { isAdmin, isTechOperator } = useUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const [selectedP2PProvider, setSelectedP2PProvider] =
    useState<P2PProvider | null>(null);
  const [createP2PProviderDialogOpen, setCreateP2PProviderDialogOpen] =
    useState(false);

  const queryResult = usePartialQuery(
    archived ? QueryKey.P2PProvidersArchive : QueryKey.P2PProviders,
    archived
      ? p2pProvidersApi.getAllArchivePaginated
      : p2pProvidersApi.getAllPaginated,
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<P2PProvider>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.provider'),
        valueGetter: (item) => <P2PProviderNameInfo provider={item} />,
      },
      {
        header: t('fields.type'),
        valueGetter: (item) => t(`types.${item.type}`),
      },
      {
        header: t('fields.enabled'),
        valueGetter: (item) =>
          formatUtils.formatBoolean(item.payinConfig?.enabled),
      },
      {
        header: t('fields.last_taken_order_at'),
        valueGetter: (item) =>
          item.payinConfig?.lastTakenOrderAt && (
            <DateLabel>
              {formatUtils.formatDate(item.payinConfig.lastTakenOrderAt)}
            </DateLabel>
          ),
        valueClassName: 'tw-min-w-[160px]',
      },
    ],
    [t],
  );

  const { mutate: update } = useMutation<
    P2PProvider,
    AxiosError,
    { id: string; data: Partial<P2PProvider> }
  >(p2pProvidersApi.update);

  const handleUpdate = useCallback(
    (
      item: P2PProvider,
      data: CloseFormikDialogData<Partial<P2PProvider>>,
      closeDialog: () => void,
    ) => {
      // TODO: filter credentials
      const dto = omit(data.values, ['type', 'assetCurrencyId']);
      update(
        { id: item.id, data: dto },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.P2PProviders);
            closeDialog();
          },
          onError: (error: AxiosError) => {
            data?.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [queryClient, update],
  );

  const { mutate: create } = useMutation<
    P2PProvider,
    AxiosError,
    P2PProviderDto
  >(p2pProvidersApi.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.P2PProviders);
    },
  });

  const handleCreate = useCallback(() => {
    setCreateP2PProviderDialogOpen(true);
  }, []);

  const handleCreateP2PProviderDialogClose = useCallback(
    ({ data, ok }: CloseFormikDialogResult<P2PProviderDto>) => {
      if (ok && data) {
        return create(data.values, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.P2PProviders);
            data.formikHelpers.resetForm();
            setCreateP2PProviderDialogOpen(false);
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        });
      }
      setCreateP2PProviderDialogOpen(false);
    },
    [queryClient, create],
  );

  const { mutate: archive } = useMutation<P2PProvider, AxiosError, string>(
    p2pProvidersApi.archive,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.P2PProviders);
      },
      notifierType: 'remove',
    },
  );

  const handleSelectP2PProvider = useCallback((p2pProvider: P2PProvider) => {
    setSelectedP2PProvider(p2pProvider);
  }, []);

  const handleDeselectP2PProvider = useCallback(() => {
    setSelectedP2PProvider(null);
  }, []);

  const selectedRowIndex = useMemo(
    () =>
      findIndex(queryResult?.data?.items, {
        id: selectedP2PProvider?.id,
      }),
    [queryResult, selectedP2PProvider],
  );

  return (
    <SidebarLayout
      sidebarProps={{
        open: !!selectedP2PProvider,
        title: t('details.title'),
        size: SidebarSize.md,
        onClose: handleDeselectP2PProvider,
        content: selectedP2PProvider && (
          <P2PProviderDetails p2pProvider={selectedP2PProvider} />
        ),
      }}
    >
      <CrudPage
        header={
          archived
            ? undefined
            : {
                title: t('title'),
                ...((isAdmin || isTechOperator) && {
                  rightContentButton: { onClick: handleCreate },
                }),
              }
        }
        table={{
          queryResult,
          columns,
          paginated: true,
          actions: [
            {
              type: CrudTableActionType.Details,
              renderDialog: P2PProviderDialog,
              onUpdate: handleUpdate,
            },
            {
              type: CrudTableActionType.Remove,
              onRemove: (item, { close }) =>
                archive(item.id, { onSuccess: close }),
            },
          ],
          onRowClick: handleSelectP2PProvider,
          selectedRowIndex,
          hideActions: archived,
        }}
      />
      <P2PProviderDialog
        open={createP2PProviderDialogOpen}
        onClose={handleCreateP2PProviderDialogClose}
      />
    </SidebarLayout>
  );
};
