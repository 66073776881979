import { Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { FieldArray } from 'formik';
import { filter, includes, isEmpty, isString, map, size } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControls,
  FormikNumericField,
  MAX_AGENTS,
  UserSelect,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { AgentInfo, User } from 'types';

type FormFieldProps = {
  agents?: AgentInfo[];
  formError?: string;
  users?: User[];
  currentUser?: User;
  headerClassName?: string;
};

export const AgentsFormFields: React.FC<FormFieldProps> = ({
  agents,
  users,
  formError,
  currentUser,
  headerClassName,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.agents_form',
  });

  const availableAgents = useMemo(() => {
    const agentIds = map(agents, (agent) => agent.userId);
    return filter(
      users,
      (user) => user.id !== currentUser?.id && !includes(agentIds, user.id),
    );
  }, [agents, users, currentUser]);

  return (
    <Fragment>
      <div className={headerClassName}>{t('title')}</div>
      <FormControls>
        <FieldArray
          name="agents"
          render={(arrayHelpers) => (
            <Fragment>
              {isEmpty(agents) && <div>{t('empty')}</div>}
              {map(agents, (agent, i) => {
                const path = `agents[${i}]`;
                return (
                  <div className="tw-flex" key={i}>
                    <div className="tw-flex-grow">
                      <div className="tw-mb-4">
                        <UserSelect
                          name={`${path}.userId`}
                          fullWidth
                          users={agent.userId ? users : availableAgents}
                        />
                      </div>
                      <FormControls row>
                        <FormikNumericField
                          name={`${path}.payinCompensationPercentage`}
                          label={t('fields.payin_cashback')}
                          required
                          allowNegative={false}
                          fullWidth
                          disabled={!agent.userId}
                          percentageSuffix
                        />
                        <FormikNumericField
                          name={`${path}.payoutCompensationPercentage`}
                          label={t('fields.payout_cashback')}
                          allowNegative={false}
                          required
                          fullWidth
                          disabled={!agent.userId}
                          percentageSuffix
                        />
                      </FormControls>
                    </div>
                    <div>
                      <IconButton onClick={() => arrayHelpers.remove(i)}>
                        <Delete color="error" />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
              <div className="tw-mb-4">
                {isString(formError) && (
                  <div className="tw-text-alert tw-mb-4">{formError}</div>
                )}
                <Button
                  variant="outlined"
                  disabled={size(agents) >= MAX_AGENTS}
                  onClick={() => arrayHelpers.push({ userId: '' })}
                >
                  {t('add_agent_btn')}
                </Button>
              </div>
            </Fragment>
          )}
        ></FieldArray>
      </FormControls>
    </Fragment>
  );
};
