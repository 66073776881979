import { indexOf, orderBy } from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { DataGridColumnDefinition, CrudPage } from 'components';
import { AssetChangeReason } from 'enums';
import { useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  AssetBalanceHistory,
  AssetBalanceHistoryFilters,
  FilterDefinition,
  PaginatedData,
} from 'types';
import { assetBalanceHistoryUtils, filtersUtils } from 'utils';

// first elements on top
const assetChangeReasonOrder = [
  AssetChangeReason.MerchantReferralCashback,
  AssetChangeReason.OrderCashback,
  AssetChangeReason.TraderReferralCashback,
  AssetChangeReason.PlatformCompensation,
  AssetChangeReason.CompleteOrder,
  AssetChangeReason.CancelOrder,
  AssetChangeReason.ChangeOrder,
  AssetChangeReason.CreateOrder,
];

type Props = {
  queryResult: UseQueryResult<PaginatedData<AssetBalanceHistory>>;
  hideTitle?: boolean;
  additionalColumns?: DataGridColumnDefinition<AssetBalanceHistory>[];
  additionalFilters?: FilterDefinition<AssetBalanceHistoryFilters>[];
};

export const BalanceHistory: React.FC<Props> = ({
  queryResult,
  hideTitle,
  additionalColumns = [],
  additionalFilters = [],
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.asset_balance_history',
  });

  const { filters } = useQueryFilters<AssetBalanceHistoryFilters>();

  const formatItems = useCallback(
    (items: AssetBalanceHistory[]) =>
      orderBy(
        items,
        [
          'createdAt',
          'orderId',
          (item) => -indexOf(assetChangeReasonOrder, item.assetChangeReason),
        ],
        ['desc', 'asc', 'desc'],
      ),
    [],
  );

  return (
    <CrudPage
      header={{ hide: hideTitle, title: t('title') }}
      filters={{
        filtersDefinitions: [
          ...filtersUtils.getCommonFilters(filters),
          ...additionalFilters,
          ...assetBalanceHistoryUtils.getCommonFilters(),
        ],
      }}
      table={{
        queryResult,
        formatItems,
        paginated: true,
        columns: [
          ...assetBalanceHistoryUtils.getCommonColumns(),
          ...additionalColumns,
        ],
      }}
    />
  );
};
