import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { ManageOrdersList } from '../ManageOrderList';

export const ActiveOrders: React.FC = () => (
  <ManageOrdersList
    tab={OrdersTab.Active}
    additionalColumns={[
      AdditionalOrderColumn.StatusActive,
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.TraderPayment,
    ]}
  />
);
