import styled from '@emotion/styled';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlinedIcon,
  Block as BlockIcon,
  AccessTime as AccessTimeIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components/StyledChip';
import { FUNDS_REQUEST_TRANSACTION_IN_PROGRESS_STATUSES } from 'constants/funds-requests.constants';
import { FundsRequestStatus } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: FundsRequestStatus;
};

const getBackgroundColor = (props: any) => {
  switch (props.status) {
    case FundsRequestStatus.Completed:
      return props.theme.palette.success.main;
    case FundsRequestStatus.Cancelled:
      return props.theme.palette.error.main;
    case FundsRequestStatus.New:
    case FundsRequestStatus.WaitingTransaction:
    case FundsRequestStatus.Revoked:
      return props.theme.palette.grey[500];
  }
};

const StyledLabel = styled(StyledChip)<
  ChipProps & { status: FundsRequestStatus }
>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

export const FundsRequestStatusLabel: React.FC<Props> = (props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'funds_request_status',
  });
  const { isTrader, isMerchant } = useUser();

  const isTransactionInProgressStatus = useMemo(
    () => FUNDS_REQUEST_TRANSACTION_IN_PROGRESS_STATUSES.includes(props.status),
    [props.status],
  );

  const status = useMemo(() => {
    if (isTransactionInProgressStatus && (isTrader || isMerchant)) {
      return FundsRequestStatus.New;
    }
    return props.status;
  }, [isMerchant, isTrader, isTransactionInProgressStatus, props.status]);

  switch (status) {
    case FundsRequestStatus.Completed:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="success"
          icon={<CheckCircleOutlinedIcon />}
        ></StyledLabel>
      );
    case FundsRequestStatus.Cancelled:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="error"
          icon={<BlockIcon />}
        ></StyledLabel>
      );
    case FundsRequestStatus.Revoked:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="default"
          icon={<ClearIcon />}
        ></StyledLabel>
      );
    case FundsRequestStatus.New:
    case FundsRequestStatus.WaitingTransaction:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="secondary"
          icon={<AccessTimeIcon />}
        ></StyledLabel>
      );
    default:
      return null;
  }
};
