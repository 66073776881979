import axios from 'axios';

import { env } from 'config';
import { TradeMethod, TradeMethodWithRelations } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/trade-methods`;

const getOne = async (id: string): Promise<TradeMethodWithRelations> =>
  (await axios.get(`${url}/${id}`)).data;

export const tradeMethodsApi = {
  ...createCrudApi<TradeMethod>('/trade-methods'),
  getOne,
};
