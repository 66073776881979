import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { OrdersTab } from 'enums';
import { TranslationNamespace } from 'i18n';

import { MerchantActiveOrders } from './ActiveOrders';
import { MerchantAllOrders } from './AllOrders';
import { MerchantCancelledOrders } from './CancelledOrders';
import { MerchantCompletedOrders } from './CompletedOrders';
import { MerchantDisputeOrders } from './DisputeOrders';

export const MerchantPayinOrdersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs
        tabs={[
          OrdersTab.Active,
          OrdersTab.Completed,
          OrdersTab.Cancelled,
          OrdersTab.Dispute,
          OrdersTab.All,
        ]}
      >
        <Tab value={OrdersTab.Active} label={t('tabs.active')} />
        <Tab value={OrdersTab.Completed} label={t('tabs.completed')} />
        <Tab value={OrdersTab.Cancelled} label={t('tabs.cancelled')} />
        <Tab value={OrdersTab.Dispute} label={t('tabs.dispute')} />
        <Tab value={OrdersTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrdersTab.Active}>
        <MerchantActiveOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Completed}>
        <MerchantCompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Cancelled}>
        <MerchantCancelledOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Dispute}>
        <MerchantDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.All}>
        <MerchantAllOrders />
      </QueryTabPanel>
    </Fragment>
  );
};
