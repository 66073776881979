import { Link } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionRow } from 'components';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

import { P2PProvidersRequestsDialog } from './P2PProvidersRequestsDialog';

type Props = {
  order: PayinOrder;
};

export const P2PProvidersRequests: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.p2p_providers_requests',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Fragment>
      <DescriptionRow
        title={t('title')}
        value={
          <div>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => setDialogOpen(true)}
            >
              {tCommon('buttons.open')}
            </Link>
          </div>
        }
      />
      <P2PProvidersRequestsDialog
        order={order}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Fragment>
  );
};
