import { Divider, Typography } from '@mui/material';
import { map, sortBy } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ordersApi } from 'api';
import { DataWrapper, DateLabel } from 'components';
import { OrderStatus, QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';
import { formatUtils, orderUtils } from 'utils';

type Props = {
  order: PayinOrder;
  loading: boolean;
};

export const OrderStatusHistory: React.FC<Props> = ({
  order,
  loading,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });

  const { role } = useUser();

  const queryKey = useMemo(
    () => [QueryKey.OrderStatusHistory, order?.id],
    [order],
  );

  const queryResult = useQuery(
    queryKey,
    () => ordersApi.getOrderStatusHistoryAsRole(role)(order.id),
    { enabled: !!order?.id },
  );

  const renderHistoryStatusItem = useCallback(
    (status: OrderStatus, statusDate: Date) => (
      <div className="tw-my-1">
        <Typography color="textSecondary">
          {orderUtils.getStatusLabel(status)}
        </Typography>
        <DateLabel>{formatUtils.formatDate(statusDate)}</DateLabel>
      </div>
    ),
    [],
  );

  return (
    <Fragment>
      <Divider textAlign="left">{t('status_history')}</Divider>
      <DataWrapper isLoading={loading || queryResult.isLoading}>
        <div className="tw-flex tw-flex-wrap">
          {map(
            sortBy(queryResult.data, (s) => s.statusAt),
            (orderStatus, index) => (
              <div key={index} className="tw-flex tw-align-middle">
                {renderHistoryStatusItem(
                  orderStatus.status,
                  orderStatus.statusAt,
                )}
                <div className="tw-mx-2 tw-self-center">{'→'}</div>
              </div>
            ),
          )}
          {renderHistoryStatusItem(order.status, order.statusAt)}
        </div>
      </DataWrapper>
    </Fragment>
  );
};
