import React from 'react';

import { formatUtils } from 'utils';
type Props = {
  value: number;
};
export const BalanceChange: React.FC<Props> = ({ value }) => {
  if (value > 0) {
    return (
      <span className="tw-text-green-500">{`(+${formatUtils.formatMoney(
        value,
      )})`}</span>
    );
  } else if (value < 0) {
    return (
      <span className="tw-text-red-500">{`(${formatUtils.formatMoney(
        value,
      )})`}</span>
    );
  }
  return null;
};
