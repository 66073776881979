import { FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isEmpty, map } from 'lodash';
import React, { Fragment, useCallback, useState } from 'react';
import { UseMutateFunction } from 'react-query';

import { CloseFormikDialogResult } from 'components/Dialog';
import { OrdersTab } from 'enums';
import { useQueryFilters } from 'hooks';
import { ExportOrdersPayload } from 'types';

import { ExportDataDialog } from './ExportDataDialog';

type Props = {
  tab: OrdersTab;
  columns: string[];
  isLoading: boolean;
  labelGetter: (item: string) => string;
  onExport: UseMutateFunction<
    void,
    unknown,
    {
      data: ExportOrdersPayload;
      filters: string;
    },
    unknown
  >;
};

export const ExportDataButton: React.FC<Props> = ({
  tab,
  columns,
  isLoading,
  labelGetter,
  onExport,
}) => {
  const [open, setOpen] = useState(false);
  const { filtersQuery } = useQueryFilters();

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<{ columns: string[] }>) => {
      if (result.ok && result.data) {
        const values = {
          columns: map(result.data.values.columns, (column) => ({
            name: column,
            label: labelGetter(column),
          })),
        };
        onExport(
          {
            data: { ...values, state: tab },
            filters: filtersQuery,
          },
          {
            onSuccess: () => {
              setOpen(false);
            },
          },
        );
      } else {
        setOpen(false);
      }
    },
    [filtersQuery, labelGetter, onExport, tab],
  );

  if (isEmpty(columns)) {
    return null;
  }

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <FileDownloadOutlinedIcon />
      </IconButton>
      <ExportDataDialog
        open={open}
        columns={columns}
        isLoading={isLoading}
        labelGetter={labelGetter}
        onClose={handleClose}
      />
    </Fragment>
  );
};
