import moment from 'moment';

import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { useQueryFilters } from 'hooks';
import { OrderFilters } from 'types';

import { ManageOrdersList } from '../ManageOrderList';

export const CancelledOrders: React.FC = () => {
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  return (
    <ManageOrdersList
      tab={OrdersTab.Cancelled}
      additionalColumns={[
        AdditionalOrderColumn.CustomerRequisites,
        AdditionalOrderColumn.CancelReason,
        AdditionalOrderColumn.Validation,
        AdditionalOrderColumn.TraderPayment,
      ]}
    />
  );
};
