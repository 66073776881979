import React from 'react';
import { useQuery } from 'react-query';

import { ordersApi } from 'api';
import { ExportDataButton } from 'components';
import { OrdersTab, QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { orderUtils } from 'utils';

type Props = {
  tab: OrdersTab;
};

export const ExportPayinOrders: React.FC<Props> = ({ tab }) => {
  const { role } = useUser();

  const { mutate: exportOrders, isLoading } = useMutation(
    ordersApi.exportAsRole(role),
    {
      notifierType: 'none',
    },
  );

  const columnsQueryResult = useQuery(
    QueryKey.ExportOrdersColumns,
    ordersApi.getExportColumnsAsRole(role),
    { staleTime: Infinity },
  );

  return (
    <ExportDataButton
      tab={tab}
      columns={columnsQueryResult.data?.columns || []}
      isLoading={isLoading}
      labelGetter={orderUtils.getExportOrdersColumnLabel}
      onExport={exportOrders}
    />
  );
};
