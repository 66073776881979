import i18next from 'i18next';

import { AnomalyType } from 'enums';
import { TranslationNamespace } from 'i18n';

const getAnomalyTypeLabel = (type: AnomalyType) => {
  const { t } = i18next;
  const key = `pages.anomalies.type.${type}`;
  return i18next.exists(key, { ns: TranslationNamespace.Admin })
    ? t(key as any, { ns: TranslationNamespace.Admin })
    : key;
};

export const anomaliesUtils = {
  getAnomalyTypeLabel,
};
