import { Box, DrawerProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { APP_LOGO_PATH } from 'constants/images.constants';
import { TranslationNamespace } from 'i18n';
import { authUtils } from 'utils';

import { Sidenav } from './Sidenav';
import {
  Brand,
  BrandChip,
  Drawer,
  DrawerContent,
  DrawerTogglePlaceholder,
} from './styles';
import { LayoutMenuSection } from '../types';

export type Props = {
  sections: LayoutMenuSection[];
} & DrawerProps;

export const Sidebar: React.FC<Props> = ({ sections, ...rest }) => {
  const { t } = useTranslation(TranslationNamespace.Common);

  return (
    <Drawer variant="permanent" {...rest}>
      <DrawerContent>
        <Brand component={NavLink as any} to="/">
          <img src={APP_LOGO_PATH} alt={t('name')} className="tw-w-32" />
          <Box ml={1}>
            <BrandChip label={t(`role.${authUtils.getUserRole()}`)} />
          </Box>
        </Brand>
        <DrawerTogglePlaceholder />
        <Sidenav sections={sections} />
      </DrawerContent>
    </Drawer>
  );
};
