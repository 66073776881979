export enum TronTransactionStatus {
  Success = 'success',
  SmallAmount = 'small_amount',
  Waiting = 'waiting',
  Cancelled = 'cancelled',
  WaitingEnergy = 'waiting_energy',
  WaitingBandwidth = 'waiting_bandwidth',
  RequestEnergyError = 'request_energy_error',
  ExtraBandwidthRequired = 'extra_bandwidth_required',
  TransactionError = 'transaction_error',
  Error = 'error',
}
