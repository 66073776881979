import { Typography } from '@mui/material';
import { filter, includes, map } from 'lodash';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikArray, FormikSelect } from 'components';
import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CurrencyExchange } from 'types';

type Props = {
  fiatCurrencyId?: string;
  assetCurrencyIds?: string[];
};

export const ShopP2PProvidersCurrencyExchanges: React.FC<Props> = ({
  fiatCurrencyId,
  assetCurrencyIds,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });
  const { currencyExchanges, getCurrencyExchangeLabel } = useCurrencies();

  const getOptions = useCallback(
    (id: string) => {
      const filteredExchanges = filter(
        currencyExchanges,
        (option: CurrencyExchange) =>
          (option.fiatCurrencyId === fiatCurrencyId &&
            !includes(assetCurrencyIds, option.assetCurrencyId)) ||
          option.id === id,
      );

      const options = map(filteredExchanges, (exchange) => ({
        value: exchange.id,
        label: getCurrencyExchangeLabel(
          exchange.assetCurrencyId,
          exchange.fiatCurrencyId,
          exchange.source,
          exchange.name,
        ),
      }));
      return options;
    },
    [
      assetCurrencyIds,
      currencyExchanges,
      fiatCurrencyId,
      getCurrencyExchangeLabel,
    ],
  );

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {t('sections.p2p_provides_currency_exchanges.title')}
      </Typography>
      <FormikArray
        addItemLabel={t('buttons.add_p2p_provider_currency_exchange')}
        name="p2pProvidersCurrencyExchanges"
        sortable
        removable
        defaultValue={{ id: '' }}
        ItemComponent={({ item, index }) => (
          <FormikSelect
            className="tw-w-full"
            name={`p2pProvidersCurrencyExchanges[${index}].id`}
            label={t('fields.p2p_provider_currency_exchange')}
            required
            options={getOptions(item.id)}
          />
        )}
      />
    </Fragment>
  );
};
