import { map } from 'lodash';
import React, { Fragment } from 'react';

import { ChipLink, ChipLinkProps } from 'components';

type Props = {
  links: ChipLinkProps[];
};

export const ChipLinkList: React.FC<Props> = ({ links }) => (
  <Fragment>
    {map(links, (link, index) => (
      <ChipLink
        key={link.to}
        {...(index && { className: 'tw-ml-2' })}
        {...link}
      />
    ))}
  </Fragment>
);
