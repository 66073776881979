import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import * as Yup from 'yup';

import { FormikPasswordInput } from 'components';
import { STRONG_PASSWORD_REGEXP } from 'constants/auth.constants';
import { TranslationNamespace } from 'i18n';
import { UserProfile } from 'types';
import { validationUtils } from 'utils';

type ChangePasswordValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type Values = UserProfile & ChangePasswordValues;

type Props = {
  initialValues: UserProfile;
  canChangePassword?: boolean;
  invited?: boolean;
  onSubmit: UseMutateFunction<
    UserProfile,
    AxiosError,
    Partial<UserProfile>,
    unknown
  >;
};

export const ProfileForm: React.FC<Props> = ({
  initialValues: initialValuesProp,
  canChangePassword,
  invited = false,
  onSubmit,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.profile',
  });

  const [changePassword, setChangePassword] = useState(false);

  const [initialValues, setInitialValues] = useState<UserProfile>(
    initialValuesProp || {
      name: '',
      email: '',
      telegram: '',
    },
  );

  const [initialPasswordFields, setInitialPasswordFields] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        email: Yup.string()
          .required(tCommon('errors.required'))
          .email(tCommon('errors.email')),
        ...(changePassword && {
          newPassword: Yup.string()
            .required(tCommon('errors.required'))
            .matches(
              STRONG_PASSWORD_REGEXP,
              tCommon('errors.is_strong_password'),
            ),
          confirmNewPassword: Yup.string()
            .required(tCommon('errors.required'))
            .oneOf([Yup.ref('newPassword')], tCommon('errors.password_match')),
        }),
      }),
    [tCommon, changePassword],
  );

  const handleSubmit = useCallback(
    (
      values: Values,
      { setSubmitting, setErrors, setFieldError }: FormikHelpers<Values>,
    ) => {
      onSubmit(
        {
          ...(values.name && { name: values.name }),
          ...(values.email && { email: values.email }),
          ...{ telegram: values.telegram },
          ...(changePassword && {
            password: values.newPassword,
            currentPassword: values.currentPassword,
          }),
        },
        {
          onSuccess: (user) => {
            setInitialValues({
              name: user.name,
              email: user.email,
              telegram: user.telegram,
            });
            setInitialPasswordFields({
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            });
          },
          onSettled: () => {
            setSubmitting(false);
          },
          onError: (error: AxiosError) => {
            setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [changePassword, onSubmit],
  );

  return (
    <Formik
      initialValues={{ ...initialValues, ...initialPasswordFields }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <div className="tw-grid tw-gap-4 tw-max-w-xs">
            <Field
              component={TextField}
              label={t('fields.name')}
              variant="standard"
              name="name"
              disabled={invited}
              type="text"
            />
            <Field
              component={TextField}
              label={t('fields.email')}
              variant="standard"
              name="email"
              disabled={invited}
              type="email"
            />
            <Field
              component={TextField}
              label={t('fields.telegram')}
              variant="standard"
              disabled={invited}
              name="telegram"
              type="text"
            />
            {canChangePassword && (
              <div>
                <FormControlLabel
                  sx={{ display: 'flex' }}
                  control={<Checkbox />}
                  label={t('fields.change_password')}
                  checked={changePassword}
                  onChange={() => setChangePassword((prev) => !prev)}
                />
                <Typography variant="caption" color="GrayText">
                  {t('fields.change_password_logout')}
                </Typography>
              </div>
            )}
            {changePassword && (
              <Fragment>
                <FormikPasswordInput
                  name="currentPassword"
                  label={t('fields.current_password')}
                  fullWidth
                />
                <FormikPasswordInput
                  name="newPassword"
                  label={t('fields.new_password')}
                  autoComplete="new-password"
                  fullWidth
                />
                <FormikPasswordInput
                  name="confirmNewPassword"
                  label={t('fields.confirm_new_password')}
                  autoComplete="new-password"
                  fullWidth
                />
              </Fragment>
            )}
            <div className="tw-mt-4">
              <Button
                type="submit"
                variant="outlined"
                disabled={formik.isSubmitting}
              >
                {tCommon('buttons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
