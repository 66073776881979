import React from 'react';

import { Title } from './styles';
import { LayoutMenuItem } from '../../../types';
import { NavList } from '../NavList';

type Props = {
  items: LayoutMenuItem[];
  index: number;
  title?: string;
};

export const NavListSection: React.FC<Props> = ({ title, index, items }) => (
  <div>
    {title && (
      <Title variant="subtitle2" index={index}>
        {title}
      </Title>
    )}
    <NavList items={items} depth={0} />
  </div>
);
