import React from 'react';

import { OrderType } from 'enums';
import { Statistics } from 'features/statistics';

export const MerchantPayinStatisticsPage: React.FC = () => (
  <Statistics orderType={OrderType.Payin} />
);
export const MerchantPayoutStatisticsPage: React.FC = () => (
  <Statistics orderType={OrderType.Payout} />
);
