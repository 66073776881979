import React, { useCallback, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PayOutlet } from 'components';

type Props = {
  path: string;
  payinElement: React.ReactNode;
  payoutElement: React.ReactNode;
};

export const PayRoutes: React.FC<Props> = ({
  path,
  payinElement,
  payoutElement,
}) => {
  const { payin, payout } = useMemo(
    () => ({
      payin: 'payin',
      payout: 'payout',
    }),
    [],
  );

  const renderOutlet = useCallback(
    () => (
      <PayOutlet payinTo={`${path}/${payin}`} payoutTo={`${path}/${payout}`} />
    ),
    [path, payin, payout],
  );

  return (
    <Routes>
      <Route path={'*'} element={renderOutlet()}>
        <Route index path={payin} element={payinElement} />
        <Route path={payout} element={payoutElement} />
        <Route path="*" element={<Navigate to={payin} />} />
      </Route>
    </Routes>
  );
};
