import * as Yup from 'yup';

import { GetFieldValidatorFn } from 'types';

import { getTCommon } from './fields.utils';

export const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4,34}$/;

/**
 * Validates if the given string is a valid IBAN
 * @see https://en.wikipedia.org/wiki/International_Bank_Account_Number
 * @param iban IBAN
 * @returns
 */
export const validateIBAN = (iban?: string | null, length?: number) => {
  if (!iban) return false;
  if (length && iban.replace(/\s/g, '').length !== length) return false;

  return ibanRegex.test(iban.replace(/\s/g, ''));
};

export const getBaseFieldValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();

  let schema = Yup.string();
  if (required) {
    schema = schema.required(tCommon('errors.required'));
  }

  return schema;
};
