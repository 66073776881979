import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { OrdersTab } from 'enums';
import { TranslationNamespace } from 'i18n';

import { TraderActiveOrders } from './ActiveOrders';
import { TraderAllOrders } from './AllOrders';
import { TraderCancelledOrders } from './CancelledOrders';
import { TraderCompletedOrders } from './CompletedOrders';
import { TraderDisputeOrders } from './DisputeOrders';

export const TraderPayoutOrdersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={OrdersTab}>
        <Tab value={OrdersTab.Active} label={t('tabs.active')} />
        <Tab value={OrdersTab.Completed} label={t('tabs.completed')} />
        <Tab value={OrdersTab.Cancelled} label={t('tabs.cancelled')} />
        <Tab value={OrdersTab.Dispute} label={t('tabs.dispute')} />
        <Tab value={OrdersTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrdersTab.Active}>
        <TraderActiveOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Completed}>
        <TraderCompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Cancelled}>
        <TraderCancelledOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Dispute}>
        <TraderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.All}>
        <TraderAllOrders />
      </QueryTabPanel>
    </Fragment>
  );
};
