import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Field, FieldAttributes, useField } from 'formik';
import { Checkbox } from 'formik-mui';
import React from 'react';

type Props = {
  name: string;
  value: any;
  fieldProps?: FieldAttributes<any>;
} & Omit<FormControlLabelProps, 'control'>;

export const FieldArrayCheckbox: React.FC<Props> = ({
  name,
  value,
  fieldProps,
  ...rest
}) => {
  const [field] = useField(name);

  return (
    <FormControlLabel
      control={
        <Field
          component={Checkbox}
          type="checkbox"
          name={name}
          checked={field.value?.includes(value)}
          value={value}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
          }}
          {...fieldProps}
        />
      }
      {...rest}
      sx={{ display: 'flex', ...rest.sx }}
      label={rest.label || field.value}
    />
  );
};
