import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { requisitesTelegramAutomationApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikPhoneInput,
  FormikTextField,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesTelegramAutomation } from 'types';
import { validationUtils } from 'utils';

type Props = DialogProps;

type Values = Pick<RequisitesTelegramAutomation, 'name' | 'phone'>;

export const CreateTelegramAutomationDialog: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites_telegram_automation',
  });
  const { t: tCommon } = useTranslation();
  const { role } = useUser();

  const initialValues = useMemo(
    () => ({
      name: '',
      phone: '',
    }),
    [],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(tCommon('errors.required')),
    phone: Yup.string().required(tCommon('errors.required')),
  });

  const createMutation = useMutation<
    RequisitesTelegramAutomation,
    AxiosError,
    Values
  >(requisitesTelegramAutomationApi.createAsRole(role));

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.RequisitesTelegramAutomation);
          onClose({ ok: true });
          formikHelpers.resetForm();
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      createMutation.mutate(values, options);
    },
    [queryClient, onClose, createMutation],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('create_dialog.title')}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={createMutation}
          okDisabled={!formik.isValid}
          onClose={handleClose}
        >
          <FormControls>
            <Alert severity="info">{t('create_dialog.description')}</Alert>
            <FormikTextField
              name="name"
              fullWidth
              label={t('fields.name')}
              required
            />
            <FormikPhoneInput
              name="phone"
              label={t('fields.phone')}
              fullWidth
              required
            />
          </FormControls>
        </Dialog>
      )}
    </Formik>
  );
};
