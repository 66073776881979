import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageHeader } from 'components';
import { NEW_ID } from 'constants/common.constants';
import { RequisitesDetailsForm } from 'features/requisites';
import { TranslationNamespace } from 'i18n';

export const RequisitesDetailsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_details',
  });

  const { id } = useParams();

  return (
    <div>
      <PageHeader
        title={t(
          id === NEW_ID ? 'create_requisites.title' : 'edit_requisites.title',
        )}
      />
      <RequisitesDetailsForm id={id || NEW_ID} />
    </div>
  );
};
