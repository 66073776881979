const storage = window.localStorage;

const getItem = (key: string): any => {
  let item: any = null as any;
  try {
    const notParsedItem = storage.getItem(key);
    if (notParsedItem) {
      item = JSON.parse(notParsedItem);
    }
  } catch {
    // Do nothing
  }
  return item;
};

const setItem = (key: string, value: any) => {
  storage.setItem(key, JSON.stringify(value));
};

const removeItem = (key: string) => {
  storage.removeItem(key);
};

export const storageUtils = {
  getItem,
  setItem,
  removeItem,
};
