import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionRow, Dialog, DialogProps } from 'components';
import { OrderAutomationType } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesGroup } from 'types';

type Props = {
  data?: RequisitesGroup;
} & DialogProps;

export const OrderGroupAutomationDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.group_list.order_automation_dialog',
  });
  const prevOpen = usePrevious(open);
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [selectedOrderAutomationType, setSelectedOrderAutomationType] =
    useState<OrderAutomationType>();

  const handleAutomationTypeChange = useCallback(
    (e: any, value: OrderAutomationType) => {
      setSelectedOrderAutomationType(value);
    },
    [],
  );

  useEffect(() => {
    if (open && !prevOpen && data) {
      setSelectedOrderAutomationType(data.orderAutomationType);
    }
  }, [data, open, prevOpen]);

  return (
    <Dialog
      open={open}
      title={t('title')}
      data={{ orderAutomationType: selectedOrderAutomationType }}
      okLabel={tCommon('buttons.save')}
      onClose={onClose}
    >
      {data && (
        <Stack direction="column" spacing={6}>
          <DescriptionRow
            title={t('fields.api_token')}
            value={data.automationApiKey}
            canCopy
          />
          <Stack direction="column" spacing={0.5}>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {t('fields.automation_type')}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={selectedOrderAutomationType}
              exclusive
              size="small"
              onChange={handleAutomationTypeChange}
            >
              <ToggleButton value={OrderAutomationType.Manual}>
                {t('fields.order_automation_types.manual')}
              </ToggleButton>
              <ToggleButton value={OrderAutomationType.Active}>
                {t('fields.order_automation_types.active')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};
