import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { p2pProvidersPayinRequestsApi } from 'api';
import { Dialog, DialogProps } from 'components';
import { QueryKey } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

import { P2PProvidersRequestsTable } from './P2PProvidersRequestsTable';

type Props = {
  order: PayinOrder;
} & DialogProps;

export const P2PProvidersRequestsDialog: React.FC<Props> = ({
  order,
  open,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.p2p_providers_requests',
  });
  const [formatJson, setFormatJson] = useState(false);
  const prevOpen = usePrevious(open);

  const queryResult = useQuery(
    QueryKey.P2PProviderPayinRequests,
    () => p2pProvidersPayinRequestsApi.getForOrder(order.id),
    { enabled: open },
  );

  const toggleSetFormatJson = useCallback(() => {
    setFormatJson((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!prevOpen && open) {
      queryResult.refetch();
    }
  }, [prevOpen, open, queryResult]);

  return (
    <Dialog
      title={t('title')}
      open={open}
      modal
      maxWidth="xl"
      onClose={onClose}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={formatJson} onChange={toggleSetFormatJson} />
          }
          label={t('dialog.format_json')}
        />
      </FormGroup>
      <P2PProvidersRequestsTable
        queryResult={queryResult}
        formatJson={formatJson}
      />
    </Dialog>
  );
};
