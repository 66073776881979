import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { requisitesApi } from 'api';
import {
  CloseDialogHandler,
  CloseDialogResult,
  Dialog,
  FormikTextField,
} from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Requisites } from 'types';

type Values = {
  blockedMessage: string;
};

type Props = {
  open: boolean;
  requisites?: Requisites;
  onClose: CloseDialogHandler;
};

export const BlockRequisitesDialog: React.FC<Props> = ({
  open,
  requisites,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        blockedMessage: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const initialValues: Values = useMemo(
    () => ({
      blockedMessage: '',
    }),
    [],
  );

  const blockMutation = useMutation<
    Requisites,
    AxiosError,
    { id: string; blockedMessage: string }
  >(requisitesApi.block, {
    onSuccess: () => {
      onClose({ ok: true });
    },
    notifierType: 'execute',
  });

  const handleSubmit = useCallback(
    (values: Values) => {
      if (requisites) {
        blockMutation.mutate({
          id: requisites.id,
          blockedMessage: values.blockedMessage,
        });
      }
    },
    [requisites, blockMutation],
  );

  const handleClose = useCallback(
    (result: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (result.ok) {
        formik.submitForm();
      } else {
        blockMutation.reset();
        formik.resetForm();
        onClose({ ok: result.ok });
      }
    },
    [blockMutation, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          mutation={blockMutation}
          title={t('block_dialog.title')}
          okDisabled={!formik.isValid}
          onClose={(data) => handleClose(data, formik)}
        >
          <Form>
            <FormikTextField
              className="tw-w-full"
              label={t('block_dialog.message')}
              name="blockedMessage"
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
