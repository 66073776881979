import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { fundsRequestsApi } from 'api';
import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  FormikNumericField,
  FormControls,
  FormikTextField,
} from 'components';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, CreatePlatformFundsRequest, FundsRequest } from 'types';
import { validationUtils } from 'utils';

type Props = {
  open: boolean;
  asset?: Asset;
  onClose: CloseDialogHandler;
};

type Values = {
  address: string;
  amount: number;
  comment?: string;
};

export const PlatformFundsRequestDialog: React.FC<Props> = ({
  open,
  asset,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request.platform_funds_request_dialog',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {});

  const { getAssetCurrencySymbol } = useCurrencies();

  const { mutate: create, reset } = useMutation<
    FundsRequest,
    AxiosError,
    CreatePlatformFundsRequest,
    unknown
  >(fundsRequestsApi.createPlatformWithdrawal);

  const initialValues: Values = useMemo(
    () => ({
      address: '',
      amount: 0,
      comment: '',
    }),
    [],
  );

  const resetComponent = useCallback(
    (formik: FormikProps<Values>) => {
      reset();
      formik.resetForm();
    },
    [reset],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (!asset) {
        return;
      }

      formikHelpers.setSubmitting(true);

      create(
        {
          assetCurrencyId: asset.assetCurrencyId,
          assetId: asset.id,
          ...values,
        },
        {
          onSuccess: () => {
            onClose({ ok: true });
            formikHelpers.resetForm();
          },
          onError: (error: AxiosError) => {
            formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
          onSettled: () => {
            formikHelpers.setSubmitting(false);
          },
        },
      );
    },
    [asset, create, onClose],
  );

  const handleClose = useCallback(
    ({ ok }: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (ok) {
        formik.submitForm();
      } else {
        resetComponent(formik);
        onClose({ ok });
      }
    },
    [onClose, resetComponent],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        address: Yup.string().required(tCommon('errors.required')),
        amount: Yup.number()
          .moreThan(0, tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
        comment: Yup.string(),
      }),
    [tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('withdrawal.title')}
          okLabel={t('buttons.ok')}
          onClose={(data) => handleClose(data, formik)}
        >
          <Form>
            <FormControls>
              <FormikTextField
                name="address"
                label={t('fields.your_address')}
                required
              />
              <FormikNumericField
                label={t('fields.amount')}
                name="amount"
                allowNegative={false}
                suffix={getAssetCurrencySymbol(asset?.assetCurrencyId)}
                required
              />
              <FormikTextField name="comment" label={t('fields.comment')} />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
