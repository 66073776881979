import React from 'react';

import { FormikTextField } from 'components';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { getTCommon, useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();
  const tCommon = getTCommon();

  return (
    <FormikTextField
      name={name}
      label={t('fields.upi_id.label')}
      fullWidth
      placeholder={`${tCommon('common.example')}: yourname@bankname`}
      required={required}
      disabled={disabled}
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();

  return getBaseFieldValidator({ required }).matches(
    /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/,
    tCommon('errors.invalid'),
  );
};
