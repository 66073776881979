import {
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import { Button, IconButton, Paper } from '@mui/material';
import { FieldArray, useField } from 'formik';
import { map } from 'lodash';
import React, { Fragment, useCallback } from 'react';

import { FormControls } from 'components/FormControls';

type ItemComponentProps = {
  index: number;
  item: any;
};

type Props = {
  name: string;
  defaultValue?: any;
  sortable?: boolean;
  removable?: boolean;
  addItemLabel?: React.ReactNode;
  ItemComponent: React.FC<ItemComponentProps>;
  paperPaddingY?: number;
};

export type { Props as FormikArrayProps };

export const FormikArray: React.FC<Props> = ({
  addItemLabel,
  name,
  sortable,
  removable,
  defaultValue,
  ItemComponent,
  paperPaddingY = 6,
}) => {
  const [field, , helpers] = useField(name);
  const changePosition = useCallback(
    (index: number, changeIndex: number) => {
      const newIndex = index + changeIndex;
      const maxIndex = field.value.length - 1;
      if (
        index < 0 ||
        index > maxIndex ||
        newIndex < 0 ||
        newIndex > maxIndex
      ) {
        return;
      }

      const newValue = [...field.value];
      const itemToChangePosition = newValue[index];
      newValue[index] = newValue[newIndex];
      newValue[newIndex] = itemToChangePosition;

      helpers.setValue(newValue);
    },
    [field, helpers],
  );

  return (
    <FieldArray
      name={name}
      validateOnChange={false}
      render={(arrayHelpers) => (
        <FormControls>
          {map(field.value, (item, i: number) => (
            <Paper sx={{ px: 4, py: paperPaddingY }} key={i}>
              <FormControls>
                <div className="tw-flex tw-items-center">
                  <ItemComponent item={item} index={i} />
                  {sortable && (
                    <Fragment>
                      <IconButton
                        onClick={() => changePosition(i, 1)}
                        disabled={i === field.value.length - 1}
                        sx={{ opacity: i === field.value.length - 1 ? 0.3 : 1 }}
                      >
                        <ArrowDownwardIcon color="action" />
                      </IconButton>
                      <IconButton
                        onClick={() => changePosition(i, -1)}
                        disabled={i === 0}
                        sx={{ opacity: i === 0 ? 0.3 : 1 }}
                      >
                        <ArrowUpwardIcon color="action" />
                      </IconButton>
                    </Fragment>
                  )}
                  {removable && (
                    <IconButton onClick={() => arrayHelpers.remove(i)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
                </div>
              </FormControls>
            </Paper>
          ))}
          {addItemLabel && (
            <div>
              <Button
                variant="outlined"
                onClick={() => arrayHelpers.push(defaultValue)}
              >
                {addItemLabel}
              </Button>
            </div>
          )}
        </FormControls>
      )}
    />
  );
};
