import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import { DrawerHeader } from './styles';

type Props = {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose: () => void;
  width: number;
};

export const Sidebar: React.FC<Props> = ({
  open,
  title,
  children,
  onClose,
  width,
}: Props) => (
  <Drawer
    sx={{
      width,
      flexShrink: 0,
      zIndex: 1000,
    }}
    PaperProps={{
      sx: {
        width,
        borderLeftWidth: 1,
      },
    }}
    anchor="right"
    open={open}
    onClose={onClose}
    hideBackdrop
  >
    <Toolbar />
    <DrawerHeader>
      <Typography variant="h6">{title}</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DrawerHeader>
    <Box sx={{ px: 4, pb: 2, width }}>{children}</Box>
  </Drawer>
);
