import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { internalWalletsDepositTransactionsApi } from 'api';
import { ConfirmButton, Dialog, DialogProps } from 'components';
import { QueryKey, TronTransactionStatus } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { InternalPlatformWalletDepositTransaction } from 'types';

type Props = DialogProps<InternalPlatformWalletDepositTransaction>;

export const DepositTransactionDetailsModal: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets_transactions.transaction_details_modal',
  });
  const [useTrxForBandwidth, setUseTrxForBandwidth] = useState(false);
  const [useTrxForEnergy, setUseTrxForEnergy] = useState(false);

  const { mutate: statusToWaiting } = useMutation(
    internalWalletsDepositTransactionsApi.statusToWaiting,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.InternalWalletTransactionsDepositActive,
        );
        onClose({ ok: true });
      },
      notifierType: 'execute',
    },
  );
  const { mutate: execute } = useMutation(
    internalWalletsDepositTransactionsApi.execute,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.InternalWalletTransactionsDepositActive,
        );
        onClose({ ok: true });
      },
      notifierType: 'execute',
    },
  );

  const onConfirmToWaiting = useCallback(() => {
    statusToWaiting(data?.id!);
  }, [data?.id, statusToWaiting]);

  const onConfirmExecute = useCallback(() => {
    execute({ id: data?.id!, data: { useTrxForBandwidth, useTrxForEnergy } });
  }, [data?.id, execute, useTrxForBandwidth, useTrxForEnergy]);

  return (
    <Dialog open={open} title={t('title')} modal onClose={onClose}>
      {data?.status === TronTransactionStatus.Error && (
        <div className="tw-mb-6">
          <ConfirmButton variant="outlined" onConfirm={onConfirmToWaiting}>
            {t('to_waiting')}
          </ConfirmButton>
          <Typography variant="caption" component="div" color="textSecondary">
            {t('execute_by_schedule')}
          </Typography>
        </div>
      )}
      <ConfirmButton variant="outlined" onConfirm={onConfirmExecute}>
        {t('execute')}
      </ConfirmButton>
      <FormControlLabel
        control={
          <Checkbox
            checked={useTrxForBandwidth}
            onChange={() => setUseTrxForBandwidth((prev) => !prev)}
          />
        }
        label={t('use_trx_for_bandwidth')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={useTrxForEnergy}
            onChange={() => setUseTrxForEnergy((prev) => !prev)}
          />
        }
        label={t('use_trx_for_energy')}
      />
    </Dialog>
  );
};
