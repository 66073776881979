export enum P2PProviderType {
  InternalLike = 'internal_like',
  Trust2Pay = 'trust2pay',
  X2X = 'x2x',
  Payscrow = 'payscrow',
  Paycraft = 'paycraft',
  Namba = 'namba',
  Exwave = 'exwave',
  Reactivepay = 'reactivepay',
}
