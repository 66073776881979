import axios from 'axios';

import { env } from 'config';

const login = async (data: { email: string; password: string }) =>
  (
    await axios.post(`${env.apiUrl}/auth/login`, data, {
      withCredentials: true,
    })
  ).data;

const refreshAccessToken = async () =>
  (
    await axios.post(`${env.apiUrl}/auth/refresh/access-token`, null, {
      withCredentials: true,
    })
  ).data;

const logout = async () =>
  (
    await axios.post(`${env.apiUrl}/auth/logout`, null, {
      withCredentials: true,
    })
  ).data;

export const authApi = {
  login,
  refreshAccessToken,
  logout,
};
