import { isNil, map } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { usersApi } from 'api';
import { FormikSelect, FormikSelectProps } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';

type Props = {
  name: string;
  label?: string;
  users?: User[];
} & Partial<FormikSelectProps>;

export const UserSelect: React.FC<Props> = ({
  name,
  users,
  label,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.user_select',
  });
  const queryResult = useQuery(QueryKey.Users, usersApi.getAll, {
    enabled: isNil(users),
  });

  const getLabel = useCallback((user: User) => {
    if (!user.name) {
      return user.email;
    }
    return `${user.name} (${user.email})`;
  }, []);

  const options = useMemo(
    () =>
      map(users || queryResult?.data, (user) => ({
        value: user.id,
        label: getLabel(user),
      })),
    [users, queryResult, getLabel],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
