import { Divider, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'components';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { Authentication } from './Authentication';
import { Notifications } from './Notifications';
import { Profile } from './Profile';

export const Settings: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings',
  });

  const { user } = useUser();

  return (
    <div>
      <PageHeader title={t('title')} />
      <Grid container spacing={{ md: 6 }}>
        <Grid item xs={12} md={6}>
          <Profile />
          <Divider sx={{ marginY: 8 }} />
          {user && <Notifications user={user} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <Divider sx={{ marginY: 8, display: { xs: 'block', md: 'none' } }} />
          <Authentication />
        </Grid>
      </Grid>
    </div>
  );
};
