import styled from '@emotion/styled';
import { ChipProps, Theme } from '@mui/material';
import { rgba } from 'polished';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components';
import { TronTransactionStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: TronTransactionStatus;
} & Partial<ChipProps>;

const getColor = (props: Props & { theme: Theme }) => {
  switch (props.status) {
    case TronTransactionStatus.Success:
      return props.theme.palette.success.main;
    case TronTransactionStatus.WaitingBandwidth:
    case TronTransactionStatus.WaitingEnergy:
    case TronTransactionStatus.SmallAmount:
    case TronTransactionStatus.Waiting:
    case TronTransactionStatus.Cancelled:
      return props.theme.palette.grey[500];
    default:
      return props.theme.palette.error.main;
  }
};

const StyledLabel = styled(StyledChip)<
  ChipProps & { status: TronTransactionStatus }
>`
  background: ${(props) => rgba(getColor(props as any), 0.1)};
`;

export const TronTransactionStatusLabel: FC<Props> = ({ status, ...rest }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets.status',
  });
  return status ? (
    <StyledLabel label={t(status)} status={status} {...rest} />
  ) : null;
};
