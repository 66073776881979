import {
  CloudUpload as CloudUploadIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { map } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { payoutOrdersFilesValidationApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  ExternalLink,
  FormControls,
  FormikTextField,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethodPayoutPDFTemplate } from 'types';

type Props = DialogProps<TradeMethodPayoutPDFTemplate>;

type Values = Pick<
  TradeMethodPayoutPDFTemplate,
  'template' | 'dateFormat' | 'dateLanguage' | 'dateTimezone'
>;

export const PayoutPDFtemplateDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix:
      'pages.trade_methods.files_validation.payout_pdf_templates.details',
  });
  const { t: tCommon } = useTranslation();

  const getInitialValues = useCallback(
    (data?: TradeMethodPayoutPDFTemplate) => ({
      template: data?.template || '',
      dateFormat: data?.dateFormat || '',
      dateLanguage: data?.dateLanguage || '',
      dateTimezone: data?.dateTimezone || '',
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState<Values>(
    getInitialValues(data),
  );
  const prevOpen = usePrevious(open);

  const isNew = useMemo(() => !data?.id, [data]);

  const title = useMemo(
    () => t(isNew ? 'create_title' : 'edit_title'),
    [isNew, t],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        template: Yup.string().required(tCommon('errors.required')),
        dateFormat: Yup.string(),
        dateLanguage: Yup.string(),
        dateTimezone: Yup.string(),
      }),
    [tCommon],
  );

  const {
    mutate: parsePdfTemplateText,
    data: parsePdfTemplateData,
    isLoading: isParseLoading,
    reset: resetParsePdfTemplateData,
  } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.parsePdfTemplateText,
    { notifierType: 'execute' },
  );

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.createForTradeMethod,
  );

  const { mutate: update, isLoading: isUpdateLoading } = useMutation(
    payoutOrdersFilesValidationApi.pdfTemplates.update,
  );

  const isLoading = useMemo(
    () => isParseLoading || isCreateLoading || isUpdateLoading,
    [isParseLoading, isCreateLoading, isUpdateLoading],
  );

  const parsedData = useMemo(() => {
    if (!parsePdfTemplateData) {
      return '';
    }

    return map(
      parsePdfTemplateData.parseResult,
      (value, key) => `${key}: ${value}`,
    ).join('\n');
  }, [parsePdfTemplateData]);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const file = event.target.files?.[0];
      event.target.value = '';
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      parsePdfTemplateText({ template: data, data: formData });
    },
    [data, parsePdfTemplateText],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data?.values) {
        result?.data?.formikHelpers.resetForm({ values: getInitialValues() });
        return onClose({ ok: false });
      }
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries([
            QueryKey.TradeMethodPayoutPDFTemplates,
            data!.tradeMethodId,
          ]);
          result?.data?.formikHelpers.resetForm({ values: getInitialValues() });
          onClose({ ok: true });
        },
      };
      if (isNew) {
        create({ id: data!.tradeMethodId, data: result.data.values }, options);
      } else {
        update({ id: data!.id, data: result.data.values }, options);
      }
    },
    [create, data, getInitialValues, isNew, onClose, queryClient, update],
  );

  useEffect(() => {
    if (open && !prevOpen && data) {
      setInitialValues(getInitialValues(data));
    }
    if (!open && prevOpen) {
      resetParsePdfTemplateData();
    }
  }, [
    data,
    initialValues,
    open,
    prevOpen,
    getInitialValues,
    resetParsePdfTemplateData,
  ]);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          maxWidth="sm"
          okDisabled={!formik.isValid}
          disabled={isLoading}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
        >
          <FormControls>
            <Typography variant="h6">{t('fields.template')}</Typography>
            <FormikTextField multiline minRows={5} fullWidth name="template" />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{t('fields.date')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControls>
                  <div className="tw-mb-2">
                    <ExternalLink
                      href="https://momentjs.com/timezone/docs/"
                      label={t('fields.docs')}
                    />
                  </div>
                  <FormikTextField
                    name="dateFormat"
                    label={t('fields.date_format')}
                    helperText={`${t('fields.default')}: ${t(
                      'fields.date_format_helper',
                    )}`}
                  />
                  <FormikTextField
                    name="dateLanguage"
                    label={t('fields.date_language')}
                    helperText={`${t('fields.default')}: ${t(
                      'fields.date_language_helper',
                    )}`}
                  />
                  <FormikTextField
                    name="dateTimezone"
                    label={t('fields.date_timezone')}
                    helperText={`${t('fields.default')}: ${t(
                      'fields.date_timezone_helper',
                    )}`}
                  />
                </FormControls>
              </AccordionDetails>
            </Accordion>
          </FormControls>
          <Divider sx={{ my: 5 }} />
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('parse_file')}
          </Typography>
          <Stack spacing={4}>
            <div>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                disabled={isLoading}
              >
                {t('upload_pdf')}
                <TextField
                  type="file"
                  sx={{ display: 'none' }}
                  inputProps={{
                    accept: 'application/pdf',
                  }}
                  onChange={handleFileChange}
                />
              </Button>
              <Typography
                variant="caption"
                component="div"
                sx={{ fontStyle: 'italic' }}
              >
                {`*${t('parse_description')}`}
              </Typography>
            </div>
            {parsePdfTemplateData?.text && (
              <TextField
                type="text"
                multiline
                fullWidth
                value={parsePdfTemplateData.text}
              />
            )}
            {parsedData && (
              <TextField
                type="text"
                multiline
                fullWidth
                disabled
                value={parsedData}
              />
            )}
          </Stack>
        </Dialog>
      )}
    </Formik>
  );
};
