import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export const OperatorMainPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Operator, {
    keyPrefix: 'pages.main',
  });
  return <div className="tw-text-center tw-text-lg tw-my-4">{t('title')}</div>;
};
