import { Tab } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi, tradersApi, usersApi } from 'api';
import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { OrderType, QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { GroupsCashback } from './GroupsCashback';

export enum CashbackTab {
  Groups = 'groups',
}

type Props = {
  orderType: OrderType;
};

export const CashbackPage: React.FC<Props> = ({ orderType }) => {
  const { role } = useUser();
  // TODO: move to common
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.cashback',
  });

  const usersQueryResult = useQuery(QueryKey.Users, usersApi.getAll);
  const tradersQueryResult = useQuery(QueryKey.Traders, () =>
    tradersApi.getAllAsRole(role)(),
  );
  const shopsQueryResult = useQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const isLoading = useMemo(
    () =>
      usersQueryResult.isLoading ||
      tradersQueryResult.isLoading ||
      shopsQueryResult.isLoading,
    [usersQueryResult, tradersQueryResult, shopsQueryResult],
  );

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={CashbackTab}>
        <Tab value={CashbackTab.Groups} label={t('tabs.groups')} />
      </QueryTabs>
      <QueryTabPanel value={CashbackTab.Groups}>
        <GroupsCashback
          orderType={orderType}
          traders={tradersQueryResult.data}
          users={usersQueryResult.data}
          shops={shopsQueryResult.data}
          isLoading={isLoading}
        />
      </QueryTabPanel>
    </Fragment>
  );
};
