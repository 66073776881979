import React from 'react';
import { matchPath } from 'react-router-dom';

import { NavList } from './NavList';
import { LayoutMenuItem } from '../../../types';
import { NavListItem } from '../NavListItem';

type Props = {
  depth: number;
  page: LayoutMenuItem;
  items: JSX.Element[];
  currentRoute: string;
};

export const reduceChildRoutes = ({
  items,
  page,
  depth,
  currentRoute,
}: Props) => {
  if (page.children) {
    const open = page.link
      ? !!matchPath(
          {
            path: page.link,
            end: false,
          },
          currentRoute,
        )
      : false;

    items.push(
      <NavListItem depth={depth} item={page} key={page.title} open={open}>
        <NavList depth={depth + 1} items={page.children} />
      </NavListItem>,
    );
  } else {
    items.push(<NavListItem depth={depth} item={page} key={page.title} />);
  }

  return items;
};
