import Uppy, { UploadResult, UploadedUppyFile } from '@uppy/core';
import Russian from '@uppy/locales/lib/ru_RU';
import { DashboardModal } from '@uppy/react';
import Webcam from '@uppy/webcam';
import { forEach } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { payoutOrdersApi } from 'api';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { PayoutOrder } from 'types';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

const uppy = new Uppy({
  autoProceed: false,
  allowMultipleUploads: false,
  restrictions: {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['image/*', 'application/pdf'], // Only allow image files and pdf
    maxFileSize: 1024 * 1024 * 3, // 3MB max file size
  },
}).use(Webcam);

type Props = {
  open: boolean;
  order: PayoutOrder;
  onClose: () => void;
};

export const UploadReceiptDialog: React.FC<Props> = ({
  open,
  order,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { role } = useUser();

  const removeFiles = useCallback(() => {
    const files = uppy.getFiles();
    forEach(files, (file) => {
      uppy.removeFile(file.id);
    });
  }, []);

  const { mutate: uploadPaymentReceipt } = useMutation(
    payoutOrdersApi.uploadReceiptAsRole(role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.PayoutOrderReceipts, order.id]);
        removeFiles();
        onClose();
      },
      notifierType: 'save',
    },
  );

  const handleUpload = useCallback(
    (file: UploadedUppyFile<any, any>) => {
      const formData = new FormData();
      formData.append('file', file.data, file.name);

      uploadPaymentReceipt({
        orderId: order.id,
        body: formData,
      });
    },
    [order.id, uploadPaymentReceipt],
  );

  const handleFileComplete = useCallback(
    (result: UploadResult<Record<string, unknown>>) => {
      const file = result.successful[0];
      handleUpload(file);
    },
    [handleUpload],
  );

  useEffect(() => {
    uppy.on('complete', handleFileComplete);
    return () => {
      uppy.off('complete', handleFileComplete);
    };
  }, [handleFileComplete]);

  return (
    <DashboardModal
      open={open}
      uppy={uppy}
      plugins={['Webcam']}
      locale={Russian}
      proudlyDisplayPoweredByUppy={false}
      onRequestClose={onClose}
    />
  );
};
