import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { usersApi } from 'api';
import { DataWrapper } from 'components';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { UserProfile } from 'types';

import { ProfileForm } from '../ProfileForm';

export const Profile: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.profile',
  });
  const [initialValues, setInitialValues] = useState<UserProfile>({
    name: '',
    email: '',
    telegram: '',
  });

  const queryResult = useQuery(QueryKey.Profile, usersApi.getProfile, {
    onSuccess: (user) => {
      setInitialValues({
        name: user.name || '',
        email: user.email,
        telegram: user.telegram || '',
      });
    },
  });

  const { mutate: updateProfile } = useMutation<
    UserProfile,
    AxiosError,
    Partial<UserProfile>,
    unknown
  >(usersApi.updateProfile);

  return (
    <div className="tw-my-4">
      <div className="tw-text-xl tw-mb-4">{t('title')}</div>
      <DataWrapper queryResult={queryResult}>
        <ProfileForm
          initialValues={initialValues}
          onSubmit={updateProfile}
          canChangePassword
        />
      </DataWrapper>
    </div>
  );
};
