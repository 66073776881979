import { Button, ButtonProps, Fab, FabProps } from '@mui/material';
import { isFunction } from 'lodash';
import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseDialogResult, Dialog, DialogProps } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  dialog?: Omit<DialogProps, 'open' | 'onClose'>;
  fabProps?: FabProps;
  onConfirm: () => void;
  children?: ReactNode | ((props: { onClick: () => void }) => ReactNode);
} & Omit<ButtonProps, 'onClick' | 'children'>;

export const ConfirmButton: React.FC<Props> = ({
  onConfirm,
  dialog,
  fabProps,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.confirm_button',
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok) {
        onConfirm();
      }
      setDialogOpen(false);
    },
    [onConfirm],
  );

  const handleClick = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <Fragment>
      {isFunction(rest.children) && rest.children({ onClick: handleClick })}
      {fabProps && !isFunction(rest.children) && (
        <Fab {...fabProps} children={rest.children} onClick={handleClick} />
      )}
      {!fabProps && !isFunction(rest.children) && (
        <Button {...rest} children={rest.children} onClick={handleClick} />
      )}
      <Dialog
        title={t('dialog.title')}
        children={t('dialog.description')}
        okDisabled={rest.disabled}
        {...dialog}
        open={dialogOpen}
        onClose={handleDialogClose}
      ></Dialog>
    </Fragment>
  );
};
