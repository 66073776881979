export enum OrderAutomationType {
  Inactive = 'inactive',
  Manual = 'manual',
  Active = 'active',
}

export enum OrderAutomationFilterTypes {
  Archive = 'archive',
  Active = 'active',
  Error = 'error',
  TemplateErrorHandled = 'template-error-handled',
  TemplateError = 'template-error',
  Technical = 'technical',
  All = 'all',
}

export enum OrderAutomationNotificationType {
  Sms = 'sms',
  Push = 'push',
}
