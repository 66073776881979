import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  FormControls,
} from 'components';
import { PAYOUT_REJECT_SELECT_DETAILS } from 'constants/order.constants';
import { PayoutRejectStatusDetails } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';

import { RejectSelect } from './RejectSelect';

type Values = {
  statusDetails: PayoutRejectStatusDetails;
};

type Props = {
  open: boolean;
  order: PayoutOrder;
  onClose: CloseDialogHandler<Values>;
};

export const RejectDialog: React.FC<Props> = ({ open, order, onClose }) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_reject_dialog',
  });
  const prevOpen = usePrevious(open);

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        statusDetails: Yup.mixed<PayoutRejectStatusDetails>()
          .oneOf(PAYOUT_REJECT_SELECT_DETAILS)
          .required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const defaultDetails = useMemo(
    () =>
      (PAYOUT_REJECT_SELECT_DETAILS.includes(
        order?.statusDetails as PayoutRejectStatusDetails,
      )
        ? order.statusDetails
        : PAYOUT_REJECT_SELECT_DETAILS[0]) as PayoutRejectStatusDetails,
    [order],
  );

  const [initialValues, setInitialValues] = useState<Values>({
    statusDetails: defaultDetails,
  });

  const handleClose = useCallback(
    (data: CloseDialogResult<Values>) => {
      onClose(data);
      if (data.data) {
        setInitialValues(data.data);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      handleClose({ ok: true, data: values });
    },
    [handleClose],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      setInitialValues({
        statusDetails: defaultDetails,
      });
    }
  }, [order, defaultDetails, open, prevOpen]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={t('title')}
          okDisabled={!formik.isValid}
          data={formik.values}
          onClose={handleClose}
        >
          <Form>
            <FormControls>
              <div className="tw-mb-4">
                <div className="tw-mb-2">{t('description')}</div>
                <div>{t('description_2')}</div>
              </div>
              <RejectSelect />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
