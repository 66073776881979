import { internalWalletsWithdrawalTransactionsApi } from 'api';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';

import { WithdrawalTransactions } from '../WithdrawalTansactionsTable';

export const WithdrawalArchiveTransactions: React.FC = () => {
  const queryResult = usePartialQuery(
    QueryKey.InternalWalletTransactionsWithdrawalArchive,
    internalWalletsWithdrawalTransactionsApi.getAllArchivePaginated,
  );

  return <WithdrawalTransactions queryResult={queryResult} />;
};
