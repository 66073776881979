import axios from 'axios';

import { env } from 'config';
import { ParsingMethodType } from 'enums';
import { CreateParsingMethodsDto, ParsingMethod } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/parsing-methods`;

const parseWithMethodById = async (
  id: string,
  value: string,
): Promise<Record<string, string>> =>
  (await axios.post(`${url}/parse/${id}`, { value })).data;

const parseWithTemplate = async (
  template: string,
  value: string,
): Promise<Record<string, string>> =>
  (await axios.post(`${url}/parse`, { value, template })).data;

const getAllByTradeMethodId = async ({
  id,
  type,
}: {
  id: string;
  type?: ParsingMethodType;
}): Promise<ParsingMethod[]> =>
  (await axios.get(`${url}/trade-method/${id}`, { params: { type } })).data;

const createByTradeMethodsIds = async (
  createDto: CreateParsingMethodsDto,
): Promise<ParsingMethod[]> =>
  (await axios.post(`${url}/create-by-trade-methods-ids`, createDto)).data;

export const parsingMethodsApi = {
  ...createCrudApi<ParsingMethod>('/parsing-methods'),
  parseWithMethodById,
  parseWithTemplate,
  getAllByTradeMethodId,
  createByTradeMethodsIds,
};
