import { isNil, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi } from 'api';
import { FormikSelect, FormikSelectProps } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { Shop } from 'types';

type Props = {
  name: string;
  label?: string;
  shops?: Shop[];
} & Partial<FormikSelectProps>;

export const ShopSelect: React.FC<Props> = ({
  name,
  label,
  shops,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.shop_select',
  });
  const queryResult = useQuery(QueryKey.ShopAssets, shopsApi.getAll, {
    enabled: isNil(shops),
  });

  const options = useMemo(
    () =>
      map(
        sortBy(shops || queryResult?.data, (shop) => shop.name),
        (shop) => ({
          value: shop.id,
          label: shop.name,
        }),
      ),
    [shops, queryResult],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
