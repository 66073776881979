import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaProps } from 'recharts';

import { statisticsApi } from 'api';
import { AreaChart, DataWrapper, Dialog, DialogProps } from 'components';
import { OrderType, QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Props = { requisiteId: string | null; orderType: OrderType } & DialogProps;

export const ChartModal: React.FC<Props> = ({
  requisiteId,
  orderType,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics.requisites',
  });

  const queryResult = usePartialQuery(
    `${QueryKey.SummaryStatistic}-${orderType}-${requisiteId}`,
    statisticsApi.getDailyRequisites(orderType, requisiteId!),
    { enabled: !!requisiteId },
  );

  const areas = useMemo(() => {
    const result: AreaProps[] = [
      {
        type: 'linear',
        dataKey: 'totalCount',
        fill: '#fbd1d3',
        fillOpacity: 0.5,
        name: t('columns.total'),
      },
      {
        type: 'linear',
        dataKey: 'completedCount',
        fill: '#7593ff',
        fillOpacity: 0.5,
        name: t('columns.completed'),
      },
    ];
    if (orderType === OrderType.Payin) {
      result.push({
        type: 'linear',
        dataKey: 'completedByAutomationCount',
        fill: '#c0d9c3',
        fillOpacity: 0.5,
        name: t('columns.completed_by_automation'),
      });
    }
    return result;
  }, [orderType, t]);

  return (
    <Dialog {...rest} maxWidth="lg" title={t('chart_modal.title')} modal>
      <DataWrapper queryResult={queryResult}>
        <AreaChart data={queryResult.data || {}} areas={areas} />
      </DataWrapper>
    </Dialog>
  );
};
