import styled from '@emotion/styled';
import {
  Avatar as MuiAvatar,
  Badge,
  Popover as MuiPopover,
} from '@mui/material';

export const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

export const AvatarBadge = styled(Badge)<{ active?: boolean }>`
  margin-right: ${(props) => props.theme.spacing(1)};
  cursor: pointer;
  span {
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    min-width: 250px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;
