import React from 'react';

import { ExternalLink } from 'components';

type Props = {
  phone: string;
};

export const PhoneLink: React.FC<Props> = ({ phone }) => (
  <span className="tw-whitespace-nowrap">
    <ExternalLink href={`tel:${phone}`} label={phone} />
  </span>
);
