import { Typography } from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControls, FormikArray, FormikNumericField } from 'components';
import { TranslationNamespace } from 'i18n';

export const PayinBlockCustomers: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.shops.manage',
  });

  const renderItemComponent = useCallback(
    ({ index }: { index: number }) => (
      <FormControls sx={{ flex: 1 }}>
        <FormControls row>
          <FormikNumericField
            label={t('sections.block_customers.fields.period')}
            name={`payinBlockCustomersConfigs[${index}].period`}
            fullWidth
            required
            decimalScale={0}
            allowNegative={false}
          />
          <FormikNumericField
            label={t('sections.block_customers.fields.count')}
            name={`payinBlockCustomersConfigs[${index}].count`}
            fullWidth
            required
            decimalScale={0}
            allowNegative={false}
          />
        </FormControls>
        <FormikNumericField
          label={t('sections.block_customers.fields.duration')}
          name={`payinBlockCustomersConfigs[${index}].duration`}
          fullWidth
          required
          decimalScale={0}
          allowNegative={false}
        />
      </FormControls>
    ),
    [t],
  );

  return (
    <Fragment>
      <Typography variant="subtitle1">
        {t('sections.block_customers.title')}
      </Typography>
      <FormikArray
        addItemLabel={t('buttons.add_rule')}
        name="payinBlockCustomersConfigs"
        removable
        defaultValue={{ period: '', count: '', duration: '' }}
        ItemComponent={renderItemComponent}
      />
    </Fragment>
  );
};
