import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  CloseDialogFormikHandler,
  Dialog,
  FormControls,
  FormikNumericField,
  FormikYesNoRadioGroup,
} from 'components';
import { OrderStatus } from 'enums';
import { useCurrencies, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder, ResolvePayinOrderDispute } from 'types';
import { validationUtils } from 'utils';

type Values = ResolvePayinOrderDispute;

type Props = {
  open: boolean;
  order: PayinOrder;
  error?: AxiosError | null;
  amountDisabled: boolean;
  onClose: CloseDialogFormikHandler<Values>;
};

export const ResolveDisputeDialog: React.FC<Props> = ({
  open,
  order,
  error,
  amountDisabled,
  onClose,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.dispute',
  });
  const prevOpen = usePrevious(open);
  const { getFiatCurrencySymbol } = useCurrencies();
  const [initialValues, setInitialValues] = useState<Values>({
    status: OrderStatus.Completed,
    amount: order.amount,
  });

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        status: Yup.mixed<OrderStatus>()
          .oneOf([OrderStatus.Completed, OrderStatus.Cancelled])
          .required(tCommon('errors.required')),
        amount: Yup.number()
          .moreThan(0, tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const currencySymbol = useMemo(
    () => getFiatCurrencySymbol(order?.fiatCurrencyId),
    [order, getFiatCurrencySymbol],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result.data?.formikHelpers.resetForm();
      }
      onClose(result);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  const renderOrderStatus = useCallback(
    (status: string) => (
      <span>
        {`${t('resolve_as.prefix')} `}
        <span className="tw-font-bold">{status}</span>
      </span>
    ),
    [t],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (open && !prevOpen) {
      setInitialValues({
        status: OrderStatus.Completed,
        amount: order.amount,
      });
    }
  }, [open, prevOpen, order]);

  useEffect(() => {
    if (error) {
      formik.setErrors(
        validationUtils.getFormErrors(error, {
          amount: { too_big: tCommon('errors.not_enough_money') },
        }),
      );
    } else {
      formik.setErrors({});
    }
  }, [tCommon, formik, error]);

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={t('title')}
        data={{ values: formik.values, formikHelpers: formik }}
        onClose={handleClose}
      >
        <Form>
          <Alert severity="info" sx={{ mb: 4, py: 0 }}>
            {formik.values.status === OrderStatus.Completed &&
              renderOrderStatus(t('resolve_as.completed'))}
            {formik.values.status === OrderStatus.Cancelled &&
              renderOrderStatus(t('resolve_as.cancelled'))}
          </Alert>
          <FormControls>
            <FormikYesNoRadioGroup
              name="status"
              label={t('payment_received')}
              yesValue={OrderStatus.Completed}
              noValue={OrderStatus.Cancelled}
            />
            {formik.values.status === OrderStatus.Completed && (
              <FormikNumericField
                name="amount"
                disabled={amountDisabled}
                label={`${t('received_amount')} (${currencySymbol})`}
                allowNegative={false}
                required
                fullWidth
              />
            )}
          </FormControls>
        </Form>
      </Dialog>
    </FormikProvider>
  );
};
