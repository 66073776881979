import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps } from 'components';
import { useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Bank } from 'types';
import { formUtils } from 'utils';

type Props = {
  banks?: Bank[];
} & Omit<FormikSelectProps, 'options' | 'label'> &
  Partial<Pick<FormikSelectProps, 'label'>>;

export const BankSelect: React.FC<Props> = ({
  label,
  banks: banksProp,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common);
  const { banks } = useUserContext();
  const options = useMemo(
    () => formUtils.getOptions(banksProp || banks),
    [banks, banksProp],
  );

  return (
    <FormikSelect
      size="small"
      {...rest}
      options={options}
      label={label || t('components.bank_select.title')}
    />
  );
};
