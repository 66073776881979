import i18next from 'i18next';

import { OrderAutomationStatus } from 'enums';

const getStatusLabel = (status: OrderAutomationStatus) => {
  const { t } = i18next;
  const key = `order_automation_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getOptionsFromStatuses = (statuses: OrderAutomationStatus[]) =>
  statuses.map((status) => ({
    value: status,
    label: getStatusLabel(status),
  }));

export const orderAutomationUtils = {
  getStatusLabel,
  getOptionsFromStatuses,
};
