import axios from 'axios';

import { env } from 'config';
import { OrderCashback } from 'types';

const url = `${env.apiUrl}/cashback`;

const getForOrder = async (orderId: string): Promise<OrderCashback[]> =>
  (await axios.get(`${url}/order/${orderId}`)).data;

const getForPayoutOrder = async (orderId: string): Promise<OrderCashback[]> =>
  (await axios.get(`${url}/payout-order/${orderId}`)).data;

export const cashbackApi = {
  getForOrder,
  getForPayoutOrder,
};
