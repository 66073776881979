import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

import { FormikTextField } from 'components';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();

  return (
    <div className="tw-flex tw-items-center">
      <FormikTextField
        name={name}
        label={t('fields.tax_id.label')}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <Tooltip title={t('fields.tax_id.tooltip')}>
        <InfoOutlinedIcon sx={{ ml: 2 }} fontSize="small" color="primary" />
      </Tooltip>
    </div>
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) =>
  getBaseFieldValidator({ required }).nullable();
