import React from 'react';

import { orderAutomationApi } from 'api';
import { OrderAutomationFilterTypes, QueryKey } from 'enums';
import { usePartialQuery, useUser } from 'hooks';

import { ManageOrderAutomationList } from '../ManageOrderAutomationList';

export const ArchiveOrderAutomation: React.FC = () => {
  const { role } = useUser();

  const queryResult = usePartialQuery(
    QueryKey.ArchiveOrderAutomation,
    orderAutomationApi.getMessagesAsRole(
      role,
      OrderAutomationFilterTypes.Archive,
    ),
    { useCachedCount: true },
  );

  return <ManageOrderAutomationList queryResult={queryResult} />;
};
