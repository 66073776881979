import { Stack, Typography } from '@mui/material';
import { find } from 'lodash';
import React, { useMemo, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import {
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  PageHeader,
  StylizedNumber,
} from 'components';
import { QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AgentInfo, Referral } from 'types';
import { formatUtils } from 'utils';

export const ReferralList: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.referral_system.referral_list',
  });

  const queryResultReferralTraders = useQuery(
    QueryKey.UserReferrals,
    usersApi.getReferralTraders,
  );

  const queryResultReferralShops = useQuery(
    QueryKey.ShopReferrals,
    shopsApi.getReferrals,
  );

  const renderAgentCompensation = useCallback(
    ({ agents }: { agents: AgentInfo[] }) => {
      const currentUserAgent = find(
        agents,
        (agent) => agent.userId === user.id,
      );

      if (currentUserAgent) {
        return (
          <Stack direction="row" spacing={1}>
            <StylizedNumber
              value={currentUserAgent.payinCompensationPercentage}
              unit="%"
            />
            <span>{'/'}</span>
            <StylizedNumber
              value={currentUserAgent.payoutCompensationPercentage}
              unit="%"
            />
          </Stack>
        );
      }
    },
    [user],
  );

  const usersColumns = useMemo(
    (): DataGridColumnDefinition<Referral>[] => [
      {
        header: t('table.columns.user_name'),
        valueKey: 'name',
      },
      {
        header: t('table.columns.email'),
        valueKey: 'email',
      },
      {
        header: t('table.columns.referrerCompensation'),
        valueGetter: renderAgentCompensation,
      },
      {
        header: t('table.columns.creation_date'),
        valueGetter: (item) => formatUtils.formatDate(item.createdAt),
      },
    ],
    [t, renderAgentCompensation],
  );

  const shopsColumns = useMemo(
    (): DataGridColumnDefinition<Referral>[] => [
      {
        header: t('table.columns.shop_name'),
        valueKey: 'name',
      },
      {
        header: t('table.columns.referrerCompensation'),
        valueGetter: renderAgentCompensation,
      },
      {
        header: t('table.columns.creation_date'),
        valueGetter: (item) => formatUtils.formatDate(item.createdAt),
      },
    ],
    [t, renderAgentCompensation],
  );

  const renderSection = useCallback(
    (
      name: string,
      columns: DataGridColumnDefinition[],
      queryResult: UseQueryResult<any>,
    ) => (
      <Fragment>
        <Typography className="tw-py-2" variant="h5">
          {name}
        </Typography>
        <DataWrapper queryResult={queryResult}>
          <DataGrid columns={columns} data={queryResult.data} />
        </DataWrapper>
      </Fragment>
    ),
    [],
  );

  return (
    <Fragment>
      <PageHeader title={t('title')} />
      {renderSection(
        t('sections.traders'),
        usersColumns,
        queryResultReferralTraders,
      )}
      {renderSection(
        t('sections.shops'),
        shopsColumns,
        queryResultReferralShops,
      )}
    </Fragment>
  );
};
