import { find } from 'lodash';
import moment from 'moment';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { merchantsApi, shopsApi, tradersApi, statisticsApi } from 'api';
import { QueryFilters } from 'components';
import { FilterDefinitionType, OrderType, QueryKey } from 'enums';
import {
  useCurrencies,
  usePartialQuery,
  useQueryFilters,
  useUser,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { DateFilters, FilterDefinition } from 'types';
import { formUtils } from 'utils';

import { SummaryCards } from '../SummaryCards';

type SummaryStatisticFilters = {
  traderId: string;
  merchantId: string;
  shopId: string;
  assetCurrencyId: string;
} & DateFilters;

type Props = {
  orderType: OrderType;
};

export const SummaryTab: React.FC<Props> = ({ orderType }) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { assetCurrenciesOptions, defaultAssetCurrency, getAssetCurrencyCode } =
    useCurrencies();

  useQueryFilters<SummaryStatisticFilters>({
    assetCurrencyId: defaultAssetCurrency?.id,
    from: moment().startOf('month').toISOString(),
  });

  const { role, isAdmin, isTechOperator, isTrader } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const queryResult = usePartialQuery(
    `${QueryKey.DailyRequisitesStatistic}-${orderType}`,
    statisticsApi.getSummary(orderType),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: canManage,
    },
  );

  const queryResultMerchants = useQuery(
    QueryKey.Merchants,
    () => merchantsApi.getAllAsRole(role)(),
    {
      enabled: canManage,
    },
  );

  const queryResultShops = useQuery(
    QueryKey.Shops,
    canManage ? shopsApi.getAll : shopsApi.getAllMy,
    { enabled: !isTrader },
  );

  const filtersDefinitions: FilterDefinition<SummaryStatisticFilters>[] =
    useMemo(
      () => [
        {
          label: tCommon('filters.trader'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          traders: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.user?.name,
          hidden: !canManage,
        },
        {
          label: tCommon('filters.merchant'),
          name: 'merchantId',
          type: FilterDefinitionType.Select,
          traders: queryResultMerchants.data,
          getDisplayName: (merchantId: string) =>
            find(queryResultMerchants.data, { id: merchantId })?.user?.name,
          options: formUtils.getOptions(
            queryResultMerchants?.data || [],
            'user.name' as any,
          ),
          hidden: !canManage,
        },
        {
          label: tCommon('filters.shop'),
          name: 'shopId',
          type: FilterDefinitionType.Shop,
          shops: queryResultShops.data,
          getDisplayName: (shopId: string) =>
            find(queryResultShops.data, { id: shopId })?.name,
          hidden: isTrader,
        },
        {
          label: tCommon('filters.asset_currency'),
          name: 'assetCurrencyId',
          type: FilterDefinitionType.Select,
          options: assetCurrenciesOptions,
          noneOption: false,
          removable: false,
          getDisplayName: getAssetCurrencyCode,
        },
      ],
      [
        tCommon,
        queryResultTraders.data,
        canManage,
        queryResultMerchants.data,
        queryResultShops.data,
        isTrader,
        assetCurrenciesOptions,
        getAssetCurrencyCode,
      ],
    );

  return (
    <Fragment>
      <QueryFilters withCommon filtersDefinitions={filtersDefinitions} />
      <SummaryCards
        queryResult={queryResult}
        exclude={['tradersReferralsCompensation']}
      />
    </Fragment>
  );
};
