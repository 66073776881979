import React from 'react';

import { MailLink, PhoneLink } from 'components';

type Props = {
  phone?: string;
  email?: string;
  telegram?: string;
};

export const ContactInfo: React.FC<Props> = ({ phone, email, telegram }) => (
  <div className="tw-flex tw-flex-col">
    {phone && <PhoneLink phone={phone} />}
    {email && <MailLink email={email} />}
    {telegram && <div>{telegram}</div>}
  </div>
);
