import React from 'react';

import { FormikPhoneInput } from 'components';
import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { getTCommon, useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();

  return (
    <FormikPhoneInput
      name={name}
      label={t('fields.phone.label')}
      fullWidth
      required={required}
      disabled={disabled}
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();

  return getBaseFieldValidator({ required }).matches(
    /^[+]?[0-9 ]+$/,
    tCommon('errors.invalid'),
  );
};
