import axios from 'axios';

import { env } from 'config';
import {
  QueryParams,
  PaginatedData,
  InternalWalletDepositTransaction,
} from 'types';

const url = `${env.apiUrl}/internal-wallets-deposit-transactions`;

const getAllActivePaginated = async (
  params?: QueryParams,
): Promise<PaginatedData<InternalWalletDepositTransaction>> =>
  (await axios.get(`${url}/active/paginated`, { params })).data;

const getAllArchivePaginated = async (
  params?: QueryParams,
): Promise<PaginatedData<InternalWalletDepositTransaction>> =>
  (await axios.get(`${url}/archive/paginated`, { params })).data;

const statusToWaiting = async (
  id: string,
): Promise<InternalWalletDepositTransaction> =>
  (await axios.post(`${url}/status-to-waiting/${id}`)).data;

const execute = async ({
  id,
  data,
}: {
  id: string;
  data: { useTrxForBandwidth: boolean; useTrxForEnergy: boolean };
}): Promise<InternalWalletDepositTransaction> =>
  (await axios.post(`${url}/execute/${id}`, data)).data;

export const internalWalletsDepositTransactionsApi = {
  getAllActivePaginated,
  getAllArchivePaginated,
  statusToWaiting,
  execute,
};
