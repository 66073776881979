import Button from '@mui/material/Button';
import { AxiosError } from 'axios';
import { Formik, Form, FormikHelpers } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction, useQuery } from 'react-query';
import * as Yup from 'yup';

import { usersApi } from 'api';
import {
  AGENTS_VALIDATION_SCHEMA,
  AgentsFormFields,
  DataWrapper,
  FormActions,
} from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { User, UserAgentInfo } from 'types';
import { validationUtils } from 'utils';

type Props = {
  user: User;
  onSubmit: UseMutateFunction<
    UserAgentInfo,
    AxiosError,
    Partial<UserAgentInfo>,
    unknown
  >;
};

export const AgentsForm: React.FC<Props> = ({ user, onSubmit }) => {
  const { t } = useTranslation(TranslationNamespace.Common);

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
  );

  const schema = useMemo(
    () =>
      Yup.object().shape({
        ...AGENTS_VALIDATION_SCHEMA,
      }),
    [],
  );

  const [initialValues, setInitialValues] = useState<UserAgentInfo>({
    agents: user?.agents || [],
  });

  const handleSubmit = useCallback(
    (
      values: UserAgentInfo,
      { setSubmitting, setErrors }: FormikHelpers<UserAgentInfo>,
    ) => {
      onSubmit(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onError: (error: AxiosError) => {
          // TODO: no error indication, see SCI-frontend for reference
          setErrors(validationUtils.getFormErrors(error));
        },
        onSuccess: (data: UserAgentInfo) => {
          setInitialValues({
            agents: data.agents || [],
          });
        },
      });
    },
    [onSubmit],
  );

  return (
    <DataWrapper queryResult={queryResultTraders}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, errors }) => (
          <Form>
            <AgentsFormFields
              agents={values.agents}
              users={queryResultTraders.data}
              currentUser={user}
              formError={errors['agents']}
              headerClassName="tw-text-xl tw-mb-4"
            />
            <FormActions>
              <Button
                type="submit"
                variant="outlined"
                disabled={isSubmitting || !isValid}
              >
                {t('buttons.save')}
              </Button>
            </FormActions>
          </Form>
        )}
      </Formik>
    </DataWrapper>
  );
};
