import React from 'react';

import { FormikTextField } from 'components';
import { FormFieldProps } from 'types';

import { useTFeature } from '../fields.utils';

export const Field: React.FC<FormFieldProps> = ({
  name,
  required = false,
  disabled = false,
}) => {
  const { t } = useTFeature();

  return (
    <FormikTextField
      name={name}
      label={t('fields.account_number.label')}
      fullWidth
      required={required}
      disabled={disabled}
    />
  );
};
