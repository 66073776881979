import { CircularProgress } from '@mui/material';
import { filter, forEach, isEmpty, map } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { internalWalletsApi } from 'api';
import { Math } from 'classes';
import {
  CopyText,
  CrudTable,
  DataGridColumnDefinition,
  Money,
  TronscanLink,
  TronTransactionStatusLabel,
  dataGridColumns,
  CrudTableActionType,
} from 'components';
import { WalletResources } from 'features/wallets';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  InternalPlatformWalletDepositTransaction,
  InternalWalletDepositTransaction,
  PaginatedData,
  WalletResourcesData,
} from 'types';
import { formatUtils } from 'utils';

import { DepositTransactionDetailsModal } from '../DepositTransactionDetailsModal';

type Props = {
  queryResult: UseQueryResult<PaginatedData<InternalWalletDepositTransaction>>;
  withResources?: boolean;
  withActions?: boolean;
};

export const DepositTransactionsTable: React.FC<Props> = ({
  queryResult,
  withResources,
  withActions,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets_transactions',
  });

  const [resources, setResources] = useState<{
    [id: string]: WalletResourcesData;
  }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const { mutate: getResources } = useMutation(
    internalWalletsApi.getResources,
    {
      onMutate: (itemId: string) => {
        setResourcesLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setResources((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setResourcesLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'execute',
    },
  );

  const getAmount = useCallback(
    (item: InternalPlatformWalletDepositTransaction) => {
      const sum = new Math();
      forEach(item.internalWalletDepositTransactions, (transaction) => {
        sum.plus(transaction.amount);
      });
      return sum.value;
    },
    [],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalPlatformWalletDepositTransaction>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.user_wallet'),
        valueGetter: (item) => (
          <div key={item.id}>
            <div>{item.userInternalWallet?.user?.name}</div>
            <CopyText truncate text={item.userInternalWallet?.address || ''} />
          </div>
        ),
      },
      {
        header: t('fields.transactions'),
        valueGetter: (item) =>
          map(item.internalWalletDepositTransactions, (transaction) => (
            <div key={transaction.id}>
              <TronscanLink hash={transaction.userInternalWalletTxID} />
            </div>
          )),
      },
      {
        header: t('fields.amount'),
        valueGetter: (item) => (
          <Money
            value={getAmount(item)}
            assetCurrencyId={item.assetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.platform_wallet'),
        valueGetter: (item) =>
          item.platformInternalWallet?.address && (
            <div className="tw-flex tw-flex-col">
              <CopyText truncate text={item.platformInternalWallet.address} />
              <TronscanLink hash={item.platformInternalWalletTxID} />
            </div>
          ),
      },
      {
        header: t('fields.energy_requests'),
        valueGetter: (item) => (
          <Fragment>
            {map(item.energyRequests, (request, i) => (
              <div
                key={i}
                className={
                  request.status ? 'tw-text-green-700' : 'tw-text-red-500'
                }
              >
                {request.amount}
              </div>
            ))}
          </Fragment>
        ),
      },
      {
        header: t('fields.status'),
        valueGetter: (item) => (
          <Fragment>
            <TronTransactionStatusLabel status={item.status} />
            {item.retryAfterDate && (
              <div className="tw-text-xs tw-text-neutral-400">
                <div>{`${t('fields.delayed')}`}</div>
                <div>{formatUtils.formatDate(item.retryAfterDate)}</div>
              </div>
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          const walletId = item.userInternalWalletId;
          if (resourcesLoading.includes(walletId)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[walletId]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(walletId)}
            />
          );
        },
        hidden: !withResources,
      },
    ],
    [getAmount, getResources, resources, resourcesLoading, withResources, t],
  );

  return (
    <CrudTable
      columns={columns}
      queryResult={queryResult}
      paginated
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: DepositTransactionDetailsModal,
        },
      ]}
      hideActions={!withActions}
    />
  );
};
