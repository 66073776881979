import i18next from 'i18next';

import { TraderWorkStatus } from 'enums';

const getWorkStatusLabel = (workStatus: TraderWorkStatus) => {
  const { t } = i18next;
  const key = `features.trader.work_status.${workStatus}`;
  return i18next.exists(key) ? t(key as any) : key;
};

export const traderUtils = {
  getWorkStatusLabel,
};
