import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard, PageHeader, StylizedNumber } from 'components';
import { TranslationNamespace } from 'i18n';
import { Trader } from 'types';

type Props = {
  trader?: Trader;
};

export const TraderInfo: React.FC<Props> = ({ trader }) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.main',
  });

  return (
    <div>
      <PageHeader title={t('title')} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <InfoCard
            title={t('compensation.title')}
            content={
              <StylizedNumber
                variant="h2"
                value={trader?.compensation}
                unit={t('compensation.unit')}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InfoCard
            title={t('payout_compensation.title')}
            content={
              <StylizedNumber
                variant="h2"
                value={trader?.payoutCompensation}
                unit={t('compensation.unit')}
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
