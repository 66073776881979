import { useQuery } from 'react-query';

import { assetsApi, shopsApi, usersApi } from 'api';
import { QueryKey } from 'enums';
import { InternalTransfersPage } from 'features/internal-transfers';

export const AdminInternalTransfersPage: React.FC = () => {
  const queryResultShops = useQuery(QueryKey.Shops, shopsApi.getAll);
  const queryResultUsers = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );
  const queryResultAssets = useQuery(QueryKey.Assets, assetsApi.getAll);

  return (
    <InternalTransfersPage
      queryResultUsers={queryResultUsers}
      queryResultShops={queryResultShops}
      queryResultAssets={queryResultAssets}
    />
  );
};
