import {
  CreditCardOff as CreditCardOffIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatusLabel } from 'components';
import { RequisitesStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: RequisitesStatus.Active | RequisitesStatus.Inactive;
} & Partial<ChipProps>;

export const RequisitesStatusLabel: React.FC<Props> = ({ status, ...rest }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'requisites_status',
  });

  return (
    <StatusLabel
      label={t(status)}
      status={status}
      icon={
        status === RequisitesStatus.Active ? (
          <CreditCardIcon />
        ) : (
          <CreditCardOffIcon />
        )
      }
      {...rest}
    />
  );
};
