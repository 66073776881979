import { internalWalletsDepositTransactionsApi } from 'api';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';

import { DepositTransactionsTable } from '../DepositTransactionsTable';

export const DepositActiveTransactions: React.FC = () => {
  const queryResult = usePartialQuery(
    QueryKey.InternalWalletTransactionsDepositActive,
    internalWalletsDepositTransactionsApi.getAllActivePaginated,
  );

  return (
    <DepositTransactionsTable
      queryResult={queryResult}
      withResources
      withActions
    />
  );
};
