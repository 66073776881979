import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { payoutOrdersApi } from 'api';
import { CloseDialogResult, Dialog, DialogProps } from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';

import { ManageReceipts } from '../ManageReceipts';

type Props = {
  order: PayoutOrder | null;
} & DialogProps;

export const ConfirmPaymentDialog: React.FC<Props> = ({
  open,
  onClose,
  order,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_payment_dialog',
  });
  const queryClient = useQueryClient();
  const { role } = useUser();

  const { mutate: confirm } = useMutation(payoutOrdersApi.confirmAsTrader);

  const queryKey = useMemo(
    () => [QueryKey.PayoutOrderReceipts, order?.id],
    [order],
  );
  const queryResultReceipts = useQuery(
    queryKey,
    () => payoutOrdersApi.getOrderReceiptsAsRole(role)(order?.id || ''),
    { enabled: open && !!order?.id },
  );

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      queryResultReceipts.remove();
      if (result.ok && order) {
        confirm(order, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.Notifications);
            queryClient.invalidateQueries(QueryKey.ActivePayoutOrders);
            onClose(result);
          },
        });
        return;
      }
      onClose(result);
    },
    [queryResultReceipts, onClose, confirm, order, queryClient],
  );

  return (
    <Dialog
      open={open}
      title={t('title')}
      okLabel={tCommon('buttons.confirm')}
      okDisabled={isEmpty(queryResultReceipts.data)}
      onClose={handleClose}
    >
      {order && (
        <ManageReceipts
          queryResultReceipts={queryResultReceipts}
          order={order}
        />
      )}
    </Dialog>
  );
};
