import { TabPanel } from 'components';
import { useQueryTab } from 'hooks';

type Props = {
  value: string;
  children: React.ReactNode;
};

export const QueryTabPanel: React.FC<Props> = ({ value, children }) => {
  const { tab } = useQueryTab();

  return (
    <TabPanel value={value} currentValue={tab}>
      {children}
    </TabPanel>
  );
};
