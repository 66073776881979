import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { User } from 'types';

import { Events } from './Events';
import { Telegram } from './Telegram';

type Props = {
  user: User;
};

export const Notifications: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.settings.notifications',
  });

  return (
    <div>
      <div className="tw-text-xl tw-mb-4">{t('title')}</div>
      <Telegram user={user} />
      <div className="tw-mt-4">
        <Events user={user} />
      </div>
    </div>
  );
};
