import { Switch } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  disabled?: boolean;
  status: RequisitesStatus.Active | RequisitesStatus.Inactive;
  updateStatus: (status: RequisitesStatus) => void;
};

export const RequisitesStatusToggle: React.FC<Props> = ({
  status,
  disabled,
  updateStatus,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });

  const isActive = useMemo(() => status === RequisitesStatus.Active, [status]);

  const changeStatusTitle = useMemo(() => {
    if (status === RequisitesStatus.Active) {
      return t('actions.deactivate');
    } else if (status === RequisitesStatus.Inactive) {
      return t('actions.activate');
    }
  }, [status, t]);

  const oppositeStatus = useMemo(() => {
    if (status === RequisitesStatus.Active) {
      return RequisitesStatus.Inactive;
    } else if (status === RequisitesStatus.Inactive) {
      return RequisitesStatus.Active;
    }
  }, [status]);

  const handleStatusChange = useCallback(() => {
    if (disabled) {
      return;
    }
    if (oppositeStatus) {
      updateStatus(oppositeStatus);
    }
  }, [disabled, oppositeStatus, updateStatus]);

  return (
    <Switch
      title={changeStatusTitle}
      checked={isActive}
      disabled={disabled}
      onChange={handleStatusChange}
    />
  );
};
