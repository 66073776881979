import { find } from 'lodash';
import React from 'react';

import { useUserContext } from 'hooks';

type Props = {
  bankId?: string;
  bankCode?: string;
  className?: string;
};

export const BankLabel: React.FC<Props> = ({ bankId, bankCode, className }) => {
  const { banks } = useUserContext();
  return (
    <span className={className}>
      {(find(banks, { id: bankId }) || find(banks, { code: bankCode }))?.name ||
        bankCode}
    </span>
  );
};
