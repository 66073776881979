import hasOwn from 'core-js/features/object/has-own';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './config/interceptors';
import './i18n';
import './index.scss';
import App from './App';
import { ThemeProvider } from './context/theme.context';
import reportWebVitals from './reportWebVitals';

// Hack to have this polyfill in project
// @see https://github.com/zloirock/core-js/issues/1114
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
hasOwn;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
