import { Fragment, ReactNode } from 'react';

import {
  CrudTable,
  CrudTableProps,
  PageHeader,
  PageHeaderProps,
  QueryFilters,
  QueryFiltersProps,
} from 'components';

type Props = {
  header?: PageHeaderProps & { hide?: boolean };
  filters?: QueryFiltersProps;
  table?: CrudTableProps;
  children?: ReactNode | ReactNode[];
};

export const CrudPage: React.FC<Props> = ({
  header,
  filters,
  table,
  children,
}) => (
  <Fragment>
    {!!header && !header.hide && <PageHeader {...header} />}
    {filters && <QueryFilters {...filters} />}
    {table && <CrudTable {...table} />}
    {children}
  </Fragment>
);
