import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  FilterDefinitionType,
  OrdersTab,
} from 'enums';
import { useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

export const CompletedOrders: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: t('filters.callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
    ],
    [t],
  );

  return (
    <ManageOrdersList
      tab={OrdersTab.Completed}
      additionalColumns={[
        AdditionalOrderColumn.CustomerPayment,
        AdditionalOrderColumn.TraderCard,
        AdditionalOrderColumn.AutomationStatus,
        AdditionalOrderColumn.OrderProcessedBy,
      ]}
      additionalFilters={additionalFilters}
    />
  );
};
