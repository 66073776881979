import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Divider, Tooltip, Typography } from '@mui/material';
import { map, sortBy } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { payoutOrdersApi } from 'api';
import { DataWrapper, DateLabel } from 'components';
import { PayoutOrderStatus, PayoutOrderStatusDetails, QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';
import { formatUtils, orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
  loading?: boolean;
};

export const PayoutOrderStatusHistory: React.FC<Props> = ({
  order,
  loading,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });

  const { role } = useUser();

  const queryKey = useMemo(
    () => [QueryKey.PayoutOrderStatusHistory, order?.id],
    [order],
  );

  const queryResult = useQuery(
    queryKey,
    () => payoutOrdersApi.getPayoutOrderStatusHistoryAsRole(role)(order.id),
    { enabled: !!order?.id },
  );

  const renderHistoryStatusItem = useCallback(
    (
      status: PayoutOrderStatus,
      statusDetails: PayoutOrderStatusDetails,
      statusDate: Date,
      traderName?: string,
    ) => (
      <div className="tw-my-1">
        <div className="tw-flex">
          <Typography color="textSecondary">
            {orderUtils.getStatusLabel(status)}
          </Typography>
          {(traderName || statusDetails) && (
            <Tooltip
              title={
                <div>
                  {traderName && <div className="tw-text-xs">{traderName}</div>}
                  {statusDetails && (
                    <div className="tw-text-xs">
                      {orderUtils.getPayoutStatusDetailsLabel(statusDetails)}
                    </div>
                  )}
                </div>
              }
            >
              <InfoOutlinedIcon
                sx={{ ml: 1 }}
                fontSize="small"
                color="primary"
              />
            </Tooltip>
          )}
        </div>
        <DateLabel>{formatUtils.formatDate(statusDate)}</DateLabel>
      </div>
    ),
    [],
  );

  return (
    <Fragment>
      <Divider textAlign="left">{t('status_history')}</Divider>
      <DataWrapper isLoading={loading || queryResult.isLoading}>
        <div className="tw-flex tw-flex-wrap">
          {map(
            sortBy(queryResult.data, (s) => s.statusAt),
            (orderStatus, index) => (
              <div key={index} className="tw-flex tw-align-middle">
                {renderHistoryStatusItem(
                  orderStatus.status,
                  orderStatus.statusDetails,
                  orderStatus.statusAt,
                  orderStatus.trader?.user?.name,
                )}
                <div className="tw-mx-2 tw-self-center">{'→'}</div>
              </div>
            ),
          )}
          {renderHistoryStatusItem(
            order.status,
            order.statusDetails,
            order.statusAt,
          )}
        </div>
      </DataWrapper>
    </Fragment>
  );
};
