import React from 'react';

import { fiatCurrencyApi } from 'api';
import { QueryKey } from 'enums';

import { CurrenciesTable } from '../CurrenciesTable';

export const FiatCurrencies: React.FC = () => (
  <CurrenciesTable api={fiatCurrencyApi} queryKey={QueryKey.FiatCurrencies} />
);
