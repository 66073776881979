import axios from 'axios';

import { env } from 'config';
import { OrderType } from 'enums';
import { Group, PaginatedData, QueryParams } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/groups`;

const getForOrderTypePaginated =
  (orderType: OrderType) =>
  async (params?: QueryParams): Promise<PaginatedData<Group>> =>
    (await axios.get(`${url}/${orderType}/paginated`, { params })).data;

export const groupsApi = {
  ...createCrudApi<Group>('/groups'),
  getForOrderTypePaginated,
};
