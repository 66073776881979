import { Fab } from '@mui/material';
import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  ContactInfo,
  CopyText,
  CrudPage,
  DataGridColumnDefinition,
  Money,
} from 'components';
import { AssetsTabs, AssetType, FilterDefinitionType, UserRole } from 'enums';
import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  Asset,
  AssetsFilters,
  FilterDefinition,
  P2PProvider,
  Shop,
  Trader,
} from 'types';
import { formatUtils, formUtils } from 'utils';

type Props = {
  tab: AssetsTabs;
  traders?: Trader[];
  shops?: Shop[];
  p2pProviders?: P2PProvider[];
  queryResult: UseQueryResult<Asset[]>;
  onFundsRequest?: (asset: Asset) => void;
  onExchange?: (asset: Asset) => void;
};

export const AssetsTable: React.FC<Props> = ({
  tab,
  shops,
  traders,
  p2pProviders,
  queryResult,
  onFundsRequest,
  onExchange,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'assets.columns',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t: tFunds } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });
  const { defaultAssetCurrency } = useCurrencies();

  const renderShopInfo = useCallback(
    (shopName?: string, website?: string) => (
      <div>
        <div>{shopName}</div>
        <div>{website}</div>
      </div>
    ),
    [],
  );

  const getInsuranceDepositAmount = useCallback(
    (userId: string) => {
      const trader = find(traders, { userId });
      return trader?.insuranceDepositAmount || 0;
    },
    [traders],
  );

  const renderP2PProviderInfo = useCallback(
    (asset: Asset) => {
      if (!asset.p2pProviderId) {
        return null;
      }
      const provider = find(
        p2pProviders,
        (provider) => provider.id === asset.p2pProviderId,
      );
      return formatUtils.formatName(provider);
    },
    [p2pProviders],
  );

  const renderAmount = useCallback(
    (asset: Asset) => {
      if (
        tab === AssetsTabs.TradersBalance &&
        asset.user.role === UserRole.Trader &&
        asset.type === AssetType.InsuranceDeposit
      ) {
        const insuranceDepositAmount = getInsuranceDepositAmount(asset.userId);
        return (
          <div className="tw-flex">
            <Money value={asset.balance} />
            <div>{'/'}</div>
            <Money
              value={insuranceDepositAmount}
              symbol
              assetCurrencyId={asset.assetCurrencyId}
            />
          </div>
        );
      }

      return (
        <Money
          value={asset.balance}
          symbol
          assetCurrencyId={asset.assetCurrencyId}
        />
      );
    },
    [tab, getInsuranceDepositAmount],
  );

  const userColumnHeader = useMemo(() => {
    if (tab === AssetsTabs.TradersBalance) {
      return t('trader');
    }
    if (tab === AssetsTabs.MerchantsBalance) {
      return t('merchant');
    }

    return null;
  }, [tab, t]);

  const columns = useMemo(
    (): DataGridColumnDefinition<Asset>[] => [
      {
        header: t('id'),
        valueGetter: (item) => <CopyText text={item.id} iconOnly />,
      },
      {
        header: userColumnHeader || '',
        valueGetter: (item) => (
          <div>
            <span>{item?.user?.name}</span>
            <ContactInfo email={item?.user?.email} />
          </div>
        ),
        hidden: !userColumnHeader,
      },
      {
        header: t('shop_id'),
        valueGetter: (item) =>
          renderShopInfo(item?.shop?.name, item?.shop?.webSiteUrl),
        hidden: [
          AssetsTabs.PlatformBalance,
          AssetsTabs.TradersBalance,
          AssetsTabs.P2PProvidersBalance,
        ].includes(tab),
      },
      {
        header: t('p2p_provider'),
        valueGetter: renderP2PProviderInfo,
        hidden: tab !== AssetsTabs.P2PProvidersBalance,
      },
      {
        header: t('balance'),
        valueGetter: renderAmount,
      },
      {
        header: t('hold_balance'),
        valueGetter: (item) => (
          <Money
            value={item.holdBalance}
            symbol
            assetCurrencyId={item.assetCurrencyId}
          />
        ),
      },
      {
        header: t('type'),
        valueGetter: (item) => <div>{tCommon(`assets.type.${item.type}`)}</div>,
        hidden: tab !== AssetsTabs.TradersBalance,
      },
      {
        header: tCommon('columns.actions'),
        valueGetter: (item) => (
          <Fragment>
            {onFundsRequest &&
              item.assetCurrencyId === defaultAssetCurrency?.id && (
                <Fab
                  variant="extended"
                  size="small"
                  disabled={!item.balance}
                  onClick={() => onFundsRequest?.(item)}
                >
                  {tFunds('buttons.withdrawal')}
                </Fab>
              )}
            {onExchange &&
              item.assetCurrencyId !== defaultAssetCurrency?.id && (
                <Fab
                  variant="extended"
                  size="small"
                  disabled={!item.balance}
                  onClick={() => onExchange?.(item)}
                >
                  {tFunds('buttons.exchange')}
                </Fab>
              )}
          </Fragment>
        ),
        hidden: !onFundsRequest && !onExchange,
      },
    ],
    [
      t,
      userColumnHeader,
      tab,
      defaultAssetCurrency,
      tCommon,
      renderAmount,
      onFundsRequest,
      renderShopInfo,
      renderP2PProviderInfo,
      tFunds,
      onExchange,
    ],
  );

  const filtersDefinitions: FilterDefinition<AssetsFilters>[] = useMemo(
    () => [
      {
        label: t('id'),
        name: 'id',
        format: 'uuid',
        type: FilterDefinitionType.Text,
        hidden: tab === AssetsTabs.PlatformBalance,
      },
      {
        label: t('shop_id'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops,
        getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
        hidden: [
          AssetsTabs.PlatformBalance,
          AssetsTabs.TradersBalance,
          AssetsTabs.P2PProvidersBalance,
        ].includes(tab),
      },
      {
        label: t('p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        getDisplayName: (id: string) => find(p2pProviders, { id })?.name,
        options: formUtils.getOptions(p2pProviders || [], 'name', 'id'),
        hidden: tab !== AssetsTabs.P2PProvidersBalance,
      },
    ],
    [tab, shops, p2pProviders, t],
  );

  return (
    <CrudPage
      filters={{ filtersDefinitions }}
      table={{ queryResult, columns }}
    />
  );
};
