import { isNumber, keys, map } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';
import { AreaProps } from 'recharts';

import { AreaChart } from 'components';
import { TranslationNamespace } from 'i18n';
import { SummaryStatistic } from 'types';

type Props = {
  queryResult: UseQueryResult<Record<string, SummaryStatistic>, unknown>;
};

export const Charts: React.FC<Props> = ({ queryResult }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.statistics.summary_daily',
  });

  const { hasTraderStatistic, hasPlatformStatistic } = useMemo(() => {
    const day = keys(queryResult?.data)[0];
    return {
      hasTraderStatistic: isNumber(
        queryResult?.data?.[day]?.traderCompensation,
      ),
      hasPlatformStatistic: isNumber(
        queryResult?.data?.[day]?.platformCompensation,
      ),
    };
  }, [queryResult?.data]);

  const charts = useMemo(() => {
    const result: {
      title: string;
      chart: { areas: AreaProps[] };
    }[] = [
      {
        title: t('fields.orders'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'totalCount',
              fill: '#fbd1d3',
              fillOpacity: 1,
              name: t('fields.total_count'),
            },
            {
              type: 'linear',
              dataKey: 'completedCount',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.completed_count'),
            },
          ],
        },
      },
      {
        title: t('fields.total_amount'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'totalAmount',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.total_amount'),
            },
          ],
        },
      },
      {
        title: t('fields.avg_amount'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'avgAmount',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.avg_amount'),
            },
          ],
        },
      },
      {
        title: t('fields.conversion'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'conversion',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.conversion'),
            },
          ],
        },
      },
    ];

    if (hasPlatformStatistic) {
      result.push({
        title: t('fields.platform_cashback'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'platformCompensation',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.platform_cashback'),
            },
          ],
        },
      });
    }

    if (hasTraderStatistic) {
      result.push({
        title: t('fields.cashback'),
        chart: {
          areas: [
            {
              type: 'linear',
              dataKey: 'traderCompensation',
              fill: '#c0d9c3',
              fillOpacity: 1,
              name: t('fields.cashback'),
            },
          ],
        },
      });
    }

    return result;
  }, [hasPlatformStatistic, hasTraderStatistic, t]);

  return (
    <Fragment>
      {map(charts, (chart, i) => (
        <AreaChart
          key={chart.title}
          className={i === charts.length - 1 ? undefined : 'tw-mb-8'}
          data={queryResult.data || {}}
          areas={chart.chart.areas}
          title={chart.title}
        />
      ))}
    </Fragment>
  );
};
