import { Form, Formik, FormikHelpers } from 'formik';
import { assign, keys, map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikSelect,
  FormikTextField,
} from 'components';
import { UnitPosition } from 'enums';
import { TranslationNamespace } from 'i18n';
import { CurrencyDto } from 'types';
import { currenciesUtils } from 'utils';

type Values = CurrencyDto;

type Props = DialogProps<CloseFormikDialogResult<Values>>;

export const CurrencyDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.currencies',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const title = useMemo(
    () => (data ? t('details.edit_default') : t('details.create_default')),
    [t, data],
  );

  const getInitialValues = useCallback(
    () => ({
      code: '',
      symbol: '',
      symbolPosition: UnitPosition.After,
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required(tCommon('errors.required')),
        symbol: Yup.string().required(tCommon('errors.required')),
        symbolPosition: Yup.mixed<UnitPosition>()
          .oneOf(Object.values(UnitPosition))
          .required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const symbolPositionOptions = useMemo(
    () =>
      map(UnitPosition, (value) => ({
        value,
        label: currenciesUtils.getCurrencySymbolPositionLabel(value),
      })),
    [],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result?.data?.formikHelpers.resetForm();
      }
      onClose(result);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  useEffect(() => {
    const _initialValues = getInitialValues();
    setInitialValues(assign(_initialValues, pick(data, keys(_initialValues))));
  }, [data, getInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
          {...rest}
        >
          <Form>
            <FormControls>
              <FormikTextField label={t('fields.code')} name="code" required />
              <FormikTextField
                label={t('fields.symbol')}
                name="symbol"
                required
              />
              <FormikSelect
                label={t('fields.symbolPosition')}
                name="symbolPosition"
                options={symbolPositionOptions}
              />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
