import { find } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { usersApi, usersBalanceReconciliationApi } from 'api';
import {
  CrudPage,
  DataGridColumnDefinition,
  StylizedMoney,
  dataGridColumns,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useCurrencies, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, UserBalanceReconciliation } from 'types';

type UsersFilters = {
  userId: string;
};

export const UsersTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.balance_reconciliation',
  });

  const { role } = useUser();

  const { getDefaultAssetCurrency } = useCurrencies();

  const queryResultUsers = useQuery(QueryKey.Users, usersApi.getAssetHolders);

  const defaultAssetCurrencyId = useMemo(
    () => getDefaultAssetCurrency()?.id,
    [getDefaultAssetCurrency],
  );

  const queryResult = usePartialQuery(
    QueryKey.BalanceReconciliationUsers,
    usersBalanceReconciliationApi.getAllPaginatedAsRole(role),
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<UserBalanceReconciliation>[] => [
      dataGridColumns.getIdColumn({ dateKeyOverride: 'date' }),
      {
        header: t('fields.user'),
        valueGetter: (item) => item.user?.name,
      },
      {
        header: t('fields.assets_balance'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.assetsBalance}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.wallets_balance'),
        valueGetter: (item) => (
          <StylizedMoney
            value={item.walletsBalance}
            assetCurrencyId={defaultAssetCurrencyId}
            symbol
          />
        ),
      },
    ],
    [t, defaultAssetCurrencyId],
  );

  const filtersDefinitions: FilterDefinition<UsersFilters>[] = useMemo(
    () => [
      {
        label: t('fields.user'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultUsers.data,
        getDisplayName: (userId: string) =>
          find(queryResultUsers.data, { id: userId })?.name,
      },
    ],
    [t, queryResultUsers],
  );

  return (
    <CrudPage
      table={{ queryResult, columns, paginated: true }}
      filters={{ filtersDefinitions }}
    />
  );
};
