import { FormControlLabel } from '@mui/material';
import { Field, FieldArray, Formik, FormikHelpers } from 'formik';
import { Checkbox } from 'formik-mui';
import { map } from 'lodash';
import React, { useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CloseFormikDialogResult, Dialog, DialogProps } from 'components';
import { TranslationNamespace } from 'i18n';

type Values = { columns: string[] };

type Props = {
  columns: string[];
  isLoading: boolean;
  labelGetter: (item: string) => string;
} & DialogProps;

export const ExportDataDialog: React.FC<Props> = ({
  open,
  columns,
  isLoading,
  labelGetter,
  onClose,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const validationSchema = Yup.object().shape({
    columns: Yup.array().min(1, tCommon('errors.required')),
  });

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result?.data?.formikHelpers.resetForm();
      }
      onClose(result);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  return (
    <Formik
      initialValues={{ columns } as Values}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={tCommon('components.export_data.title')}
          data={{ values: formik.values, formikHelpers: formik }}
          okDisabled={!formik.isValid || isLoading}
          onClose={onClose}
        >
          <Fragment>
            <div className="tw-font-bold tw-mb-4">{`${tCommon(
              'components.export_data.selected_count',
            )}: ${formik.values.columns.length}`}</div>
            <FieldArray name={'columns'}>
              {() =>
                map(columns, (item, i) => (
                  <FormControlLabel
                    key={item}
                    sx={{ display: 'flex' }}
                    control={
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        name={'columns'}
                        checked={formik.values.columns.includes(item)}
                        disabled={isLoading}
                        value={item}
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                      />
                    }
                    label={labelGetter(item)}
                  />
                ))
              }
            </FieldArray>
          </Fragment>
        </Dialog>
      )}
    </Formik>
  );
};
