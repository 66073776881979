import i18next from 'i18next';

import { AutomationSourceType } from 'enums';

const getTypeLabel = (type: AutomationSourceType) => {
  const { t } = i18next;
  const key = `automation_source_type.${type}`;
  return t(key as any, key);
};

export const automationSourcesUtils = {
  getTypeLabel,
};
