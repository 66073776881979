import { filter, map, transform } from 'lodash';
import { useMemo } from 'react';

import { Math } from 'classes';
import { useCurrencies } from 'hooks';
import { Asset, Currency } from 'types';

export const useCalculateAssetBalances = (assets: Asset[]) => {
  const { assetCurrencies } = useCurrencies();
  const balances = useMemo(
    () =>
      !assets
        ? {}
        : transform<Currency, { [currency: string]: number }>(
            assetCurrencies,
            (totalMap, currency) => {
              const values = map(
                filter(assets, { assetCurrencyId: currency.id }),
                (asset) => asset.balance + asset.holdBalance,
              );
              totalMap[currency.id] = new Math(totalMap[currency.id] || 0).plus(
                ...values,
              ).value;
            },
            {},
          ),
    [assetCurrencies, assets],
  );

  return { balances };
};
