import {
  Home as HomeIcon,
  Assignment as AssignmentIcon,
  CreditCard as CreditCardIcon,
  BarChart as BarChartIcon,
  Archive as ArchiveIcon,
  AutoMode as AutoModeIcon,
} from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LayoutMenuSection } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { TranslationNamespace } from 'i18n';
import { UserLayout } from 'layouts/User';

type Props = {
  children: React.ReactElement;
};

export const OperatorLayout: React.FC<Props> = ({ children }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Operator, {
    keyPrefix: 'layout',
  });
  const menuSections: LayoutMenuSection[] = useMemo(
    () => [
      {
        title: t('menu_sections.main'),
        items: [
          {
            title: t('menu_items.main'),
            MuiIcon: HomeIcon,
            link: ROUTE_PATH.OPERATOR.MAIN,
          },
          {
            title: t('menu_items.assets'),
            MuiIcon: AssignmentIcon,
            link: ROUTE_PATH.OPERATOR.ASSETS,
          },
          {
            title: t('menu_items.orders'),
            MuiIcon: AssignmentIcon,
            link: ROUTE_PATH.OPERATOR.ORDERS,
          },
          {
            title: t('menu_items.traders'),
            MuiIcon: BarChartIcon,
            link: ROUTE_PATH.OPERATOR.TRADERS,
          },
          {
            title: t('menu_items.requisites'),
            MuiIcon: CreditCardIcon,
            link: ROUTE_PATH.OPERATOR.PAYIN_REQUISITES,
          },
          {
            title: t('menu_items.order_automation'),
            MuiIcon: AutoModeIcon,
            link: ROUTE_PATH.OPERATOR.ORDER_AUTOMATION,
          },
          {
            title: t('menu_items.archive'),
            MuiIcon: ArchiveIcon,
            link: ROUTE_PATH.OPERATOR.ARCHIVE,
          },
        ],
      },
    ],
    [t],
  );
  return <UserLayout menuSections={menuSections} children={children} />;
};
