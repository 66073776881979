import styled from '@emotion/styled';

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(2, 4, 0, 4)};
  background-color: ${(props) => props.theme.palette.background.paper};
  position: sticky;
  top: ${(props) => props.theme.mixins.toolbar.minHeight}px;
  min-height: ${(props) => props.theme.mixins.toolbar.minHeight}px;
  z-index: 1;

  ${(props) => props.theme.breakpoints.up('sm')} {
    top: ${(props) =>
      props.theme.mixins.toolbar[props.theme.breakpoints.up('sm')].minHeight}px;
    min-height: ${(props) =>
      props.theme.mixins.toolbar[props.theme.breakpoints.up('sm')].minHeight}px;
  }
`;
