import { find } from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';

import { payoutOrdersApi } from 'api';
import { PaginationWrapper } from 'components';
import { TIME_INTERVAL } from 'constants/time-interval.constants';
import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { usePartialQuery, useQueryFilters, useUser } from 'hooks';
import { FilterDefinition, PayoutOrder, PayoutOrderFilters } from 'types';
import { orderUtils } from 'utils';

import { OrderListWithAction } from '../OrderList';
import { OrdersActions } from '../OrdersActions';

type Props = {
  tab: OrdersTab;
  additionalColumns?: AdditionalOrderColumn[];
  additionalFilters?: FilterDefinition[];
};

export const Orders: React.FC<Props> = ({
  tab,
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const { role } = useUser();
  const { filters } = useQueryFilters<PayoutOrderFilters>();
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const refetch = useMemo(() => tab === OrdersTab.Active, [tab]);

  const queryResult = usePartialQuery(
    `${tab}-payout-orders`,
    payoutOrdersApi.getAllForRole(role, tab),
    {
      useCachedCount: true,
      ...(refetch && {
        refetchInterval: TIME_INTERVAL.FIFTEEN_SECONDS,
        refetchIntervalInBackground: true,
      }),
    },
  );

  const selectedOrder: PayoutOrder | null = useMemo(
    () =>
      find(queryResult.data?.items, (order) => order.id === selectedOrderId) ||
      null,
    [selectedOrderId, queryResult.data],
  );

  return (
    <Fragment>
      <OrdersActions
        tab={tab}
        queryResult={queryResult}
        filtersDefinitions={[
          ...orderUtils.getCommonPayoutOrderFilters(filters),
          ...additionalFilters,
        ]}
      />
      <PaginationWrapper
        queryResult={queryResult}
        ignoreState={{ refetchLoading: refetch }}
      >
        <OrderListWithAction
          selectedOrder={selectedOrder}
          items={queryResult.data?.items || []}
          handleOrderSelection={setSelectedOrderId}
          columns={orderUtils.getPayoutOrderColumns(additionalColumns, role)}
        />
      </PaginationWrapper>
    </Fragment>
  );
};
