import { isEmpty } from 'lodash';
import React from 'react';

import { PayoutOrder } from 'types';

import { PayoutOrderReceiptsValidation } from '../../ReceiptsValidation';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderValidationColumn: React.FC<Props> = ({ order }) => (
  <div>
    {!isEmpty(order.receiptsValidation) && (
      <PayoutOrderReceiptsValidation order={order} />
    )}
  </div>
);
