import styled from '@emotion/styled';
import {
  Divider as MuiDivider,
  Grid,
  Button,
  Typography,
  TypographyProps,
} from '@mui/material';
import { spacing } from '@mui/system';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { TranslationNamespace } from 'i18n';

const Divider = styled(MuiDivider)(spacing);

type Props = {
  title: string | ReactElement;
  titleProps?: TypographyProps;
  subtitle?: string | ReactElement;
  subcontent?: string | ReactElement;
  rightContent?: ReactNode;
  rightContentButton?:
    | {
        title?: string;
        onClick: React.MouseEventHandler<HTMLButtonElement>;
      }
    | false;
  divider?: boolean;
};

export type PageHeaderProps = Props;

export const PageHeader: React.FC<Props> = ({
  title,
  titleProps,
  subtitle,
  subcontent,
  rightContent,
  rightContentButton,
  divider = true,
}: Props) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const outletContext = useOutletContext<{
    headerSubcontent: ReactElement;
  }>();

  return (
    <Fragment>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom {...titleProps}>
            {title}
          </Typography>
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
          {(subcontent || outletContext?.headerSubcontent) && (
            <div className="tw-mt-6 tw-mb-2">
              {subcontent || outletContext?.headerSubcontent}
            </div>
          )}
        </Grid>

        <Grid item>
          {rightContent}
          {!rightContent && rightContentButton && (
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={rightContentButton.onClick}
            >
              {rightContentButton.title || tCommon('buttons.create')}
            </Button>
          )}
        </Grid>
      </Grid>

      {divider && <Divider my={6} />}
    </Fragment>
  );
};
