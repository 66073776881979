import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { tradersApi } from 'api';
import { DataWrapper, TraderWorkStatusSelect } from 'components';
import { QueryKey, TraderWorkStatus } from 'enums';
import { User } from 'types';

import { UserDropdown } from '../UserDropdown';

interface Props {
  user: User;
  onLogout: () => void;
}

export const TraderDropdown: FC<Props> = ({ user, onLogout }) => {
  const queryResult = useQuery(QueryKey.MyInfo, tradersApi.getMyInfo);
  const { data } = queryResult;

  const active = useMemo(
    () => data?.workStatus === TraderWorkStatus.Active,
    [data?.workStatus],
  );

  return (
    <Stack direction="row" spacing={3}>
      <DataWrapper queryResult={queryResult}>
        <TraderWorkStatusSelect trader={data} />
      </DataWrapper>

      <UserDropdown user={user} onLogout={onLogout} active={active} />
    </Stack>
  );
};
