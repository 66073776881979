import { Stack, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CopyText,
  DescriptionRow,
  StylizedMoney,
  StylizedNumber,
} from 'components';
import { useCurrencies, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider } from 'types';
import { formatUtils, tradeMethodsUtils } from 'utils';

type Props = {
  p2pProvider: P2PProvider;
};

export const P2PProviderDetails: React.FC<Props> = ({ p2pProvider }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const {
    getDefaultAssetCurrency,
    getAssetCurrencyCode,
    getFiatCurrencyCode,
    fiatCurrencies,
  } = useCurrencies();
  const { banks, tradeMethods, paymentTypes } = useUserContext();

  return (
    <Stack direction="column" spacing={4}>
      <DescriptionRow title={t('details.id')} value={p2pProvider.id} canCopy />
      <DescriptionRow title={t('details.name')} value={p2pProvider.name} />
      <DescriptionRow
        title={t('details.type')}
        value={t(`types.${p2pProvider.type}`)}
      />
      <DescriptionRow
        title={t('details.assetCurrencyId')}
        value={getAssetCurrencyCode(p2pProvider.assetCurrencyId)}
      />
      <DescriptionRow
        title={t('details.fiatCurrencyId')}
        value={getFiatCurrencyCode(p2pProvider.fiatCurrencyId)}
      />
      <Typography variant="h6">{t('details.payin_details')}</Typography>
      <DescriptionRow
        title={t('details.status.title')}
        value={
          p2pProvider.payinConfig?.enabled
            ? t('details.status.enabled')
            : t('details.status.disabled')
        }
      />
      <DescriptionRow
        title={t('details.url')}
        value={p2pProvider.payinConfig?.url}
        canCopy
      />
      <DescriptionRow
        title={t('details.api_key')}
        value={p2pProvider.payinConfig?.credentials?.apiKey}
        canCopy
      />
      <DescriptionRow
        title={t('details.signature_key')}
        value={p2pProvider.payinConfig?.credentials?.signatureKey}
        canCopy
      />
      <DescriptionRow
        title={t('details.response_timeout')}
        description={t('details.response_timeout_description')}
        value={
          p2pProvider.payinConfig?.responseTimeout
            ? `${formatUtils.formatNumber(
                p2pProvider.payinConfig?.responseTimeout,
              )}${tCommon('suffixes.ms')}`
            : ''
        }
      />
      <DescriptionRow
        title={t('details.expected_fee')}
        value={
          isNumber(p2pProvider.payinConfig?.expectedFee) && (
            <StylizedNumber
              value={p2pProvider.payinConfig?.expectedFee}
              unit="%"
            />
          )
        }
      />
      <DescriptionRow
        title={t('details.withdrawal_fee')}
        value={
          isNumber(p2pProvider.withdrawalFee) && (
            <StylizedMoney
              value={p2pProvider.withdrawalFee}
              assetCurrencyId={getDefaultAssetCurrency()?.id}
              symbol
            />
          )
        }
      />
      {p2pProvider.payinConfig?.lastTakenOrderAt && (
        <DescriptionRow
          title={t('details.last_taken_order_at')}
          value={formatUtils.formatDate(
            p2pProvider.payinConfig?.lastTakenOrderAt,
          )}
        />
      )}
      <DescriptionRow
        title={t('details.trade_methods')}
        value={
          <Stack direction="column" spacing={2}>
            {p2pProvider.payinConfig?.tradeMethods?.map(({ id }) => (
              <Stack key={id} direction="column" spacing={0}>
                <CopyText text={id} />
                <div>
                  {tradeMethodsUtils.getTradeMethodLabel({
                    tradeMethodId: id,
                    banks,
                    tradeMethods,
                    paymentTypes,
                    fiatCurrencies,
                  })}
                </div>
              </Stack>
            ))}
          </Stack>
        }
      />
    </Stack>
  );
};
