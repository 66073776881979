import { Button } from '@mui/material';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

type ToggleButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ isExpanded, onClick }) => {
  const { t } = useTranslation(TranslationNamespace.Common);

  return (
    <div className="tw-flex tw-justify-center mt-2">
      <Button onClick={onClick}>
        {isExpanded ? t('buttons.collapse') : t('buttons.expand')}
      </Button>
    </div>
  );
};

type Props = {
  text: string;
  maxLines?: number;
  maxLength?: number;
  indent?: number;
};

const MAX_LINES = 5;
const MAX_LENGTH = 100;
const DEFAULT_INDENT = 2;

export const ExpandedJson: React.FC<Props> = ({
  text,
  maxLines = MAX_LINES,
  maxLength = MAX_LENGTH,
  indent = DEFAULT_INDENT,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const renderToggleButton = useCallback(
    () => (
      <ToggleButton
        isExpanded={isExpanded}
        onClick={toggleExpanded}
      ></ToggleButton>
    ),
    [isExpanded, toggleExpanded],
  );

  const { parsedText, lines, isJson } = useMemo(() => {
    try {
      const parsedText = JSON.stringify(JSON.parse(text), null, indent);
      const lines = parsedText.split('\n');
      return { parsedText, lines, isJson: true };
    } catch {
      return { parsedText: text, lines: [], isJson: false };
    }
  }, [text, indent]);

  const displayJson = useMemo(
    () =>
      !isExpanded && lines.length > maxLines
        ? lines.slice(0, maxLines).join('\n') +
          '\n' +
          ' '.repeat(indent) +
          '...\n}'
        : parsedText,
    [isExpanded, parsedText, lines, maxLines, indent],
  );

  if (!text) {
    return null;
  }
  if (isJson) {
    return (
      <div>
        <pre>{displayJson}</pre>
        {lines.length > maxLines && renderToggleButton()}
      </div>
    );
  }
  return (
    <div>
      <p className={cx({ 'tw-line-clamp-3': isExpanded })}>{parsedText}</p>
      {parsedText.length > maxLength && renderToggleButton()}
    </div>
  );
};
