import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { anomaliesApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Anomaly } from 'types';
import { formatUtils } from 'utils';

import { AnomaliesTable } from '../AnomaliesTable';

export const ReviewedAnomalies: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.anomalies',
  });
  const queryResult = usePartialQuery(
    QueryKey.ReviewedAnomalies,
    anomaliesApi.getAllReviewed,
  );

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<Anomaly>[] => [
      {
        header: t('fields.reviewed'),
        valueGetter: (item) =>
          item.reviewer && (
            <div>
              <div className="tw-mb-1">{item.reviewer?.name}</div>
              <div>{formatUtils.formatDate(item.reviewedAt)}</div>
            </div>
          ),
      },
    ],
    [t],
  );

  return (
    <AnomaliesTable
      queryResult={queryResult}
      additionalColumns={additionalColumns}
    />
  );
};
