import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavigateListener } from 'components';
import { useTheme } from 'hooks';

import { AppRoutes } from './AppRoutes';
import './App.scss';
import createTheme from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { theme } = useTheme();

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <NavigateListener />
            <AppRoutes />
            <ToastContainer
              position="top-center"
              hideProgressBar
              autoClose={3000}
            />
          </BrowserRouter>
        </LocalizationProvider>
      </QueryClientProvider>
    </MuiThemeProvider>
  );
}

export default App;
