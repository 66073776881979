import { Shadows } from '@mui/material/styles/shadows';
import { fill } from 'lodash';

const createShadow = () => `0px 1px 2px 0px rgba(0, 0, 0, 0.05)`;

const SHADOW = createShadow();

const shadows = ['none', ...fill(Array(24), SHADOW)] as Shadows;

export default shadows;
