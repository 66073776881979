import React, { useEffect } from 'react';

import { storageUtils } from 'utils';

import { DEFAULT_LIGHT_THEME } from '../config/themes';

const THEME_KEY = 'spay-theme';

const initialState = {
  theme: DEFAULT_LIGHT_THEME,
  setTheme: (theme: string) => {},
};

const ThemeContext = React.createContext(initialState);

type ThemeProviderProps = {
  children: React.ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, _setTheme] = React.useState<string>(initialState.theme);

  useEffect(() => {
    const storedTheme = storageUtils.getItem(THEME_KEY);

    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (theme: string) => {
    storageUtils.setItem(THEME_KEY, JSON.stringify(theme));
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
