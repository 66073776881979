import { TypographyProps } from '@mui/material';
import i18next from 'i18next';
import moment from 'moment';

import { CurrencyExchangeSource, FiatCurrencyExchangeSource } from 'enums';

const ALERT_DATE_AFTER_MINUTES = 5;

const getCurrencyExchangeSourceLabel = (source: CurrencyExchangeSource) => {
  const { t } = i18next;
  const key = `currency_exchange_source.${source}`;
  return i18next.exists(key) ? t(key as any) : source;
};

const getFiatCurrencyExchangeSourceLabel = (
  source: FiatCurrencyExchangeSource,
) => {
  const { t } = i18next;
  const key = `fiat_currency_exchange_source.${source}`;
  return i18next.exists(key) ? t(key as any) : source;
};

const getDateColor = (
  date: string,
  item: { refresh: boolean },
  dataUpdatedAt: string | number,
): TypographyProps['color'] => {
  if (
    item.refresh &&
    (!date ||
      moment(date)
        .add(ALERT_DATE_AFTER_MINUTES, 'minutes')
        .isBefore(dataUpdatedAt))
  ) {
    return 'red';
  }
  return 'textSecondary';
};

export const currencyExchangeUtils = {
  getCurrencyExchangeSourceLabel,
  getFiatCurrencyExchangeSourceLabel,
  getDateColor,
};
