import { Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { anomaliesApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import { QueryKey } from 'enums';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Anomaly } from 'types';

import { AnomaliesTable } from '../AnomaliesTable';

export const NewAnomalies: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.anomalies',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();

  const queryResult = usePartialQuery(
    QueryKey.NewAnomalies,
    anomaliesApi.getAllNew,
  );

  const { mutate: review } = useMutation(anomaliesApi.review, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.NewAnomalies);
      queryClient.invalidateQueries(QueryKey.ReviewedAnomalies);
    },
    notifierType: 'save',
  });

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<Anomaly>[] => [
      {
        header: tCommon('columns.actions'),
        valueGetter: (item) => (
          <Button variant="outlined" onClick={() => review(item.id)}>
            {t('buttons.reviewed')}
          </Button>
        ),
      },
    ],
    [t, tCommon, review],
  );

  return (
    <AnomaliesTable
      queryResult={queryResult}
      additionalColumns={additionalColumns}
    />
  );
};
