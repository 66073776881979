import { Button, Divider, Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { map } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { parsingMethodsApi } from 'api';
import {
  FormActions,
  FormControls,
  FormikSelect,
  FormikTextField,
} from 'components';
import { ParsingMethodType } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { ParsingMethod, CreateParsingMethodDto, TradeMethod } from 'types';
import { validationUtils } from 'utils';

import { ParsingMethodsTestForm } from './ParsingMethodsTestForm';

type Values = CreateParsingMethodDto;

type Props = {
  tradeMethod: TradeMethod | undefined;
  selectedParsingMethod?: ParsingMethod | null;
  withDescription?: boolean;
  tab: ParsingMethodType;
  onSuccess?: (values: ParsingMethod) => void;
};

export const ParsingMethodsForm: React.FC<Props> = ({
  tradeMethod,
  selectedParsingMethod = null,
  tab,
  onSuccess,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const options = map(ParsingMethodType, (type) => ({
    value: type,
    label: t(`types.${type}`),
  }));

  const initialValues: Values = useMemo(
    () => ({
      template: selectedParsingMethod?.template ?? '',
      tradeMethodId: tradeMethod?.id ?? '',
      type: selectedParsingMethod?.type ?? tab,
      description: selectedParsingMethod?.description ?? '',
    }),
    [selectedParsingMethod, tradeMethod, tab],
  );

  const prepareParsingMethod = useCallback((values: Values) => {
    if (values.type !== ParsingMethodType.Technical) {
      values.description = null;
    }

    return values;
  }, []);

  const createParsingMethod = useMutation<ParsingMethod, AxiosError, Values>(
    (parsingMethod) =>
      parsingMethodsApi.create(prepareParsingMethod(parsingMethod)),
  );

  const updateParsingMethod = useMutation<ParsingMethod, AxiosError, Values>(
    (parsingMethod) =>
      parsingMethodsApi.update({
        id: selectedParsingMethod!.id,
        data: prepareParsingMethod(parsingMethod),
      }),
  );

  const doSubmit = useMemo(() => {
    if (selectedParsingMethod) {
      return updateParsingMethod;
    }

    return createParsingMethod;
  }, [selectedParsingMethod, createParsingMethod, updateParsingMethod]);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<any>) => {
      doSubmit.mutate(values, {
        onSuccess: (values: ParsingMethod) => {
          onSuccess?.(values);
        },
        onError: (error) => {
          formikHelpers.setErrors(validationUtils.getFormErrors(error));
        },
        onSettled: () => formikHelpers.setSubmitting(false),
      });
    },
    [onSuccess, doSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Fragment>
          <Form>
            <FormControls>
              <FormikTextField
                label={t('details_form.fields.template')}
                name="template"
              />
              {values.type === ParsingMethodType.Technical && (
                <FormikTextField
                  label={t('details_form.fields.description')}
                  name="description"
                />
              )}
              <FormikSelect
                label={t('details_form.fields.type')}
                name="type"
                options={options}
              />
            </FormControls>
            <FormActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {tCommon('buttons.save')}
              </Button>
            </FormActions>
          </Form>
          <Divider sx={{ mt: 4 }} />
          <Paper sx={{ mt: 4 }}>
            <ParsingMethodsTestForm template={values.template} />
          </Paper>
        </Fragment>
      )}
    </Formik>
  );
};
