import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InviteDialog, PageHeader } from 'components';
import { TranslationNamespace } from 'i18n';

import { UserList } from './UserList';

export const UsersPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.users',
  });

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  const handleInviteDialogClose = useCallback(() => {
    setInviteDialogOpen(false);
  }, []);

  return (
    <div>
      <PageHeader
        title={t('title')}
        rightContent={
          <Button variant="outlined" onClick={() => setInviteDialogOpen(true)}>
            {t('buttons.invite')}
          </Button>
        }
      />
      <UserList />
      <InviteDialog open={inviteDialogOpen} onClose={handleInviteDialogClose} />
    </div>
  );
};
