import { map } from 'lodash';
import React from 'react';
import {
  LineChart as LineChartRecharts,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineProps,
  XAxisProps,
  YAxisProps,
} from 'recharts';

type Props = {
  height?: number | string;
  width?: number | string;
  xAxis?: XAxisProps;
  yAxis?: YAxisProps;
  lines: LineProps[];
  data: any[];
};

export const LineChart: React.FC<Props> = ({
  height = 300,
  width = '100%',
  xAxis,
  yAxis,
  lines,
  data,
}) => (
  <ResponsiveContainer width={width} height={height}>
    <LineChartRecharts
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" {...xAxis} />
      <YAxis {...yAxis} />
      <Tooltip />
      {map(lines, (line, i) => (
        <Line key={i} {...line} ref={null} />
      ))}
    </LineChartRecharts>
  </ResponsiveContainer>
);
