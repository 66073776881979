import React from 'react';

import { PayOutlet } from 'components';
import { ROUTE_PATH } from 'constants/routes';

export const AdminRequisitesPage: React.FC = () => (
  <PayOutlet
    payinTo={ROUTE_PATH.ADMIN.PAYIN_REQUISITES}
    payoutTo={ROUTE_PATH.ADMIN.PAYOUT_REQUISITES}
  />
);
