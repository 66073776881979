import { map } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CopyText,
  CrudTable,
  DataGridColumnDefinition,
  Money,
  TronscanLink,
  TronTransactionStatusLabel,
  dataGridColumns,
  CopyTextId,
  CrudTableActionType,
} from 'components';
import { CreateUserWalletWrapper } from 'features/wallets';
import { TranslationNamespace } from 'i18n';
import { InternalWalletWithdrawalTransaction, PaginatedData } from 'types';

import { WithdrawalTransactionDetailsModal } from '../WithdrawalTransactionDetailsModal';

type Props = {
  queryResult: UseQueryResult<
    PaginatedData<InternalWalletWithdrawalTransaction>
  >;
  withActions?: boolean;
};

export const WithdrawalTransactions: React.FC<Props> = ({
  queryResult,
  withActions,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets_transactions',
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalWalletWithdrawalTransaction>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.funds_request'),
        valueGetter: (item) => (
          <CopyTextId id={item.fundsRequestId} disableIconColor />
        ),
      },
      {
        header: t('fields.user_wallet'),
        valueGetter: (item) => (
          <div key={item.id}>
            <div>{item.fundsRequest?.user?.name}</div>
            <CreateUserWalletWrapper
              data={{
                userId: item.fundsRequest?.user?.id!,
                address: item.fundsRequest?.recipient,
                network: item.fundsRequest?.network,
              }}
            >
              <CopyText truncate text={item.fundsRequest?.recipient || ''} />
            </CreateUserWalletWrapper>
          </div>
        ),
      },
      {
        header: t('fields.transaction'),
        valueGetter: (item) => <TronscanLink hash={item.fundsRequest.hash} />,
      },
      {
        header: t('fields.amount'),
        valueGetter: (item) => (
          <Money
            value={item.fundsRequest?.amount}
            assetCurrencyId={item.fundsRequest?.asset?.assetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.platform_wallet'),
        valueGetter: (item) =>
          item.platformInternalWallet?.address && (
            <CopyText truncate text={item.platformInternalWallet.address} />
          ),
      },
      {
        header: t('fields.energy_requests'),
        valueGetter: (item) => (
          <Fragment>
            {map(item.energyRequests, (request, i) => (
              <div
                key={i}
                className={
                  request.status ? 'tw-text-green-700' : 'tw-text-red-500'
                }
              >
                {request.amount}
              </div>
            ))}
          </Fragment>
        ),
      },
      {
        header: t('fields.status'),
        valueGetter: (item) => (
          <TronTransactionStatusLabel status={item.status} />
        ),
      },
    ],
    [t],
  );

  return (
    <CrudTable
      columns={columns}
      queryResult={queryResult}
      paginated
      actions={[
        {
          type: CrudTableActionType.Details,
          renderDialog: WithdrawalTransactionDetailsModal,
        },
      ]}
      hideActions={!withActions}
    />
  );
};
