import {
  APP_STATE_STORAGE_KEY,
  DEFAULT_APP_STATE,
} from 'constants/app-state.constants';
import { AppState } from 'types';

import { useStorage } from './use-storage';

export const useAppState = () => {
  const { value, set, merge } = useStorage<AppState>(
    APP_STATE_STORAGE_KEY,
    DEFAULT_APP_STATE,
  );

  return {
    appState: value,
    setAppState: set,
    mergeAppState: merge,
  };
};
