import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  FilterDefinitionType,
  OrderStatus,
  OrdersTab,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

export const ActiveOrders: React.FC = () => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: tCommon('status'),
        name: 'status',
        type: FilterDefinitionType.OrderStatus,
        orderStatuses: [
          OrderStatus.New,
          OrderStatus.Requisites,
          OrderStatus.TraderConfirm,
          OrderStatus.CustomerConfirm,
        ],
        getDisplayName: (value: OrderStatus) =>
          orderUtils.getStatusLabel(value),
      },
    ],
    [tCommon],
  );

  return (
    <ManageOrdersList
      tab={OrdersTab.Active}
      additionalColumns={[
        AdditionalOrderColumn.StatusActive,
        AdditionalOrderColumn.TraderCard,
        AdditionalOrderColumn.OrderProcessedBy,
      ]}
      additionalFilters={additionalFilters}
    />
  );
};
