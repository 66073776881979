import { encode, decode } from 'js-base64';
import { isEmpty, omit, isNumber, pickBy, isEqual, isBoolean } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

export function useQueryData<T>(
  queryKey: string,
  initialData?: Partial<T>,
  options: { encode: boolean; plain?: boolean } = {
    encode: true,
  },
): {
  data: T;
  dataQuery: string; // base64 string with encoded data
  initialized: boolean;
  setData: (data: T, navigateOptions?: NavigateOptions) => void;
  setProperty: (key: keyof T, value: any) => void;
  reset: (navigateOptions?: NavigateOptions) => void;
  resetProperty: (name: string) => void;
} {
  const [data, setData] = useState(null as T);
  const [initialized, setInitialized] = useState(false);
  const [dataQuery, setDataQuery] = useState<string>(null as unknown as string);

  const location = useLocation();
  const navigate = useNavigate();

  const setLocalData = useCallback(
    (search: string) => {
      const _dataQuery = new URLSearchParams(search).get(queryKey) as string;
      const _dataQueryDecoded = _dataQuery
        ? options?.encode
          ? decode(_dataQuery)
          : _dataQuery
        : null;
      let _data = null;
      try {
        _data = _dataQueryDecoded
          ? options?.plain
            ? _dataQueryDecoded
            : JSON.parse(_dataQueryDecoded)
          : (null as T);
      } catch {}

      if (dataQuery !== _dataQuery) {
        setDataQuery(_dataQuery);
      }
      if (!isEqual(data, _data)) {
        setData(_data);
      }
      if (!initialized) {
        setInitialized(true);
      }
      return { data: _data };
    },
    [queryKey, options?.encode, options?.plain, dataQuery, data, initialized],
  );

  const handleSetData = useCallback(
    (values: T, navigateOptions?: NavigateOptions) => {
      const newSearch = new URLSearchParams(window.location.search);
      const currentSearch = new URLSearchParams(window.location.search);
      const filteredData = options?.plain
        ? values
        : pickBy(
            values as object,
            (value) => isBoolean(value) || isNumber(value) || !isEmpty(value),
          );
      if (!isEmpty(filteredData)) {
        let data = filteredData as string;
        if (!options?.plain) {
          data = JSON.stringify(data);
        }
        if (options?.encode) {
          data = encode(data);
        }
        newSearch.set(queryKey, data);
      } else {
        newSearch.delete(queryKey);
      }
      const search = newSearch.toString();
      if (search !== currentSearch.toString()) {
        navigate({ search }, navigateOptions);
      }
      setLocalData(search);
    },
    [navigate, setLocalData, queryKey, options],
  );

  const setProperty = useCallback(
    (key: keyof T, value: any) => {
      handleSetData({ [key]: value, ...data } as T);
    },
    [handleSetData, data],
  );

  const reset = useCallback(
    (navigateOptions?: NavigateOptions) => {
      handleSetData({} as T, navigateOptions);
    },
    [handleSetData],
  );

  const resetProperty = useCallback(
    (name: string) => {
      if (options?.plain) {
        // eslint-disable-next-line no-console
        return console.error('"resetProperty" is not supported for plain data');
      }
      handleSetData(omit(data as any as object, [name]) as T);
    },
    [data, handleSetData, options],
  );

  useEffect(() => {
    if (!initialized && initialData) {
      const { data: newData } = setLocalData(window.location.search);
      handleSetData({ ...initialData, ...newData }, { replace: true });
    } else {
      setLocalData(window.location.search);
    }
  }, [handleSetData, initialData, initialized, location, setLocalData]);

  return {
    data,
    dataQuery,
    setData: handleSetData,
    reset,
    resetProperty,
    setProperty,
    // used in case value should be analyzed (e.g. reset invalid value)
    // initially it's equal to null
    initialized,
  };
}
