import React from 'react';

import { PayOutlet } from 'components';
import { ROUTE_PATH } from 'constants/routes';

export const AdminOrdersPage: React.FC = () => (
  <PayOutlet
    payinTo={ROUTE_PATH.ADMIN.PAYIN_ORDERS}
    payoutTo={ROUTE_PATH.ADMIN.PAYOUT_ORDERS}
  />
);
