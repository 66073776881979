export enum AdditionalOrderColumn {
  Status = 'status',
  StatusActive = 'status_active',
  CustomerPayment = 'customer_payment',
  TraderPayment = 'trader_payment',
  ConfirmationDate = 'confirm_date',
  CustomerRequisites = 'customer_requisites',
  CustomerId = 'customer_id',
  Customer = 'customer',
  CustomerContacts = 'customer_contacts',
  OrderProcessedBy = 'order_processed_by',
  Trader = 'trader',
  P2PProvider = 'p2p_provider',
  Merchant = 'merchant',
  TraderCard = 'trader_card',
  Bank = 'bank',
  CancelReason = 'cancel_reason',
  Shop = 'shop',
  Dispute = 'dispute',
  ResolveDispute = 'resolve_dispute',
  CancelledToDispute = 'admin_cancelled_to_dispute',
  AutomationStatus = 'automation_status',
  Validation = 'validation',
}
