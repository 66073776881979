import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { payoutOrdersApi } from 'api';
import {
  ReceiptsModal as BaseReceiptsModal,
  CloseDialogResult,
  DialogProps,
} from 'components';
import { OrderType, QueryKey } from 'enums';
import { useUser } from 'hooks';
import { PayoutOrder } from 'types';

type Props = {
  order: PayoutOrder;
} & DialogProps;

export const ReceiptsModal: React.FC<Props> = ({ open, order, onClose }) => {
  const { role } = useUser();

  const queryKey = useMemo(
    () => [QueryKey.PayoutOrderReceipts, order?.id],
    [order],
  );
  const queryResultReceipts = useQuery(
    queryKey,
    () => payoutOrdersApi.getOrderReceiptsAsRole(role)(order.id),
    { enabled: open && !!order?.id },
  );

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      queryResultReceipts.remove();
      onClose(result);
    },
    [queryResultReceipts, onClose],
  );

  return (
    <BaseReceiptsModal
      open={open}
      orderId={order?.id}
      orderType={OrderType.Payout}
      queryResult={queryResultReceipts}
      onClose={handleClose}
    />
  );
};
