import { createContext } from 'react';

export type NotificationsContextProps = {
  activePayinOrders: number;
  activePayoutOrders: number;
};

export const NotificationsContext = createContext<NotificationsContextProps>(
  {} as NotificationsContextProps,
);
