import axios from 'axios';

import { env } from 'config';
import { P2PProviderPayinWebhook } from 'types';

const url = `${env.apiUrl}/p2p-providers-payin-webhooks`;

export const p2pProvidersPayinWebhooksApi = {
  getForOrder: async (
    providerOrderId: string,
  ): Promise<P2PProviderPayinWebhook[]> =>
    (await axios.get(`${url}/order/${providerOrderId}`)).data,
};
